import { appModule } from "@/store/modules/app.module"
import {notificationModule} from "@/store/modules/notification.module";
import runtimeProcessorModule from "@/store/modules/runtime-processor.module";
import { PAGES } from "@/types/router"
import { Route } from "vue-router"

export default async function afterEachRouteEnter(to: Route, from: Route) {

    const workoutActivePages = [ PAGES.WORKOUT_ACTIVE_PAGE, PAGES.MONTHLY_TRAINING_ACTIVE,
    PAGES.MSS_WORKOUT_ACTIVE_PAGE, PAGES.ACTIVE_VIRTUAL_CLASS, 
    PAGES.TIMERS_ACTIVE, PAGES.EGYM_TRAINING_ACTIVE ]

    if (appModule.readyToStart) {
        if (workoutActivePages.indexOf(from.name as PAGES) >= 0) {
            notificationModule.hideNotification()
            runtimeProcessorModule.changeActionTimeout(0)
            window.setTimeout(() => appModule.ping(), 1500)
            appModule.updateContent()
        }
        window.setTimeout(() => appModule.refreshContentWithInterval(), 5000)
    }
}