import { httpService } from '@/services/http.service'
import { MssWorkout, MssWorkoutPagination } from '@/types/workouts'
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules'
import store from '../index'
import {MADE_BY_EGYM_TRAINER} from "@/types/e-gym";

@Module
class MssWorkoutsInZoneModule extends VuexModule {
  
  private page = 0
  private readonly perPage = 4
  private workoutsEntries: MssWorkout[] = []

  get workouts() {
    return this.workoutsEntries
  }

  get egymTrainingPlans() { return this.workoutsEntries.filter((workout) => workout.made_by == MADE_BY_EGYM_TRAINER) }

  @Mutation
  private changePage(page: number) {
    this.page = page
  }

  @Mutation
  private setWorkouts(workouts: MssWorkout[]) {
    this.workoutsEntries = workouts.filter((workout, workoutIndex) => {
      const idx = workouts.findIndex(item => workout.id === item.id)
      return idx < 0 || idx == workoutIndex
    })
  }

  @Mutation
  private injectWorkout(workout: MssWorkout) {

    this.workoutsEntries.unshift(workout)
  }

  @Mutation
  removeWorkout(id: string) {
    
    const workoutIdx = this.workoutsEntries.findIndex(workout => workout.id === id)
    
    if (workoutIdx >= 0) {
      this.workoutsEntries.splice(workoutIdx, 1)
    }
  }

  @Action
  async getWorkouts() {
    
    if (this.page == 0) {
      this.changePage(1)
      let response = null
      try {
        response = await httpService.mssWorkoutsInZone(this.page, this.perPage) as MssWorkoutPagination
      } catch(err) {
        // eslint-disable-next-line
        console.error(err)
      }
      
      if (response != null && (!response?.cached || this.workouts.length < 1 || response?.total != this.workouts.length)) {
        let workouts: MssWorkout[] = []
        while (response.workouts.length > 0) {
          workouts = workouts.concat(response.workouts)
          if (response.per_page * response.current_page >= response.total) {
            break
          }
          this.changePage(this.page + 1)
          try {
            response = await httpService.mssWorkoutsInZone(this.page, this.perPage) as MssWorkoutPagination
          } catch(err) {
            // eslint-disable-next-line
            console.error(err)
            break
          }
        }
        this.setWorkouts(workouts)
      }
      this.changePage(0)
    }
  }

  @Action
  async updateWorkout(id: string) {
    let workout = null
    try {
      workout = await httpService.mssWorkoutInZoneById(id) as MssWorkout
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)

      if ((err as any).response && (err as any).response.status === 418) {
        this.removeWorkout(id)
      }
    }

    if (workout != null) {
      this.removeWorkout(id)
      this.injectWorkout(workout)
    }
    return id
  }

  @Action
  async append(workouts: MssWorkout[])
  {
    this.setWorkouts(this.workoutsEntries.concat(workouts));
  }

  @Action
  deleteEgymTrainings()
  {
    this.egymTrainingPlans.forEach(workout => this.removeWorkout(workout.id))
  }

  @Action
  async initialize() {
    await Promise.all([
      this.getWorkouts()
    ])
  }

  @Action
  resetToDefault() {
    this.changePage(0)
    this.setWorkouts([])
  }
}

export const mssWorkoutsInZoneModule = new MssWorkoutsInZoneModule({ store, name: 'mssWorkoutsInZone' })