import Vue from 'vue'
import Component from 'vue-class-component';
import { TimerBaseWorkout } from '@/types/timer';
import timeformat from "@/filters/timeformat"
import { Emit, Prop, VModel } from 'vue-property-decorator';

@Component({
    filters: {
        timeformat
    }
})
export default class TimerPreSetsComponent extends Vue {

    @Prop()
    preSets!: TimerBaseWorkout[]

    @VModel({default: null})
    activePreSet!: string | null

    @Emit()
    change() {
        return this.activePreSet
    }

    @Emit()
    start() {
        return this.activePreSet
    }

    get timerPreSets() {
        return this.preSets != undefined ? this.preSets : []
    }

    get isActive() {
        return ['primary-color-bg', 'primary-color-border']
    }

    selectPreSet(name: string) {
        this.activePreSet = (this.activePreSet === name ? null : name)
        this.change()
    }

    startTimer(){
        if (this.activePreSet) {
            this.start()
        }
    }
}