import { BaseWorkoutFilter } from "../workouts"
import { BaseFilter } from "../virtual-classes"
import { PAGES } from "../router"

  export enum DurationFiltersNames {
    SHORT = 'short',
    MEDIUM = 'medium',
    LONG = 'long'
  }
  
  export enum DurationFiltersRanges {
    SHORT_FROM = 0,
    SHORT_TO = 900,
    MEDIUM_FROM = 900,
    MEDIUM_TO = 1800,
    LONG_FROM = 1800,
    LONG_TO = 200000
  }
  
  export const DurationFilterShort = {
    id: 'SHORT',
    name: DurationFiltersNames.SHORT,
    from: DurationFiltersRanges.SHORT_FROM,
    to: DurationFiltersRanges.SHORT_TO,
    selected: false,
    available: false
  }
  
  export const DurationFilterMedium = {
    id: 'MEDIUM',
    name: DurationFiltersNames.MEDIUM,
    from: DurationFiltersRanges.MEDIUM_FROM,
    to: DurationFiltersRanges.MEDIUM_TO,
    selected: false,
    available: false
  }
  
  export const DurationFilterLong = {
    id: 'LONG',
    name: DurationFiltersNames.LONG,
    from: DurationFiltersRanges.LONG_FROM,
    to: DurationFiltersRanges.LONG_TO,
    selected: false,
    available: false
  }

  interface GeneralFilterConfig {
    type: FilterType;
    isActive: boolean;
    Page: PAGES;
  }

  export interface UpdateFilterConfig extends GeneralFilterConfig {
    filter: BaseFilter;
  }

  export interface UpdateWorkoutFilterConfig extends GeneralFilterConfig {
    filter: BaseWorkoutFilter;
  }

  export enum FilterType {
    DURATION = 'duration',
    CATEGORY = 'category',
    TYPE = 'type',
    EQUIPMENT = 'equipment',
    INTENSITY = 'intensity',
    TRAINER = 'trainer',
    FITNESS_CATEGORY = 'fitness_category',
    FITNESS_TYPE = 'fitness_type'
  }