import workoutService from '@/services/workout.service'
import { BaseAdditionalActivity, BaseWorkout, ComplexWorkout, WorkoutTypes, WorkoutIntensities, MssPreviewSlideData, MssWorkout, MssWorkoutTypeNames, MssWorkoutType } from '@/types/workouts'
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules'
import store from '../index'
import { mssWorkoutService } from '@/services/mss-workout.service'
import { unique } from '@/utils/array.utils'

@Module
class MssWorkoutDescriptionModule extends VuexModule {

    slidesArray: MssPreviewSlideData[] = []

    workout: MssWorkout | null = null

    previewMode = false

    get workoutPreviewSlidesData() {
        return this.slidesArray
    }

    get workoutDescription() {
        return this.workout?.description
    }

    get tags(): string[] {
        const level = this.workout ? WorkoutIntensities.get(this.workout.difficulty) as string : ''
        const trainer = this.workout?.creatorName as string
        const duration = this.workout ? workoutService.getWorkoutDuration(this.workout) : 0
        return unique([ duration, level, trainer].flat().filter(tag => tag) as string[])
    }

    get fitnessTypeTag(): any {
        return this.workout?.circuit_mss ? MssWorkoutTypeNames.get(MssWorkoutType.MSS_CIRCUIT) : MssWorkoutTypeNames.get(MssWorkoutType.MSS_BOUTIQUE)
    }

    get equipmentTags(): string[] {
        return this.workout ? this.workout.gearList : []
    }

    get durationFormat(): string {
        return this.workout ? [ Math.floor(this.workout?.totalTime / 60).toString(), 'min' ].join(' ') : ''
    }

    get coverImageUrl(): string | null {

        if (this.workout && this.workout.coverImage) {
            const { small } = this.workout.coverImage
            return !small ? null : small
        }

        return null
    }

    @Mutation
    parse(workout: MssWorkout) {

        this.workout = workout

        this.slidesArray = []

        // eslint-disable-next-line
        const { warming_ups, cooling_downs, ...theWorkoutItself } = workout

        // eslint-disable-next-line
        const workoutPartsArray = (warming_ups as (BaseAdditionalActivity | ComplexWorkout)[])
            .concat(([theWorkoutItself] as MssWorkout[]), cooling_downs) as (BaseAdditionalActivity | ComplexWorkout)[]

        for (const partIdx in workoutPartsArray) {

            // eslint-disable-next-line
            if ((workoutPartsArray[partIdx] as BaseAdditionalActivity).activity_type) {

                // eslint-disable-next-line
                const { name, activity_type, type, duration, timer, random } = workoutPartsArray[partIdx] as BaseAdditionalActivity

                const exercises = workoutService.parseExercises(workoutPartsArray[partIdx])

                // eslint-disable-next-line
                this.slidesArray.push({ name, activity_type, type, duration, exercises, timer, random })

            } else if ((workoutPartsArray[partIdx] as BaseWorkout).type == WorkoutTypes.CIRCUIT) {

                const mssWorkoutPreviewSlides = mssWorkoutService.getMssWorkoutPreviewSlides(workoutPartsArray[partIdx] as MssWorkout)

                if (mssWorkoutPreviewSlides.length) {
                    this.slidesArray = this.slidesArray.concat(mssWorkoutPreviewSlides)
                }
            }
        }
    }

    @Mutation
    updatePrviewMode(previewMode: boolean) {
        this.previewMode = previewMode
    }

    @Action
    init(workout: MssWorkout) {
        this.parse(workout)
    }

    @Action
    togglePreviewMode() {
        this.updatePrviewMode(!this.previewMode)
    }
}

export const mssWorkoutDescriptionModule = new MssWorkoutDescriptionModule({ store, name: 'mssWorkoutDescriptionModule' })