import Vue from 'vue';
import axios, {AxiosError, AxiosRequestConfig} from 'axios';
import VueAxios from 'vue-axios';
import {settings} from '../settings/settings';
import {authModule} from '@/store/modules/auth.module';
import {AuthResponse, Device, DeviceConfigResponse, ScheduleResponse, ThemeInfo} from '@/types/http';
import {VirtualClassApiConfig} from '@/types/virtual-classes';
import {BaseWorkout, MonthlyTrainingCircuit, FunxtionFitnessCategory, FunxtionFitnessType, MssWorkout, Equipment, MssWorkoutPagination, BaseWorkoutPagination, NotificationPayloadValue} from '@/types/workouts';
import {MssWorkoutSchedule, RegularWorkoutSchedule, Schedule, MonthlyTrainingSchedule} from '@/types/schedule';
import {TimerBaseWorkout} from '@/types/timer';
import {EGymAuthResponse, EGymUserResponse, EGymWorkoutData} from "@/types/e-gym";

Vue.use(VueAxios, axios);

class HttpService
{
  private getUserAuthorizationHeader(): AxiosRequestConfig
  {
    return {
      headers: {
        'Authorization': `Bearer ${authModule.userToken?.value}`
      }
    };
  }

  private getDeviceAuthorizationHeader(): AxiosRequestConfig
  {
    return {
      headers: {
        'Authorization': `Bearer ${authModule.deviceToken?.value}`
      }
    };
  }

  authUser(username: string, password: string)
  {
    return Vue.axios.post<AuthResponse>(settings.url.authUser(), {
      username: username,
      password: password
    })
      .then(axiosResp => axiosResp.data.token)
      .catch((error: AxiosError) =>
      {
        return error;
      });
  }

  async authDevice(deviceId: string)
  {
    return Vue.axios.post<AuthResponse>(settings.url.authDevice(), {
      deviceId
    })
      .then(axiosResp => axiosResp.data.token)
      .catch((error: AxiosError) =>
      {
        return error;
      });
  }

  async userTokenRefresh()
  {
    return Vue.axios.get<AuthResponse>(settings.url.userTokenRefresh(), this.getUserAuthorizationHeader())
      .then(axiosResp => axiosResp.data.token)
      .catch((error: AxiosError) =>
      {
        return error;
      });
  }

  async deviceTokenRefresh()
  {
    return Vue.axios.get<AuthResponse>(settings.url.deviceTokenRefresh(), this.getDeviceAuthorizationHeader())
      .then(axiosResp => axiosResp.data.token)
      .catch((error: AxiosError) =>
      {
        return error;
      });
  }

  async devices()
  {
    return Vue.axios.get<Device[]>(settings.url.devices(), this.getUserAuthorizationHeader())
      .then(axiosResp => axiosResp.data)
      .catch(() =>
      {
        return [];
      });
  }

  async deviceConfig()
  {
    return Vue.axios.get<DeviceConfigResponse>(settings.url.deviceConfig(), this.getDeviceAuthorizationHeader())
      .then(axiosResp => axiosResp.data)
      .catch(() =>
      {
        return {} as DeviceConfigResponse;
      });
  }

  async virtualClasses()
  {
    return Vue.axios.get<VirtualClassApiConfig[]>(settings.url.virtualClasses(), this.getDeviceAuthorizationHeader())
      .then(axiosResp => axiosResp.data)
      .catch(() =>
      {
        return [];
      });
  }

  async virtualClassesStates()
  {
    return Vue.axios.get<{[key: string]: number;}>(settings.url.virtualClassesStates(), this.getDeviceAuthorizationHeader())
      .then(axiosResp => axiosResp.data);
  }

  async regularWorkouts(page = -1, perPage = 0)
  {
    let url = settings.url.regularWorkouts();
    if (page + perPage > 0) {
      url += '?' + new URLSearchParams({page: page.toString(), perPage: perPage.toString()}).toString();
    }
    return Vue.axios.get<BaseWorkout[] | BaseWorkoutPagination>(url, this.getDeviceAuthorizationHeader())
      .then(axiosResp => axiosResp.data);
  }

  async mssWorkouts(page = -1, perPage = 0)
  {
    let url = settings.url.mssWorkouts();
    if (page + perPage > 0) {
      url += '?' + new URLSearchParams({page: page.toString(), perPage: perPage.toString()}).toString();
    }

    return Vue.axios.get<MssWorkout[] | MssWorkoutPagination>(url, this.getDeviceAuthorizationHeader())
      .then(axiosResp => axiosResp.data);
  }

  mssWorkoutById(id: string)
  {
    return Vue.axios.get<MssWorkout>(settings.url.mssWorkoutById(id), this.getDeviceAuthorizationHeader())
      .then(axiosResp => axiosResp.data);
  }

  async mssWorkoutsInZone(page = -1, perPage = 0)
  {
    let url = settings.url.mssWorkoutsInZone();
    if (page + perPage > 0) {
      url += '?' + new URLSearchParams({page: page.toString(), perPage: perPage.toString()}).toString();
    }
    return Vue.axios.get<MssWorkout[] | MssWorkoutPagination>(url, this.getDeviceAuthorizationHeader())
      .then(axiosResp => axiosResp.data);
  }

  mssWorkoutInZoneById(id: string)
  {
    return Vue.axios.get<MssWorkout>(settings.url.mssWorkoutInZoneById(id), this.getDeviceAuthorizationHeader())
      .then(axiosResp => axiosResp.data);
  }

  async monthlyTrainings()
  {
    return Vue.axios.get<MonthlyTrainingCircuit[]>(settings.url.monthlyTrainings(), this.getDeviceAuthorizationHeader())
      .then(axiosResp => axiosResp.data)
      .catch(() =>
      {
        return [];
      });
  }

  async scheduledVirtualClasses()
  {
    return Vue.axios.get<Schedule[]>(settings.url.scheduledVirtualClasses(), this.getDeviceAuthorizationHeader())
      .then(axiosResp => axiosResp.data);
  }

  async scheduledRegularWorkouts()
  {
    return Vue.axios.get<RegularWorkoutSchedule[]>(settings.url.scheduledRegularWorkouts(), this.getDeviceAuthorizationHeader())
      .then(axiosResp => axiosResp.data)
      .catch(() =>
      {
        return [];
      });
  }

  async scheduledMssWorkouts()
  {
    return Vue.axios.get<MssWorkoutSchedule[]>(settings.url.scheduledMssWorkouts(), this.getDeviceAuthorizationHeader())
      .then(axiosResp => axiosResp.data)
      .catch(() =>
      {
        return [];
      });
  }

  async scheduledMonthlyTrainings()
  {
    return Vue.axios.get<MonthlyTrainingSchedule[]>(settings.url.scheduledMonthlyTrainings(), this.getDeviceAuthorizationHeader())
      .then(axiosResp => axiosResp.data)
      .catch(() =>
      {
        return [];
      });
  }

  async workoutFitnessCategories()
  {
    return Vue.axios.get<FunxtionFitnessCategory[]>(settings.url.workoutFitnessCategories())
      .then(axiosResp => axiosResp.data)
      .catch(() =>
      {
        return [];
      });
  }

  async workoutFitnessTypes()
  {
    return Vue.axios.get<FunxtionFitnessType[]>(settings.url.workoutFitnessTypes())
      .then(axiosResp => axiosResp.data)
      .catch(() =>
      {
        return [];
      });
  }

  async workoutEquipments()
  {
    return Vue.axios.get<Equipment[]>(settings.url.workoutEquipments())
      .then(axiosResp => axiosResp.data)
      .catch(() =>
      {
        return [];
      });
  }

  workoutById(id: string)
  {
    return Vue.axios.get<BaseWorkout>(settings.url.workoutById(id), this.getDeviceAuthorizationHeader())
      .then(axiosResp => axiosResp.data);
  }

  egymAuth(rfid: string)
  {
    return Vue.axios.post<EGymUserResponse>(settings.url.eGymAuth(), {rfid}, this.getDeviceAuthorizationHeader())
      .then(axiosResp => axiosResp.data)
      .catch(() => false);
  }

  egymReportSend(payload: EGymWorkoutData)
  {
    return Vue.axios.post<EGymUserResponse>(settings.url.eGymReport(), payload, this.getDeviceAuthorizationHeader())
      .then(axiosResp => axiosResp.data);
  }

  egymTrainingPlans(type: string, rfid: string)
  {
    return Vue.axios.get<BaseWorkoutPagination>(
      settings.url.eGymTrainingPlans(type) + '?' + (new URLSearchParams({rfid})).toString(),
      this.getDeviceAuthorizationHeader()).then(axiosResp => axiosResp.data);
  }

  async virtualClassEquipments()
  {
    return Vue.axios.get<Equipment[]>(settings.url.virtualClassEquipments())
      .then(axiosResp => axiosResp.data)
      .catch(() =>
      {
        return [];
      });
  }

  async styles()
  {
    return Vue.axios.get<ThemeInfo>(settings.url.styles(), this.getDeviceAuthorizationHeader())
      .then(axiosResp => axiosResp.data)
      .catch(() =>
      {
        return {};
      });
  }

  notificationDelivered(payload: NotificationPayloadValue): Promise<any>
  {
    return Vue.axios.post<string[]>(settings.url.notificationDelivered(), payload, this.getDeviceAuthorizationHeader())
      .then(axiosResp => axiosResp.data);
  }

  timerPresets()
  {
    return Vue.axios.get<{timers: TimerBaseWorkout[];}>(settings.url.timerPresets(), this.getDeviceAuthorizationHeader())
      .then(axiosResp => axiosResp.data);
  }
}

export const httpService = new HttpService();