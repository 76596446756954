import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules'
import store from '../index'
import { httpService } from '../../services/http.service'
import { AxiosError } from 'axios'
import { Credentials, Token } from '@/types/http'
import { authService } from '@/services/auth.service'
import { notificationModule } from './notification.module'
import { NotificationCommand, NotificationType } from '@/types/notification'

const enum AUTH_ERROR {
  TOKEN_EXPIRED = 1001,
  TOKEN_REFRESH_FAILED = 1002,
  TOKEN_NOT_FOUND = 1003,
}

const TOKEN_STORAGE_VARIABLE_NAME = 'deviceToken'

class AuthException {

  public msg: string
  public code: AUTH_ERROR

  constructor(msg: string, code: AUTH_ERROR) {

    this.msg = msg
    this.code = code
  }
}

@Module
class AuthModule extends VuexModule {
  private _userToken: Token | undefined = {} as Token
  private _deviceToken: Token | undefined = {} as Token

  get userToken(): Token | undefined {
    return this._userToken
  }

  get deviceToken(): Token | undefined {
    return this._deviceToken
  }

  @Mutation
  private setUserToken(token?: Token | undefined) {
    this._userToken = token

    if (this._userToken) {
      window.localStorage.setItem('userToken', JSON.stringify(this._userToken))
    } else {
      window.localStorage.removeItem('userToken')
    }
  }

  @Mutation
  private setDeviceToken(token?: Token | undefined) {
    this._deviceToken = token

    if (this._deviceToken) {
      window.localStorage.setItem(TOKEN_STORAGE_VARIABLE_NAME, JSON.stringify(this._deviceToken))
    } else {
      window.localStorage.removeItem(TOKEN_STORAGE_VARIABLE_NAME)
    }
  }

  @Action
  async authUser(creds: Credentials) {
    const response: Token | AxiosError = await httpService.authUser(creds.email, creds.password)
    
    if (!(response instanceof Error)) {
      this.setUserToken(response)
    } else {
      throw response
    }
  }

  @Action
  async authDevice(deviceId: string) {
    const resp: Token | AxiosError = await httpService.authDevice(deviceId)
    if (resp instanceof Error) {
      notificationModule.showNotification({ command: NotificationCommand.SOMETHING_WENT_WRONG, type: NotificationType.ALERT })
    } else {
      this.setDeviceToken(resp)
      this.setUserToken()
    }
  }

  @Action
  async userTokenRefresh() {
    const resp: Token | AxiosError = await httpService.userTokenRefresh()
    if (!(resp instanceof Error)) {
      this.setUserToken(resp)
    } else {
      throw new AuthException('App can\'t refresh user token', AUTH_ERROR.TOKEN_REFRESH_FAILED)
    }
  }

  @Action
  userTokenValidityCheck() {
    if (this.userToken && !authService.isTokenValid(this.userToken)) {
      this.userTokenRefresh()
    }
  }

  @Action
  async deviceTokenValidityCheck() {
    if (!this.deviceToken || Object.keys(this.deviceToken).length < 1) {
      this.restoreTokenFromStorage()
    }
    if (this.deviceToken && !authService.isTokenValid(this.deviceToken)) {
      this.refreshToken()
    }
  }

  @Action
  async refreshToken() {
    const resp: Token | AxiosError = await httpService.deviceTokenRefresh();
    if (!(resp instanceof Error)) {
      this.setDeviceToken(resp);
    } else {
      throw new AuthException('App can\'t refresh the token', AUTH_ERROR.TOKEN_REFRESH_FAILED);
    }
  }

  @Action
  restoreTokenFromStorage() {
    const stringifiedToken = localStorage.getItem(TOKEN_STORAGE_VARIABLE_NAME)
    const savedToken = stringifiedToken ? JSON.parse(stringifiedToken) : null

    if (savedToken) {
      this.setDeviceToken(savedToken as Token)
    } else {
      throw new AuthException('Token can\'t be restored from the storage', AUTH_ERROR.TOKEN_NOT_FOUND)
    }
  }

  @Action
  logout() {
    this.setDeviceToken()
    this.setUserToken()
  }
}

export const authModule = new AuthModule({ store, name: 'auth' })