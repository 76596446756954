import Vue from 'vue'
import timeformat from '@/filters/timeformat'
import timeformat12h from '@/filters/timeformat12h'
import truncate from '@/filters/truncate'
import { schedulesAggregatorModule } from '@/store/modules/schedules-aggregator.module'
import { ScheduleWithTime } from '@/types/schedule'
import { Component, Prop, Watch } from 'vue-property-decorator'
import ScrollContainerComponent from '../scroll-container/scroll-container.component.vue'

@Component({
  components: {
    'scroll-container-component': ScrollContainerComponent,
  },
  filters: {
    timeformat,
    timeformat12h,
    truncate
  }
})
export default class SchedulesListComponent extends Vue {

  @Prop() schedules!: ScheduleWithTime[]
  forWatch: ScheduleWithTime[] = []
  timeToNextTraining = 0
  notificationInterval: number | null = null
  IntervalTimer = 100
  trainingsOfTheDayWatcher!: CallableFunction

  get notificationMinutes() {
    return 30
  }

  get progressBarWidthPercentage() {
    if (this.timeToNextTraining) {
      return (this.timeToNextTraining * 100 / (this.notificationMinutes * 60 * 1000)).toFixed(5)
    }
  }

  get notificationShouldBeShown() {
    return this.timeToNextTraining <= this.notificationMinutes * 60 * 1000
  }

  get schedulesExist() {
    return this.schedules.length > 0
  }

  updated() {
    this.forWatch = this.schedules
  }

  created() {
    this.runNotificationInterval()
  }

  updateTimeToNextTraining() {

    if (this.schedules.length > 0) {
      this.timeToNextTraining = this.schedules[0].time - Date.now()
    } else {
      this.timeToNextTraining = 0
    }
  }

  @Watch('schedules')
  runNotificationInterval() {

    if (this.schedulesExist) {
      this.clearNotificationInterval()
      this.updateTimeToNextTraining()
      this.notificationInterval = window.setInterval(() => {
        this.updateTimeToNextTraining()
      }, this.IntervalTimer)
    }
  }

  clearNotificationInterval() {
    if (this.notificationInterval) {
      clearInterval(this.notificationInterval)
      this.notificationInterval = null
    }
  }

  beforeDestroy() {
    if (this.trainingsOfTheDayWatcher) {
      this.trainingsOfTheDayWatcher()
    }
    this.clearNotificationInterval()
  }
}