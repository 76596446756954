import { PreviewSlideData, WorkoutTypes } from '@/types/workouts'
import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'
import ZonePreviewComponent from '../zone-preview/zone-preview.component.vue'

@Component({
    components: {
        'zone-preview-component': ZonePreviewComponent
    }
})
export default class RoundPreviewComponent extends Vue {
    @Prop({ type: Array, default: []}) slides!: PreviewSlideData[]
    @Prop({ type: Number, default: 1}) index!: number
    @Prop({ type: Boolean, default: true}) collapsed!: boolean

    public activeRoundIndex = 0

    get rounds() {
        if (this.slides[0].rounds) {
            return this.slides[0].rounds
        } else {
            return 0
        }
    }

    get activeClassRound() {
        return ['active-round', 'primary-color-text']
    }

    get roundName() {
        if (!this.slides[0].timer) {
            return this.slides[0].type
        } else {
            return this.$t('workouts.timer')
        }
    }

    get accordionId() {
        return (this.slides[0].activity_type != undefined ? this.slides[0].activity_type : this.slides[0].type) + this.index
    }

    get round() {
        const roundsData: PreviewSlideData[] = []
        if (this.rounds >= 1 && this.slides[0].type != WorkoutTypes.RFT) {

            this.slides.forEach((slide: PreviewSlideData) => {

                if(slide.round == this.activeRoundIndex && slide.zone == undefined) {
                    roundsData.push(slide)
                } else if (slide.round == this.activeRoundIndex + 1) {
                    roundsData.push(slide)
                }
            })
            return roundsData
        }
        return this.slides
    }

    get zoneNumbers() {
        return this.slides.map(slide => slide.zone).filter((zoneNumber, idx, zonesList) => {
            return zoneNumber != undefined && zonesList.indexOf(zoneNumber) === idx
        })
    }

    mounted() {
        this.activeRoundIndex = this.index
    }

    renderRound(activeRoundIndex: number) {
        this.activeRoundIndex = activeRoundIndex
        this.onSlideChange()
    }

    @Emit()
    onSlideChange() {
        return this.activeRoundIndex
    }
}