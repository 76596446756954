import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { Location } from 'vue-router'
import { MonthlyTrainingCircuit } from '@/types/workouts'
import { PAGES } from '@/types/router'
import { LocaleMessages } from 'vue-i18n'
import InfoTagComponent from '../info-tag/info-tag.component.vue'
import monthlyTrainingService from '@/services/monthly-training.service'

@Component({
  components: {
    'info-tag-component': InfoTagComponent
  }
})
export default class MonthlyTrainingTileComponent extends Vue {
  @Prop() workoutEntity!: MonthlyTrainingCircuit
  private descriptionLocation!: Location

  get coverImageUrl(): string | null {

    if (this.workoutEntity && this.workoutEntity.coverImage) {
      const { small } = this.workoutEntity.coverImage 
      return !small ? null : small
    }
    return null
  }

  get tags() {
    return [
      this.workoutEntity?.type,
      this.level,
      monthlyTrainingService.getMonthlyTrainingDuration(this.workoutEntity)
    ].filter(tag => tag)
  }

  get charactersInTags() {
    switch(this.tags.length) {
      case 1: { return 25 }
      case 2: { return 15 }
      case 3: { return 12 }
    }
  }

  get level(): LocaleMessages {

    const difficulty = parseInt(this.workoutEntity?.difficulty)

    if (difficulty < 2) {
      return this.$t('common.beginner') as LocaleMessages
    } else if (difficulty < 4){
      return this.$t('common.intermediate') as LocaleMessages
    }
    return this.$t('common.advanced') as LocaleMessages
  }

  get workoutName() {
    return this.workoutEntity.name
  }

  goTo() {
    this.$router.push(this.descriptionLocation)
  }

  created() {
    this.descriptionLocation = { 
      name: PAGES.MONTHLY_TRAINING_DESCRIPTION,
      params: { id: this.workoutEntity.id } }
  }
}