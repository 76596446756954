import { ColorNames } from '@/types/custom-colors'
import { WhiteLabelElementName, WhiteLabelStyleRule } from '@/types/white-label'

export const whiteLabelStyleRulesList: WhiteLabelStyleRule[] = [
  {
    selector: '.big-logo',
    style: 'background-image: url(:bgImg); background-color: #:bgColor;',
    elementName: WhiteLabelElementName.BIG_LOGO
  },
  {
    selector: '.small-logo',
    style: 'background-image: url(:bgImg); background-color: #:bgColor; width: :width;',
    elementName: WhiteLabelElementName.SMALL_LOGO
  },
  {
    selector: '.screensaver',
    style: 'background-image: url(:bgImg);',
    elementName: WhiteLabelElementName.SCREENSAVER
  },
  {
    selector: '.background-overlay::before',
    style: 'background: :overlay;',
    elementName: WhiteLabelElementName.BACKGROUND_OVERLAY
  },
  {
    selector: '.swiper-header',
    style: 'color: #:color; font-family: :fontName; font-style: :fontStyle;',
    elementName: WhiteLabelElementName.SWIPER_HEADER
  },
  {
    selector: '.active-timer',
    style: 'color: #:color; font-family: :fontName; font-size: :fontSize; font-style: :fontStyle;',
    elementName: WhiteLabelElementName.ACTIVE_TIMER
  },
  {
    selector: '.loader-dots::after',
    style: 'background: radial-gradient(circle closest-side, :color 90%, :color0) 0/calc(100%/3) 100% space;',
    elementName: WhiteLabelElementName.LOADER_DOTS
  },
  {
    selector: '.timer-btn',
    style: 'color: #:color; font-size: :fontSize;',
    elementName: WhiteLabelElementName.TIMER_BTN
  },
  {
    selector: '.timer-btn-text',
    style: 'color: #:color; font-size: :fontSize; font-family: :fontName; font-style: :fontStyle;',
    elementName: WhiteLabelElementName.TIMER_BTN_TEXT
  },
  {
    selector: '.swiper-link',
    style: 'color: #:color; font-family: :fontName; font-size: :fontSize;',
    elementName: WhiteLabelElementName.SWIPER_LINK
  },
  {
    selector: '.schedule-board-header',
    style: 'font-family: :fontName;',
    elementName: WhiteLabelElementName.SCHEDULE_BOARD_HEADER
  },
  {
    selector: '.list-header',
    style: 'background-color: #:bgColor; color: #:color; font-family: :fontName; font-style: :fontStyle; margin-left: :marginLeft;',
    elementName: WhiteLabelElementName.LIST_HEADER
  },
  {
    selector: '.search-btn',
    style: 'color: #:color; font-family: :fontName; border-color: #:borderColor;',
    elementName: WhiteLabelElementName.SEARCH_BTN
  },
  {
    selector: '.list-background',
    style: 'background-color: #:bgColor;',
    elementName: WhiteLabelElementName.BACKGROUND_COLOR
  },
  {
    selector: '.tile-title',
    style: 'color: #:color; font-family: :fontName;',
    elementName: WhiteLabelElementName.TILE_TITLE
  },
  {
    selector: '.tile-info',
    style: 'color: #:color; font-family: :fontName; opacity: :opacity;',
    elementName: WhiteLabelElementName.TILE_INFO
  },
  {
    selector: '.image-part',
    style: 'background-image: url(:bgImg);',
    elementName: WhiteLabelElementName.TILE_IMAGE
  },
  {
    selector: '.workout-title',
    style: 'color: #:color; font-family: :fontName;',
    elementName: WhiteLabelElementName.WORKOUT_TITLE
  },
  {
    selector: '.workout-description-title',
    style: 'color: #:color; font-family: :fontName;',
    elementName: WhiteLabelElementName.WORKOUT_DESCRIPTION_TITLE
  },
  {
    selector: '.workout-description-detail',
    style: 'color: #:color; font-family: :fontName;',
    elementName: WhiteLabelElementName.WORKOUT_DESCRIPTION_DETAIL
  },
  {
    selector: '.slide-exercise-name',
    style: 'color: #:color; font-family: :fontName;',
    elementName: WhiteLabelElementName.SLIDE_EXERCISE_NAME
  },
  {
    selector: '.info-tag-color',
    style: 'border-color: #:color;',
    elementName: WhiteLabelElementName.INFO_TAG_COLOR
  },
  {
    selector: '.back-btn',
    style: 'color: #:color; font-family: :fontName;',
    elementName: WhiteLabelElementName.BACK_BTN
  },
  {
    selector: '.schedule-next-training',
    style: 'color: #:color; font-family: :fontName;',
    elementName: WhiteLabelElementName.SCHEDULE_NEXT_TRAINING
  },
  {
    selector: '.schedule-progressbar',
    style: 'background-color: #:bgColor;',
    elementName: WhiteLabelElementName.SCHEDULE_PROGRESSBAR
  },
  {
    selector: '.warning-message-title',
    style: 'color: #:color; font-family: :fontName;',
    elementName: WhiteLabelElementName.WARNING_MESSAGE_TITLE
  },
  {
    selector: '.warning-message-yes',
    style: 'font-family: :fontName;',
    elementName: WhiteLabelElementName.WARNING_MESSAGE_YES
  },
  {
    selector: '.warning-message-no',
    style: 'border-color: #:borderColor; font-family: :fontName;',
    elementName: WhiteLabelElementName.WARNING_MESSAGE_NO
  },
  {
    selector: '.filter-title',
    style: 'font-family: :fontName;',
    elementName: WhiteLabelElementName.FILTER_TITLE
  },
  {
    selector: '.menu-title',
    style: 'font-family: :fontName;',
    elementName: WhiteLabelElementName.MENU_TITLE
  },
  {
    selector: '.image-tag',
    style: 'font-family: :fontName;',
    elementName: WhiteLabelElementName.IMAGE_TAG
  },
  {
    selector: '.menu-width',
    style: 'width: :width;',
    elementName: WhiteLabelElementName.MENU_WIDTH
  },
  {
    selector: '.back-btn-margin',
    style: 'margin-left: :marginLeft;',
    elementName: WhiteLabelElementName.BACK_BTN_MARGIN
  },
  {
    selector: '.logo-description-page-width',
    style: 'width: :width;',
    elementName: WhiteLabelElementName.LOGO_DESCRIPTION_PAGE_WIDTH
  },
  {
    selector: '.menu-color',
    style: 'background-color: #:bgColor;',
    elementName: WhiteLabelElementName.MENU_BACKGROUND_COLOR
  },
  {
    selector: '.menu-text-color',
    style: 'color: #:textColor;',
    elementName: WhiteLabelElementName.MENU_TEXT_COLOR
  },
  {
    selector: '.training-dropdown',
    style: 'background-color: #:bgColor; border-color: #:borderColor;',
    elementName: WhiteLabelElementName.TRAINING_DROPDOWN
  }
]

// TO DO: Custom Colors Are Deprecated / Need to be Replaced to white-label styles
export const deprecatedCustomColorsRulesList: WhiteLabelStyleRule[] = [
  {
    selector: '.primary-color-text',
    style: `color: #:${ColorNames.PRIMARY};`,
    elementName: null
  },
  {
    selector: '.primary-color-bg',
    style: `background-color: #:${ColorNames.PRIMARY};`,
    elementName: null
  },
  {
    selector: '.primary-color-border',
    style: `border-color: #:${ColorNames.PRIMARY};`,
    elementName: null
  },
  {
    selector: '.primary-color-shadow',
    style: `box-shadow: 0px 0px 2px #:${ColorNames.PRIMARY};`,
    elementName: null
  },
  // {
  //   selector: '.secondary-color-bg',
  //   style: `background-color: #:${ColorNames.SECONDARY};`,
  //   elementName: null
  // },
  // {
  //   selector: '.secondary-color-border',
  //   style: `border-color: #:${ColorNames.SECONDARY};`,
  //   elementName: null
  // },
  {
    selector: '.basic-white-color-bg',
    style: `background-color: #:${ColorNames.BASIC_WHITE};`,
    elementName: null
  },
  {
    selector: '.basic-white-color-text',
    style: `color: #:${ColorNames.BASIC_WHITE};`,
    elementName: null
  },
  {
    selector: '.bottom-big-gradient.basic-white-color-gradient::before',
    style: `background: linear-gradient(180deg, rgba(0, 0, 0, 0) 18%, #:${ColorNames.BASIC_WHITE} 55%);`,
    elementName: null
  },
  {
    selector: '.right-small-gradient.basic-white-color-gradient::after',
    style: `background: linear-gradient(90deg, rgba(0,0,0,0) 0%, #:${ColorNames.BASIC_WHITE} 100%);`,
    elementName: null
  },
  {
    selector: '.bottom-small-gradient.basic-white-color-gradient::after',
    style: `background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #:${ColorNames.BASIC_WHITE} 90%);`,
    elementName: null
  },
  {
    selector: '.left-big-gradient.basic-white-color-gradient::before',
    style: `background: linear-gradient(270deg, rgba(0, 0, 0, 0) 35%, #:${ColorNames.BASIC_WHITE} 70%);`,
    elementName: null
  },
  {
    selector: '.left-bottom-big-gradient.basic-white-color-gradient::before',
    style: `background: linear-gradient(270deg, rgba(0, 0, 0, 0) 35%, #:${ColorNames.BASIC_WHITE} 70%), linear-gradient(180deg, rgba(0, 0, 0, 0) 20%, #:${ColorNames.BASIC_WHITE} 60%);`,
    elementName: null
  },
  {
    selector: '.bottom-large-gradient.basic-white-color-gradient::before',
    style: `background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #:${ColorNames.BASIC_WHITE} 45%);`,
    elementName: null
  },
  {
    selector: '.basic-white-color-text-hover:hover',
    style: `color: #:${ColorNames.BASIC_WHITE};`,
    elementName: null
  },
  {
    selector: '.fontA-color-bg',
    style: `background-color: #:${ColorNames.FONT_A};`,
    elementName: null
  },
  {
    selector: '.fontA-color-text',
    style: `color: #:${ColorNames.FONT_A};`,
    elementName: null
  },
  {
    selector: '.basic-anthracite-color-text',
    style: `color: #:${ColorNames.BASIC_ANTHRACITE};`,
    elementName: null
  },
  {
    selector: '.basic-anthracite-color-border',
    style: `border-color:#:${ColorNames.BASIC_ANTHRACITE};`,
    elementName: null
  },
  {
    selector: '.background-color-bg',
    style: `background-color:#:${ColorNames.BACKGROUND};`,
    elementName: null
  },
  {
    selector: '.background-color-bg-opacity',
    style: `background-color: #:${ColorNames.BACKGROUND};`,
    elementName: null
  }
]