import Vue from 'vue'
import { monthlyTrainingsModule } from '@/store/modules/monthly-trainings.module'
import { PAGES } from '@/types/router'
import { Component } from 'vue-property-decorator'
import { Location } from 'vue-router'
import { Measure, MonthlyTrainingCircuit } from '@/types/workouts'
import { LocaleMessages } from 'vue-i18n'
import InfoTagComponent from '@/components/info-tag/info-tag.component.vue'
import monthlyTrainingService from '@/services/monthly-training.service'
import DetailDescriptionComponent from '@/components/detail-description/detail-description.component.vue'
import TrainingPreviewComponent from '@/components/training-preview/training-preview.component.vue'
import workoutPreviewManager from '@/store/managers/workout-preview.manager'
import { appModule } from '@/store/modules/app.module'

@Component({
    components: {
        'info-tag-component': InfoTagComponent,
        'workout-details-component': DetailDescriptionComponent,
        'training-preview-component': TrainingPreviewComponent
    }
})
export default class MonthlyTrainingDescriptionPageComponent extends Vue {

    public activeWorkoutLocation = { name: PAGES.MONTHLY_TRAINING_ACTIVE } as Location

    public workoutPreviewRoundIdx?: number

    public workoutPreviewPartIdx = 0

    private previewMode = false

    public isPreviewActive = false

    get id() {
        return this.$route.params.id
    }

    get training(): MonthlyTrainingCircuit {
        return monthlyTrainingsModule.monthlyTrainings.find(training => training.id === this.$route.params.id) as MonthlyTrainingCircuit
    }

    get tags() {
        return [this.training.type, this.level, this.training.creatorName, monthlyTrainingService.getMonthlyTrainingDuration(this.training)]
            .concat(this.training.gearList.filter((value, index, self) => self.indexOf(value) === index))
            .slice(0, 4)
    }

    get equipmentTags() {
        return null
    }

    get level(): LocaleMessages {

        const difficulty = parseInt(this.training.difficulty)

        if (difficulty < 2) {
            return this.$t('common.beginner') as LocaleMessages
        } else if (difficulty < 4) {
            return this.$t('common.intermediate') as LocaleMessages
        }
        return this.$t('common.advanced') as LocaleMessages
    }

    get workoutName() {
        return this.training.name
    }

    get workoutDescription() {
        return this.training?.description
    }

    get workoutPreviewSlidesData() {

        const slides = []

        for (const roundsIdx in this.training.roundsData) {

            const roundNumber = parseInt(roundsIdx)

            const exercises = this.training.roundsData[roundsIdx].circuits[0].exercises

            const { name, type, rounds } = this.training

            const workInterval = this.training.roundsData[roundsIdx].workInterval
            const restInterval = this.training.roundsData[roundsIdx].restInterval
            const measures: Measure[] = []

            slides.push({ name, type, rounds, exercises, round: roundNumber, workInterval, restInterval, measures })
        }
        return slides
    }

    get coverImageUrl(): string | null {

        if (this.training && this.training.coverImage) {
            const { small } = this.training.coverImage
            return !small ? null : small
        }

        return null
    }

    get fitnessTypeTag() {
        return null
    }

    get previewIcon() {
        return this.isPreviewActive ? "theme-style timer-btn stop-icon icon-stop" : "theme-style timer-btn triangle-icon icon-tirangle-arrow-right"
      }

    togglePreviewMode() {

        this.previewMode = !this.previewMode

        if (this.id != undefined) {

            if (this.previewMode) {
                workoutPreviewManager.start(this.id, 'mt',
                    this.workoutPreviewPartIdx, this.workoutPreviewRoundIdx == undefined ? 0 : this.workoutPreviewRoundIdx)
                this.isPreviewActive = true
            } else {
                workoutPreviewManager.stop(this.id, 'mt',
                    this.workoutPreviewPartIdx, this.workoutPreviewRoundIdx)
                this.isPreviewActive = false
            }
            appModule.getPreview(this.isPreviewActive)
        }
    }

    onPreviewUpdate(d: { workoutPartIdx: number; roundIdx?: number }) {

        const { workoutPartIdx, roundIdx } = d
    
        if (this.training?.id && this.previewMode) {
          if (workoutPartIdx != this.workoutPreviewPartIdx) {
            workoutPreviewManager.changeWorkoutPart(this.id, 'mt',
              workoutPartIdx, roundIdx)
          } else if (roundIdx != undefined && roundIdx != this.workoutPreviewRoundIdx) {
            workoutPreviewManager.changeRound(this.id, 'mt',
              workoutPartIdx, roundIdx)
          }
        }
    
        this.workoutPreviewPartIdx = workoutPartIdx
        this.workoutPreviewRoundIdx = roundIdx
      }

    returnBack() {
        return this.$router.back()
    }

    beforeDestroy() {
        if (this.isPreviewActive) {
          this.togglePreviewMode()
        }
      }
}