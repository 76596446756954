import Dexie from 'dexie'

class DexieDbService extends Dexie {

    workouts: Dexie.Table<WorkoutQueue, number>

    constructor() {
        super("SvtAppDb")
        const version = process.env.VUE_APP_VERSION ? process.env.VUE_APP_VERSION : '0.1' 
        this.version(parseFloat(version)).stores({
            workouts: '++id, mss, uuid, notificationId, time',
            //...other tables goes here...
        })
        this.workouts = this.table("workouts")
    }
}

export type WorkoutQueue = {
    id?: string;
    mss?: boolean;
    uuid: string;
    notificationId: string;
    time: number;
}

export const dexieDbService = new DexieDbService()