export const DURATION_IN_SECONDS = 'duration-in-seconds'
export const PROVIDER = 'provider'
export const THUMBNAIL_URL = 'thumbnail-url'
export const THUMBNAIL_SMALL_URL = 'thumbnail-small-url'
export const THUMBNAIL_BIG_URL = 'thumbnail-big-url'
export const VIDEO_URL = 'video-url'
export const EXTERNAL_ID = 'external-id'
export const CREATED_AT = 'created-at'

export interface BaseApiConfig {
  id: string;
  type: string;
}

export interface VirtualClassApiConfig extends BaseApiConfig {
  attributes: {
    id: string;
    name: string;
    description: string;
    [DURATION_IN_SECONDS]: number;
    [THUMBNAIL_URL]: string;
    [THUMBNAIL_SMALL_URL]: string;
    [THUMBNAIL_BIG_URL]: string;
    [VIDEO_URL]: string;
    [PROVIDER]: string;
    [CREATED_AT]: string;
    [EXTERNAL_ID]: string;
    language: VirtualClassLanguageApiConfig | null;
  };
  relationships: {
    categories: VirtualClassCategoryApiConfig[] | null;
    instructors: VirtualClassTrainerApiConfig[] | null;
    level: VirtualClassIntensityApiConfig | null;
    types: VirtualClassTypeApiConfig[] | null;
    equipment: VirtualClassEquipmentApiConfig[] | null;
  };
  video: string;
  progress: number;
}

export interface VirtualClassIntensityApiConfig extends BaseApiConfig {
  attributes: {
    id: string;
    name: string;
  };
}

export interface VirtualClassTrainerApiConfig extends BaseApiConfig {
  attributes: {
    id: string;
    name: string;
  };
}

export interface VirtualClassEquipmentApiConfig extends BaseApiConfig {
  attributes: {
    id: string;
    name: string;
  };
}

export interface VirtualClassLanguageApiConfig extends BaseApiConfig {
  attributes: {
    id: string;
    name: string;
  };
}

export interface VirtualClassCategoryApiConfig extends BaseApiConfig {
  attributes: {
    id: string;
    name: string;
    icon: string;
    type: string;
  };
}

export interface VirtualClassLanguageApiConfig extends BaseApiConfig {
  attributes: {
    id: string;
    name: string;
  };
}

export interface VirtualClassTypeApiConfig extends BaseApiConfig {
  attributes: {
    id: string;
    name: string;
    icon: string;
    type: string;
  };
}

export enum FilterType {
  DURATION = 'duration',
  CATEGORY = 'category',
  TYPE = 'type',
  EQUIPMENT = 'equipment',
  INTENSITY = 'intensity',
  TRAINER = 'trainer',
  FITNESS_CATEGORY = 'fitness_category',
  FITNESS_TYPE = 'fitness_type',
  LANGUAGE = 'language',
  BODY_PART = 'body_part'
}

export interface BaseFilter {
  id: string;
  name: string;
  selected: boolean;
}

export interface DurationFilter extends BaseFilter {
  from: number;
  to: number;
}

export interface CategoryFilter extends BaseFilter {
  type: string;
}

export interface TypeFilter extends BaseFilter {
  type: string;
}

export interface EquipmentFilter extends BaseFilter {
  type: string;
}

export interface IntensityFilter extends BaseFilter {
  type: string;
}

export interface TrainerFilter extends BaseFilter {
  type: string;
}

export interface LanguageFilter extends BaseFilter {
  type: string;
}

export interface BodyPartFilter extends BaseFilter {
  type: string;
}