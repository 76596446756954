var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"theme-style screensaver class-overview-wrapper"},[_c('div',{staticClass:"theme-style background-overlay"}),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',[_c('div',{staticClass:"schedules-wrapper"},[_c('schedules-list-component',{attrs:{"schedules":_vm.trainingsOfTheDay}})],1)])],1),_c('b-row',[_c('b-col',[_c('div',{staticClass:"activities-lists-wrapper"},[_c('div',{staticClass:"select-swiper-tab"},[(_vm.workoutsAvailable)?_c('div',{staticClass:"theme-style swiper-header",class:_vm.activeTabName === _vm.workoutsLocation.name
                  ? _vm.activeSwiper
                  : _vm.InactiveSwiper,on:{"click":function($event){return _vm.tabActivate(_vm.workoutsLocation.name)}}},[_vm._v(" "+_vm._s(_vm.$t("workouts.workouts"))+" ")]):_vm._e(),(_vm.virtualClassesAvailable)?_c('div',{staticClass:"theme-style swiper-header",class:_vm.activeTabName === _vm.virtualClassesLocation.name
                  ? _vm.activeSwiper
                  : _vm.InactiveSwiper,on:{"click":function($event){return _vm.tabActivate(_vm.virtualClassesLocation.name)}}},[_vm._v(" "+_vm._s(_vm.$t("virtual_classes.virtual_classes"))+" ")]):_vm._e(),(_vm.egymTrainingPlans.length > 0)?_c('div',{staticClass:"theme-style swiper-header",class:_vm.activeTabName === _vm.egymTrainingPlansLocation.name
                  ? _vm.activeSwiper
                  : _vm.InactiveSwiper,on:{"click":function($event){return _vm.tabActivate(_vm.egymTrainingPlansLocation.name)}}},[_vm._v(" "+_vm._s(_vm.$t("egym.training_plans"))+" ")]):_vm._e()]),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('keep-alive',[(
                    _vm.virtualClassesAvailable &&
                    _vm.activeTabName === _vm.virtualClassesLocation.name
                  )?_c('div',{staticClass:"activities-list"},[_c('activities-list-slider-component',{scopedSlots:_vm._u([{key:"all-activities-link",fn:function(){return [_vm._m(0)]},proxy:true},{key:"activities",fn:function(){return [_c('swiper',{staticClass:"preview-classes-swiper",attrs:{"options":_vm.swiperOptions}},_vm._l((_vm.virtualClasses),function(virtualClass){return _c('swiper-slide',{key:virtualClass.id},[_c('virtual-class-tile',{attrs:{"classEntity":virtualClass}})],1)}),1)]},proxy:true}],null,false,1800755540)})],1):_vm._e()]),_c('keep-alive',[(
                    _vm.workoutsAvailable &&
                    _vm.activeTabName === _vm.workoutsLocation.name
                  )?_c('div',{staticClass:"activities-list"},[_c('activities-list-slider-component',{scopedSlots:_vm._u([{key:"all-activities-link",fn:function(){return [_vm._m(1)]},proxy:true},{key:"activities",fn:function(){return [_c('swiper',{staticClass:"preview-classes-swiper",attrs:{"options":_vm.swiperOptions}},_vm._l((_vm.workouts),function(workout){return _c('swiper-slide',{key:workout.id},[(workout.hasOwnProperty('circuit_mss'))?[_c('mss-tile',{attrs:{"workoutEntity":workout}})]:[_c('workout-tile',{attrs:{"workoutEntity":workout}})]],2)}),1)]},proxy:true}],null,false,653489199)})],1):_vm._e()]),_c('keep-alive',[(
                    _vm.egymTrainingPlans.length > 0 &&
                    _vm.activeTabName === _vm.egymTrainingPlansLocation.name
                  )?_c('div',{staticClass:"activities-list"},[_c('activities-list-slider-component',{scopedSlots:_vm._u([{key:"all-activities-link",fn:function(){return [_vm._m(2)]},proxy:true},{key:"activities",fn:function(){return [_c('swiper',{staticClass:"preview-classes-swiper",attrs:{"options":_vm.swiperOptions}},_vm._l((_vm.egymTrainingPlans),function(workout){return _c('swiper-slide',{key:workout.id},[_c('egym-tile',{attrs:{"workoutEntity":workout}})],1)}),1)]},proxy:true}],null,false,128378636)})],1):_vm._e()])],1)],1)],1)])],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('router-link',{staticClass:"theme-style swiper-link link primary-color-border primary-color-text",attrs:{"to":_vm.virtualClassesLocation}},[_vm._v(_vm._s(_vm.$t("virtual_classes.see_all_virtual_classes")))])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('router-link',{staticClass:"theme-style swiper-link link primary-color-border primary-color-text",attrs:{"to":_vm.workoutsLocation}},[_vm._v(_vm._s(_vm.$t("workouts.see_all_workouts")))])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('router-link',{staticClass:"theme-style swiper-link link primary-color-border primary-color-text",attrs:{"to":_vm.egymTrainingPlansLocation}},[_vm._v(_vm._s(_vm.$t("workouts.see_all_workouts")))])
}]

export { render, staticRenderFns }