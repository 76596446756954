import Vue from 'vue'
import Vuex from 'vuex'
import { CtrlCmdMessage, FeedbackCtrlCmdMessage } from '@/types/socket'

Vue.use(Vuex)

export default new Vuex.Store({
    state: { },
    mutations: {
        SOCKET_ONOPEN(state, event) {
            Vue.prototype.$socket = event.currentTarget as WebSocket
            // eslint-disable-next-line
            (state as { websoketConnectionModule: any }).websoketConnectionModule.connected = true
            // eslint-disable-next-line
            console.info('SOCKET_ONOPEN ->', state, event)
        },
        async SOCKET_ONCLOSE(state, event) {
            // eslint-disable-next-line
            (state as { websoketConnectionModule: any }).websoketConnectionModule.connected = false
            // eslint-disable-next-line
            console.error('SOCKET_ONCLOSE ->', state, event)
            
        },
        SOCKET_ONERROR(state, event) {
            (state as { websoketConnectionModule: any }).websoketConnectionModule.connected = false
            // eslint-disable-next-line
            console.error('SOCKET_ONERROR ->', state, event)
        },
        // default handler called for all methods
        SOCKET_ONMESSAGE(state, message: CtrlCmdMessage | FeedbackCtrlCmdMessage) {

            const pool = ((state as { controlCommandModule: any }).controlCommandModule.msgPool as (CtrlCmdMessage | FeedbackCtrlCmdMessage)[])

            if (pool.length > 10) {
                pool.pop()
            }
            // eslint-disable-next-line
            pool.unshift(message as (CtrlCmdMessage | FeedbackCtrlCmdMessage))
        },
        // mutations for reconnect methods
        SOCKET_RECONNECT(state, count) {
            // eslint-disable-next-line
            console.info('SOCKET_RECONNECT ->', state, count)
        },
        SOCKET_RECONNECT_ERROR(state) {
            (state as { websoketConnectionModule: any }).websoketConnectionModule.connected = false
            // eslint-disable-next-line
            console.error('WSS connection error')
        },
    }
})


