import Vue from 'vue'
import Component from 'vue-class-component';
import { Emit, Model, VModel } from 'vue-property-decorator';
import VuePicker from 'vue-pickers'
import timeformat from '@/filters/timeformat'
import { generatePickerOptions } from '@/utils/common.utils';


@Component({
    components: {
        VuePicker
    },
    filters: { timeformat }
})
export default class TimerCountdownComponent extends Vue {

    readonly minutesStep = 1
    readonly secondsStep = 5
    readonly roundsStep = 1 

    public minutes = 0
    public seconds = 0

    public showPicker = false
    
    @Model()
    public time!: number

    get countdownTime() {
        return (this.minutes * 60 + this.seconds)
    }

    get countdownTimeMs() {
        return this.countdownTime * 1000
    }

    get minutesOptions(): Array<{ value: number; label: string }> {
        return generatePickerOptions(60, this.minutesStep)
    }
    
    get secondsOptions(): Array<{ value: number; label: string }> {
        return generatePickerOptions(60, this.secondsStep)
    }

    @Emit('start')
    start() {
        return this.countdownTime
    }

    @Emit('change')
    change() {
        return this.countdownTime
    }

    onTimeChange(time: any[] ) {
        this.minutes = time[0] != undefined ? time[0].value : this.minutes
        this.seconds = time[1] != undefined ? time[1].value : this.seconds
        this.change()
    }

    togglePicker() {
        this.showPicker = !this.showPicker
    }
}