import { render, staticRenderFns } from "./egym-training-description.component.vue?vue&type=template&id=5faff686&scoped=true"
import script from "./egym-training-description.component.ts?vue&type=script&lang=ts&external"
export * from "./egym-training-description.component.ts?vue&type=script&lang=ts&external"
import style0 from "./egym-training-description.component.scss?vue&type=style&index=0&id=5faff686&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5faff686",
  null
  
)

export default component.exports