import { bluredContentModule } from '@/store/modules/blured-content.module'
import { PAGES } from '@/types/router'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { appModule } from '@/store/modules/app.module'
import DevModeButtonComponent from '../devmode-button/devmode-button.component.vue'
import EGymAddUserComponent from '../e-gym-add-user/e-gym-add-user.component.vue'
import { eGymTrainingsModule } from '@/store/modules/egym-trainings.module'
import { notificationModule } from '@/store/modules/notification.module'
import { NotificationCommand, NotificationType, UserAnswer } from '@/types/notification'
import {workoutsModule} from "@/store/modules/workouts.module";
import {ctrlCmdModule} from "@/store/modules/control-commands.module";
import {CTRL_COMMAND} from "@/types/socket";
import workoutsStoreManager from "@/store/managers/workouts.store.manager";
import {eGymUsersModule} from "@/store/modules/egym-users.module";

@Component({
  components: {
    'dev-button': DevModeButtonComponent,
    'egym-add-user-component': EGymAddUserComponent
  }
})
export default class MenuOverlayComponent extends Vue {
  private linksList = [
    {
      name: this.$t('common.home'),
      route: PAGES.HOME
    },
    {
      name: "egym_training_plans",
      route: PAGES.EGYM_TRAINING_PLANS
    },
    {
      name: "virtual_classes",
      route: PAGES.VIRTUAL_CLASSES
    },
    {
      name: this.$t('workouts.workouts'),
      route: PAGES.REGULAR_WORKOUTS
    },
    {
      name: "monthly_trainings",
      route: PAGES.MONTHLY_TRAINING_OVERVIEW
    },
    {
      name: "timers",
      route: PAGES.TIMERS
    },
    {
      name: "dev_monitor",
      route: PAGES.DEVMONITOR
    }
  ]

  get availableLinks() {
    const links = this.linksList.slice()
    if (eGymTrainingsModule.workouts.length < 1) {
      links.splice(links.findIndex(link => link.route === PAGES.EGYM_TRAINING_PLANS), 1)
    }
    if (!appModule.virtualClassesAvailable) {
      links.splice(links.findIndex(link => link.route === PAGES.VIRTUAL_CLASSES), 1)
    }
    if (!appModule.workoutsAvailable) {
      links.splice(links.findIndex(link => link.route === PAGES.REGULAR_WORKOUTS), 1) 
    }
    if (!appModule.monthlyTrainingsAvailable) {
      links.splice(links.findIndex(link => link.route === PAGES.MONTHLY_TRAINING_OVERVIEW), 1)
    }
    if (!appModule.timersAvailable) {
      links.splice(links.findIndex(link => link.route === PAGES.TIMERS), 1)
    }
    if (!appModule.devModeOn) {
      links.splice(links.findIndex(link => link.route === PAGES.DEVMONITOR), 1)
    }

    return links
  }

  get isItDevMenu() {
    return appModule.devModeOn
  }

  get currentRoute() {
    return this.$route.name
  }

  get isButtonVisible() {
    const routes = [
      PAGES.LOGIN, PAGES.DEVICE_CHANGING
    ]
    return !routes.some(route => route === this.$route.name)
  }

  get isMenuVisible() {
    const routes = [
      PAGES.MONTHLY_TRAINING_DESCRIPTION, PAGES.MSS_WORKOUT_DESCRIPTION,
      PAGES.REGULAR_WORKOUT_DESCRIPTION, PAGES.VIRTUAL_CLASS_DESCRIPTION,
      PAGES.ACTIVE_VIRTUAL_CLASS, PAGES.WORKOUT_ACTIVE_PAGE,
      PAGES.MSS_WORKOUT_ACTIVE_PAGE, PAGES.MONTHLY_TRAINING_ACTIVE, PAGES.TIMERS_ACTIVE,
      PAGES.EGYM_ADD_USERS, PAGES.EGYM_TRAINING_DESCRIPTION, PAGES.EGYM_TRAINING_ACTIVE
    ]
    return !routes.some(route => route === this.$route.name)
  }

  get isContentBlured() {
    return bluredContentModule.isBlured
  }

  get isActiveLinkClasses() {
    return ['is-active']
  }

  get isInactiveLinkClasses() {
    return ['fontA-color-text']
  }
  
  get isEgymActive() {
    return appModule.eCheckInOn
  }

  get isEgymSingleUserMode() {
    return eGymUsersModule.usersCheckedIn.length == 1
  }

  get isEgymGroupUsersMode() {
    return eGymUsersModule.usersCheckedIn.length > 1
  }

  egymLogOut() {
    const watcher = notificationModule.$watch(
      m => m.answer,
      (answer: UserAnswer) => {
        this.toggleBluredContent(true)
        if (answer === UserAnswer.POSITIVE) {
          eGymUsersModule.resetToDefault()
          eGymTrainingsModule.resetToDefault()
          workoutsStoreManager.deleteEgymTrainings()
          ctrlCmdModule.triggerCtrlCmd({ trigger: CTRL_COMMAND.RESET_EGYM_TRAINING_PLANS, value: { id: 'none' } })
          watcher()
        }
      })
    notificationModule.showNotification({ command: NotificationCommand.EGYM_LOGOUT, type: NotificationType.CONFIRM })
  }
  

  menuIcon(linkName: string, isActive: string){
    let iconPath = ""
     if(linkName === this.$t('common.home')){
       if(isActive === PAGES.HOME){
         return iconPath = "icon-Home"
        }else {
          return iconPath = "icon-notactive_home"
        }
      }else if(linkName === "virtual_classes"){
        if(isActive === PAGES.VIRTUAL_CLASSES){
          return iconPath = "icon-OnDemand"
        }else {
          return iconPath = "icon-notactive_On_Demand"
        }
      }else if(linkName === 'egym_training_plans'){
        if(isActive === PAGES.EGYM_TRAINING_PLANS){
          return iconPath = "icon-Training"
        } else {
          return iconPath = "icon-notactive_training"
        }
      }else if(linkName === this.$t('workouts.workouts')){
        if(isActive === PAGES.REGULAR_WORKOUTS){
          return iconPath = "icon-Training"
        } else {
          return iconPath = "icon-notactive_training"
        }
      }else if(linkName === "monthly_trainings"){
        if(isActive === PAGES.MONTHLY_TRAINING_OVERVIEW){
          return iconPath = "icon-Circuit"
        }else {
          return iconPath = "icon-notactive_circuit"
        }
     } else if(linkName === "timers") {
       return iconPath = "icon-icon_stopwatch"
     } else {
       return ""
     }
  }

  toggleBluredContent(isMenuOpened: boolean) {
    bluredContentModule.toggleStatus(isMenuOpened)
  }

  goToHomePage() {
    const routes = [PAGES.ACTIVE_VIRTUAL_CLASS, PAGES.WORKOUT_ACTIVE_PAGE,
      PAGES.MSS_WORKOUT_ACTIVE_PAGE, PAGES.MONTHLY_TRAINING_ACTIVE]
      if( !routes.some(route => route === this.$route.name)) {
        this.$router.push({name: PAGES.HOME})
      }
  }
}