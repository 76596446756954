import { whiteLabelService } from '@/services/white-label.service'
import { deprecatedCustomColorsRulesList, whiteLabelStyleRulesList } from '@/settings/white-labeling-rules'
import { PAGES } from '@/types/router'
import { Mutation, Action, Module, VuexModule } from 'vuex-class-modules'
import store from '../index'


const GLOBAL_APP_STYLESHEET_NAME = 'funxtion'
const THEME_STYLE_PREFIX = 'theme-style'

@Module
class WhiteLabelModule extends VuexModule {

  private currentTheme = GLOBAL_APP_STYLESHEET_NAME
  private themeConfig: { [key: string]: any } = {}
  private styleSheetElement: HTMLStyleElement | null = null

  private activeStyleSheetsMap: Map<string, HTMLStyleElement> = new Map()

  get styleSheet() {
    if (this.styleSheetElement) {
      return this.styleSheetElement.sheet
    }
  }

  @Mutation
  private setCurrentTheme(theme: string) {
    this.currentTheme = theme
  }

  @Mutation
  private setThemeConfig(themeConfig: { [key: string]: any }) {
    this.themeConfig = themeConfig
  }

  @Mutation
  addStyleSheet(name: string) {
    const styleSheet = document.createElement('style') as HTMLStyleElement
    styleSheet.setAttribute('id', name)
    document.getElementsByTagName('head')[0].appendChild(styleSheet)
    this.activeStyleSheetsMap.set(name, styleSheet)
  }

  @Mutation
  deleteStyleSheet(name: string) {
    document.getElementById(name)?.remove();
    this.activeStyleSheetsMap.delete(name)
  }

  @Mutation
  fillInStyleSheet(arg: { name: string; rules: string[] }) {
    const { name, rules } = arg
    rules.forEach(rule => {
      const styleSheet = this.activeStyleSheetsMap.get(name)?.sheet as CSSStyleSheet
      styleSheet.insertRule(rule, styleSheet.cssRules.length)
    })
  }

  @Action
  async initialize(themeInfo: { [key: string]: any }) {

    if (themeInfo.theme && themeInfo.theme !== this.currentTheme) {
      this.deleteStyleSheet(this.currentTheme)
      this.setCurrentTheme(themeInfo.theme)
    }

    if (!this.activeStyleSheetsMap.get(this.currentTheme)) {

      this.setThemeConfig(themeInfo)

      const rules = whiteLabelService.getRules(whiteLabelStyleRulesList, this.themeConfig.app, this.themeConfig.global, THEME_STYLE_PREFIX)
      /* TO DO: Get rid of old css classes and remove this part */
      const deprecatedRules = whiteLabelService.getRules(deprecatedCustomColorsRulesList, this.themeConfig.global)
  
      this.addStyleSheet(this.currentTheme)
      this.fillInStyleSheet({ name: this.currentTheme, rules: rules.concat(deprecatedRules) })
    }
  }

  @Action
  preloadPageStyles(pageName: PAGES) {
    
    this.resetPageStyles()

    if (this.themeConfig[pageName]) {

      const name = [this.currentTheme, pageName].join('-');

      const rules = whiteLabelService.getRules(whiteLabelStyleRulesList, this.themeConfig[pageName], this.themeConfig.global, THEME_STYLE_PREFIX)
      if (rules.length > 0) {
        this.addStyleSheet(name)
        this.fillInStyleSheet({ name, rules })
      }
    } else {
      return null
    }
  }

  @Action
  resetPageStyles() {

    this.activeStyleSheetsMap.forEach((styleSheet, styleKey) => {
      if (styleKey != this.currentTheme) {
        this.deleteStyleSheet(styleKey)
      }
    })
  }
}

export const whiteLabelModule = new WhiteLabelModule({ store, name: 'whiteLabel' })