import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import ScrollContainerComponent from '@/components/scroll-container/scroll-container.component.vue'
import MonthlyTrainingTileComponent from '@/components/monthly-training-tile/monthly-training-tile.component.vue'
import InfoTagComponent from '@/components/info-tag/info-tag.component.vue'
import { monthlyTrainingsModule } from '@/store/modules/monthly-trainings.module'
import { BaseWorkout } from '@/types/workouts'

@Component({
  components: {
    'scroll-container-component': ScrollContainerComponent,
    'monthly-training-tile': MonthlyTrainingTileComponent,
    'info-tag-component': InfoTagComponent
  }
})
export default class MonthlyTrainingsComponent extends Vue {
  forWatch: BaseWorkout[] = []

  get searchPhrase() {
    return ''
  }

  get monthlyTrainings() {
    return monthlyTrainingsModule.monthlyTrainings
  }

  get isSearchActive() {
    return false
  }

  get isFiltersActive() {
    return false
  }

  get selectedIconClasses() {
    return ['selected', 'fontA-color-bg', 'primary-color-text']
  }

  get selectedFilterClasses() {
    return ['secondary-color-border']
  }

  updated() {
    this.forWatch = this.monthlyTrainings
  }

  onChangeSearchPhrase() {
    // on search
  }

  clearFilters() {
    // clearFilters
  }
}