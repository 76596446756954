import Vue from 'vue'
import { VuexModule, Module, Action } from 'vuex-class-modules'
import store from '../index'

@Module
class WebsocketConnectionModule extends VuexModule {

    connected = false

    @Action
    connect() {
        if (!this.connected) {
            Vue.prototype.$connect()
        }
    }

    @Action
    disconnect() {
        if (this.connected) {
            Vue.prototype.$disconnect()
        }
    }

    @Action
    reconnect() {
        this.disconnect()
        setTimeout(() => this.connect(), 3000)
    }
}

export const websoketConnectionModule = new WebsocketConnectionModule({ store, name: 'websoketConnectionModule' })