import Vue from 'vue'
import { Component ,Prop } from 'vue-property-decorator'

@Component({})
export default class VirtualClassVideoStreamComponent extends Vue {
    @Prop({ type: String, default: ''}) videoLink!: string



    mounted() {
        window.setTimeout(() => {
            (document.getElementsByClassName('video-preview')[0] as HTMLVideoElement).pause()
        }, 2 * 60 * 1000)
    }
}