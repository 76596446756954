export default function (time: number, options: { showHours: boolean } = { showHours: false }) {

    const seconds = Math.floor((time / 1000) % 60)
    const minutes = Math.floor((time / (1000 * 60)) % 60)
    const hours = Math.floor(time / (1000 * 60 * 60))

    const timeStr = `${ (minutes < 10) ? "0" + minutes : minutes }:${ (seconds < 10) ? "0" + seconds : seconds }`

    if (options.showHours) {
        return `${ (hours < 10) ? "0" + hours : hours }:${ timeStr }`
    } else {
        return timeStr
    }
}