import { virtualClassesModule } from '@/store/modules/virtual-classes.module'
import { EXTERNAL_ID } from '@/types/virtual-classes'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component
export default class DevMonitorPageComponent extends Vue {
  
    get vcData() {
        return virtualClassesModule.classesList?.map(virtualClass => {
            const { progress } = virtualClass
            const { name } = virtualClass.attributes
            return { id: virtualClass.attributes[EXTERNAL_ID], name, progress }
        })
    }

    get vcTableHeaders() {
        return [
            {key: 'id', label: 'ID'},
            {key: 'name', label: 'Name'},
            {key: 'progress', label: 'Progress'}
        ]
    }
}