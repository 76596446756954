export interface EGymAuth {
    grant_type: string;
    machine_type: string;
    serial_number: string;
    rfid: string;
    rfid_format: string
}

export enum EGYM_AUTH_SETTINGS {
    GRANT_TYPE = 'rfid',
    MACHINE_TYPE = 'funxtion',
    RFID_FORMAT = 'MIFARE'
}

export interface EGymConfig {
    rfidNumber: string;
    serialNumber: string;
}

export interface EGymAuthResponse {
    access_token: string;
    expiration_time: number;
    token_type: string;
}

export interface EGymUserResponse {
    dateOfBirth: string;
    firstName: string;
    gender: string;
    height: number;
    lastName: string;
    locale: string;
    termsAndConditionsAccepted: boolean;
    unitSystem: string;
    userId: string;
    weight: number;
    avatar?: string;
}


export interface EGymUser {
    userInfo: EGymUserResponse;
    rfid: string;
}

export interface EGymWorkoutStrength {
    startTimestamp: number;
    exerciseName: string;
    sets: EGymSet[];
}

export interface EGymSet {
    setId: number;
    duration: number;
    weight: number;
    repetitions: number;
}

export interface EGymWorkoutData {
    exercises: EGymWorkoutStrength[]
    users: string[]
    tries?: number;
}

export interface rfidList {
    rfid: string;
    token: string
}

export const MADE_BY_EGYM_TRAINER = "egym-training-plans"