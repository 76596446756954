import { BaseAdditionalActivity, BaseWorkout, WorkoutTimeFrame, CircuitAdditionalActivity, CircuitWorkout, TimerAdditionalActivity, WorkoutTypes, RftWorkout, WorkoutSyncInterface } from '@/types/workouts'
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules'
import store from '../index'
import runtimeProcessorModule from './runtime-processor.module';
import { TIMER_TYPE } from '@/types/timer';
import { CTRL_COMMAND_FEEDBACK, CTRL_COMMAND, FeedbackCtrlCmdMessage } from '@/types/socket';
import { ctrlCmdModule } from './control-commands.module';
import workoutService from '@/services/workout.service';
import { TRAINING_TRIGGERED_BY } from '@/types/active-training';


@Module
class ActiveWorkoutModule extends VuexModule implements WorkoutSyncInterface {
    
    startedAt = 0
    public workoutPartIdx = 0
    public activeWorkoutTimeFramesArrayIdx = 0
    public triggeredBy: TRAINING_TRIGGERED_BY | null = null

    public warmingUps: BaseAdditionalActivity[] = []
    public coolingDowns: BaseAdditionalActivity[] = []
    public workout: BaseWorkout | null = null

    get timeFrames(): WorkoutTimeFrame[] {
        return this.activeWorkoutTimeFramesArray
            .filter((timeFrame: WorkoutTimeFrame, idx) => idx < this.activeWorkoutTimeFramesArrayIdx)
    }

    get elapsedTimeOfActiveWorkoutPart(): number {

        const timeFrames = this.timeFrames

        if ((this.activeWorkoutPart as BaseWorkout).type === WorkoutTypes.RFT) {

            if (this.isItAWarmingUp && this.isItLastTimeFrameInSet) {
                timeFrames.splice(this.activeWorkoutTimeFramesArrayIdx - 1, 1)
            }
        }

        if (timeFrames.length > 0) {

            return timeFrames.map(timeFrame => timeFrame.duration)
                .reduce((acc, timeFrameDuration) => acc += timeFrameDuration)
        }
        return 0
    }

    get elapsedTime(): number {

        let elapsedTime = 0

        const previousWorkoutParts = ([] as (BaseWorkout | BaseAdditionalActivity)[]).concat(
            this.warmingUps, this.workout as BaseWorkout, this.coolingDowns
        ).slice(0, this.workoutPartIdx)

        if (previousWorkoutParts.length > 0) {
            // Previous workout parts full duration
            elapsedTime = previousWorkoutParts.reduce((acc, workout, workoutPartIdx) => {

                acc += (workout as BaseWorkout).duration

                if ((workout as BaseAdditionalActivity).prep_time) {
                    acc += (workout as BaseAdditionalActivity).prep_time
                }
                return acc
            }, 0) + 5 // +5 seconds of static preparation time before the workout's start
        }
        return this.elapsedTimeOfActiveWorkoutPart + elapsedTime
    }

    get activeWorkoutPart(): BaseWorkout | BaseAdditionalActivity | null {

        const warmingUpsPlusWorkout = (this.warmingUps.length + 1)
        if (this.workoutPartIdx < 0) {
            return null // workout isn't running
        }
        else if (this.workoutPartIdx < this.warmingUps.length) {

            return this.warmingUps[this.workoutPartIdx]

        } else if (this.workoutPartIdx < warmingUpsPlusWorkout) {

            return this.workout;

        } else {

            const coolingDownIdx = this.workoutPartIdx - warmingUpsPlusWorkout
            return this.coolingDowns[coolingDownIdx]
        }
    }

    get activeWorkoutTimeFramesArray(): WorkoutTimeFrame[] {

        const prepTimeFrames: WorkoutTimeFrame[] = []

        if ((this.isItARegularWorkout && this.warmingUps.length < 1) || (this.isItAWarmingUp && this.workoutPartIdx == 0)) {
            prepTimeFrames.push({ duration: 5 }) // Preparation Time
        }
        
        if ((this.activeWorkoutPart as BaseWorkout).type === WorkoutTypes.CIRCUIT) {

            if ((this.activeWorkoutPart as BaseAdditionalActivity).activity_type) {

                return prepTimeFrames.concat(workoutService.parseCircuitWuCdTimeFrames(this.activeWorkoutPart as CircuitAdditionalActivity))
            }
            return prepTimeFrames.concat(workoutService.parseCircuitWorkoutTimeFrames(this.activeWorkoutPart as CircuitWorkout))

        }
        return prepTimeFrames.concat(workoutService.parseTimeFrames(this.activeWorkoutPart as BaseWorkout))
    }

    get timeFrameCoordinates() {
        return { timeFrameIdx: this.activeWorkoutTimeFramesArrayIdx, workoutPartIdx: this.workoutPartIdx }
    }

    get activeTimeFrame() {
        return this.activeWorkoutTimeFramesArray[this.activeWorkoutTimeFramesArrayIdx]
    }

    get rounds(): number {

        if (this.isItACircuit) {
            return (this.activeWorkoutPart as CircuitWorkout).roundsData.length
        } else if (this.isItAnRft) {
            return (this.activeWorkoutPart as RftWorkout).rounds
        }
        return -1
    }

    get activeCircuitRoundIdx(): number {

        if (this.isItACircuit) {

            let totalRoundsDuration = 0

            for (const roundIdx in (this.activeWorkoutPart as CircuitWorkout).roundsData) {

                const round = (this.activeWorkoutPart as CircuitWorkout).roundsData[roundIdx]

                totalRoundsDuration += round.duration

                if (totalRoundsDuration > this.elapsedTimeOfActiveWorkoutPart) {
                    return parseInt(roundIdx)
                }
            }
        }
        return -1
    }

    get isItTheLastRound(): boolean {
        return this.isItACircuit && (this.activeCircuitRoundIdx + 1 == this.rounds)
    }

    get activeWorkoutPartName(): string {
        return (this.activeWorkoutPart as BaseWorkout)?.name
    }

    get isItAWarmingUp(): boolean {
        return this.workoutPartIdx < this.warmingUps.length
    }

    get isItACoolingDown(): boolean {
        return this.workoutPartIdx > this.warmingUps.length;
    }

    get isItARegularWorkout(): boolean {
        return !this.isItAWarmingUp && !this.isItACoolingDown
    }

    get isItATimer(): boolean {
        return !this.isItARegularWorkout && (this.activeWorkoutPart as TimerAdditionalActivity).timer > 0
    }

    get isItACircuit(): boolean {
        return (this.activeWorkoutPart as BaseWorkout).type === WorkoutTypes.CIRCUIT
    }

    get isItAnRft(): boolean {
        return (this.activeWorkoutPart as BaseWorkout).type == WorkoutTypes.RFT
    }

    get isItTheLastPart(): boolean {
        return (this.warmingUps.length + this.coolingDowns.length) - this.workoutPartIdx == 0;
    }

    get isItFirstTimeFrame(): boolean {
        return this.workoutPartIdx < 1 && this.activeWorkoutTimeFramesArrayIdx < 1
    }

    get isItFirstTimeFrameInSet(): boolean {
        return this.activeWorkoutTimeFramesArrayIdx == 0
    }

    get isItLastTimeFrameInSet(): boolean {
        return this.activeWorkoutTimeFramesArrayIdx === this.activeWorkoutTimeFramesArray.length - 1
    }

    get isItTheLastTimeFrame(): boolean {
        return this.isItTheLastPart && this.isItLastTimeFrameInSet
    }

    get isItPreparationTime(): boolean {

        return (this.isItFirstTimeFrame || (this.isItAWarmingUp && this.isItLastTimeFrameInSet) || 
            (this.isItACoolingDown && this.isItFirstTimeFrameInSet)) &&
            this.activeWorkoutTimeFramesArray.length > 1
    }

    get totalTime() {
        return this.workout?.totalTime
    }

    get activeWorkoutData() {
        return this.workout
    }

    @Mutation
    reset() {
        this.startedAt = 0
        this.workoutPartIdx = 0
        this.activeWorkoutTimeFramesArrayIdx = 0

        this.triggeredBy = null
        this.workout = null
        this.warmingUps = []
        this.coolingDowns = []
    }

    @Mutation
    updateStartTime(time: number) {
        this.startedAt = time
    }

    @Mutation
    setTrriggeredBy(triggeredBy: TRAINING_TRIGGERED_BY) {
        this.triggeredBy = triggeredBy
    }

    @Mutation
    parse(workout: BaseWorkout) {

        this.workoutPartIdx = 0
        this.activeWorkoutTimeFramesArrayIdx = 0

        // eslint-disable-next-line
        const { warming_ups, cooling_downs, ...mainPart } = workout

        // eslint-disable-next-line
        this.warmingUps = warming_ups
        // eslint-disable-next-line
        this.coolingDowns = cooling_downs
        this.workout = mainPart as BaseWorkout
    }

    @Mutation
    setTimeFrameIdx(idx: number) {
        this.activeWorkoutTimeFramesArrayIdx = idx
    }

    @Mutation
    setWorkoutPartIdx(idx: number) {
        this.workoutPartIdx = idx
    }

    @Action
    loadTheNextTimeFrame() {

        if (this.activeWorkoutTimeFramesArrayIdx < this.activeWorkoutTimeFramesArray.length - 1) {

            this.setTimeFrameIdx(this.activeWorkoutTimeFramesArrayIdx + 1)

        } else if (!this.isItTheLastTimeFrame) {

            this.setTimeFrameIdx(0)
            this.setWorkoutPartIdx(this.workoutPartIdx + 1)
        }
    }

    @Action
    init(arg: { workout: BaseWorkout; triggeredBy: TRAINING_TRIGGERED_BY }) {

        this.parse(arg.workout)
        this.setTrriggeredBy(arg.triggeredBy)
    }

    @Action
    timeFrameStart() {

        let { duration } = this.activeTimeFrame

        if (duration > 0) {
            
            if (this.isItAnRft && !this.isItPreparationTime) {
                duration = 0
                runtimeProcessorModule.changeTimerType(TIMER_TYPE.UP)
            } else {
                runtimeProcessorModule.changeTimerType(TIMER_TYPE.DOWN)
            }
            runtimeProcessorModule.start(duration)
        } else if (!this.isItTheLastTimeFrame){

            this.loadTheNextTimeFrame()
            this.timeFrameStart()
        }
    }

    @Action
    start() {
        this.updateStartTime(Date.now())
        this.timeFrameStart()
    }

    @Action
    onResume(activeTimeframeSecondsLeft: number) {
        this.updateStartTime((Date.now() - (this.elapsedTime + activeTimeframeSecondsLeft) * 1000))
    }

    @Action
    resetToDefault() {
        this.reset()
    }

}

export default new ActiveWorkoutModule({ store, name: 'activeWorkout' })