import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { Exercise } from '@/types/workouts'
import { TIME } from '@/types/timer'

@Component({})
export default class ExerciseTileComponent extends Vue {
  @Prop() exercise!: Exercise
  @Prop({ type: Number, default: 1}) exercisePosition!: number
  @Prop({ type: Number, default: 0}) workInterval!: number

  get thumbnailUrl() {
    return `/exercises/${this.exercise.category}/${this.exercise.image}`
  }

  get measures() {
    if(this.workInterval !== 0 && this.exercise.measures) {
      if (this.exercise.measures[0]?.name !== TIME.SEC) {
        this.exercise.measures.unshift({name: TIME.SEC, description: this.workInterval.toString()})
      }
    } else if (this.exercise.workInterval && this.exercise.measures) {
      if (this.exercise.measures[0]?.name !== TIME.SEC) {
        this.exercise.measures.unshift({name: TIME.SEC, description: this.exercise.workInterval.toString()})
      }
    } else if (this.workInterval) {
      this.exercise.measures = [{name: TIME.SEC, description: this.workInterval.toString()}]
    }
    return this.exercise.measures ? this.exercise.measures.slice(0,3) : null
  }

  get isTextOnly() {
    return this.exercise.only_text
  }

  get position() {
    return this.exercisePosition + 1
  }
}