import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { virtualClassesModule } from '@/store/modules/virtual-classes.module'
import { Location } from 'vue-router'
import { VirtualClassApiConfig, VIDEO_URL } from '@/types/virtual-classes'
import { PAGES } from '@/types/router'
import { virtualClassDescriptionModule } from '@/store/modules/virtual-class-description.module'
import DetailDescriptionComponent from '@/components/detail-description/detail-description.component.vue'
import VirtualClassVideoStreamComponent from '@/components/virtual-classes-video-stream/virtual-class-video-stream.component.vue'

@Component({
  components: {
    'workout-details-component': DetailDescriptionComponent,
    'virtual-class-video-stream-component': VirtualClassVideoStreamComponent
  }
})
export default class VirtualClassDescriptionComponent extends Vue {

  virtualClassThumbnail = ''

  public virtualClassesLocation = { name: PAGES.VIRTUAL_CLASSES } as Location
  public activeVirtualClassLocation = { name: PAGES.ACTIVE_VIRTUAL_CLASS } as Location
  public activeVirtualClass = {} as VirtualClassApiConfig

  get className() { return virtualClassDescriptionModule.className }
  get tags() { return virtualClassDescriptionModule.tags.slice(0,8) }
  get classDescription() { return virtualClassDescriptionModule.classDescription }
  get equipmentTags() { return virtualClassDescriptionModule.equipmentTags }
  get virtualClassVideoLink() { return (this.activeVirtualClass.attributes[VIDEO_URL]).slice(0, -1) + "52" }

  created() {
    const id = this.$route.params.id
    this.activeVirtualClass = virtualClassesModule.classes.find(activity => activity.id === id) as VirtualClassApiConfig
    this.activeVirtualClassLocation.params = { id }
    virtualClassDescriptionModule.init(this.activeVirtualClass)
  }

  returnBack() {
    return this.$router.back()
  }
}