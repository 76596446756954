import { httpService } from '@/services/http.service'
import { IntervalTimer, TimerBaseWorkout, TimerNames, TimerTypes } from '@/types/timer'
import { generatePickerOptions, pickerOptionsFormat } from '@/utils/common.utils'
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules'
import store from '../index'

export enum TimerTabs {
    PRESETS = 0,
    INTERVAL = 1,
    STOPWATCH = 2,
    COUNTDOWN = 3
}

@Module
class TimersModule extends VuexModule {

  private activeTimerPresetName: TimerNames | null = null

  private activeTimerType: TimerTabs = TimerTabs.PRESETS

  private intervalTimer: IntervalTimer = {
    workInterval: 0,
    restInterval: 0,
    rounds: 0
  }

  private countdown = 0

  private presets: TimerBaseWorkout[] = []

  private readonly minutesStep = 1

  private readonly secondsStep = 5

  get intervalTimerData() {
    return this.intervalTimer
  }

  get countdownTime() {
    return this.countdown
  }

  get activeTimerPreset() {
      return this.presets.find(preset => preset.id === this.activeTimerPresetName)
  }

  get presetsList() {
    return this.presets
  }

  get minutes() {
    return generatePickerOptions(60, this.minutesStep)
  }

  get seconds() {
    return generatePickerOptions(60, this.secondsStep)
  }

  get workout(): TimerBaseWorkout | null {
    if (this.activeTimerType === TimerTabs.INTERVAL) {
      // check if work interval is set
      const { rounds, workInterval, restInterval } = this.intervalTimer
      return {
          id: TimerNames.INTERVAL,
          workInterval,
          restInterval,
          rounds
      }
    } else if (this.activeTimerType == TimerTabs.COUNTDOWN) {
        return {
            id: TimerNames.COUNTDOWN,
            duration: this.countdownTime
        }
    } else if (this.activeTimerType == TimerTabs.PRESETS && this.activeTimerPreset != undefined ){
      return this.activeTimerPreset
    } else if (this.activeTimerType == TimerTabs.STOPWATCH) {
      return { id: TimerNames.STOP_WATCH }
    }
    return null
  }

  @Mutation
  private setTimerPresets(presets: TimerBaseWorkout[]) {
    this.presets = presets
  }

  @Mutation
  private setActiveTimerPresetName(name: TimerNames| null) {
    this.activeTimerPresetName = name
  }

  @Mutation
  private setCountdownTimer(seconds: number) {
    this.countdown = seconds
  }

  @Mutation
  private setIntervalTimer(interval: IntervalTimer) {
      this.intervalTimer = interval
  }

  @Mutation
  private setActiveTimerType(tabType: TimerTabs) {
    this.activeTimerType = tabType
  }

  @Action
  async loadPresets() {

    let response = null
    try {
      response = await httpService.timerPresets()
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
    }

    if (response != null) {
      this.setTimerPresets(response.timers)
    }
  }

  @Action
  initialize() {
    this.resetToDefault()
    return Promise.all([
      this.loadPresets()
    ])
  }

  @Action
  updateCountdown(seconds: number) {
    this.setCountdownTimer(seconds)
  }

  @Action
  updateInterval(interval: IntervalTimer) {
      this.setIntervalTimer(interval)
  }

  @Action
  updateTmerPreset(name: TimerNames | null) {
    this.setActiveTimerPresetName(name)
  }

  @Action
  updateTimerType(tabType: TimerTabs) {
    this.setActiveTimerType(tabType)
  }

  @Action
  resetToDefault() {
    this.setActiveTimerType(TimerTabs.PRESETS)
    this.setIntervalTimer({ workInterval: 0, restInterval: 0, rounds: 0 })
    this.setCountdownTimer(0)
  }
}

export const timersModule = new TimersModule({ store, name: 'timers' })