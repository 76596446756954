export enum WhiteLabelElementName {
    BIG_LOGO = 'bigLogo',
    SMALL_LOGO = 'smallLogo',
    SCREENSAVER = 'screensaver',
    BACKGROUND_OVERLAY = 'backgroundOverlay',
    SWIPER_HEADER = 'swiperHeader',
    ACTIVE_TIMER = 'activeTimer',
    LOADER_DOTS = 'loaderDots',
    TIMER_BTN = 'timerBtn',
    TIMER_BTN_TEXT = 'timerBtnText',
    SWIPER_LINK = 'swiperLink',
    SCHEDULE_BOARD_HEADER = 'scheduleBoardHeader',
    LIST_HEADER = 'listHeader',
    SEARCH_BTN = 'searchBtn',
    BACKGROUND_COLOR ='backgroundColor',
    TILE_TITLE = 'tileTitle',
    TILE_INFO = 'tileInfo',
    TILE_IMAGE = 'tileImage',
    WORKOUT_TITLE = 'workoutTitle',
    WORKOUT_DESCRIPTION_TITLE = 'workoutDescriptionTitle',
    WORKOUT_DESCRIPTION_DETAIL = 'workoutDescriptionDetail',
    SLIDE_EXERCISE_NAME = 'slideExerciseName',
    INFO_TAG_COLOR = 'infoTagColor',
    BACK_BTN = 'backBtn',
    ACTIVE_BACKGROUND_COLOR = 'activeBackgroundColor',
    SCHEDULE_NEXT_TRAINING = 'scheduleNextTraining',
    SCHEDULE_PROGRESSBAR = 'scheduleProgressbar',
    WARNING_MESSAGE_TITLE = 'warningMessageTitle',
    WARNING_MESSAGE_YES = 'warningMessageYes',
    WARNING_MESSAGE_NO = 'warningMessageNo',
    FILTER_TITLE = 'filterTitle',
    MENU_TITLE = 'menuTitle',
    IMAGE_TAG = 'imageTag',
    MENU_WIDTH = 'menuWidth',
    BACK_BTN_MARGIN = 'backBtnMargin',
    LOGO_DESCRIPTION_PAGE_WIDTH = 'logoDescriptionPageWidth',
    MENU_BACKGROUND_COLOR = 'menuBackgroundColor',
    MENU_TEXT_COLOR = "menuTextColor",
    TRAINING_DROPDOWN = 'trainingDropdown'
}

export interface WhiteLabelStyleRule {
    selector: string;
    style: string;
    elementName: WhiteLabelElementName | null;
}
