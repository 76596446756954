import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import InfoTagComponent from '../info-tag/info-tag.component.vue'
import { Location } from 'vue-router'
import { BaseWorkout, WorkoutIntensities } from '@/types/workouts'
import { PAGES } from '@/types/router'
import workoutService from '@/services/workout.service'
import { workoutsModule } from '@/store/modules/workouts.module'

@Component({
  components: {
    'info-tag-component': InfoTagComponent
  }
})
export default class WorkoutTileComponent extends Vue {
  @Prop() workoutEntity!: BaseWorkout
  private descriptionLocation!: Location

  get coverImageUrl(): string | null {

    if (this.workoutEntity && this.workoutEntity.coverImage) {
      const { small } = this.workoutEntity.coverImage
      return !small ? null : small
    }
    return null
  }

  get goal(): string {
    return workoutService.getFitnessCategoryNameById(this.workoutEntity?.category as number, workoutsModule.workoutFitnessCategories) as string
  }

  get name(): string {
    return this.workoutEntity.name
  }

  get tags(): string[] {
    return [
      workoutService.getWorkoutDuration(this.workoutEntity),
      this.workoutEntity?.type as string,
      this.workoutEntity?.creatorName
    ].filter(tag => tag)
  }

  get charactersInTags() {
    switch(this.tags.length) {
      case 1: { return 25 }
      case 2: { return 15 }
      case 3: { return 12 }
      case 4: { return 8 }
    }
  }

  goToActivity() {
    if (this.$route.name != PAGES.REGULAR_WORKOUT_DESCRIPTION) {
      this.$router.push(this.descriptionLocation)
    }
  }

  created() {
    this.descriptionLocation = { 
      name: PAGES.REGULAR_WORKOUT_DESCRIPTION,
      params: { id: this.workoutEntity.id } }
  }
}