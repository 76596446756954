import { BaseWorkout, BodyParts, Equipment, EQUIPMENT_OTHER, FunxtionFitnessCategory, FunxtionFitnessType, MssWorkout, MssWorkoutType, MssWorkoutTypeNames, WorkoutBodyPartFilter, WorkoutDurationFilter, WorkoutEquipmentFilter, WorkoutFilterType, WorkoutFitnessCategoryFilter, WorkoutFitnessTypeFilter, WorkoutIntensities, WorkoutIntensityFilter, WorkoutTrainerFilter, WorkoutTypeFilter, WorkoutTypes } from '@/types/workouts'
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules'
import store from '../index'
import workoutService from '@/services/workout.service'
import { workoutsModule } from './workouts.module'
import { allWorkoutsModule } from './all-workouts.module'
import { hasOwnProp } from '@/utils/object.utils'
import { DurationFilterLong, DurationFilterMedium, DurationFilterShort } from '@/types/state/general-filter'
import { DurationFilter } from '@/types/virtual-classes'

@Module
class AllWorkoutsOverviewModule extends VuexModule {
  
  private _filteredWorkouts: BaseWorkout[] = []

  readonly pageSize = 20

  currentPage = 1

  get lazyRenderingSliceSize() { return this.pageSize * this.currentPage }

  get filteredWorkouts() { return this._filteredWorkouts.slice(0, this.lazyRenderingSliceSize) }

  get categories(): WorkoutFitnessCategoryFilter[] {
    const list = this._filteredWorkouts.map(workout => workout.category)
    return list.map((cateoryId, idx) => {

      const fitnessCategoryName = workoutService.getFitnessCategoryNameById(cateoryId, workoutsModule.workoutFitnessCategories)

      if (!fitnessCategoryName || list.indexOf(cateoryId) !== idx) {
         return null
      }
      return {
        id: cateoryId.toString(),
        type: WorkoutFilterType.FITNESS_CATEGORY,
        name: fitnessCategoryName
      } as WorkoutFitnessCategoryFilter
    }).filter((categoryFilter) => categoryFilter !=null) as WorkoutFitnessCategoryFilter[]
  }

  get fitnessTypes(): WorkoutFitnessTypeFilter[] {

    const list = this._filteredWorkouts.map(workout => workout.fitness_type)
    return list.map((fitnessTypeId, idx) => {

      const fitnessTypeName = workoutService.getFitnessTypeNameById(fitnessTypeId, workoutsModule.workoutFitnessTypes)

      if (!fitnessTypeName || list.indexOf(fitnessTypeId) != idx) {
         return null
      }
      return {
        id: fitnessTypeId.toString(),
        type: WorkoutFilterType.FITNESS_TYPE,
        name: fitnessTypeName
      } as WorkoutFitnessTypeFilter

    }).filter(item => item != null) as WorkoutFitnessTypeFilter[]
  }

  get types(): WorkoutTypeFilter[] {

    if (this.filteredWorkouts.length < 1) {
      return []
    }
    
    const list = this._filteredWorkouts.map(workout => {
      
      if ( hasOwnProp(workout, 'circuit_mss')) {

        if (!(workout as MssWorkout).circuit_mss) {
          return MssWorkoutType.MSS_BOUTIQUE
        } else {
          return MssWorkoutType.MSS_CIRCUIT
        }
      }
      return workout.type.toString()
    })

    return list.filter((workoutTypeName, idx) => {
      return list.indexOf(workoutTypeName) === idx
    }).map(workoutTypeName => {

      const result = {
        id: workoutTypeName,
        type: workoutTypeName,
        name: ''
      }

      if (workoutTypeName == MssWorkoutType.MSS_BOUTIQUE) {
        result.name = MssWorkoutTypeNames.get(MssWorkoutType.MSS_BOUTIQUE) as string
      } else if (workoutTypeName == MssWorkoutType.MSS_CIRCUIT) {
        result.name =  MssWorkoutTypeNames.get(MssWorkoutType.MSS_CIRCUIT) as string
      } else {
        result.name = workoutTypeName
      }

      return result as WorkoutTypeFilter
    })
    
  }

  get equipment(): WorkoutEquipmentFilter[] {

    if (this.filteredWorkouts.length < 1) {
      return []
    }

    const commonEquipmentNames = workoutsModule.workoutEquipments.map(e => e.name)

    const list = this._filteredWorkouts.map(workout => workout.gearList).reduce((prevValue, cValue) => prevValue.concat(cValue))
    .map(equipmentName => commonEquipmentNames.indexOf(equipmentName) >= 0 ? equipmentName : EQUIPMENT_OTHER)
    
    return list.filter((workoutEquipment, idx) => {
      return list.indexOf(workoutEquipment) === idx
    }).map(equipmentName => {
      return {
        id: equipmentName,
        type: WorkoutFilterType.EQUIPMENT,
        name: equipmentName
      }
    }) as WorkoutEquipmentFilter[]
  }

  get trainers(): WorkoutTrainerFilter[] {

    if (this._filteredWorkouts.length < 1) {
      return []
    }

    const list = this._filteredWorkouts.map(workout => workout.creatorName)
    return list.filter((workoutTrainer, idx) => list.indexOf(workoutTrainer) === idx && workoutTrainer).map(trainerName => {
      return {
        id: trainerName,
        type: WorkoutFilterType.TRAINER,
        name: trainerName
      }
    }) as WorkoutTrainerFilter[]
  }

  get intensity(): WorkoutIntensityFilter[] {

    const list = this._filteredWorkouts.map(workout => workout.difficulty).filter(difficulty => difficulty != '')
    return list.filter((workoutDifficulty, idx) => list.indexOf(workoutDifficulty) === idx).map(workoutDifficulty => {
      return {
        id: workoutDifficulty,
        type: WorkoutFilterType.INTENSITY,
        name: WorkoutIntensities.get(workoutDifficulty) as string
      }
    })
  }

  get durations(): WorkoutDurationFilter[] {
    
    return [DurationFilterShort, DurationFilterMedium, DurationFilterLong].filter(durationFilter => {

      let workoutIdx = 0
      let filterIsAvailable = false
      while (!filterIsAvailable && workoutIdx < this._filteredWorkouts.length) {

        const workout = this._filteredWorkouts[workoutIdx]
        filterIsAvailable = workout.duration > durationFilter.from && workout.duration < durationFilter.to
        workoutIdx++
      }
      return filterIsAvailable
    }) as WorkoutDurationFilter[]
  }

  get bodyPart(): WorkoutBodyPartFilter[] {

    if (this._filteredWorkouts.length < 1) {
      return []
    }

    const commonBodyParts: string[] = [BodyParts.FULL_BODY, BodyParts.LOWER_BODY, BodyParts.UPPER_BODY, BodyParts.CORE]

    const list = this._filteredWorkouts.map(workout => workout.bodytypes).reduce((prevValue, cValue) => prevValue.concat(cValue))
    .map(bodyPart => commonBodyParts.indexOf(bodyPart) >= 0 ? bodyPart : null)
    return list.filter((workoutBodyPart, idx) => {
      return list.indexOf(workoutBodyPart) === idx
    }).map(bodyPartName => {
      return {
        id: bodyPartName,
        type: WorkoutFilterType.BODY_PART,
        name: bodyPartName
      }
    }) as WorkoutBodyPartFilter[]
  }


  @Mutation
  private setFilteredWorkouts(workouts: BaseWorkout[]) {
    this._filteredWorkouts = workouts
  }

  @Mutation
  changePage(page: number) {
    this.currentPage = page
  }

  @Action
  applyFilters(arg: {
    workouts: BaseWorkout[];
    workoutFitnessCategories: FunxtionFitnessCategory[];
    workoutEquipments: Equipment[];
    workoutFitnessTypes: FunxtionFitnessType[];
    durationFilters: DurationFilter[];
    fitnessCategoryFilters: string[];
    fitnessTypeFilters: string[];
    typeFilters: string[];
    equipmentFilters: string[];
    intensityFilters: string[];
    trainerFilters: string[];
    searchPhraze: string;
    bodyPartFilters: string[];
  }) {

    this.changePage(1)

    let filteredWorkouts = arg.workouts
    if (arg.searchPhraze) {
      filteredWorkouts = workoutService.filterWorkoutsByName(filteredWorkouts, arg.searchPhraze)
    }
    if (arg.durationFilters.length) {
      filteredWorkouts = workoutService.filterWorkoutsByDuration(filteredWorkouts, arg.durationFilters)
    }
    if (arg.typeFilters.length) {
      filteredWorkouts = workoutService.filterWorkoutsByType(filteredWorkouts, arg.typeFilters)
    }
    if (arg.fitnessCategoryFilters.length) {
      filteredWorkouts = workoutService.filterWorkoutsByFitnessCategory(filteredWorkouts, arg.fitnessCategoryFilters,
        arg.workoutFitnessCategories)
    }
    if (arg.fitnessTypeFilters.length) {
      filteredWorkouts = workoutService.filterWorkoutsByFitnessType(filteredWorkouts, arg.fitnessTypeFilters, arg.workoutFitnessTypes)
    }
    if (arg.equipmentFilters.length) {
      filteredWorkouts = workoutService.filterWorkoutsByEquipment(filteredWorkouts, arg.equipmentFilters, arg.workoutEquipments)
    }
    if (arg.trainerFilters.length) {
      filteredWorkouts = workoutService.filterWorkoutsByTrainer(filteredWorkouts, arg.trainerFilters)
    }
    if (arg.intensityFilters.length) {
      filteredWorkouts = workoutService.filterWorkoutsByIntensity(filteredWorkouts, arg.intensityFilters)
    }
    if (arg.bodyPartFilters.length) {
      filteredWorkouts = workoutService.filterWorkoutsByBodyParts(filteredWorkouts, arg.bodyPartFilters)
    }
    this.setFilteredWorkouts(filteredWorkouts)
  }

  @Action
  initialize(workots: BaseWorkout[]) {
    this.setFilteredWorkouts(workots)
  }

  @Action
  nextPage() {
    if (this._filteredWorkouts.length > this.lazyRenderingSliceSize) {
      this.changePage(this.currentPage + 1)
    }
  }

  @Action
  reset() {
    this.setFilteredWorkouts(allWorkoutsModule.workouts)
  }
}

export const allWorkoutsOverviewModule = new AllWorkoutsOverviewModule({ store, name: 'allWorkoutsOverview' })