import { BaseWorkout, MonthlyTrainingCircuit } from './workouts';

export enum ScheduleTypes {
  WORKOUT = 'workout',
  MSS_WORKOUT = 'workout_mss'
}

export enum MonthlyTrainingScheduleTypes {
  AC_SHORT = 'ac_short',
  AC_LONG = 'ac_long',
  SBTK_SHORT = 'sbtk_short',
  SBTK_LONG = 'sbtk_long',
  RUMBLE_SHORT = 'rumble_short',
  RUMBLE_LONG = 'rumble_long',
  MOVE_IT_SHORT = 'move_it_short',
  MOVE_IT_LONG = 'move_it_long'
}

export interface ScheduleWithTime {
  name: string;
  time: number;
  category: string;
}

export interface SpecificScheduleWithTime<T> extends ScheduleWithTime {
  training: T;
}

export interface Schedule {
  ID: string;
  starttime: string;
  startTs: number;
  multi: boolean;
  category: string;
  interval?: number;
  endtime?: string;
}

export interface RegularWorkoutSchedule extends Schedule {
  category: ScheduleTypes.WORKOUT;
  workout: BaseWorkout;
}

export interface MssWorkoutSchedule extends Schedule {
  category: ScheduleTypes.MSS_WORKOUT;
  workout: BaseWorkout;
}

export interface MonthlyTrainingSchedule extends Schedule {
  category: MonthlyTrainingScheduleTypes;
  workout: MonthlyTrainingCircuit;
}