import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import LoginFormComponent from '@/components/login-form/login-form.component.vue'
import { authModule } from '@/store/modules/auth.module'
import { Credentials } from '@/types/http'
import { PAGES } from '@/types/router'
import { authService } from '@/services/auth.service'
import { appModule } from '@/store/modules/app.module'
import { NotificationCommand, NotificationType } from '@/types/notification'
import { notificationModule } from '@/store/modules/notification.module'

@Component({
  components: {
    'login-form': LoginFormComponent
  }
})
export default class LoginComponent extends Vue {

  onSubmit(creds: Credentials) {

    appModule.pendingStart()

    authModule.authUser(creds).then(() => {

      appModule.pendingStop()
      if (authModule.userToken != undefined) {
        if (authService.isTokenValid(authModule.userToken)) this.$router.push({ name: PAGES.DEVICE_CHANGING })
      }
    }).catch(() => {
      appModule.pendingStop()
      notificationModule.showNotification({ command: NotificationCommand.WRONG_CREDENTIALS, type: NotificationType.ALERT })
    })
  }
}