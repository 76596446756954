import { schedulesService } from '@/services/schedules.service'
import { ScheduleWithTime } from '@/types/schedule'
import { Module, VuexModule, Action, Mutation } from 'vuex-class-modules'
import store from '../index'
import { mssWorkoutsModule } from './mss-workouts.module'
import { workoutsModule } from './workouts.module'
import { virtualClassesModule } from './virtual-classes.module'
import { monthlyTrainingsModule } from './monthly-trainings.module'

@Module
class SchedulesAggregatorModule extends VuexModule {

  private allSchedulesWithTime: ScheduleWithTime[] = []

  private regularWorkoutScheduleWatcher: CallableFunction|null = null
  private mssWorkoutsScheduleWatcher: CallableFunction|null = null
  private virtualClassesScheduleWatcher: CallableFunction|null = null
  private monthlyTrainingsScheduleWatcher: CallableFunction|null = null

  get trainingsOfTheDay(): ScheduleWithTime[] {
    const schedulesWithTime = this.allSchedulesWithTime
    if (schedulesWithTime.length) {
      return schedulesWithTime.sort((scheduleA, scheduleB) => {
          return scheduleA.time < scheduleB.time ? -1 : 1
        })
    } else {
      return []
    }
  }

  timeLeftToTheNextTraining(): number | null {
    const nearestTraining = this.trainingsOfTheDay[0]
    if (nearestTraining) {
      return nearestTraining.time - Date.now()
    } else {
      return null
    }
  }

  @Mutation
  private updateSchedulesWithTime() {
    this.allSchedulesWithTime = ([] as ScheduleWithTime[]).concat(
      schedulesService.getVirtualClassesSchedulesWithTime(virtualClassesModule.schedules, virtualClassesModule.classes),
      schedulesService.getRegularWorkoutsSchedulesWithTime(workoutsModule.scheduledWorkouts),
      schedulesService.getMssWorkoutsSchedulesWithTime(mssWorkoutsModule.scheduledWorkouts),
      schedulesService.getMonthlyTrainingsSchedulesWithTime(monthlyTrainingsModule.scheduledMonthlyTrainings)
    )
  }
  
  @Mutation
  watchForRegularWorkoutsSchedule(watcher: CallableFunction) {
    if (this.regularWorkoutScheduleWatcher) {
      this.regularWorkoutScheduleWatcher()
    }
    this.regularWorkoutScheduleWatcher = watcher
  }
  
  @Mutation
  watchForMssWorkoutsSchedule(watcher: CallableFunction) {
    if (this.mssWorkoutsScheduleWatcher) {
      this.mssWorkoutsScheduleWatcher()
    }
    this.mssWorkoutsScheduleWatcher = watcher
  }
  
  @Mutation
  watchForVirtualClassesSchedule(watcher: CallableFunction) {
    if (this.virtualClassesScheduleWatcher) {
      this.virtualClassesScheduleWatcher()
    }
    this.virtualClassesScheduleWatcher = watcher
  }
  
  @Mutation
  watchForMonthlyTrainingsSchedule(watcher: CallableFunction) {
    if (this.monthlyTrainingsScheduleWatcher) {
      this.monthlyTrainingsScheduleWatcher()
    }
    this.monthlyTrainingsScheduleWatcher = watcher
  }

  @Action
  update() {
    this.updateSchedulesWithTime()

    this.watchForRegularWorkoutsSchedule(
      workoutsModule.$watch(module => module.scheduledWorkouts, () => this.updateSchedulesWithTime())
    )

    this.watchForMssWorkoutsSchedule(
      mssWorkoutsModule.$watch(module => module.scheduledWorkouts, () => this.updateSchedulesWithTime())
    )

    this.watchForVirtualClassesSchedule(
      virtualClassesModule.$watch(module => module.schedules, () => this.updateSchedulesWithTime())
    )

    this.watchForMonthlyTrainingsSchedule(
      monthlyTrainingsModule.$watch(module => module.scheduledMonthlyTrainings, () => this.updateSchedulesWithTime())
    )
  }
}

export const schedulesAggregatorModule = new SchedulesAggregatorModule({ store, name: 'schedulesAggregatorModule' })