import { PreviewSlideData, WorkoutWuCd } from '@/types/workouts'
import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'
import RoundPreviewComponent from '../round-preview/round-preview.component.vue'

@Component({
    components: {
        'round-preview-component': RoundPreviewComponent
    }
})
export default class TrainingPreviewComponent extends Vue {
    @Prop({ type: Array, default: []}) workoutData!: PreviewSlideData[]

    get warmingUpSlides() {
        return this.workoutData.filter(workout => workout.activity_type == WorkoutWuCd.WU)
    }

    get coolingDownSlides() {
        return this.workoutData.filter(workout => workout.activity_type == WorkoutWuCd.CD)
    }

    get actualWorkoutSlides() {
        return this.workoutData.filter(workout => workout.activity_type == undefined)
    }

    get hasWarmingUps() {
        return this.warmingUpSlides.length > 0
    }
    
    get hasCoolingDowns() {
        return this.coolingDownSlides.length > 0
    }

    @Emit()
    onPreviewUpdate(d: {workoutPartIdx: number; roundIdx?: number}) {
        return d
    }

    onRoundUpdate(roundIdx: number) {
        this.onPreviewUpdate({ workoutPartIdx: this.warmingUpSlides.length, roundIdx })
    }

    onWarmingUpTouch(workoutPartIdx: number) {
        this.onPreviewUpdate({ workoutPartIdx })
    }

    onCoolingDownTouch(coolingDownIdx: number) {
        this.onPreviewUpdate({ workoutPartIdx: (this.warmingUpSlides.length + coolingDownIdx + 1) })
    }
}