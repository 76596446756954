import { render, staticRenderFns } from "./egym-training-active.component.vue?vue&type=template&id=556e5ee4"
import script from "./egym-training-active.component.ts?vue&type=script&lang=ts&external"
export * from "./egym-training-active.component.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports