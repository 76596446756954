import { CommandType } from "@/types/socket";


class ControlCommandsService {

    formUpAMessage(channel: string, command: CommandType, payload: {[key: string]: any} | null = null): string {

        const msg = { channel, command }

        if (payload) {
            return JSON.stringify({ ...msg, 
                payload: JSON.stringify(payload)
            })
        }

        return JSON.stringify(msg)
    }
}

export const controlCommandsService = new ControlCommandsService()