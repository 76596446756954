import { MssWorkoutSchedule, RegularWorkoutSchedule, Schedule, SpecificScheduleWithTime, MonthlyTrainingSchedule } from '@/types/schedule';
import { VirtualClassApiConfig } from '@/types/virtual-classes';
import { BaseWorkout, MonthlyTrainingCircuit } from '@/types/workouts';

class SchedulesService {
  getVirtualClassesSchedulesWithTime(schedules: Schedule[], virtualClasses: VirtualClassApiConfig[]) {
    return schedules.reduce((schedulesWithTime, currentSchedule) => {
      const virtualClass = virtualClasses.find(virtualClass => virtualClass.id === currentSchedule.category)
      if (virtualClass) {
        schedulesWithTime = schedulesWithTime.concat(this.generateSchedulesWithTime<VirtualClassApiConfig>(currentSchedule, virtualClass, virtualClass.attributes.name))
      }
      return schedulesWithTime
    }, [] as SpecificScheduleWithTime<VirtualClassApiConfig>[])
  }

  getRegularWorkoutsSchedulesWithTime(schedules: RegularWorkoutSchedule[]): SpecificScheduleWithTime<BaseWorkout>[] {
    return schedules ? schedules.reduce((schedulesWithTime, currentSchedule) => {
      if (currentSchedule.workout) {
        schedulesWithTime = schedulesWithTime.concat(this.generateSchedulesWithTime<BaseWorkout>(currentSchedule, currentSchedule.workout, currentSchedule.workout.name))
      }
      return schedulesWithTime
    }, [] as SpecificScheduleWithTime<BaseWorkout>[]) : []
  }

  getMssWorkoutsSchedulesWithTime(schedules: MssWorkoutSchedule[]): SpecificScheduleWithTime<BaseWorkout>[] {
    return schedules.reduce((schedulesWithTime, currentSchedule) => {
      if (currentSchedule.workout) {
        schedulesWithTime = schedulesWithTime.concat(this.generateSchedulesWithTime<BaseWorkout>(currentSchedule, currentSchedule.workout, currentSchedule.workout.name))
      }
      return schedulesWithTime
    }, [] as SpecificScheduleWithTime<BaseWorkout>[])
  }

  getMonthlyTrainingsSchedulesWithTime(schedules: MonthlyTrainingSchedule[]): SpecificScheduleWithTime<MonthlyTrainingCircuit>[] {
    return schedules.reduce((schedulesWithTime, currentSchedule) => {
      if (currentSchedule.workout) {
        schedulesWithTime = schedulesWithTime.concat(this.generateSchedulesWithTime<MonthlyTrainingCircuit>(currentSchedule, currentSchedule.workout, currentSchedule.workout.name))
      }
      return schedulesWithTime
    }, [] as SpecificScheduleWithTime<MonthlyTrainingCircuit>[])
  }

  generateSchedulesWithTime<T>(schedule: Schedule, training: T, name: string): SpecificScheduleWithTime<T>[] {
    const schedulesWithTime: SpecificScheduleWithTime<T>[] = []

    const { startTs, category } = schedule

    schedulesWithTime.push({
      training,
      name,
      category,
      time: startTs*1000
    })
    return schedulesWithTime
  }
}

export const schedulesService = new SchedulesService()