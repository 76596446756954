export enum CACHE_SECTION {
    VITRUAL_CLASSES = 'virtual-classes',
    WORKOUTS = 'workouts'
}

export class CacheService {

    private cacheInstances: Map<string, Cache> = new Map()

    private init(name: string): Promise<Cache> {
        return window.caches.open(name)
    }

    private async getInstance(sectionName: string) {
        if (!this.cacheInstances.has(sectionName)) {
            const cache = await this.init(sectionName)
            this.cacheInstances.set(sectionName, cache)
            return cache
        }
        return this.cacheInstances.get(sectionName)
    }

    match(sectionName: string, fileUrl: string):  Promise<string>  {

        return new Promise((resolve, reject) => {

            return this.getInstance(sectionName).then(cacheInstance => {
                if (cacheInstance != undefined) {

                    cacheInstance.match(fileUrl).then(function (response) {
                        if (response) {
                            response.blob().then(function (fileBlob) {
                                resolve(URL.createObjectURL(fileBlob))
                            })
                        } else {
                            cacheInstance.add(fileUrl).finally(() => resolve(fileUrl))
                        }
                    }).catch((err) => reject(err))
                }
            })
        })
    }

    refresh(sectionName: string, keys: string[]) {

        this.getInstance(sectionName).then(cacheInstance => {
            if (cacheInstance != undefined) {
                cacheInstance.keys().then((cacheKeys) => {
                    for (const request of cacheKeys) {
                        if (keys.indexOf(request.url) < 0) {
                            cacheInstance.delete(request)
                        }
                    }
                })
            }
        })
    }
}
