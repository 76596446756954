import { TRAINING_TRIGGERED_BY } from "@/types/active-training"
import { CTRL_COMMAND, CTRL_COMMAND_FEEDBACK, FeedbackCtrlCmdMessage } from "@/types/socket"
import { TIMER_TYPE } from "@/types/timer"
import { BaseWorkout, PAUSE_TIMEOUT, RESUME_TIMEOUT, WorkoutTimeFrame } from "@/types/workouts"
import { ctrlCmdModule } from "../modules/control-commands.module"
import runtimeProcessorModule from "../modules/runtime-processor.module"
import workoutActiveModule from "../modules/workout-active.module"
import workoutsSyncManager from "./workouts.sync.manager"
import {notificationModule} from "../modules/notification.module";
import {NotificationCommand, NotificationType} from "@/types/notification";

class WorkoutActiveManager {

    sync(cmd: FeedbackCtrlCmdMessage) {

        const { value } = cmd
        const { id, time } = value

        if (id == workoutActiveModule.workout?.id && !workoutActiveModule.isItAnRft && time) {
            workoutsSyncManager.sync(time, workoutActiveModule)
        }

        ctrlCmdModule.cmdDisposal(cmd.event)
    }

    forward() {

        workoutActiveModule.onResume(runtimeProcessorModule.currentTime / 1000)

        const { paused } = runtimeProcessorModule.status
        if (paused) {
            runtimeProcessorModule.resume()
        }

        if (!workoutActiveModule.isItPreparationTime && workoutActiveModule.isItTheLastPart &&
            ((workoutActiveModule.isItACircuit && workoutActiveModule.isItTheLastRound) || workoutActiveModule.isItTheLastTimeFrame)) {

            this.stop()

        } else {

            const { id } = workoutActiveModule.workout as BaseWorkout

            ctrlCmdModule.triggerCtrlCmd({
                trigger: CTRL_COMMAND.WORKOUT_FORWARD, value: { id }
            })
        }
    }

    backward() {

        const { paused } = runtimeProcessorModule.status
        if (paused) {
            runtimeProcessorModule.resume()
        }
        const { id } = workoutActiveModule.workout as BaseWorkout

        ctrlCmdModule.triggerCtrlCmd({
            trigger: CTRL_COMMAND.WORKOUT_BACKWARD, value: { id }
        })
    }

    start() {

        if (workoutActiveModule.triggeredBy == TRAINING_TRIGGERED_BY.USER) {

            const { id } = workoutActiveModule.workout as BaseWorkout
            ctrlCmdModule.triggerCtrlCmd({ trigger: CTRL_COMMAND.WORKOUT_START, value: { id, time: 10 } })

        } else {
            
            workoutActiveModule.start()
        }
    }

    pause() {
        ctrlCmdModule.triggerCtrlCmd({
            trigger: CTRL_COMMAND.WORKOUT_PAUSE, value: {
                id: workoutActiveModule.workout?.id as string
            }
        })
        runtimeProcessorModule.pause()
        const tDialog = window.setTimeout(() => {
            notificationModule.showNotification({ command: NotificationCommand.RESUME_WORKOUT, type: NotificationType.CONFIRM })
            const tResume = window.setTimeout(() => this.resume(), RESUME_TIMEOUT)
            runtimeProcessorModule.changeActionTimeout(tResume)
        }, PAUSE_TIMEOUT)
        runtimeProcessorModule.changeActionTimeout(tDialog)
    }

    stop() {
        ctrlCmdModule.triggerCtrlCmd({ 
            trigger: CTRL_COMMAND.WORKOUT_STOP, value: { 
                id: workoutActiveModule.workout?.id as string
            }
        })
        runtimeProcessorModule.stop()
    }

    resume() {
        const { paused } = runtimeProcessorModule.status
        if (paused) {
            runtimeProcessorModule.resume()
            runtimeProcessorModule.changeActionTimeout(0)
            notificationModule.hideNotification()
            ctrlCmdModule.triggerCtrlCmd({
                trigger: CTRL_COMMAND.WORKOUT_RESUME, value: {
                    id: workoutActiveModule.workout?.id as string,
                    time: runtimeProcessorModule.elapsedTimeSeconds
                }
            })
        }
    }

    controlCommandsProcessor(cmd: FeedbackCtrlCmdMessage) {

        if (cmd?.event === CTRL_COMMAND_FEEDBACK.WORKOUT_STARTED) {
            workoutActiveModule.start()
        } else if (cmd?.event === CTRL_COMMAND_FEEDBACK.WORKOUT_STOPPED) {
            ctrlCmdModule.cleanUp()
        } else if ([CTRL_COMMAND_FEEDBACK.WORKOUT_GOT_FORWARD, CTRL_COMMAND_FEEDBACK.WORKOUT_GOT_BACKWARD]
            .indexOf(cmd?.event) >= 0) {
            const { value } = cmd
            const { time } = value
            if (time != undefined) {
                workoutsSyncManager.sync(time, workoutActiveModule)
            }
        } else if (cmd?.event === CTRL_COMMAND_FEEDBACK.WORKOUT_PAUSED) {
            runtimeProcessorModule.pause()
            const { value } = cmd
            const { time } = value
            if (time != undefined) {
                workoutsSyncManager.sync(time, workoutActiveModule)
            }
        } else if (cmd?.event === CTRL_COMMAND_FEEDBACK.WORKOUT_RESUMED) {
            const { value } = cmd
            const { time } = value
            if (time != undefined) {
                workoutsSyncManager.sync(time, workoutActiveModule)
            }
            if (workoutActiveModule.isItAnRft && !workoutActiveModule.isItPreparationTime) {
                workoutActiveModule.onResume(runtimeProcessorModule.currentTime / 1000)
            } else {
                workoutActiveModule.onResume(runtimeProcessorModule.elapsedTimeSeconds)
            }
            runtimeProcessorModule.resume()
        }
        
        ctrlCmdModule.cmdDisposal(cmd.event)
    }

    timeFrameSwitchingProcessor(activeTimeFrame: WorkoutTimeFrame) {

        let { duration } = activeTimeFrame
        
        if (duration > 0) {

            if (workoutActiveModule.isItAnRft && !workoutActiveModule.isItPreparationTime) {
                duration = 0
                runtimeProcessorModule.changeTimerType(TIMER_TYPE.UP)
            } else {
                runtimeProcessorModule.changeTimerType(TIMER_TYPE.DOWN)
            }
            runtimeProcessorModule.start(duration)
        }
    }

    syncOverTime(arg: { currentTime: number; prevTimeMarker: number }) {

        const { currentTime, prevTimeMarker } = arg

        const xSecondsLeft = workoutActiveModule.isItAnRft ? Math.floor(currentTime / 1000) : Math.ceil(currentTime / 1000)
        const prevValue = workoutActiveModule.isItAnRft ? Math.floor(prevTimeMarker / 1000) : Math.ceil(prevTimeMarker / 1000)
        const totalTime = runtimeProcessorModule.totalTime / 1000

        if ((workoutActiveModule.isItAnRft || xSecondsLeft < totalTime) && xSecondsLeft != prevValue &&
            xSecondsLeft % 5 === 0 && xSecondsLeft >= 5) { // Every 5 seconds

            const time = this.getCurrentWorkoutTime()

            if (time > workoutActiveModule.elapsedTime) {
                
                const diff = time - workoutActiveModule.elapsedTime
                let currentTimeCorrection = 0

                if (workoutActiveModule.isItAnRft && !workoutActiveModule.isItPreparationTime) {
                    currentTimeCorrection = diff
                    runtimeProcessorModule.currentTimeReset(currentTimeCorrection * 1000)
                } else if (totalTime > diff) {
                    currentTimeCorrection = totalTime - diff
                    runtimeProcessorModule.currentTimeReset(currentTimeCorrection * 1000)
                }
            }
        }
    }

    private getCurrentWorkoutTime(): number {
        return (Date.now() - workoutActiveModule.startedAt as number) / 1000
    }

    destroy() {
        runtimeProcessorModule.stop()
        notificationModule.hideNotification()
        workoutActiveModule.resetToDefault()
    }
}

export default new WorkoutActiveManager()