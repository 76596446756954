import workoutService from '@/services/workout.service'
import { BaseAdditionalActivity, BaseWorkout, CircuitWorkout, ComplexWorkout, PreviewSlideData, WorkoutTypes, WorkoutIntensities } from '@/types/workouts'
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules'
import store from '../index'
import { unique } from '@/utils/array.utils'

@Module
class WorkoutDescriptionModule extends VuexModule {

    slidesArray: PreviewSlideData[] = []

    workout: BaseWorkout | null = null

    previewMode = false

    get workoutPreviewSlidesData() {
        return this.slidesArray
    }

    get workoutDescription() {
        return this.workout?.description
    }

    get workoutName() {
        return this.workout?.name
    }

    get descriptionTags(): string[] {
        const duration = this.workout ? workoutService.getWorkoutDuration(this.workout) : 0
        const level = this.workout ? WorkoutIntensities.get(this.workout.difficulty) as string : ''
        const trainer = this.workout?.creatorName as string
        return unique([ duration, level, trainer].flat().filter(tag => tag) as string[])
    }

    get fitnessTypeTag(): string {
        return this.workout?.type as string
    }

    get equipmentTags(): string[] {
        return this.workout ? this.workout.gearList : []
    }

    get durationFormat(): string {
        return this.workout ? [ Math.floor(this.workout?.totalTime / 60).toString(), 'min' ].join(' ') : ''
    }

    get coverImageUrl(): string | null {
        
        if (this.workout && this.workout.coverImage) {
            const { small } = this.workout.coverImage
            return !small ? null : small
        }
        return null
    }

    @Mutation
    parse(workout: BaseWorkout) {

        this.workout = workout

        this.slidesArray = []

        // eslint-disable-next-line
        const { warming_ups, cooling_downs, ...theWorkoutItself } = workout

        // eslint-disable-next-line
        const workoutPartsArray = (warming_ups as (BaseAdditionalActivity | ComplexWorkout)[])
            .concat(([theWorkoutItself] as ComplexWorkout[]), cooling_downs) as (BaseAdditionalActivity | ComplexWorkout)[]

        for (const partIdx in workoutPartsArray) {

            // eslint-disable-next-line
            if ((workoutPartsArray[partIdx] as BaseAdditionalActivity).activity_type) {

                // eslint-disable-next-line
                const { name, activity_type, type, duration, timer, random } = workoutPartsArray[partIdx] as BaseAdditionalActivity

                const exercises = workoutService.parseExercises(workoutPartsArray[partIdx])

                const workInterval = (workoutPartsArray[partIdx] as any).roundsData ? (workoutPartsArray[partIdx] as any).roundsData[0].workInterval : 0

                const restInterval = (workoutPartsArray[partIdx] as any).roundsData ? (workoutPartsArray[partIdx] as any).roundsData[0].restInterval : 0

                // eslint-disable-next-line
                this.slidesArray.push({ name, activity_type, type, duration, exercises, timer, random, workInterval, restInterval })

            } else if ((workoutPartsArray[partIdx] as BaseWorkout).type == WorkoutTypes.CIRCUIT) {

                for (const roundsIdx in (theWorkoutItself as CircuitWorkout).roundsData) {

                    const roundNumber = parseInt(roundsIdx)

                    const exercises = (theWorkoutItself as CircuitWorkout).roundsData[roundsIdx].circuits[0].exercises

                    const duration = (theWorkoutItself as CircuitWorkout).roundsData[roundsIdx].duration

                    const workInterval = (theWorkoutItself as CircuitWorkout).roundsData[roundsIdx].workInterval

                    const restInterval = (theWorkoutItself as CircuitWorkout).roundsData[roundsIdx].restInterval

                    const { name, type, rounds } = theWorkoutItself

                    this.slidesArray.push({ name, type, rounds, exercises, round: roundNumber, roundDuration: duration, workInterval, restInterval })
                }
            } else {

                const exercises = workoutService.parseExercises((theWorkoutItself as ComplexWorkout))

                const { name, type, rounds } = theWorkoutItself

                this.slidesArray.push({ name, type, rounds, exercises })
            }
        }
    }

    @Mutation
    updatePrviewMode(previewMode: boolean) {
        this.previewMode = previewMode
    }

    @Action
    init(workout: BaseWorkout) {
        this.parse(workout)
    }

    @Action
    togglePreviewMode() {
        this.updatePrviewMode(!this.previewMode)
    }
}

export default new WorkoutDescriptionModule({ store, name: 'workoutDescriptionModule' })