import { VuexModule, Mutation, Action, Module } from 'vuex-class-modules'
import store from '../index'
import { NotificationCommand, NotificationType, UserAnswer, NotificationTitle } from '@/types/notification'
import { bluredContentModule } from './blured-content.module'

const notificationsMap  = new Map([
    [ NotificationCommand.CANNOT_STOP_TRAINING, 'error_messages.training_cannot_be_stopped' ],
    [ NotificationCommand.SCHEDULED_TRAINING_WILL_START_SOON, 'error_messages.scheduled_training_will_start_soon' ],
    [ NotificationCommand.ARE_YOU_SURE, 'error_messages.are_you_sure_to_stop_training' ],
    [ NotificationCommand.WRONG_CREDENTIALS, 'error_messages.credentials_are_wrong' ],
    [ NotificationCommand.ARE_YOU_SURE_TO_START_RFT, 'error_messages.are_you_sure_to_start_rft' ],
    [ NotificationCommand.SOMETHING_WENT_WRONG, 'error_messages.something_went_wrong' ],
    [ NotificationCommand.NO_PLAYER_FEEDBACK, 'error_messages.no_player_feedback' ],
    [ NotificationCommand.USER_NOT_FOUND, 'error_messages.no_user_found'],
    [ NotificationCommand.EGYM_LOGOUT, 'error_messages.sure_you_want_logout'],
    [ NotificationCommand.RESUME_WORKOUT, 'common.resume_workout_confirmation']
])
const notificationsTitleMap = new Map([
    [ NotificationTitle.CANNOT_STOP_TRAINING, 'error_titles.stopping_scheduled_class' ],
    [ NotificationTitle.SCHEDULED_TRAINING_WILL_START_SOON, 'error_titles.scheduled_class' ],
    [ NotificationTitle.ARE_YOU_SURE, 'error_titles.stopping_class' ],
    [ NotificationTitle.WRONG_CREDENTIALS, 'error_titles.error' ],
    [ NotificationTitle.ARE_YOU_SURE_TO_START_RFT, 'error_titles.rtf' ],
    [ NotificationTitle.SOMETHING_WENT_WRONG, 'error_titles.error' ],
    [ NotificationTitle.NO_PLAYER_FEEDBACK, 'error_titles.error' ],
    [ NotificationTitle.USER_NOT_FOUND, 'error_titles.no_user_found'],
    [ NotificationTitle.EGYM_LOGOUT, 'error_titles.stopping_class'],
    [ NotificationTitle.RESUME_WORKOUT, 'common.resume']
])

@Module
class NotificationModule extends VuexModule {
    
    private _title = ''
    private _message = ''
    private _dynamicPart = ''
    private _type: NotificationType = NotificationType.ALERT
    private _userAnswer = UserAnswer.NONE
    private _command?: NotificationCommand

    get title(){
        return this._title
    }

    get message() {
        return this._message
    }

    get dynamicPart() {
        return this._dynamicPart
    }

    get type() {
        return this._type
    }

    get answer() {
        return this._userAnswer
    }

    get isActive() {
        return !!this.message.length
    }

    get command() {
        return this._command
    }
    
    @Mutation
    private updateUserAnswer(answer: UserAnswer) {
        this._userAnswer = answer
    }

    @Mutation
    private updateType(type: NotificationType) {
        this._type = type
    }
    
    @Mutation
    private updateMessage(command: NotificationCommand) {
        this._command = command
        this._message = notificationsMap.get(NotificationCommand[command]) as string
        this._title = notificationsTitleMap.get(NotificationTitle[command]) as string
    }

    @Mutation
    private empty()
    {
        this._message = ''
        this._title = ''
        this._dynamicPart = ''
    }

    @Mutation
    private updateDynamicPart(dynamicData: string) {
        this._dynamicPart = dynamicData
    }

    @Action
    showNotification({ command, type }: { command: NotificationCommand; type: NotificationType }) {
        this.updateUserAnswer(UserAnswer.NONE)
        this.updateType(type)
        this.updateMessage(command)
        bluredContentModule.toggleStatus(true)
    }

    @Action
    showNotificationForRFT(time: string) {
        this.updateDynamicPart(time)
        this.showNotification({ command: NotificationCommand.ARE_YOU_SURE_TO_START_RFT, type: NotificationType.CONFIRM })
    }

    @Action
    saveUserAnswer(userAnswer: UserAnswer) {
        this.updateUserAnswer(userAnswer)
    }

    @Action
    hideNotification() {
        this.empty()
        bluredContentModule.toggleStatus(false)
    }
}

export const notificationModule = new NotificationModule({ store, name: 'notification' })