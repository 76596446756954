import Vue from "vue"
import { notificationModule } from "@/store/modules/notification.module"
import { schedulesAggregatorModule } from "@/store/modules/schedules-aggregator.module"
import workoutActiveModule from "@/store/modules/mss-workout-active.module"
import { TRAINING_TRIGGERED_BY } from "@/types/active-training"
import { NotificationCommand, NotificationType, UserAnswer } from "@/types/notification"
import { BaseWorkout, MssWorkout, WorkoutIntensities, WorkoutTypes } from "@/types/workouts"
import { Route, NavigationGuardNext } from "vue-router"
import { appModule } from "@/store/modules/app.module"
import {convertTimeToString} from "@/utils/string.utils"
import {workoutsModule} from "@/store/modules/workouts.module"
import {eGymTrainingsModule} from "@/store/modules/egym-trainings.module"

export default function (to: Route, from: Route, next: NavigationGuardNext<Vue>) {

    const routeArgs = to.params as { id: string; triggeredBy?: TRAINING_TRIGGERED_BY }

    const { id } = routeArgs
    let { triggeredBy } = routeArgs

    const workout = eGymTrainingsModule.workouts.find(workout => {
        return workout.id === id
    }) as MssWorkout

    if (!triggeredBy) {
        triggeredBy = TRAINING_TRIGGERED_BY.USER
    }

    const timeToNearestTraining = schedulesAggregatorModule.timeLeftToTheNextTraining();
    if (timeToNearestTraining) {
        if ((workout as BaseWorkout).totalTime * 1000 > timeToNearestTraining) {
            notificationModule.showNotification({command: NotificationCommand.SCHEDULED_TRAINING_WILL_START_SOON, type: NotificationType.ALERT});
            return;
        }
    }

    if (workout) {
        const workoutCategory = workoutsModule.workoutFitnessCategories
            .filter(category => category.id == workout.category)[0]?.attributes.name
        workoutActiveModule.init({ workout, triggeredBy })
        appModule.stopContentRefreshing()
        Vue.gtm.trackEvent({
            event: 'egym-training-start',
            scheduledStart: triggeredBy == TRAINING_TRIGGERED_BY.SCHEDULE,
            workoutName: workout.name,
            workoutDuration: convertTimeToString(workout.totalTime * 1000),
            workoutLevel: WorkoutIntensities.get(workout.difficulty),
            workoutCategory,
        })
        next()
    }
}