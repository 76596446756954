
import { dexieDbService } from "@/services/dexie-db.service"
import { httpService } from "@/services/http.service"
import { CtrlCmdMessage, CTRL_COMMAND, CTRL_COMMAND_FEEDBACK } from "@/types/socket"
import { NotificationPayloadValue } from "@/types/workouts"
import { ctrlCmdModule } from "../modules/control-commands.module"
import { deviceModule } from "../modules/device.module"
import { mssWorkoutsInZoneModule } from "../modules/mss-workouts-in-zone.module"
import { mssWorkoutsModule } from "../modules/mss-workouts.module"
import runtimeProcessorModule from "../modules/runtime-processor.module"
import { workoutsModule } from "../modules/workouts.module"

class ControlCommandsManger {

    // Content update
    onContentUpdate(contentUpdate: CtrlCmdMessage | undefined) {

      if (contentUpdate != undefined) {

        const onUpdateSucces = () =>
          httpService.notificationDelivered(contentUpdate.value as NotificationPayloadValue)

        const { id, notificationId } = contentUpdate.value as NotificationPayloadValue

        const onLocalQueueUpdate = (mss=false) =>  dexieDbService.workouts.add({
          uuid: id as string,
          time: Date.now(),
          notificationId,
          mss,
          // eslint-disable-next-line
        }).then(onUpdateSucces).catch(err => console.error(err))

        if (contentUpdate.trigger === CTRL_COMMAND.REALTIME_WORKOUT_UPDATE && id != undefined) {

          if (!runtimeProcessorModule.isItInProcess) {
            // eslint-disable-next-line
            workoutsModule.updateWorkout(id).then(onUpdateSucces).catch(err => console.error(err))
          } else {
            onLocalQueueUpdate()
          }
        } else if (contentUpdate.trigger === CTRL_COMMAND.REALTIME_WORKOUT_MSS_UPDATE && id != undefined) {

          if (!runtimeProcessorModule.isItInProcess) {
            // eslint-disable-next-line
            mssWorkoutsModule.updateWorkout(id).then(onUpdateSucces).catch(err => console.error(err))
            // eslint-disable-next-line
            mssWorkoutsInZoneModule.updateWorkout(id).then(onUpdateSucces).catch(err => console.error(err))
          } else {
            onLocalQueueUpdate(true)
          }
        }
  
        if (contentUpdate.trigger != undefined) {
          ctrlCmdModule.cmdDisposal(contentUpdate.trigger)
        }
      }
    }
}

export default new ControlCommandsManger()