import EGymAddUserComponent from "@/components/e-gym-add-user/e-gym-add-user.component.vue";
import celebrationManager from "@/store/managers/celebration.manager";
import { ctrlCmdModule } from "@/store/modules/control-commands.module";
import runtimeProcessorModule from "@/store/modules/runtime-processor.module";
import { PAGES } from "@/types/router";
import { CTRL_COMMAND_FEEDBACK } from "@/types/socket";
import Vue from "vue";
import Component from "vue-class-component";
import timeformat from '@/filters/timeformat'
import { eGymTrainingsModule } from "@/store/modules/egym-trainings.module";
import workoutsStoreManager from "@/store/managers/workouts.store.manager";
import egymDataManager from "@/store/managers/egym-data.manager";

@Component({
    components: {
        'egym-add-user-component': EGymAddUserComponent
    },
    filters: { timeformat }
})
export default class CelebrationComponent extends Vue {
    private runtimeProcessWatcher!: CallableFunction
    private controlCommandsWatcher?: CallableFunction
    private workoutTimeWatcher!: CallableFunction

    get currentTime() {
        return runtimeProcessorModule.currentTime
    }

    get totalTime(): number {
        return parseInt(this.$router.currentRoute.params.totalTime) * 1000
    }

    get showHours() {
        return (this.totalTime / 60 / 60) / 1000 >= 1
    }

    get hasWorkoutBeenPlayed() {
        return eGymTrainingsModule.timeframesTrace.length + 1
    }

    mounted() {

        if (!this.hasWorkoutBeenPlayed || !this.totalTime) {
            this.$router.push({ name: PAGES.HOME })
        }

        this.runtimeProcessWatcher = runtimeProcessorModule.$watch(timerModule => timerModule.status, status => {

            const { finished } = status

            if (finished) {
                egymDataManager.sendData()
                workoutsStoreManager.deleteEgymTrainings()
                this.$router.push({ name: PAGES.HOME })
            }

        })

        this.workoutTimeWatcher = runtimeProcessorModule.$watch(timerModule => timerModule.currentTime,
            (currentTime, prevTimeMarker) => {
                celebrationManager.syncOverTime({ currentTime, prevTimeMarker })
            })

        this.controlCommandsWatcher = ctrlCmdModule.$watch(module => module.celebrationCmd, celebrationCmd => {

            if (celebrationCmd != undefined) {
                if ([ CTRL_COMMAND_FEEDBACK.CELEBRATION_GOT_STARTED ].indexOf(celebrationCmd.event) >= 0) {
                    if (celebrationCmd.sentAt) {
                        celebrationManager.sync(celebrationCmd.sentAt)
                    }
                }
                ctrlCmdModule.cmdDisposal(celebrationCmd.event)
            }
        })

        celebrationManager.start()
    }

    beforeDestroy() {
        [this.runtimeProcessWatcher,this.controlCommandsWatcher, this.workoutTimeWatcher].map(unsubscibe => unsubscibe != undefined ? unsubscibe() : 0)
        celebrationManager.destroy()
        // eGymTrainingsModule.resetToDefault()
    }
}