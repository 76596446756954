export enum ColorNames {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  BASIC_WHITE = 'basicWhite',
  BASIC_ANTHRACITE = 'basicAnthracite',
  BACKGROUND = 'background',
  FONT_A = 'fontA',
  FONT_B = 'fontB',
  FONT_C = 'fontC',
}

export enum BackgroundImages {
  SCREENSAVER = 'svtScreensaverImg',
  WORKOUT_SCREENSAVER = 'svtWorkoutScreensaverImg'
}
export interface ThemeConfig {
  [ColorNames.PRIMARY]: string;
  [ColorNames.SECONDARY]: string;
  [ColorNames.BASIC_WHITE]: string;
  [ColorNames.BASIC_ANTHRACITE]: string;
  [ColorNames.BACKGROUND]: string;
  [ColorNames.FONT_A]: string;
  [ColorNames.FONT_B]: string;
  [ColorNames.FONT_C]: string;
}

export interface CSSRuleConfig {
  selector: string;
  style: string;
  color: ColorNames;
  hexToRGB?: boolean;
}

export interface CSSImageRuleConfig {
  selector: string;
  style: string;
  image: BackgroundImages;
}