import Vue from 'vue'
import { Component, Ref } from 'vue-property-decorator'
import WorkoutTileComponent from '@/components/workout-tile/workout-tile.component.vue'
import InfoTagComponent from '@/components/info-tag/info-tag.component.vue'
import MssWorkoutTileComponent from '@/components/mss-workout-tile/mss-workout-tile.component.vue'
import { allWorkoutsOverviewModule } from '@/store/modules/all-workouts-overview.module'
import { WorkoutFilterType, BaseWorkoutFilter, WorkoutEquipmentFilter, WorkoutTypeFilter, WorkoutFitnessCategoryFilter, WorkoutDurationFilter, WorkoutTrainerFilter, WorkoutIntensityFilter, WorkoutBodyPartFilter } from '@/types/workouts'
import workoutGeneralFIltersModule from '@/store/modules/workouts-general-filters.module'
import { FilterType } from '@/types/virtual-classes'
import workoutsGeneralFiltersModule from '@/store/modules/workouts-general-filters.module'
import workoutsStoreManager from '@/store/managers/workouts.store.manager'
import { allWorkoutsModule } from '@/store/modules/all-workouts.module'
import { PAGES } from '@/types/router'
import { workoutsModule } from '@/store/modules/workouts.module'
import { mssWorkoutsModule } from '@/store/modules/mss-workouts.module'
import { eGymTrainingsModule } from '@/store/modules/egym-trainings.module'

const itemsPerRow = 4

@Component({
  components: {
    'workout-tile-component': WorkoutTileComponent,
    'mss-workout-tile-component': MssWorkoutTileComponent,
    'info-tag-component': InfoTagComponent
  },
  beforeRouteEnter: (to, from, next) => {
    if (from.name != PAGES.MSS_WORKOUT_DESCRIPTION && from.name != PAGES.REGULAR_WORKOUT_DESCRIPTION) {
      workoutsStoreManager.backToDefault()
      allWorkoutsOverviewModule.reset()
    } 
    next()
  }
})
export default class WorkoutListPageCpmponent extends Vue {
  
  private searchDebounce?: number
  private scrollDebounce?: number
  private scrollTop = 0
  public menuIsTriggered = false
  @Ref() readonly scrollContainer!: any

  get workouts() { return allWorkoutsOverviewModule.filteredWorkouts }
  
  get durationFilterType() { return WorkoutFilterType.DURATION }
  get typeFilterType() { return WorkoutFilterType.TYPE }
  get fintessCategoryType() { return WorkoutFilterType.FITNESS_CATEGORY }
  get fitnessTypeFilterType() { return WorkoutFilterType.FITNESS_TYPE }
  get equipmentFilterType() { return WorkoutFilterType.EQUIPMENT }
  get trainerFilterType() { return WorkoutFilterType.TRAINER }
  get intensityFilterType() { return WorkoutFilterType.INTENSITY }
  get bodyPartFilterType() { return WorkoutFilterType.BODY_PART }
    
  get searchPhrase(): string {
    return workoutGeneralFIltersModule.searchPhraze
  }

  get tileHeightPx() {
    return window.screen.availWidth <= 1440 ? 283.81 : 370
  }

  get durationFilters(): WorkoutDurationFilter[] {
    return allWorkoutsModule.durations.map(durationFilter => {

      const selected = workoutsGeneralFiltersModule.durationFilters.findIndex(filter => durationFilter.id == filter.id) >= 0
      const { id, name } = durationFilter

      return {
        id,
        name,
        selected
      } as WorkoutDurationFilter
    })
  }

  get fitnessCategoryFilters(): WorkoutFitnessCategoryFilter[]  {
    return allWorkoutsModule.categories.map(category => {

      const { id, name, type } = category
      const selected = workoutsGeneralFiltersModule.fitnessCategoryFilters.findIndex((id) => category.id == id) >= 0

      return {
        id,
        name,
        type,
        selected
      } as WorkoutFitnessCategoryFilter
    })
  }

  get typeFilters(): WorkoutTypeFilter[] {
    return allWorkoutsModule.types.map(typeFilter => {

      const { id,  name, type  } = typeFilter
      const selected = workoutsGeneralFiltersModule.typeFilters.findIndex((id) => typeFilter.id == id) >= 0

      return {
        id,
        name,
        type,
        selected
      } as WorkoutTypeFilter
    })
  }

  get fitnessTypeFilters(): WorkoutTypeFilter[] {
    return allWorkoutsModule.fitnessTypes.map(typeFilter => {

      const { id,  name, type  } = typeFilter
      const selected = workoutsGeneralFiltersModule.fitnessTypeFilters.findIndex((id) => typeFilter.id == id) >= 0

      return {
        id,
        name,
        type,
        selected
      } as WorkoutTypeFilter
    })
  }

  get trainerFilters(): WorkoutTrainerFilter[] {
    return allWorkoutsModule.trainers.map(trainer => {

      const { id,  name, type  } = trainer
      const selected = workoutsGeneralFiltersModule.trainerFilters.findIndex((id) => trainer.id == id) >= 0

      return {
        id,
        name,
        type,
        selected
      } as WorkoutTrainerFilter
    })
  }

  get intensityFilters(): WorkoutIntensityFilter[] {
    return allWorkoutsModule.intensity.map(intensity => {

      const { id,  name, type  } = intensity
      const selected = workoutsGeneralFiltersModule.intensityFilters.findIndex((id) => intensity.id == id) >= 0

      return {
        id,
        name,
        type,
        selected
      } as WorkoutIntensityFilter
    })
  }

  get bodyPartFilters(): WorkoutBodyPartFilter[] {
    return allWorkoutsModule.bodyPart.map(bodyPart => {

      const { id, type  } = bodyPart
      const name = bodyPart.name.replace('_', ' ')
      const selected = workoutsGeneralFiltersModule.bodyPartFilters.findIndex((id) => bodyPart.id == id) >= 0

      return {
        id,
        name,
        type,
        selected
      } as WorkoutBodyPartFilter
    })
  }

  get equipmentFilters(): WorkoutEquipmentFilter[] {
    return allWorkoutsModule.equipment.map(equipment => {

      const { id,  name, type  } = equipment
      const selected = workoutsGeneralFiltersModule.equipmentFilters.findIndex((id) => equipment.id == id) >= 0

      return {
        id,
        name,
        type,
        selected
      } as WorkoutEquipmentFilter
    })
  }

  get isSearchActive() { return workoutGeneralFIltersModule.isSearchFilterActive }
  get isFiltersActive() { return workoutGeneralFIltersModule.isFiltersActive }

  get selectedIconClasses() {
    return ['selected', 'fontA-color-bg', 'primary-color-text']
  }

  get selectedFilterClasses() {
    return ['primary-color-bg', 'selected']
  }

  get isMenuOpen() {
    return eGymTrainingsModule.isFilterMenuOpen
  }

  created() {
    allWorkoutsModule.initialize({ regularWorkouts: workoutsModule.workouts, mssWorkouts: mssWorkoutsModule.workouts })
    // allWorkoutsOverviewModule.reset()
  }
  
  mounted() {
    this.toggleFilterMenu(false)  
  }

  toggleFilter(type: FilterType, filter: BaseWorkoutFilter) {
    const { id } = filter
    workoutGeneralFIltersModule.updateFilter({ id, type })
    workoutsStoreManager.applyFilters()
    this.scrollContainer.scrollTo({y: 0})
  }

  onChangeSearchPhrase(newPhrase: string) {
    window.clearTimeout(this.searchDebounce)
    this.searchDebounce = window.setTimeout(() => {
      workoutGeneralFIltersModule.updateSearchPhraze(newPhrase)
      workoutsStoreManager.applyFilters()
      this.scrollContainer.scrollTo({y: 0})
    }, 700)
  }

  onScroll(e: any) {
    window.clearTimeout(this.scrollDebounce)
    this.scrollDebounce = window.setTimeout(() => {
      this.scrollTop = e.scrollTop
      allWorkoutsOverviewModule.nextPage()
    }, 400)
  }

  renderTile(tileIndex: number) {
    const from = Math.floor(this.scrollTop / this.tileHeightPx) * itemsPerRow
    const to = from + (itemsPerRow * 4)
    return tileIndex >= from && tileIndex < to
  }

  clearFilters() {
    workoutGeneralFIltersModule.reset()
    this.scrollContainer.scrollTo({y: 0})
  }

  goToDescriptionPage(id: string, type: any) {
    if (type?.circuit_mss !== undefined && (this.$route.name != PAGES.REGULAR_WORKOUT_DESCRIPTION && this.$route.name != PAGES.MSS_WORKOUT_DESCRIPTION)) {
      this.$router.push({ name: PAGES.MSS_WORKOUT_DESCRIPTION, params: { id: id } })
    } else {
      this.$router.push({ name: PAGES.REGULAR_WORKOUT_DESCRIPTION, params: { id: id } })
    }
  }

  toggleFilterMenu(isOpen: boolean) {
    if (!this.menuIsTriggered) {
      eGymTrainingsModule.getFilterMenuState(isOpen)
      this.menuIsTriggered = true
    }

    window.setTimeout(() => {
      this.menuIsTriggered = false
    }, 200)
  }

  destroyed() {
    this.toggleFilterMenu(false)
  }
}