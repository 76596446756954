import Vue from 'vue'
import { Component, Ref } from 'vue-property-decorator'
import VirtualClassTileComponent from '@/components/virtual-class-tile/virtual-class-tile.component.vue'
import { virtualClassesOverviewModule } from '@/store/modules/virtual-classes-overview.module'
import { virtualClassesFiltersModule } from '@/store/modules/virtual-classes-filters.module'
import InfoTagComponent from '@/components/info-tag/info-tag.component.vue'
import { BaseFilter, CategoryFilter, DurationFilter, EquipmentFilter, FilterType, IntensityFilter, TrainerFilter, TypeFilter, VirtualClassApiConfig, LanguageFilter, THUMBNAIL_SMALL_URL } from '@/types/virtual-classes'
import ListOverlayLoaderComponent from '@/components/list-overlay-loader/list-overlay-loader.component.vue'
import virtualClassesStoreManager from '@/store/managers/virtual-classes.store.manager'
import { virtualClassesModule } from '@/store/modules/virtual-classes.module'
import { PAGES } from '@/types/router'
import { eGymTrainingsModule } from '@/store/modules/egym-trainings.module'

const itemsPerRow = 4

@Component({
  components: {
    'virtual-class-tile-component': VirtualClassTileComponent,
    'info-tag-component': InfoTagComponent,
    'list-overlay-loader': ListOverlayLoaderComponent
  },
  beforeRouteEnter: (to, from, next) => {
    if (from.name != PAGES.VIRTUAL_CLASS_DESCRIPTION) {
      virtualClassesStoreManager.backToDefault()
      virtualClassesStoreManager.load()
    }
    next()
  },
})
export default class VirtualClassListPageComponent extends Vue {
  
  private renderingTimeout = 0
  private searchDebounce?: number
  private scrollDebounce?: number
  private scrollTop = 0
  @Ref() readonly scrollContainer!: any

  get searchPhraze(): string {
    return virtualClassesFiltersModule.searchPhraze
  }

  get tileHeightPx() {
    return window.screen.availWidth <= 1440 ? 283.81 : 370
  }

  get showLoader() {
    return this.renderingTimeout > 0
  }

  get thumbnail() {
    return THUMBNAIL_SMALL_URL
  }

  get durationFilterType() { return FilterType.DURATION}
  get categoryFilterType() { return FilterType.CATEGORY}
  get equipmentFilterType() { return FilterType.EQUIPMENT }
  get intensityFilterType() { return FilterType.INTENSITY }
  get trainerFilterType() { return FilterType.TRAINER }
  get languageFilterType() { return FilterType.LANGUAGE }
  get typeFilterType() { return FilterType.TYPE }

  get durationFilters(): DurationFilter[] {
    return virtualClassesModule.durations.map(durationFilter => {
      const selected = virtualClassesFiltersModule.durationFilters.findIndex(filter => durationFilter.id == filter.id) >= 0
      const { id, name } = durationFilter

      return {
        id,
        name,
        selected
      } as DurationFilter
    })
  }

  get languageFilters(): LanguageFilter[] {
    return virtualClassesModule.language.map(language => {

      const { id, type } = language
      const { name } = language.attributes
      const selected = virtualClassesFiltersModule.languageFilters.findIndex((id) => language.id == id) >= 0
      return {
        id,
        name,
        type,
        selected
      } as LanguageFilter
    })
  }

  get categoryFilters(): CategoryFilter[]  {
    return virtualClassesModule.categories.map(category => {

      const { id } = category
      const { name, type } = category.attributes
      const selected = virtualClassesFiltersModule.categoriesFilters.findIndex((id) => category.id == id) >= 0

      return {
        id,
        name,
        type,
        selected
      } as CategoryFilter
    })
  }

  get typeFilters(): TypeFilter[] {
    return virtualClassesModule.types.map(typeFilter => {

      const { id } = typeFilter
      const { name, type } = typeFilter.attributes
      const selected = virtualClassesFiltersModule.typeFilters.findIndex((id) => typeFilter.id == id) >= 0

      return {
        id,
        name,
        type,
        selected
      } as TypeFilter
    })
  }

  get equipmentFilters(): EquipmentFilter[] {
    return virtualClassesModule.equipment.map(equipment => {

      const { type, id } = equipment
      const { name } = equipment.attributes
      const selected = virtualClassesFiltersModule.equipmentFilters.findIndex((id) => equipment.id == id) >= 0

      return {
        id,
        name,
        type,
        selected
      } as EquipmentFilter
    })
  }

  get intensityFilters(): IntensityFilter[] {
    return virtualClassesModule.intensity.map(intensity => {

      const { type, id } = intensity
      const { name } = intensity.attributes
      const selected = virtualClassesFiltersModule.intensityFilters.findIndex((id) => intensity.id == id) >= 0

      return {
        id,
        name,
        type,
        selected
      } as EquipmentFilter
    })
  }

  get trainerFilters(): TrainerFilter[] {
    return virtualClassesModule.trainers.map(trainer => {

      const { type, id } = trainer
      const { name } = trainer.attributes
      const selected = virtualClassesFiltersModule.trainerFilters.findIndex((id) => trainer.id == id) >= 0

      return {
        id,
        name,
        type,
        selected
      } as TrainerFilter
    })
  }

  get virtualClasses(): Array<VirtualClassApiConfig> {
    return virtualClassesOverviewModule.filteredClasses
  }

  get isSearchActive() {
    return virtualClassesFiltersModule.isSearchFilterActive
  }

  get isFiltersActive() {
    return virtualClassesFiltersModule.isFiltersActive
  }

  get selectedIconClasses() {
    return ['selected', 'fontA-color-bg', 'primary-color-text']
  }

  get selectedFilterClasses() {
    return ['primary-color-bg', 'selected']
  }

  get thumbnailUrl() {
    return 'thumbnail-small-url'
  }

  toggleFilter(type: FilterType, filter: BaseFilter) {
    const { id } = filter
    virtualClassesFiltersModule.updateFilter({ id, type })
    virtualClassesStoreManager.applyFilters()
    this.scrollContainer.scrollTo({y: 0})
  }

  onChangeSearchPhraze(newPhraze: string) {
    window.clearTimeout(this.searchDebounce)
    this.searchDebounce = window.setTimeout(() => {
      virtualClassesFiltersModule.updateSearchPhraze(newPhraze)
      virtualClassesStoreManager.applyFilters() 
      this.scrollContainer.scrollTo({y: 0})
    }, 300)
  }

  clearFilters() {
    virtualClassesStoreManager.load()
    this.scrollContainer.scrollTo({y: 0})
  }

  nextPageLoad(e: any) {
    window.clearTimeout(this.scrollDebounce)
    this.scrollDebounce = window.setTimeout(() => { 
      this.scrollTop = e.scrollTop
      virtualClassesOverviewModule.nextPage()
    }, 400)
  }

  renderTile(tileIndex: number) {
    const from = Math.floor(this.scrollTop / this.tileHeightPx) * itemsPerRow
    const to = from + (itemsPerRow * 4)
    return tileIndex >= from && tileIndex < to
  }

  goToDescriptionPage(id: string) {
    this.$router.push({ name: PAGES.VIRTUAL_CLASS_DESCRIPTION, params: { id: id } })
  }

  beforeDestroy() {
    window.clearTimeout(this.renderingTimeout)
  }

  toggleFilterMenu(isOpen: boolean) {
    eGymTrainingsModule.getFilterMenuState(isOpen)
  }

  destroyed() {
    this.toggleFilterMenu(false)
  }
}