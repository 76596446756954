import {Action, Module, Mutation, VuexModule} from 'vuex-class-modules';
import store from '../index';
import {EGymConfig, EGymUser, EGymUserResponse, EGymWorkoutData, EGymWorkoutStrength} from '@/types/e-gym';
import {BaseWorkout, BaseWorkoutPagination, CircuitWorkout, WorkoutTimeFrame} from '@/types/workouts';
import {httpService} from "@/services/http.service";

@Module
class EGymTrainingsModule extends VuexModule
{
    private users: EGymUser[] = [];

    get usersCheckedIn()
    {
        return this.users;
    }

    @Mutation
    setUser(user: EGymUser)
    {
        this.users.unshift(user);
    }

    @Mutation
    reset()
    {
        this.users = [];
    }

    @Mutation
    removeUserFromList(rfid: string)
    {
        const removedUserIndex = this.users.findIndex((user: EGymUser) => user.rfid == rfid);
        if (removedUserIndex >= 0) {
            this.users.splice(removedUserIndex, 1);
        }
    }

    @Action
    async addUser(config: EGymConfig)
    {
        if (this.users.find((user: EGymUser): boolean => user.rfid == config.rfidNumber)) {
            return true;
        }
        const userInfo: EGymUserResponse | boolean = await httpService.egymAuth(config.rfidNumber);

        if (userInfo) {
            this.setUser({userInfo, rfid: config.rfidNumber} as EGymUser);
            return true;
        } else {
            return false;
        }
    }

    @Action
    resetToDefault()
    {
        this.reset();
    }

    @Action
    removeUser(rfid: string)
    {
        this.removeUserFromList(rfid);
    }
}

export const eGymUsersModule = new EGymTrainingsModule({store, name: 'eGymUsers'});