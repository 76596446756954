import { Module, VuexModule, Action, Mutation } from 'vuex-class-modules'
import { httpService } from '@/services/http.service'
import store from '../index'
import { VirtualClassApiConfig, DURATION_IN_SECONDS, CREATED_AT, VirtualClassCategoryApiConfig, VirtualClassTypeApiConfig, VirtualClassEquipmentApiConfig, VirtualClassTrainerApiConfig, VirtualClassIntensityApiConfig, THUMBNAIL_URL, THUMBNAIL_SMALL_URL, THUMBNAIL_BIG_URL, VirtualClassLanguageApiConfig, EXTERNAL_ID } from '@/types/virtual-classes'
import { Schedule } from '@/types/schedule'
import { Equipment } from '@/types/workouts'
import { virtualClassesService } from '@/services/virtual-classes.service'
import { getUniqueMetrics } from '@/utils/virtual-class.utils'
import { DurationFilterLong, DurationFilterMedium, DurationFilterShort } from '@/types/state/general-filter'
import Vue from 'vue'
import {deviceModule} from "./device.module";
import {themeModule} from "./theme.module";

@Module
class VirtualClassesModule extends VuexModule {
  
  public classesList: VirtualClassApiConfig[] | null = null
  
  private scheduledVirtualClassesInstances: Schedule[] = []

  private _virtualClassEquipments: Equipment[] = []

  private _syncWithPlayers = false

  get classes() {
    return this.classesList ? this.classesList.filter(virtualClass => virtualClass.progress === 100) : []
  }

  get schedules() {
    return this.scheduledVirtualClassesInstances
  }

  get durations() {
    return [DurationFilterShort, DurationFilterMedium, DurationFilterLong]
  }

  get language(): VirtualClassLanguageApiConfig[] {
    return getUniqueMetrics(this.classes, 'language') as VirtualClassLanguageApiConfig[]
  }

  get categories(): VirtualClassCategoryApiConfig[] {
    return getUniqueMetrics(this.classes, 'categories') as VirtualClassCategoryApiConfig[]
  }

  get types(): VirtualClassTypeApiConfig[] {
    return getUniqueMetrics(this.classes, 'types') as VirtualClassTypeApiConfig[]
  }

  get equipment(): VirtualClassEquipmentApiConfig[] {
    return getUniqueMetrics(this.classes, 'equipment') as VirtualClassEquipmentApiConfig[]
  }

  get trainers(): VirtualClassTrainerApiConfig[] {
    return getUniqueMetrics(this.classes, 'instructors') as VirtualClassTrainerApiConfig[]
  }

  get intensity(): VirtualClassIntensityApiConfig[] {
    return getUniqueMetrics(this.classes, 'level') as VirtualClassIntensityApiConfig[]
  }

  get languages(): VirtualClassLanguageApiConfig[] {
    return getUniqueMetrics(this.classes, 'language') as VirtualClassLanguageApiConfig[]
  }

  get virtualClassEquipments() { return this._virtualClassEquipments }

  getClassCategories(classId: string) {
    const virtualClass = this.classes.find(classEntity => classEntity.id === classId)
    if (virtualClass) {
      return virtualClass.relationships.categories
    }
    return []
  }

  getClassTrainers(classId: string) {
    const virtualClass = this.classes.find(classEntity => classEntity.id === classId)
    if (virtualClass) {
      return virtualClass.relationships.instructors
    }
    return []
  }

  getClassTypes(classId: string) {
    const virtualClass = this.classes.find(classEntity => classEntity.id === classId)
    if (virtualClass) {
      return virtualClass.relationships.types
    }
    return null
  }

  getClassEquipment(classId: string) {
    const virtualClass = this.classes.find(classEntity => classEntity.id === classId)
    if (virtualClass) {
      return virtualClass.relationships.equipment
    }
    return null
  }

  getClassIntensity(classId: string) {
    const virtualClass = this.classes.find(classEntity => classEntity.id === classId)
    if (virtualClass) {
      return virtualClass.relationships.level
    }
    return null
  }

  getClassDuration(classId: string) {
    const virtualClass = this.classes.find(classEntity => classEntity.id === classId)
    if (virtualClass) {
      return virtualClass.attributes[DURATION_IN_SECONDS]
    }
    return null
  }

  getClassTags(classId: string) {
    const intensity = this.getClassIntensity(classId)?.attributes.name
    const trainers = (this.getClassTrainers(classId) || [])
    const duration = (virtualClassesService.getVirtualClassDurationTag(this.getClassDuration(classId) || 0) || '')
    const tags = [duration, intensity, trainers[0]?.attributes.name].filter(t => t)
    return tags
  }

  @Mutation
  private setScheduledClasses(scheduledClasses: Schedule[]) {
    this.scheduledVirtualClassesInstances = scheduledClasses
  }

  @Mutation
  private setClasses(classes: VirtualClassApiConfig[]) {
    if (classes.length > 1) {
      this.classesList = classes.sort((virtualClassA, virtualClassB) => {
        const vcDateA = new Date(virtualClassA.attributes[CREATED_AT])
        const vcDateB = new Date(virtualClassB.attributes[CREATED_AT])
        if (vcDateA < vcDateB) {
          return 1
        } else if (vcDateA > vcDateB) {
          return -1
        }
        return 1
      })
    }
  }

  @Mutation
  private setVirtualClassEquipments(equipments: Equipment[]) {
    this._virtualClassEquipments = equipments
  }

  @Mutation
  private doThePlayerSync() {
    this._syncWithPlayers = true
  }

  @Action
  private async getClasses() {
    let virtualClasses: VirtualClassApiConfig[] = []
    try {
      virtualClasses = await httpService.virtualClasses()
    } catch (err) {
      // eslint-disable-next-line
      console.error(err)
    }

    let virtualClasseStates: { [key: string]: number } = {}

    try {
      virtualClasseStates = await httpService.virtualClassesStates()
    } catch (err) {
      // eslint-disable-next-line
      console.error(err)
    }

    virtualClasses = virtualClasses.map(virtualClass => {
      virtualClass.attributes[THUMBNAIL_SMALL_URL] = `${virtualClass.attributes[THUMBNAIL_URL]}/width/250`
      virtualClass.attributes[THUMBNAIL_BIG_URL] = `${virtualClass.attributes[THUMBNAIL_URL]}/width/1280`

      if (this._syncWithPlayers) {
        virtualClass.progress = virtualClasseStates[virtualClass.attributes[EXTERNAL_ID]] != undefined ? virtualClasseStates[virtualClass.attributes[EXTERNAL_ID]] : 0
      } else {
        virtualClass.progress = 100
      }
      
      return virtualClass
    })

    const categoriesEnabled = getUniqueMetrics(virtualClasses, 'categories') as VirtualClassCategoryApiConfig[];
    const categoryIds = categoriesEnabled.map(cat => cat.id);

    if (this.categories.length != categoriesEnabled.length ||
      this.categories.map(cat => cat.id).filter(id => !categoryIds.includes(id)).length > 0) {

      const locationName = deviceModule.deviceConfig.location_name;
      const locationBrand = themeModule.themeInfo.theme;
      const playerName = deviceModule.deviceConfig.name;
      const playerSerial = deviceModule.deviceConfig.pid;
      const virtualClassCategoriesEnabled = categoriesEnabled.sort((catA, catB) =>
        parseInt(catA.id) - parseInt(catB.id)
      ).map(cat =>
        [cat.attributes.name, cat.id].join('_')
      ).join(', ');

      Vue.gtm.trackEvent({
        event: 'svt-classes-change',
        virtualClassCategoriesEnabled,
        locationName,
        locationBrand,
        playerName,
        playerSerial
      });
    }

    this.setClasses(virtualClasses)

    const previewsCollection = virtualClasses.map(virtualClass => virtualClass.attributes[THUMBNAIL_SMALL_URL])
      .concat(virtualClasses.map(virtualClass => virtualClass.attributes[THUMBNAIL_BIG_URL])) as string[]

    const cacheSectionName = 'virtual-classes'
    Vue.prototype.$cacheService.refresh(cacheSectionName, previewsCollection)
  }

  @Action
  async getScheduledClasses() {

    const list = await httpService.scheduledVirtualClasses()
    /*const virtualClassIds = list.map(virtaulClass => virtaulClass.category).filter(category => !isNaN(parseInt(category)))

    if (virtualClassIds.length > 0) {

      if (this.classes.length > 0) {
        for (const virtaulClass of this.classes) {
          if (virtualClassIds.indexOf(virtaulClass.id) < 0) {
            this.getClasses()
            break
          }
        }
      } else {
        this.getClasses()
      }
    }*/
    this.setScheduledClasses(list)
  }

  @Action
  async getVirtualClassEquipments() {
    this.setVirtualClassEquipments(await httpService.virtualClassEquipments())
  }

  @Action
  syncWithPlayers() {
    this.doThePlayerSync()
  }

  @Action
  async initialize() {
    await Promise.all([
      this.getClasses(),
      this.getScheduledClasses(),
      this.getVirtualClassEquipments()
    ])
  }
}

export const virtualClassesModule = new VirtualClassesModule({ store, name: 'virtualClasses' })