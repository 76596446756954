import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import DeviceListComponent from '@/components/device-list/device-list.component.vue'
import { deviceModule } from '@/store/modules/device.module'
import { PAGES } from '@/types/router'
import { appModule } from '@/store/modules/app.module'

@Component({
  components: {
    'device-list': DeviceListComponent
  },
  beforeRouteEnter: async (to, from, next) => {
    await deviceModule.receiveDevices()
    next()
  }
})
export default class DeviceChangingComponent extends Vue {
  async onSelect (deviceId: string) {
    appModule.pendingStart()
    await appModule.initialize(deviceId)
    await appModule.loadContent()
    appModule.pendingStop()
    this.$router.push({ name: PAGES.HOME })
  }

  get devices() {
    return deviceModule.devices
  }
}