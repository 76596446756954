import Vue from 'vue'
import { Component, Emit, Prop, Watch } from 'vue-property-decorator'

@Component
export default class WorkoutActiveTimerComponent extends Vue {

    public isItPaused?: boolean
    public timeMs = 0

    @Prop({ type: Number, default: false }) time!: number
    @Prop({ type: Boolean, default: false }) paused!: boolean

    @Watch('paused')
    onPauseTrigger(isItPaused: boolean) {

        if (this.isItPaused !== isItPaused) {
            this.isItPaused = isItPaused
        }
    }

    @Watch('time')
    onTimeChange(time: number) {

        this.timeMs = time
    }

    @Emit('onPause')
    onPause() {
        this.isItPaused = true
        return 0
    }

    @Emit('onStop')
    onStop() {
        return 0
    }

    @Emit('onResume')
    onResume() {
        this.isItPaused = false
        return 0
    }

    @Emit('onForward')
    onForward() {
        return 0
    }

    @Emit('onBackward')
    onBackward() {
        return 0
    }
}