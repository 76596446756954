import { render, staticRenderFns } from "./celebration.component.vue?vue&type=template&id=2445f9d6&scoped=true"
import script from "./celebration.component.ts?vue&type=script&lang=ts&external"
export * from "./celebration.component.ts?vue&type=script&lang=ts&external"
import style0 from "./celebration.component.scss?vue&type=style&index=0&id=2445f9d6&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2445f9d6",
  null
  
)

export default component.exports