import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules'
import store from '../index'

@Module
class BluredContentModule extends VuexModule {
  private bluredStatus = false

  get isBlured() {
    return this.bluredStatus
  }

  @Mutation
  private setStatus(status: boolean) {
    this.bluredStatus = status
  }

  @Action
  toggleStatus(status: boolean) {
    this.setStatus(status)
  }
}

export const bluredContentModule = new BluredContentModule({ store, name: 'bluredContent' })