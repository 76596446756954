import { render, staticRenderFns } from "./scroll-container.component.vue?vue&type=template&id=2f09b22b&scoped=true"
import script from "./scroll-container.component.ts?vue&type=script&lang=ts&external"
export * from "./scroll-container.component.ts?vue&type=script&lang=ts&external"
import style0 from "./scroll-container.component.scss?vue&type=style&index=0&id=2f09b22b&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f09b22b",
  null
  
)

export default component.exports