import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { convertTimeToString } from '@/utils/string.utils'

@Component({})
export default class TimerPreviewTileComponent extends Vue {
  @Prop() time!: number

  get duration() {
    return convertTimeToString(this.time * 1000, { showHours: false })
  }
}