export function parseTimeString(timeStr: string): { h: number; m: number; s: number } { // "00:00:00"
    const splittedStr = timeStr.split(':')
    return {
        h: parseInt(splittedStr[0]),
        m: parseInt(splittedStr[1]),
        s: parseInt(splittedStr[2])
    }
}

export function convertTimeToString(time: number, options: { showHours: boolean | 'auto' } = { showHours: 'auto' }) {
    const seconds = Math.floor((time / 1000) % 60)
    const minutes = Math.floor((time / (1000 * 60)) % 60)
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24)

    const timeStr = `${ (minutes < 10) ? "0" + minutes : minutes }:${ (seconds < 10) ? "0" + seconds : seconds }`

    if (options.showHours === 'auto') {
        return hours > 0 ? `${ (hours < 10) ? "0" + hours : hours }:${ timeStr }` : timeStr
    } else if (options.showHours) {
        return `${ (hours < 10) ? "0" + hours : hours }:${ timeStr }`
    } else {
        return timeStr
    }
}

export function stringFormat(stringToFormat: string, ...args: string[]) {
    return stringToFormat.replace(/{(\d+)}/g, function(match, number) {
      return typeof args[number] != 'undefined' ? args[number] : match;
    });
}

export function hexToRgb(hex: string): { r: string; g: string; b: string } | null {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result ? {
      r: `${parseInt(result[1], 16)}`,
      g: `${parseInt(result[2], 16)}`,
      b: `${parseInt(result[3], 16)}`
    } : null
}