import {Action, Module, Mutation, VuexModule} from 'vuex-class-modules';
import store from '../index';
import {EGymConfig, EGymUser, EGymUserResponse, EGymWorkoutData, EGymWorkoutStrength} from '@/types/e-gym';
import {BaseWorkout, BaseWorkoutPagination, CircuitWorkout, WorkoutTimeFrame} from '@/types/workouts';
import {httpService} from "@/services/http.service";
import workoutService from "@/services/workout.service";

@Module
class EGymTrainingsModule extends VuexModule
{
    public users: EGymUser[] = [];
    public timeframesTrace: EGymWorkoutStrength[] = [];
    public activeTimeFrame: EGymWorkoutStrength | null = null;
    public requestBacklog: EGymWorkoutData[] = [];
    public isFilterMenuOpen = false;
    public isCelebrationActive = false;
    public startedAt = 0;
    public workouts: BaseWorkout[] = [];
    searchExpr!: string;

    readonly pageSize = 20;

    currentPage = 1;

    get lazyRenderingSliceSize() {return this.pageSize * this.currentPage;}


    get filterMenuState()
    {
        return this.isFilterMenuOpen;
    }

    get filteredWorkouts() { 
        return workoutService.filterWorkoutsByName(this.workouts, this.searchExpr)
        .slice(0, this.lazyRenderingSliceSize)
    }

    @Mutation
    private setWorkouts(workouts: BaseWorkout[])
    {
        this.workouts = workouts.filter((workout, workoutIndex) =>
        {
            const idx = workouts.findIndex(item => workout.id === item.id);
            return idx < 0 || idx == workoutIndex;
        });
    }

    @Mutation
    setSearchExpr(expr: string)
    {
        this.searchExpr = expr;
    }

    @Mutation
    changePage(page: number)
    {
        this.currentPage = page;
    }

    @Mutation
    reset()
    {
        this.workouts = [];
        this.timeframesTrace = [];
        this.activeTimeFrame = null;
        this.isCelebrationActive = false;
        this.searchExpr = '';
    }


    @Mutation
    setActiveTimeFrame(timeFrame: WorkoutTimeFrame | null)
    {
        if (timeFrame) {
            this.timeframesTrace.push({
                startTimestamp: Date.now(),
                exerciseName: timeFrame?.exerciseName as string,
                sets: [{
                    setId: (timeFrame?.roundIdx as number) + 1,
                    duration: timeFrame?.duration as number * 1000,
                    weight: 1,
                    repetitions: 1
                }]
            });
        }
    }

    @Mutation
    pushCompletedTimeFrame()
    {
        if (this.activeTimeFrame) {
            this.timeframesTrace.push(this.activeTimeFrame);
        }
    }

    @Mutation
    setBacklog(failedRequest: EGymWorkoutData)
    {
        this.requestBacklog.push(failedRequest);
    }

    @Mutation
    removeBacklog(idx: number)
    {
        this.requestBacklog.slice(idx, 1);
    }

    @Mutation
    setFilterMenuState(state: boolean)
    {
        this.isFilterMenuOpen = state;
    }

    @Mutation
    setStartCelebration()
    {
        this.isCelebrationActive = true;
    }

    @Mutation
    setUpdateStartTime(time: number)
    {
        this.startedAt = time;
    }

    @Action
    async getWorkouts(rfid: string)
    {
        let workouts: BaseWorkout[] = [];
        for (const trainingPlanType of ['strength', 'cardio']) {
            try {
                const response = await httpService.egymTrainingPlans(trainingPlanType, rfid) as BaseWorkoutPagination;
                if (response.workouts.length > 0) {
                    workouts = workouts.concat(response.workouts);
                }
            } catch (err) {
                // eslint-disable-next-line
                console.error(err);
                continue;
            }
        }
        this.setWorkouts(workouts);
    }

    @Action
    updateSearchExpr(expr: string)
    {
        this.setSearchExpr(expr);
    }

    @Action
    resetWorkouts()
    {
        this.setWorkouts([]);
    }


    @Action
    resetToDefault()
    {
        this.reset();
    }
    
    @Action
    traceTimeFrame(timeFrame: WorkoutTimeFrame)
    {

        this.pushCompletedTimeFrame();

        if (timeFrame.workInterval) {
            this.setActiveTimeFrame(timeFrame);
        } else {
            this.setActiveTimeFrame(null);
        }
    }

    @Action
    clearActiveTimeFrame()
    {
        this.setActiveTimeFrame(null);
    }

    @Action
    async sendBacklog()
    {
        if (this.requestBacklog.length > 0) {
            for (let index = 0; index < this.requestBacklog.length; index++) {
                const failedRequest = this.requestBacklog[index];
                this.removeBacklog(index);
                try {
                    await httpService.egymReportSend(failedRequest);
                } catch (e) {
                    let tries = failedRequest.tries == undefined ? 0 : failedRequest.tries;
                    if (tries < 3) {
                        tries++;
                        failedRequest.tries = tries;
                        this.setBacklog(failedRequest);
                    }
                }
            }
        }
    }

    @Action
    getFilterMenuState(state: boolean)
    {
        this.setFilterMenuState(state);
    }

    @Action
    getUpdateStartTime(startTime: number)
    {
        this.setUpdateStartTime(startTime);
    }

    @Action
    startCelebration()
    {
        this.setStartCelebration();
        this.setUpdateStartTime(Date.now());
    }

    @Action
    nextPage()
    {
        if (this.filteredWorkouts.length > this.lazyRenderingSliceSize) {
            this.changePage(this.currentPage + 1);
        }
    }
}

export const eGymTrainingsModule = new EGymTrainingsModule({store, name: 'eGymTrainings'});