import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import truncate from '@/filters/truncate'
import { MssWorkoutType, MssWorkoutTypeNames } from '@/types/workouts'

@Component({
  filters: { truncate }
})
export default class InfoTagComponent extends Vue {
  @Prop({ type: String, default: '' }) name!: string
  @Prop({ type: Number, default: -1 }) maxAmountOfCharacters!: number

  get isTruncateNeeded () {
    return this.maxAmountOfCharacters > this.isItMss
  }

  get isItMss() {
    return this.name == (MssWorkoutTypeNames.get(MssWorkoutType.MSS_CIRCUIT) || MssWorkoutTypeNames.get(MssWorkoutType.MSS_BOUTIQUE)) ?  15 : 0
  }
}