import { BaseAdditionalActivity, BaseWorkout, WorkoutTimeFrame, CircuitWorkout, MonthlyTrainingTimeFrame, MonthlyTrainingCircuit } from '@/types/workouts'
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules'
import store from '../index'
import runtimeProcessorModule from './runtime-processor.module'
import { TIMER_TYPE } from '@/types/timer'
import { CTRL_COMMAND_FEEDBACK, CTRL_COMMAND, FeedbackCtrlCmdMessage } from '@/types/socket'
import { ctrlCmdModule } from './control-commands.module'
import { TRAINING_TRIGGERED_BY } from '@/types/active-training'
import monthlyTrainingService from '@/services/monthly-training.service'

@Module
class MonthlyTrainingActiveWorkoutModule extends VuexModule {

    public activeWorkoutTimeFramesArrayIdx = 0
    public triggeredBy: TRAINING_TRIGGERED_BY | null = null

    public workout: BaseWorkout | null = null

    public theEndOfTimeFrameWatcher: CallableFunction | null = null
    public synchronizarionWatcher: CallableFunction | null = null

    private workoutCommandsWatcher: CallableFunction | null = null

    get activeWorkoutTimeProgress() {

        const timeFrames = this.activeWorkoutTimeFramesArray
            .filter((timeFrame: WorkoutTimeFrame, idx) => idx < this.activeWorkoutTimeFramesArrayIdx)

        return timeFrames.length > 0 ?
            timeFrames.map(timeFrame => timeFrame.duration)
                .reduce((acc, timeFrameDuration) => acc += timeFrameDuration) : 0
    }

    get activeWorkoutPart(): BaseWorkout | BaseAdditionalActivity | null {

        return this.workout;
    }

    get activeWorkoutTimeFramesArray(): MonthlyTrainingTimeFrame[] {

        if (this.workout) {
            return monthlyTrainingService.parseTimeFrames(this.workout as MonthlyTrainingCircuit)
        }
        return []
    }

    get activeTimeFrame(): MonthlyTrainingTimeFrame {
        return this.activeWorkoutTimeFramesArray[this.activeWorkoutTimeFramesArrayIdx]
    }

    get rounds(): number {

        return (this.activeWorkoutPart as CircuitWorkout).roundsData.length
    }

    get activeCircuitRoundIdx(): number {

        let totalRoundsDuration = 0

        for (const roundIdx in (this.activeWorkoutPart as CircuitWorkout).roundsData) {

            const round = (this.activeWorkoutPart as CircuitWorkout).roundsData[roundIdx]

            totalRoundsDuration += round.duration

            if (totalRoundsDuration > this.activeWorkoutTimeProgress) {
                return parseInt(roundIdx)
            }
        }

        return 0
    }

    get isItTheLastRound(): boolean {
        return (this.activeCircuitRoundIdx + 1 == this.rounds)
    }

    get roundsNumber() {

        return (this.activeWorkoutPart as CircuitWorkout).roundsData
            .map(round => (round.circuits[0].exercises).length)
            .reduce((acc, exerciseNumber) => acc += exerciseNumber)
    }

    get currentRoundNumber() {
        return this.activeWorkoutTimeFramesArray.filter((timeFrame, timeFrameIdx) => {

            return timeFrameIdx <= this.activeWorkoutTimeFramesArrayIdx &&
                timeFrame.workInterval &&
                timeFrame.roundIdx != undefined
        }).length
    }

    get isItTheLastRoundNumber() {
        return this.currentRoundNumber >= this.roundsNumber
    }

    get isItRestInterval(): boolean {
        return (this.activeTimeFrame as MonthlyTrainingTimeFrame).restInterval === true
    }

    get isItTheLastTimeFrame(): boolean {
        return this.activeWorkoutTimeFramesArrayIdx === this.activeWorkoutTimeFramesArray.length - 1
    }

    get totalTime(): number {
        return this.workout?.totalTime as number
    }

    get currentTimeSec() {
        return ((runtimeProcessorModule.totalTime - runtimeProcessorModule.currentTime) / 1000) + this.activeWorkoutTimeProgress
    }

    get currentTimeProgressSec() {
        return this.totalTime - this.currentTimeSec
    }

    @Mutation
    reset() {
        this.activeWorkoutTimeFramesArrayIdx = 0
        this.workout = null
        this.triggeredBy = null
    }

    @Mutation
    setTheEndOfTimeFrameWatcher(watcher: CallableFunction) {
        this.theEndOfTimeFrameWatcher = watcher
    }

    @Mutation
    stopWatchingTheEndOfTimeFrame() {
        this.theEndOfTimeFrameWatcher ? this.theEndOfTimeFrameWatcher() : null
    }

    @Mutation
    setWorkoutCommandWatcher(watcher: CallableFunction | null = null) {
        if (this.workoutCommandsWatcher) {
            this.workoutCommandsWatcher()
        }
        this.workoutCommandsWatcher = watcher
    }

    @Mutation
    setSyncWatcher(watcher: CallableFunction | null = null) {
        if (this.synchronizarionWatcher) {
            this.synchronizarionWatcher()
        }
        this.synchronizarionWatcher = watcher
    }

    @Mutation
    setTrriggeredBy(triggeredBy: TRAINING_TRIGGERED_BY) {
        this.triggeredBy = triggeredBy
    }

    @Mutation
    parse(workout: BaseWorkout) {

        this.activeWorkoutTimeFramesArrayIdx = 0
        this.workout = workout
    }

    @Mutation
    setTimeFrameIdx(idx: number) {
        this.activeWorkoutTimeFramesArrayIdx = idx
    }

    @Action
    loadTheNextTimeFrame() {

        if (!this.isItTheLastTimeFrame) {
            this.setTimeFrameIdx(this.activeWorkoutTimeFramesArrayIdx + 1)
        }
    }

    @Action
    timeFrameForward() {

        if (!this.isItTheLastTimeFrame) {

            const timeFrameIdx = this.activeWorkoutTimeFramesArray.findIndex((timeFrame: WorkoutTimeFrame, timeFrameIdx: number) => {
                return timeFrameIdx > this.activeWorkoutTimeFramesArrayIdx && timeFrame.workInterval
            })

            if (timeFrameIdx >= 0) {
                this.setTimeFrameIdx(timeFrameIdx)
            }
        }
    }

    @Action
    timeFrameBackward() {

        const timeFrameDiff = this.activeWorkoutTimeFramesArray.slice(0, this.activeWorkoutTimeFramesArrayIdx)
            .reverse().findIndex((timeFrame: WorkoutTimeFrame) => {
                return !timeFrame.restInterval
            })

            const timeFrameIdx = this.activeWorkoutTimeFramesArrayIdx - (timeFrameDiff + 1)

            const timeFrame =  this.activeWorkoutTimeFramesArray[timeFrameIdx] as MonthlyTrainingTimeFrame
    
            this.setTimeFrameIdx(timeFrame.video != undefined ? 0 : timeFrameIdx)
    }

    @Action
    init(arg: { monthlyTraining: MonthlyTrainingCircuit; triggeredBy: TRAINING_TRIGGERED_BY }) {

        this.resetToDefault()
        
        this.setSyncWatcher(ctrlCmdModule.$watch(module => module.workoutCmdLastFeedback, lastMessage => {

            if (lastMessage?.event === CTRL_COMMAND_FEEDBACK.WORKOUT_GOT_FORWARD) {
                
                this.sync(lastMessage)
                this.timeFrameStart()
                
                ctrlCmdModule.cmdDisposal(lastMessage.event)
            }
        }))

        this.parse(arg.monthlyTraining)
        this.setTrriggeredBy(arg.triggeredBy)
    }

    @Action
    timeFrameStart() {

        this.watchTheEndOfTimeFrame()

        runtimeProcessorModule.changeTimerType(TIMER_TYPE.DOWN)
        const duration = this.activeTimeFrame.duration
        runtimeProcessorModule.start(duration)
    }

    @Action
    watchTheEndOfTimeFrame() {

        this.stopWatchingTheEndOfTimeFrame()

        const theEndOfTimeFrameWatcher = runtimeProcessorModule.$watch(timerModule => timerModule.status, status => {

            const { stopped, finished } = status

            if (stopped) {
                this.stop()
            }
            else if (finished) { // finished automatically

                if (!this.isItTheLastTimeFrame) {

                    this.loadTheNextTimeFrame()
                    this.timeFrameStart()
                }
            }
        })
        this.setTheEndOfTimeFrameWatcher(theEndOfTimeFrameWatcher)
    }

    @Action
    start() {

        if (this.triggeredBy == TRAINING_TRIGGERED_BY.USER) {

            this.setWorkoutCommandWatcher(ctrlCmdModule.$watch(module => module.workoutCmdLastFeedback, lastMessage => {

                if (lastMessage?.event === CTRL_COMMAND_FEEDBACK.WORKOUT_STARTED) {

                    this.timeFrameStart()

                    ctrlCmdModule.cmdDisposal(lastMessage.event)
                }
            }))

            const { id } = this.workout as BaseWorkout
            ctrlCmdModule.triggerCtrlCmd({ trigger: CTRL_COMMAND.MONTHLY_START, value: { id } })

        } else {

            this.timeFrameStart()
        }
    }

    @Action
    pause() {

        runtimeProcessorModule.pause()

        this.setWorkoutCommandWatcher(ctrlCmdModule.$watch(module => module.workoutCmdLastFeedback, lastMessage => {

            if (lastMessage?.event === CTRL_COMMAND_FEEDBACK.WORKOUT_PAUSED) {

                this.sync(lastMessage)

                ctrlCmdModule.cmdDisposal(lastMessage.event)
            }
        }))
        ctrlCmdModule.triggerCtrlCmd({
            trigger: CTRL_COMMAND.WORKOUT_PAUSE, value: {
                id: this.workout?.id as string,
                time: runtimeProcessorModule.currentTime
            }
        })
    }

    @Action
    stop() {

        runtimeProcessorModule.stop()

        const { id } = this.workout as BaseWorkout

        this.setWorkoutCommandWatcher(ctrlCmdModule.$watch(module => module.workoutCmdLastFeedback, lastMessage => {

            if (lastMessage?.event === CTRL_COMMAND_FEEDBACK.WORKOUT_STOPPED) {

                ctrlCmdModule.cleanUp()

                ctrlCmdModule.cmdDisposal(lastMessage.event)
            }
        }))
        ctrlCmdModule.triggerCtrlCmd({ trigger: CTRL_COMMAND.WORKOUT_STOP, value: { id } })
    }

    @Action
    resume() {

        runtimeProcessorModule.resume()

        this.setWorkoutCommandWatcher(ctrlCmdModule.$watch(module => module.workoutCmdLastFeedback, lastMessage => {

            if (lastMessage?.event === CTRL_COMMAND_FEEDBACK.WORKOUT_RESUMED) {

                this.sync(lastMessage)
            }
        }))

        ctrlCmdModule.triggerCtrlCmd({
            trigger: CTRL_COMMAND.WORKOUT_RESUME, value: {
                id: this.workout?.id as string,
                time: runtimeProcessorModule.currentTime
            }
        })
    }

    @Action
    forward() {

        if (!this.isItTheLastTimeFrame) {

            const { id } = this.workout as BaseWorkout

            ctrlCmdModule.triggerCtrlCmd({
                trigger: CTRL_COMMAND.WORKOUT_FORWARD, value: { id }
            })
            this.timeFrameForward()
            this.timeFrameStart()
        } else {
            this.stop()
        }
    }

    @Action
    backward() {

        this.setWorkoutCommandWatcher(ctrlCmdModule.$watch(module => module.workoutCmdLastFeedback, lastMessage => {

            if (lastMessage?.event === CTRL_COMMAND_FEEDBACK.WORKOUT_GOT_BACKWARD) {

                this.timeFrameBackward()

                this.sync(lastMessage)
                this.timeFrameStart()

                ctrlCmdModule.cmdDisposal(lastMessage.event)
            }
        }))

        const { id } = this.workout as BaseWorkout

        ctrlCmdModule.triggerCtrlCmd({
            trigger: CTRL_COMMAND.WORKOUT_BACKWARD, value: { id }
        })
    }

    @Action
    sync(msg: FeedbackCtrlCmdMessage) {

        const { timeFrameIdx } = msg.value

        if (timeFrameIdx != undefined && timeFrameIdx != this.activeWorkoutTimeFramesArrayIdx ) {
            this.setTimeFrameIdx(timeFrameIdx)
        }
    }

    @Action
    resetToDefault() {
        this.reset()
        this.stopWatchingTheEndOfTimeFrame()
        this.setWorkoutCommandWatcher()
        this.setSyncWatcher()
    }
}

export default new MonthlyTrainingActiveWorkoutModule({ store, name: 'monthlyTrainingActiveWorkout' })