import virtualClassesStoreManager from "@/store/managers/virtual-classes.store.manager";
import workoutsStoreManager from "@/store/managers/workouts.store.manager";
import {allWorkoutsOverviewModule} from "@/store/modules/all-workouts-overview.module";
import {appModule} from "@/store/modules/app.module";
import {authModule} from "@/store/modules/auth.module";
import {themeModule} from "@/store/modules/theme.module";
import {deviceModule} from "@/store/modules/device.module";
import {whiteLabelModule} from "@/store/modules/white-label.module";
import {PAGES} from "@/types/router";
import {Route, NavigationGuardNext} from "vue-router";
import Vue from "vue";
import {mssWorkoutsModule} from "@/store/modules/mss-workouts.module";
import {eGymTrainingsModule} from "@/store/modules/egym-trainings.module";

export default async function beforeEachRouteEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>)
{

  const publicRouteNames = [PAGES.LOGIN, PAGES.DEVICE_CHANGING];

  if (publicRouteNames.indexOf(to.name as PAGES) < 0) {

    if (!appModule.readyToStart) {
      try {
        authModule.restoreTokenFromStorage();
        await authModule.refreshToken();
      } catch (err) {
        // eslint-disable-next-line
        console.error(err);
        return next({name: PAGES.LOGIN});
      }
    }

    try {
      await authModule.deviceTokenValidityCheck();
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
      appModule.resetToDefault();
      return next({name: PAGES.LOGIN});
    }

    if (!appModule.readyToStart) {
      try {
        await appModule.connectToDevice();
      } catch (err) {
        // eslint-disable-next-line 
        console.error(err);
        appModule.resetToDefault();
        return next({name: PAGES.LOGIN});
      }

      try {
        await themeModule.initialize();
      } catch (err) {
        // eslint-disable-next-line
        console.error(err);
      }

      if (themeModule.themeInfo) {
        whiteLabelModule.initialize(themeModule.themeInfo);
      }

      appModule.pendingStart();
      appModule.loadContent().finally(() => appModule.pendingStop());

      appModule.changeReadinessToStart(true);
    }

  } else if (appModule.readyToStart) {

    return next(PAGES.HOME);
  }

  if (appModule.readyToStart) {

    Vue.gtm.trackEvent({
      event: "page_view",
      locationName: deviceModule.deviceConfig.location_name,
      locationBrand: themeModule.themeInfo.theme,
      playerName: deviceModule.deviceConfig.name,
      playerSerial: deviceModule.deviceConfig.pid,
    });

    if (whiteLabelModule.preloadPageStyles(to.name as PAGES)) {
      whiteLabelModule.initialize(themeModule.themeInfo);
    }

    whiteLabelModule.preloadPageStyles(to.name as PAGES);

    const vircutalClassesRouteNames = [PAGES.VIRTUAL_CLASSES, PAGES.VIRTUAL_CLASS_DESCRIPTION, PAGES.ACTIVE_VIRTUAL_CLASS];

    if (vircutalClassesRouteNames.indexOf(to.name as PAGES) >= 0 && !appModule.virtualClassesAvailable) {
      return next({name: PAGES.HOME});
    }

    if ([PAGES.EGYM_TRAINING_DESCRIPTION, PAGES.EGYM_TRAINING_ACTIVE].indexOf(to.name as PAGES) >= 0 && eGymTrainingsModule.workouts.length < 0) {
      return next({name: PAGES.HOME});
    }

    const workoutsRouteNames = [PAGES.REGULAR_WORKOUTS, PAGES.REGULAR_WORKOUT_DESCRIPTION, 
    PAGES.MSS_WORKOUT_DESCRIPTION, PAGES.MONTHLY_TRAINING_OVERVIEW, PAGES.MONTHLY_TRAINING_DESCRIPTION ];

    if (workoutsRouteNames.indexOf(to.name as PAGES) >= 0 && !appModule.workoutsAvailable && eGymTrainingsModule.workouts.length < 0) {
      return next({name: PAGES.HOME});
    }

  } else {

    const readinessWarcher = appModule.$watch(m => m.readyToStart, readyToStart =>
    {
      if (readyToStart) {
        whiteLabelModule.preloadPageStyles(to.name as PAGES);
        readinessWarcher();
        next();
      }
    });
  }

  if (from.name == PAGES.WORKOUT_ACTIVE_PAGE || from.name == PAGES.MSS_WORKOUT_ACTIVE_PAGE) {
    workoutsStoreManager.backToDefault();
    allWorkoutsOverviewModule.reset();
  }

  if (from.name == PAGES.ACTIVE_VIRTUAL_CLASS) {
    virtualClassesStoreManager.backToDefault();
    virtualClassesStoreManager.load();
  }

  return next();
}