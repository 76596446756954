import { CTRL_COMMAND, } from "@/types/socket"
import { ctrlCmdModule } from "../modules/control-commands.module"

class WorkoutPreviewManager {

    start(id: string, type: string, workoutPartIdx: number, workoutRoundIdx?: number) {
        ctrlCmdModule.triggerCtrlCmd({
            trigger: CTRL_COMMAND.WORKOUT_PREVIEW_START,
            value: { id, type, workoutPartIdx, workoutRoundIdx }
        })
    }

    stop(id: string, type: string, workoutPartIdx: number, workoutRoundIdx?: number) {
        ctrlCmdModule.triggerCtrlCmd({
            trigger: CTRL_COMMAND.WORKOUT_PREVIEW_STOP,
            value: { id, type, workoutPartIdx, workoutRoundIdx }
        })
    }

    changeRound(id: string, type: string, workoutPartIdx: number, workoutRoundIdx: number) {
        ctrlCmdModule.triggerCtrlCmd({
            trigger: CTRL_COMMAND.WORKOUT_PREVIEW_CHANGE_ROUND,
            value: { id, type, workoutPartIdx, workoutRoundIdx }
        })
    }

    changeWorkoutPart(id: string, type: string, workoutPartIdx: number, workoutRoundIdx?: number) {
        ctrlCmdModule.triggerCtrlCmd({
            trigger: CTRL_COMMAND.WORKOUT_PREVIEW_CHANGE_PART,
            value: { id, type, workoutPartIdx, workoutRoundIdx }
        })
    }
}

export default new WorkoutPreviewManager()