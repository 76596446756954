import { TIMER_TYPE } from "@/types/timer"
import { WorkoutSyncInterface } from "@/types/workouts"
import runtimeProcessorModule from "../modules/runtime-processor.module"


class WorkoutSyncManager {

    sync(refTime: number, storeModule: WorkoutSyncInterface) {

        const elapsedTotalTime = this.elapsedTotalTime(storeModule)

        if (elapsedTotalTime != refTime) {

            if (refTime > 0) {

                const elapsedTotalTime = this.elapsedTotalTime(storeModule)
                const timeDiff = Number.parseFloat((elapsedTotalTime - refTime).toFixed(3))
                if (timeDiff < 0) {
                    this.syncAhead(refTime, storeModule)
                } else {
                    this.syncBack(refTime, storeModule)
                }
            } else {
                storeModule.setWorkoutPartIdx(0)
                storeModule.setTimeFrameIdx(0)
                const timeFrameMs = storeModule.activeTimeFrame.duration * 1000
                runtimeProcessorModule.currentTimeReset(timeFrameMs)
                runtimeProcessorModule.totalTimeReset(timeFrameMs)
            }
        }
    }

    syncAhead(refTime: number, storeModule: WorkoutSyncInterface) {

        let timeFrameShiftIdx = -1

        do {
            let elapsedTime = storeModule.elapsedTime
            timeFrameShiftIdx = storeModule.activeWorkoutTimeFramesArray.slice(
                storeModule.activeWorkoutTimeFramesArrayIdx, 
                storeModule.activeWorkoutTimeFramesArray.length).findIndex(timeFrame => {
                    const timeFrameDuration = timeFrame.duration
                    const isItTheIdx = elapsedTime + timeFrameDuration > refTime
                    if (!isItTheIdx) {
                        elapsedTime += timeFrameDuration
                    }
                    return isItTheIdx
                })

            if (timeFrameShiftIdx >= 0) {
                const timeFrameIdx = storeModule.activeWorkoutTimeFramesArrayIdx + timeFrameShiftIdx
                const timeFrameDuration = storeModule.activeWorkoutTimeFramesArray[ timeFrameIdx ].duration
                const timeFrameElapsedTime = refTime - elapsedTime
                runtimeProcessorModule.currentTimeReset((timeFrameDuration - timeFrameElapsedTime) * 1000)
                runtimeProcessorModule.totalTimeReset(timeFrameDuration * 1000)
                storeModule.setTimeFrameIdx(timeFrameIdx)
            } else if (!storeModule.isItTheLastPart) {
                storeModule.setWorkoutPartIdx(storeModule.workoutPartIdx + 1)
                storeModule.setTimeFrameIdx(0)
            } else {
                storeModule.setTimeFrameIdx(storeModule.activeWorkoutTimeFramesArray.length - 1)
            }
            
        } while (timeFrameShiftIdx < 0 && !storeModule.isItTheLastTimeFrame)
    }

    syncBack(refTime: number, storeModule: WorkoutSyncInterface) {

        let timeFrameShiftIdx = -1

        do {
            let elapsedTime = storeModule.elapsedTime
            timeFrameShiftIdx = storeModule.activeWorkoutTimeFramesArray.slice(0, 
                storeModule.activeWorkoutTimeFramesArrayIdx).reverse().findIndex(timeFrame => {
                    const timeFrameDuration = timeFrame.duration
                    const isItTheIdx = elapsedTime - timeFrameDuration < refTime
                    if (!isItTheIdx) {
                        elapsedTime -= timeFrameDuration
                    }
                    return isItTheIdx
                })

            if (timeFrameShiftIdx >= 0) {
                const timeFrameIdx = storeModule.activeWorkoutTimeFramesArrayIdx - (timeFrameShiftIdx > 0 ? timeFrameShiftIdx : 0)
                const timeFrameDuration = storeModule.activeWorkoutTimeFramesArray[ timeFrameIdx ].duration
                const timeFrameElapsedTime = refTime - elapsedTime
                runtimeProcessorModule.currentTimeReset((timeFrameDuration - timeFrameElapsedTime) * 1000)
                runtimeProcessorModule.totalTimeReset(timeFrameDuration * 1000)
                storeModule.setTimeFrameIdx(timeFrameIdx)
            } else if (storeModule.workoutPartIdx > 0) {
                storeModule.setWorkoutPartIdx(storeModule.workoutPartIdx - 1)
                storeModule.setTimeFrameIdx(storeModule.activeWorkoutTimeFramesArray.length - 1)
            } else {
                storeModule.setTimeFrameIdx(0)
            }
        } while (timeFrameShiftIdx < 0 && !storeModule.isItFirstTimeFrame)
    }

    elapsedTotalTime(storeModule: WorkoutSyncInterface): number {
        
        let runTimeSeconds = 0
        const { currentTime, totalTime } = runtimeProcessorModule

        if (runtimeProcessorModule.timerType == TIMER_TYPE.UP) {
            runTimeSeconds = currentTime
        } else {
            runTimeSeconds = (totalTime - currentTime)
        }

        return storeModule.elapsedTime + Math.floor(runTimeSeconds / 1000)
    }
}

export default new WorkoutSyncManager()