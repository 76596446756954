import { notificationModule } from '@/store/modules/notification.module'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { NotificationType, UserAnswer } from '@/types/notification'

@Component({})
export default class ModalComponent extends Vue {

  get isActive() {
    return notificationModule.isActive
  }

  get message() {
    return notificationModule.message
  }

  get dynamicPart() {
    return notificationModule.dynamicPart
  }

  get type() {
    return notificationModule.type
  }

  get alertType() {
    return NotificationType.ALERT
  }

  get confirmType() {
    return NotificationType.CONFIRM
  }

  get positiveAnswer() {
    return UserAnswer.POSITIVE
  }

  get negativeAnswer() {
    return UserAnswer.NEGATIVE
  }

  get title(){
    return notificationModule.title
  }

  onUserAnswer(answer: UserAnswer) {
    notificationModule.saveUserAnswer(answer)
    notificationModule.hideNotification()
  }
}