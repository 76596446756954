
import { BigRound, CircuitWorkout, MonthlyTrainingCircuit, MonthlyTrainingTimeFrame, MonthlyTrainingVideo } from "@/types/workouts";

class MonthlyTrainingService {

    parseTimeFrames(workout: MonthlyTrainingCircuit): MonthlyTrainingTimeFrame[] {

        const roundsNumber = (workout as CircuitWorkout).roundsData.length

        const timeFramesArray = (workout as CircuitWorkout).roundsData.map((round: BigRound, roundIdx: number) => {

            const cicrcuit = round.circuits[0]
            const exercisesNumber = cicrcuit?.exercises.length as number

            let currentIdx = 0
            const timeFramesArray: MonthlyTrainingTimeFrame[] = []

            while (currentIdx < exercisesNumber) {

                timeFramesArray.push({ duration: round.workInterval as number, workInterval: true, roundIdx })
                
                if (roundIdx < roundsNumber - 1 || currentIdx < exercisesNumber - 1) {
                    timeFramesArray.push({ restInterval: true, roundIdx, duration: round.restInterval })
                }
                currentIdx++
            }
            return timeFramesArray
        }).reduce((acc: MonthlyTrainingTimeFrame[], currentValue: MonthlyTrainingTimeFrame[]) => acc.concat(currentValue)) as MonthlyTrainingTimeFrame[]

        const oneMinuteSec = 60

        if (workout.preparationTime > oneMinuteSec) {

            timeFramesArray.unshift({ duration: oneMinuteSec, video: MonthlyTrainingVideo.PREVIEW_WITH_COUNTDOWN })
            
            let previewMinute = workout.preparationTime / oneMinuteSec

            while (previewMinute > 1) {
                timeFramesArray.unshift({ duration: oneMinuteSec, video: MonthlyTrainingVideo.PREVIEW_NO_COUNTDOWN })
                previewMinute--
            }
        } else {
            timeFramesArray.unshift({ duration: workout.preparationTime, video: MonthlyTrainingVideo.PREVIEW_WITH_COUNTDOWN })
        }

        return timeFramesArray
    }

    getMonthlyTrainingDuration(workout: MonthlyTrainingCircuit) {
        const workoutDuration = workout.totalTime
        return workoutDuration ? `${Math.round(workoutDuration / 60)} min` : ''
    }
}



export default new MonthlyTrainingService()