import { CACHE_SECTION } from '@/services/cache.service'
import { httpService } from '@/services/http.service'
import { RegularWorkoutSchedule } from '@/types/schedule'
import { BaseWorkout, FunxtionFitnessCategory, FunxtionFitnessType, Equipment, BaseWorkoutPagination } from '@/types/workouts'
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules'
import Vue from 'vue'
import store from '../index'
import { sleep } from '@/utils/common.utils'

@Module
class WorkoutsModule extends VuexModule {

  private page = 0
  private readonly perPage = 10
  private workoutsEntries: BaseWorkout[] = []
  private scheduledworkoutsEntries: RegularWorkoutSchedule[] = []
  private _workoutFitnessCategories: FunxtionFitnessCategory[] = []
  private _workoutFitnessTypes: FunxtionFitnessType[] = []
  private _workoutEquipments: Equipment[] = []

  get workouts() { return this.workoutsEntries }
  get scheduledWorkouts() { return this.scheduledworkoutsEntries }
  get workoutFitnessCategories() { return this._workoutFitnessCategories }
  get workoutFitnessTypes() { return this._workoutFitnessTypes }
  get workoutEquipments() { return this._workoutEquipments }
  get egymTrainingPlans() { return this.workoutsEntries.filter((workout) => workout.made_by == "egym-training-plans") }

  @Mutation
  private changePage(page: number) {
    this.page = page
  }

  @Mutation
  private setWorkouts(workouts: BaseWorkout[]) {
    this.workoutsEntries = workouts.filter((workout, workoutIndex) => {
      const idx = workouts.findIndex(item => workout.id === item.id)
      return idx < 0 || idx == workoutIndex
    })
  }

  @Mutation
  private setScheduledWorkouts(scheduledWorkouts: RegularWorkoutSchedule[]) {
    this.scheduledworkoutsEntries = scheduledWorkouts
  }

  @Mutation
  private setFitnessCategories(categories: FunxtionFitnessCategory[]) {
    this._workoutFitnessCategories = categories
  }

  @Mutation
  private setFitnessTypes(types: FunxtionFitnessType[]) {
    this._workoutFitnessTypes = types
  }

  @Mutation
  private setWorkoutEquipments(equipments: Equipment[]) {
    this._workoutEquipments = equipments
  }

  @Mutation
  private injectWorkout(workout: BaseWorkout) {

    this.workoutsEntries.unshift(workout)
  }

  @Mutation
  removeWorkout(id: string) {
    const idx = this.workoutsEntries.findIndex(workout => workout.id === id)
    if (idx >= 0) {
      this.workoutsEntries.splice(idx, 1)
    }
  }

  @Action
  async getWorkouts() {

    if (this.page == 0) {
      this.changePage(1)
      let response = null
      try {
        response = await httpService.regularWorkouts(this.page, this.perPage) as BaseWorkoutPagination
      } catch (err) {
        // eslint-disable-next-line
        console.error(err)
      }

      if (response != null && (!response?.cached || this.workoutsEntries.length < 1 || response?.total != this.workoutsEntries.length)) {
        let workouts: BaseWorkout[] = []
        while (response.workouts.length > 0) {
          let nextPageWorkouts: BaseWorkout[] = []
          try {
            nextPageWorkouts = await Promise.all(response.workouts.map(async (workout) => {
              if (workout.coverImage != undefined && workout.coverImage.small) {
                const { small } = workout.coverImage
                if (small) {
                  try {
                    workout.coverImage.small = await Vue.prototype.$cacheService.match(CACHE_SECTION.WORKOUTS, small)
                  } catch (err) {
                    // eslint-disable-next-line
                    console.error(err)
                  }
                }
              }
              return workout as BaseWorkout
            }))
          } catch (err) {
            // eslint-disable-next-line
            console.error(err)
          }
          workouts = workouts.concat(nextPageWorkouts)
          if (response.per_page * response.current_page >= response.total) {
            break
          }
          
          try {
            response = await httpService.regularWorkouts(this.page, this.perPage) as BaseWorkoutPagination
            this.changePage(this.page + 1)
          } catch (err) {
            // eslint-disable-next-line
            console.error(err)
            const seconds = Math.floor(Math.random() * 10) + 1
            await sleep(seconds * 1000) // wait from 1 to 10 seconds
          }
        }
        this.setWorkouts(workouts)
      }
      this.changePage(0)
    }
  }

  @Action
  deleteEgymTrainings()
  {
    this.egymTrainingPlans.forEach(workout => this.removeWorkout(workout.id))
  }

  @Action
  async updateWorkout(id: string) {
    let workout = null

    while (workout == null) {
      try {
        workout = await httpService.workoutById(id) as BaseWorkout
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
  
        if ((err as any).response && (err as any).response.status === 418) {
          this.removeWorkout(id)
          break;
        } else {
          const seconds = Math.floor(Math.random() * 10) + 1;
          await sleep(seconds * 1000) // wait from 1 to 10 seconds
        }
      }
    }

    if (workout != null) {
      this.removeWorkout(id)
      this.injectWorkout(workout)
    }
    return id
  }

  @Action
  async getScheduledWorkouts() {
    this.setScheduledWorkouts(await httpService.scheduledRegularWorkouts())
  }

  @Action
  async getFitnessCategories() {
    this.setFitnessCategories(await httpService.workoutFitnessCategories())
  }

  @Action
  async getFitnessTypes() {
    this.setFitnessTypes(await httpService.workoutFitnessTypes())
  }

  @Action
  async getWorkoutEquipments() {
    this.setWorkoutEquipments(await httpService.workoutEquipments())
  }

  @Action
  async initialize() {
    await Promise.all([
      this.getWorkouts(),
      this.getScheduledWorkouts(),
      this.getFitnessCategories(),
      this.getFitnessTypes(),
      this.getWorkoutEquipments()
    ])
  }

  @Action
  resetToDefault() {
    this.changePage(0)
    this.setWorkouts([])
    this.setScheduledWorkouts([])
  }
}

export const workoutsModule = new WorkoutsModule({ store, name: 'workouts' })