export const settings = {
  apiDomain: process.env.VUE_APP_API_URL,
  wsDomain: process.env.VUE_APP_WS_URL,
  themeConfigFileName: process.env.VUE_APP_THEME,
  url: {
    authUser: () => `${settings.apiDomain}auth`,
    authDevice: () => `${settings.apiDomain}auth/ctrl`,
    userTokenRefresh: () => `${settings.apiDomain}auth/refresh-token`,
    deviceTokenRefresh: () => `${settings.apiDomain}auth/bsapp/refresh-token`,
    devices: () => `${settings.apiDomain}players`,
    deviceConfig: () => `${settings.apiDomain}connect`,
    socket: () => `${settings.wsDomain}control`,
    virtualClasses: () => `${settings.apiDomain}virtual-trainings`,
    virtualClassesStates: () => `${settings.apiDomain}virtual-trainings/monitor`,
    regularWorkouts: () => `${settings.apiDomain}workouts`,
    workoutById: (id: string) => `${settings.apiDomain}workouts/${id}`,
    mssWorkouts: () => `${settings.apiDomain}workouts/mss/overview`,
    mssWorkoutById: (id: string) => `${settings.apiDomain}workouts/mss/overview/${id}`,
    mssWorkoutsInZone: () => `${settings.apiDomain}workouts/mss`,
    mssWorkoutInZoneById: (id: string) => `${settings.apiDomain}workouts/mss/${id}`,
    monthlyTrainings: () => `${settings.apiDomain}monthly_trainings`,
    scheduledVirtualClasses: () => `${settings.apiDomain}virtual-trainings/scheduled`,
    scheduledRegularWorkouts: () => `${settings.apiDomain}workouts/scheduled`,
    scheduledMssWorkouts: () => `${settings.apiDomain}workouts/scheduled/mss`,
    scheduledMonthlyTrainings: () => `${settings.apiDomain}monthly_trainings/scheduled`,
    timerPresets: () => `${settings.apiDomain}timers`,
    notificationDelivered: () => `${settings.apiDomain}ntf-delivered`,
    workoutFitnessCategories: () => `/data/fitness-categories.json`,
    workoutFitnessTypes: () => `/data/fitness-types.json`,
    workoutEquipments: () => `/data/workouts-equipements.json`,
    virtualClassEquipments: () => `/data/virtual-classes-equipments.json`,
    styles: () => process.env.VUE_APP_MODE == 'test' ? `themes/${process.env.VUE_APP_THEME}.json` : `${settings.apiDomain}styles?r=tablet`,
    eGymAuth: () => `${settings.apiDomain}egym/auth`,
    eGymReport: () => `${settings.apiDomain}egym/report`,
    eGymTrainingPlans: (type: string) => `${settings.apiDomain}egym/training-plans/${type}`,
  }
}