import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'
import { Device } from '@/types/http'

@Component
export default class DeviceListComponent extends Vue {
  @Prop() devices!: Device[]

  public search = ''

  @Emit()
  select(deviceId: string) {
    return deviceId
  }

  get filteredDevices () {
    return this.devices.filter(device => {
      return device.name.toLowerCase().includes(this.search.toLowerCase())
    })
  }
}