import { Module, VuexModule, Action, Mutation } from 'vuex-class-modules'
import store from '../index'
import { allWorkoutsOverviewModule } from './all-workouts-overview.module'
import { DurationFilterShort, DurationFilterMedium, DurationFilterLong } from "@/types/state/general-filter"
import { DurationFilter, FilterType } from '@/types/virtual-classes'

@Module
class WorkoutGeneralFiltersModule extends VuexModule {
  
  public durationFilters: DurationFilter[] = []
  public fitnessCategoryFilters: string[] = []
  public fitnessTypeFilters: string[] = []
  public typeFilters: string[] = []
  public equipmentFilters: string[] = []
  public intensityFilters: string[] = []
  public trainerFilters: string[] = []
  public bodyPartFilters: string[] = []
  public phraze = ''


  get searchPhraze() { return this.phraze }

  get isFiltersActive() {
    return this.durationFilters.length > 0 ||
      this.fitnessCategoryFilters.length > 0 ||
      this.typeFilters.length > 0 ||
      this.equipmentFilters.length > 0 ||
      this.intensityFilters.length > 0 ||
      this.trainerFilters.length > 0 ||
      this.bodyPartFilters.length > 0
  }

  get isSearchFilterActive() {
    return this.phraze.length > 0
  }
  

  @Mutation
  setFilter(filter: { type: FilterType; id: string }) {
    const { type, id } = filter

    if (type === FilterType.DURATION) {
      const filterIdx = this.durationFilters.findIndex((filter) => filter.id == id)

      if (filterIdx >= 0) {
        this.durationFilters.splice(filterIdx, 1)
      } else {

        const durations = [DurationFilterShort, DurationFilterMedium, DurationFilterLong]
        const durationIdx = durations.findIndex(d => d.id == id)
        this.durationFilters.push(durations[durationIdx])
      }
    }
    else if (type === FilterType.TYPE) {
      const filterIdx = this.typeFilters.findIndex((filterId) => filterId == id)

      if (filterIdx >= 0) {
        this.typeFilters.splice(filterIdx, 1)
      } else {
        this.typeFilters.push(id)
      }
    }
    else if (type === FilterType.EQUIPMENT) {
      const filterIdx = this.equipmentFilters.findIndex((filterId) => filterId == id)

      if (filterIdx >= 0) {
        this.equipmentFilters.splice(filterIdx, 1)
      } else {
        this.equipmentFilters.push(id)
      }
    } else if (type === FilterType.INTENSITY) {
      const filterIdx = this.intensityFilters.findIndex((filterId) => filterId == id)

      if (filterIdx >= 0) {
        this.intensityFilters.splice(filterIdx, 1)
      } else {
        this.intensityFilters.push(id)
      }
    } else if (type === FilterType.BODY_PART) {
      const filterIdx = this.bodyPartFilters.findIndex((filterId) => filterId == id)

      if (filterIdx >= 0) {
        this.bodyPartFilters.splice(filterIdx, 1)
      } else {
        this.bodyPartFilters.push(id)
      }
    }
    else if (type === FilterType.TRAINER) {
      const filterIdx = this.trainerFilters.findIndex((filterId) => filterId == id)

      if (filterIdx >= 0) {
        this.trainerFilters.splice(filterIdx, 1)
      } else {
        this.trainerFilters.push(id)
      }
    }
    else if (type === FilterType.FITNESS_TYPE){
      const filterIdx = this.fitnessTypeFilters.findIndex((filterId) => filterId == id)

      if (filterIdx >= 0) {
        this.fitnessTypeFilters.splice(filterIdx, 1)
      } else {
        this.fitnessTypeFilters.push(id)
      }
    }
    else if (type === FilterType.FITNESS_CATEGORY){

      const filterIdx = this.fitnessCategoryFilters.findIndex((filterId) => filterId == id)

      if (filterIdx >= 0) {
        this.fitnessCategoryFilters.splice(filterIdx, 1)
      } else {
        this.fitnessCategoryFilters.push(id)
      }
    }
  }

  @Mutation
  private setSearchPhraze(searchPhraze: string) {
    this.phraze = searchPhraze
  }

  @Mutation
  private unselectFilters() {
    this.durationFilters = []
    this.fitnessCategoryFilters = []
    this.fitnessTypeFilters = []
    this.typeFilters = []
    this.equipmentFilters = []
    this.intensityFilters = []
    this.trainerFilters = []
    this.bodyPartFilters = []
    this.phraze = ''
  }

  @Action
  updateSearchPhraze(newPhraze: string) {
    this.setSearchPhraze(newPhraze)
  }

  @Action
  updateFilter(config: {id: string; type: FilterType}) {
    this.setFilter(config)
  }

  @Action
  reset() {
    this.unselectFilters()
    allWorkoutsOverviewModule.reset()
  }
}

export default new WorkoutGeneralFiltersModule({ store, name: 'workoutsFilters' })