export const PAUSE_TIMEOUT = 5 * 60 * 1000 // 5 minutes
export const RESUME_TIMEOUT = 2 * 60 * 1000 // 2 minutes

export enum WorkoutTypes {
  CIRCUIT = 'circuit',
  AMRAP = 'amrap',
  RFT = 'rft',
}

export enum MssWorkoutType {
  MSS_CIRCUIT = 'mss_circuit',
  MSS_BOUTIQUE = 'mss_boutique'
}

export const MssWorkoutTypeNames = new Map([
  [ MssWorkoutType.MSS_BOUTIQUE, 'Multi screen workout' ],
  [ MssWorkoutType.MSS_CIRCUIT, 'Multi screen circuit' ]
])

export enum WorkoutWuCd {
  WU = 'warming_up',
  CD = 'cooling_down'
}

export enum MonthlyTrainigType {
  ABS_CORE_LONG = 'ac_long',
  ABS_CORE_SHORT = 'ac_short',
  MOVE_IT_LONG = 'move_it_long',
  MOVE_IT_SHORT = 'move_it_short',
  RUMBLE_LONG = 'rumble_long',
  RUMBLE_SHORT = 'rumble_short',
  SBKT_LONG = 'sbtk_long',
  SBKT_SHORT = 'sbtk_short'
}

export enum MonthlyTrainingVideo {
  PREVIEW_NO_COUNTDOWN = 'mt_preview_no_countdown',
  PREVIEW_WITH_COUNTDOWN = 'mt_preview_with_countdown'
}

export const WorkoutIntensities = new Map([
  [ 'beg', 'beginner' ],
  [ 'inter', 'intermediate' ],
  [ 'adv', 'advanced' ]
])

export const EQUIPMENT_OTHER = 'Other'

export interface BaseWorkout {
  id: string;
  name: string;
  category: number;
  tags: WorkoutTag[];
  creatorName: string;
  description: string;
  difficulty: string;
  duration: number;
  gearList: string[];
  gearWeights: GearWeight[];
  thumbnail: string;
  goal: string[];
  totalTime: number;
  type: WorkoutTypes;
  rounds: number;
  videoTexts: VideoTexts;
  warming_ups: BaseAdditionalActivity[];
  cooling_downs: BaseAdditionalActivity[];
  made_by?: string;
  coverImage: {fhd: string; small: string; big: string};
  fitness_type: number;
  assignedAt: number;
  bodytypes: string[];
}

export interface WorkoutTag extends BaseWorkout {
  name: string;
  createdAt: string;
}

export interface ComplexWorkout extends BaseWorkout {
  exercises: Exercise[];
  menuText: MenuTexts;
}

// tslint:disable-next-line
export interface RftWorkout extends ComplexWorkout {
  name: string;
}

// tslint:disable-next-line
export interface AmrapWorkout extends ComplexWorkout {
  name: string;
}

export interface CircuitWorkout extends BaseWorkout {
  audiofile: string;
  totalTime: number;
  roundsData: BigRound[];
  rounds: number;
}

export interface SmallRound {
  workInterval: number;
  restInterval: number;
  duration: number;
}

export interface BigRound extends SmallRound {
  id: number;
  round: number;
  duration: number;
  circuits: RoundCircuit[];
}

export interface RoundCircuit {
  ID: number;
  name: string;
  exercises: Exercise[];
  duration: number;
  workInterval?: number;
  restInterval?: number;
  measures?: Measure[];
}

export interface Tier {
  regressive?: Exercise;
  regular: Exercise;
  progressive?: Exercise;
}

export interface Exercise {
  ID: string;
  name: string;
  category: string;
  image: string;
  video: string;
  only_text: boolean;
  regions: string[];
  measures: Measure[];
  workInterval: number;
}

export interface Measure {
  name: string;
  description: string;
}

export interface GearWeight {
  [key: string]: {
    kg: WeightForGenders;
    lbs: WeightForGenders;
  };
}

export interface WeightForGenders {
  male: string;
  female: string;
}

export interface VideoTexts {
  en: string[];
  nl: string[];
  de: string[];
  es: string[];
  fr: string[];
  he: string[];
  ru: string[];
  pt: string[];
  it: string[];
}

export interface MenuTexts {
  en: string;
  nl: string;
  de: string;
  es: string;
  fr: string;
  he: string;
  ru: string;
  pt: string;
  it: string;
}

export interface BaseAdditionalActivity {
  ID: number;
  name: string;
  type: string;
  prep_time: number;
  exercises: Exercise[];
  activity_type: string;
  timer: number;
  random: boolean;
  duration: number;
  workInterval?: number;
}

export interface TimerAdditionalActivity extends BaseAdditionalActivity {
  timer: number;
}

export interface WorkoutAdditionalActivity extends BaseAdditionalActivity {
  exercises: Exercise[];
}

export interface CircuitAdditionalActivity extends WorkoutAdditionalActivity {
  name: string;
  type: string;
  rounds: number;
  creatorName: string;
  totalTime: number;
  videoTexts: VideoTexts;
  gearWeights: GearWeight;
  gearList: string[];
  roundsData: SmallRound[];
  exercises: Exercise[];
}

export interface GearInfo {
  [key: string]: {
    name: string;
    key: string;
    image: string;
  };
}

export interface PreviewSlideData {
  name: string;
  exercises: Exercise[];
  type: string;
  random?: boolean;
  timer?: number;
  activity_type?: string;
  duration?: number;
  rounds?: number;
  round?: number;
  zone?: number;
  zoneName?: string;
  roundDuration?: number;
  workInterval?: number;
  restInterval?: number;
  zoneDuration?: number;
  zonePrep?: number;
}


export interface WorkoutTimeFrame {
  duration: number;
  roundIdx?: number;
  workInterval?: boolean;
  restInterval?: boolean;
  exerciseName?: string;
}

export interface MonthlyTrainingTimeFrame extends WorkoutTimeFrame {
  video?: MonthlyTrainingVideo;
}

export enum WorkoutFilterType {
  DURATION = 'duration',
  TYPE = 'type',
  FITNESS_CATEGORY = 'fitness_category',
  FITNESS_TYPE = 'fitness_type',
  TRAINER = 'trainer',
  INTENSITY = 'intensity',
  EQUIPMENT = 'equipment',
  BODY_PART = 'body_part'
}

export interface BaseWorkoutFilter {
  id: string;
  name: string;
  selected?: boolean;
}
export interface WorkoutDurationFilter extends BaseWorkoutFilter {
  from: number;
  to: number;
}

export interface WorkoutTypeFilter extends BaseWorkoutFilter {
  type: WorkoutTypes | MssWorkoutType;
}

export interface WorkoutFitnessCategoryFilter extends BaseWorkoutFilter {
  type: string;
}

export interface WorkoutFitnessTypeFilter extends BaseWorkoutFilter {
  type: string;
}

export interface WorkoutEquipmentFilter extends BaseWorkoutFilter {
  type: string;
}

export interface WorkoutTrainerFilter extends BaseWorkoutFilter {
  type: string;
}

export interface WorkoutIntensityFilter extends BaseWorkoutFilter {
  type: string;
}

export interface WorkoutBodyPartFilter extends BaseWorkoutFilter {
  type: string;
}

export interface FunxtionFitnessCategory {
  id: number;
  attributes: {
    type: string;
    name: string;
  };
}

export interface FunxtionFitnessType {
  id: number;
  attributes: {
    type: string;
    name: string;
  };
}

export interface Equipment {
  name: string;
}

export interface MonthlyTrainingCircuit extends CircuitWorkout {
  trainingId: MonthlyTrainigType;
  preparationTime: number;
}

export interface MssPreviewSlideData extends PreviewSlideData {
  zone?: number;
  zoneName?: string;
  zoneDuration?: number;
  zonePrep?: number;
}

export interface MssExercise extends Exercise{
  workInterval: number;
  restInterval: number;
  zone: {
    name: string;
    position: number;
  };
}

export interface MssRoundCircuit extends RoundCircuit {
  exercises: MssExercise[];
  round_prep: number;
}

export interface MssBigRound extends BigRound {
  circuits: MssRoundCircuit[];
  round_prep: number;
  zone_prep: number;
}

export interface MssWorkout extends ComplexWorkout {
  roundsData: MssBigRound[];
  circuit_mss: boolean;
  workout_prep: number;
  boutiqueFinalInterval?: number;
}

export type MssWorkoutPagination = {
  workouts: MssWorkout[];
  current_page: number;
  per_page:  number;
  total: number;
  cached?: boolean;
}

export type BaseWorkoutPagination = {
  workouts: BaseWorkout[];
  current_page: number;
  per_page:  number;
  total: number;
  cached?: boolean;
}
export enum BodyParts {
  FULL_BODY = 'full_body',
  LOWER_BODY = 'lower_body',
  UPPER_BODY = 'upper_body',
  CORE = 'core'
}

export interface NotificationPayloadValue {
  id?: string;
  type: string;
  poolName: string;
  notificationId: string;
}

export interface WorkoutSyncInterface {
  readonly isItFirstTimeFrame: boolean;
  readonly isItTheLastTimeFrame: boolean;
  readonly activeWorkoutTimeFramesArray: WorkoutTimeFrame[];
  readonly timeFrames: WorkoutTimeFrame[];
  readonly activeTimeFrame: WorkoutTimeFrame;
  readonly isItTheLastPart: boolean;
  readonly elapsedTime: number;
  activeWorkoutTimeFramesArrayIdx: number;
  workoutPartIdx: number;
  setWorkoutPartIdx(idx: number): void;
  setTimeFrameIdx(idx: number): void;
  loadTheNextTimeFrame(): void;
}