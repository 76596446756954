import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import VirtualClassTileComponent from '@/components/virtual-class-tile/virtual-class-tile.component.vue'
import WorkoutTileComponent from '@/components/workout-tile/workout-tile.component.vue'
import ActivitiesListSliderComponent from '@/components/activities-list-slider/activities-list-slider.component.vue'
import { virtualClassesModule } from '@/store/modules/virtual-classes.module'
import { virtualClassesFiltersModule } from '@/store/modules/virtual-classes-filters.module'
import SchedulesListComponent from '@/components/schedules-list/schedules-list.component.vue'
import { Location } from 'vue-router'
import { schedulesAggregatorModule } from '@/store/modules/schedules-aggregator.module'
import { PAGES } from '@/types/router'
import MssWorkoutTileComponent from '@/components/mss-workout-tile/mss-workout-tile.component.vue'
import workoutGeneralFIltersModule from '@/store/modules/workouts-general-filters.module'
import { appModule } from '@/store/modules/app.module'
import { workoutsModule } from '@/store/modules/workouts.module'
import { mssWorkoutsModule } from '@/store/modules/mss-workouts.module'
import {MADE_BY_EGYM_TRAINER} from "@/types/e-gym";
import {eGymTrainingsModule} from "@/store/modules/egym-trainings.module";
import EgymTrainingTileComponent from "@/components/egym-training-tile/egym-training-tile.component.vue";
import {homepageModule} from "@/store/modules/homepage.module";

@Component({
  components: {
    'virtual-class-tile': VirtualClassTileComponent,
    'workout-tile': WorkoutTileComponent,
    'mss-tile': MssWorkoutTileComponent,
    'egym-tile': EgymTrainingTileComponent,
    'activities-list-slider-component': ActivitiesListSliderComponent,
    'schedules-list-component': SchedulesListComponent
  },
  beforeRouteEnter: (to, from, next) => {
    virtualClassesFiltersModule.reset()
    workoutGeneralFIltersModule.reset()
    next()
  }
})
export default class HomePageComponent extends Vue {

  public virtualClassesLocation = { name: PAGES.VIRTUAL_CLASSES } as Location

  public workoutsLocation = { name: PAGES.REGULAR_WORKOUTS } as Location

  public egymTrainingPlansLocation = { name: PAGES.EGYM_TRAINING_PLANS } as Location
  
  public monthlyTrainingsLocation = { name: PAGES.MONTHLY_TRAINING_OVERVIEW } as Location
  
  get virtualClasses() {
    return virtualClassesModule.classes.slice(0, 10)
  }

  get activeTabName()
  {
    return homepageModule.activeTab
  }

  get workouts() {
    const max = 10
    return (workoutsModule.workouts.slice(0, max)
    .concat(mssWorkoutsModule.workouts.filter((workout) => workout.made_by != MADE_BY_EGYM_TRAINER)
    .slice(0, max)))
    .sort((workoutA, workoutB) => {
      if (workoutA.assignedAt != workoutB.assignedAt) {
        return workoutA.assignedAt < workoutB.assignedAt ? 1 : -1
      }
      return 0
    }).slice(0, max)
  }

  get egymTrainingPlans() {
    return eGymTrainingsModule.workouts
  }

  get trainingsOfTheDay() {
    return schedulesAggregatorModule.trainingsOfTheDay
  }

  get virtualClassesAvailable() {
    return appModule.virtualClassesAvailable && this.virtualClasses.length > 0
  }

  get workoutsAvailable() {
    return appModule.workoutsAvailable && this.workouts.length > 0
  }

  get activeSwiper() {
    return ['is-active-swiper']
  }

  get InactiveSwiper() {
    return ['is-inactive-swiper']
  }

  @Watch('virtualClassesAvailable', { immediate: true, deep: true })
  @Watch('workoutsAvailable', { immediate: true, deep: true })
  @Watch('egymTrainingPlans', { immediate: true, deep: true })
  onDataChange()
  {
    if (!this.activeTabName || (this.egymTrainingPlans.length < 1 && this.activeTabName == this.egymTrainingPlansLocation.name)) {
      if (this.workoutsAvailable) {
        this.tabActivate(this.workoutsLocation.name as string)
      } else if (this.virtualClassesAvailable) {
        this.tabActivate(this.virtualClassesLocation.name as string)
      } 
    }
  }


  tabActivate(tabName: string) {
    homepageModule.changeActiveTab(tabName)
  }

  /* Place for workouts config */
  public swiperOptions = {
    slidesPerView: 4,
    spaceBetween: 40
  }
}