import Vue from 'vue'
import { Component, Ref } from 'vue-property-decorator'
import InfoTagComponent from '@/components/info-tag/info-tag.component.vue'
import { allWorkoutsOverviewModule } from '@/store/modules/all-workouts-overview.module'
import workoutGeneralFIltersModule from '@/store/modules/workouts-general-filters.module'
import workoutsStoreManager from '@/store/managers/workouts.store.manager'
import { PAGES } from '@/types/router'
import { eGymTrainingsModule } from '@/store/modules/egym-trainings.module'
import EgymTrainingTileComponent from "@/components/egym-training-tile/egym-training-tile.component.vue";

const itemsPerRow = 4

@Component({
  components: {
    'egym-tile': EgymTrainingTileComponent,
    'info-tag-component': InfoTagComponent
  },
  beforeRouteEnter: (to, from, next) => {
    if (from.name != PAGES.MSS_WORKOUT_DESCRIPTION && from.name != PAGES.REGULAR_WORKOUT_DESCRIPTION) {
      workoutsStoreManager.backToDefault()
      allWorkoutsOverviewModule.reset()
    } 
    next()
  }
})
export default class EgymTrainingsListPageCpmponent extends Vue {
  
  private searchDebounce?: number
  private scrollDebounce?: number
  private scrollTop = 0
  public menuIsTriggered = false
  @Ref() readonly scrollContainer!: any

  get workouts() { return eGymTrainingsModule.filteredWorkouts }
    
  get searchPhrase(): string {
    return eGymTrainingsModule.searchExpr
  }

  get tileHeightPx() {
    return window.screen.availWidth <= 1440 ? 283.81 : 370
  }

  get isSearchActive() { return workoutGeneralFIltersModule.isSearchFilterActive }

  get selectedIconClasses() {
    return ['selected', 'fontA-color-bg', 'primary-color-text']
  }

  get selectedFilterClasses() {
    return ['primary-color-bg', 'selected']
  }

  get isMenuOpen() {
    return eGymTrainingsModule.isFilterMenuOpen
  }
  
  mounted() {
    this.toggleFilterMenu(false)
  }

  onChangeSearchPhrase(newPhrase: string) {
    window.clearTimeout(this.searchDebounce)
    this.searchDebounce = window.setTimeout(() => {
      eGymTrainingsModule.updateSearchExpr(newPhrase)
      this.scrollContainer.scrollTo({y: 0})
    }, 700)
  }

  onScroll(e: any) {
    window.clearTimeout(this.scrollDebounce)
    this.scrollDebounce = window.setTimeout(() => {
      this.scrollTop = e.scrollTop
      eGymTrainingsModule.nextPage()
    }, 400)
  }

  renderTile(tileIndex: number) {
    const from = Math.floor(this.scrollTop / this.tileHeightPx) * itemsPerRow
    const to = from + (itemsPerRow * 4)
    return tileIndex >= from && tileIndex < to
  }

  clearFilters() {
    workoutGeneralFIltersModule.reset()
    this.scrollContainer.scrollTo({y: 0})
  }

  goToDescriptionPage(id: string, type: any) {
    if (type?.circuit_mss !== undefined && (this.$route.name != PAGES.REGULAR_WORKOUT_DESCRIPTION && this.$route.name != PAGES.MSS_WORKOUT_DESCRIPTION)) {
      this.$router.push({ name: PAGES.MSS_WORKOUT_DESCRIPTION, params: { id: id } })
    } else {
      this.$router.push({ name: PAGES.REGULAR_WORKOUT_DESCRIPTION, params: { id: id } })
    }
  }

  toggleFilterMenu(isOpen: boolean) {
    if (!this.menuIsTriggered) {
      eGymTrainingsModule.getFilterMenuState(isOpen)
      this.menuIsTriggered = true
    }

    window.setTimeout(() => {
      this.menuIsTriggered = false
    }, 200)
  }

  destroyed() {
    this.toggleFilterMenu(false)
  }

}