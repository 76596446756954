import Vue from 'vue'
import Component from 'vue-class-component'
import WorkoutActiveTimerComponent from '@/components/workout-active-timer/workout-active-timer.component.vue'
import runtimeProcessorModule from '@/store/modules/runtime-processor.module'
import timeformat from '@/filters/timeformat'
import { ctrlCmdModule } from '@/store/modules/control-commands.module'
import { CTRL_COMMAND_FEEDBACK } from '@/types/socket'
import monthlyTrainingActiveWorkoutModule from '@/store/modules/monthly-training-workout-active'
import { notificationModule } from '@/store/modules/notification.module'
import { UserAnswer, NotificationCommand, NotificationType } from '@/types/notification'
import { TRAINING_TRIGGERED_BY } from '@/types/active-training'
import {PAUSE_TIMEOUT, RESUME_TIMEOUT} from "@/types/workouts";

@Component({
    components: {
        'timer': WorkoutActiveTimerComponent
    },
    filters: { timeformat }
})
export default class MonthlyTrainingActivePageComponent extends Vue {

    private notificationAnswerWatcher!: CallableFunction

    runtimeProcessWatcher!: CallableFunction
    onWorkoutStopWatcher!: CallableFunction

    isItPaused = true

    get timeLeftMs() {
        return monthlyTrainingActiveWorkoutModule.currentTimeProgressSec * 1000
    }

    get isItScheduled() {
        return monthlyTrainingActiveWorkoutModule.triggeredBy === TRAINING_TRIGGERED_BY.SCHEDULE
    }

    private stopTheWorkout()
    {
        this.onWorkoutStopWatcher()
        monthlyTrainingActiveWorkoutModule.stop()
    }

    mounted() {

        this.notificationAnswerWatcher = notificationModule.$watch(
            thisModule => thisModule.answer, this.userAnswerHandler)

        this.runtimeProcessWatcher = runtimeProcessorModule.$watch(timerModule => timerModule.status, status => {
            
            const { paused, finished, stopped } = status

            if (stopped || (finished && monthlyTrainingActiveWorkoutModule.isItTheLastTimeFrame)) {
                this.$router.back()
            } else {
                this.isItPaused = paused
            }
        })

        this.start()
    }

    forward() {
        if (monthlyTrainingActiveWorkoutModule.isItTheLastRoundNumber) {
            this.stop()
        } else {
            monthlyTrainingActiveWorkoutModule.forward()
        }
    }

    backward() {
        monthlyTrainingActiveWorkoutModule.backward()
    }

    pause() {
        monthlyTrainingActiveWorkoutModule.pause()
        const tDialog = window.setTimeout(() => {
            notificationModule.showNotification({ command: NotificationCommand.RESUME_WORKOUT, type: NotificationType.CONFIRM })
            const tResume = window.setTimeout(() => this.resume(), RESUME_TIMEOUT)
            runtimeProcessorModule.changeActionTimeout(tResume)
        }, PAUSE_TIMEOUT)
        runtimeProcessorModule.changeActionTimeout(tDialog)
    }

    resume() {
        if (runtimeProcessorModule.status.paused) {
            monthlyTrainingActiveWorkoutModule.resume()
            notificationModule.hideNotification()
            runtimeProcessorModule.changeActionTimeout(0)
        }
    }

    start() {

        this.onWorkoutStopWatcher = ctrlCmdModule.$watch(module => module.workoutCmdLastFeedback, feedback => {

            if (feedback?.event == CTRL_COMMAND_FEEDBACK.WORKOUT_STOPPED) {
                monthlyTrainingActiveWorkoutModule.stop()
                ctrlCmdModule.cmdDisposal(feedback.event)
            }
        })
        monthlyTrainingActiveWorkoutModule.start()
    }

    stop() {
        if (this.isItScheduled) {
            notificationModule.showNotification({ command: NotificationCommand.CANNOT_STOP_TRAINING, type: NotificationType.ALERT })
        } else {
            notificationModule.showNotification({ command: NotificationCommand.ARE_YOU_SURE, type: NotificationType.CONFIRM })
        }
    }

    userAnswerHandler(answer: UserAnswer) {
        if (notificationModule.command == NotificationCommand.RESUME_WORKOUT) {
            if (answer === UserAnswer.POSITIVE) {
                this.resume()
            } else if (answer === UserAnswer.NEGATIVE) {
                this.stopTheWorkout()
            }
        } else if (notificationModule.command == NotificationCommand.ARE_YOU_SURE && answer === UserAnswer.POSITIVE && !this.isItScheduled) {
            this.stopTheWorkout()
        }
    }

    beforeDestroy() {
        [
            this.notificationAnswerWatcher, 
            this.onWorkoutStopWatcher,
            this.runtimeProcessWatcher
        ].forEach(unsubscibe => unsubscibe != undefined ? unsubscibe() : 0)
        monthlyTrainingActiveWorkoutModule.resetToDefault()
    }
}