import Vue from 'vue'
import Component from 'vue-class-component';
import { Emit, VModel } from 'vue-property-decorator';
import VuePicker from 'vue-pickers'
import timeformat from '@/filters/timeformat'
import { generatePickerOptions } from '@/utils/common.utils';
import { IntervalTimer } from '@/types/timer';

enum IntervalTymerParts {
    WORK = 0,
    REST = 1,
    ROUND = 2
}

@Component({
    components: {
        VuePicker
    },
    filters: { timeformat }
})
export default class TimerIntervalComponent extends Vue {

    public tabId!: IntervalTymerParts | null

    readonly minutesStep = 1
    readonly secondsStep = 5
    readonly roundsStep = 1 

    public minutes = 0
    public seconds = 0

    public timerTabs = [false, false, false]

    @VModel()
    public interval!: IntervalTimer

    get intervalData() {
        let { workInterval, restInterval } = this.interval
        workInterval *= 1000
        restInterval *= 1000
        return { workInterval, restInterval, rounds: this.interval.rounds }
    }

    get minutesOptions(): Array<{ value: number; label: string }> {
        return generatePickerOptions(60, this.minutesStep)
    }
    
    get secondsOptions(): Array<{ value: number; label: string }> {
        return generatePickerOptions(60, this.secondsStep)
    }
    
    get roundsOptions(): Array<{ value: number; label: string }> {
        return generatePickerOptions(100, this.roundsStep)
    }

    get isItValid() {
        return this.intervalData.workInterval < 1 || this.intervalData.rounds < 1
    }

    @Emit('start')
    start() {
        return this.interval
    }

    @Emit('change')
    change() {
        return this.interval
    }

    onTimeChange(time: any[] ) {
        const { workInterval, restInterval } = this.interval
        if (this.tabId === IntervalTymerParts.WORK) {
            this.fillInTime(time, workInterval)
            this.interval.workInterval = this.minutes * 60 + this.seconds
        } else if (this.tabId === IntervalTymerParts.REST) {
            this.fillInTime(time, restInterval)
            this.interval.restInterval = this.minutes * 60 + this.seconds
        }
        this.change()
    }

    onRoundChange(round: any[]) {
        this.interval.rounds = round[0] ? round[0].value : 0
        this.change()
    }

    fillInTime(time: any[], defaultTime: number) {
        this.minutes = time[0] ? time[0].value : Math.floor(defaultTime / 60)
        this.seconds = time[1] ? time[1].value : defaultTime % 60
    }

    onTabChange(tabId: IntervalTymerParts) {
        if (this.tabId != null) {
            this.timerTabs[this.tabId] = false
        }
        const { workInterval, restInterval } = this.interval
        if (tabId === IntervalTymerParts.WORK) {
            this.minutes = Math.floor(workInterval / 60)
            this.seconds = workInterval % 60
        } else if (tabId === IntervalTymerParts.REST) {
            this.minutes = Math.floor(restInterval / 60)
            this.seconds = restInterval % 60
        }
        this.tabId = tabId
    }
}