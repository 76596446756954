import Vue from 'vue';
import Component from 'vue-class-component';
import {deviceModule} from '@/store/modules/device.module';
import {eGymTrainingsModule} from '@/store/modules/egym-trainings.module';
import {Prop} from 'vue-property-decorator';
import {PAGES} from '@/types/router';
import LottieAnimation from "lottie-web-vue";
import {EGymUser} from '@/types/e-gym';
import {notificationModule} from '@/store/modules/notification.module';
import {NotificationCommand, NotificationType} from '@/types/notification';
import {CTRL_COMMAND} from "@/types/socket";
import {ctrlCmdModule} from "@/store/modules/control-commands.module";
import {eGymUsersModule} from "@/store/modules/egym-users.module";

@Component({
    components: {
        'lottie-animation': LottieAnimation
    }
})
export default class EGymAddUserComponent extends Vue
{
    @Prop({type: Boolean, default: true, required: true}) isReaderActive!: boolean;
    @Prop({type: Boolean, default: false, required: false}) openPopup!: boolean;

    public rfidLoading = false;
    public rfidNumber = '';
    public popUpOpen = false;
    public isWavesActive = true;
    public addUser = true;
    public minimizeView = false;
    public eGymNewUser = false;
    public eGymAlreadyAdded: null | EGymUser = null;

    get serialNumber()
    {
        return {
            rfidNumber: this.rfidNumber,
            serialNumber: deviceModule.deviceConfig.location_name + '|' + deviceModule.deviceConfig.pid
        };
    }

    get newUser()
    {
        return this.eGymNewUser;
    }

    get isRfidLoading()
    {
        return this.rfidLoading;
    }

    get users()
    {
        return eGymUsersModule.usersCheckedIn;
    }

    get fullViewVisible()
    {
        const routes = [
            PAGES.HOME, PAGES.WORKOUT_ACTIVE_PAGE, PAGES.REGULAR_WORKOUTS, PAGES.REGULAR_WORKOUT_DESCRIPTION
        ];
        return routes.some(route => route === this.$route.name);
    }

    get amountOfUsersShown()
    {
        if (this.$router.currentRoute.name == PAGES.REGULAR_WORKOUT_DESCRIPTION) {
            return 9;
        } else {
            return 6;
        }
    }

    get isItCelebration()
    {
        return this.$router.currentRoute.name == PAGES.CELEBRATION;
    }

    removeAnimation()
    {
        if (!this.users.find((user: EGymUser): boolean => user.rfid == this.rfidNumber)) {
            this.eGymNewUser = false;
        } else {
            this.eGymAlreadyAdded = null;
            this.eGymNewUser = false;
        }
    }

    created()
    {
        if (this.openPopup) {
            this.togglePopUp();
        }
        if (this.isReaderActive && !this.openPopup) {
            this.minimizeView = false;
        } else {
            this.minimizeView = true;
        }
    }

    mounted()
    {
        this.addReader();
    }

    destroyed()
    {
        this.removeReader();
        this.eGymNewUser = false;
        this.eGymAlreadyAdded = null;
    }

    async rfidReader(event: KeyboardEvent)
    {
        if (event.key == 'Enter') {
            const idx = this.users.findIndex((user: EGymUser): boolean => user.rfid == this.rfidNumber);
            if (idx < 0) {
                if (await eGymUsersModule.addUser(this.serialNumber)) {
                    this.eGymNewUser = true;
                    this.eGymAlreadyAdded = null;
                } else {
                    return notificationModule.showNotification({command: NotificationCommand.USER_NOT_FOUND, type: NotificationType.ALERT});
                }
            } else {
                this.eGymNewUser = true;
                this.eGymAlreadyAdded = this.users[idx];
            }
            if (this.users.length == 1) {
                await eGymTrainingsModule.getWorkouts(this.rfidNumber);
                ctrlCmdModule.triggerCtrlCmd({trigger: CTRL_COMMAND.UPDATE_EGYM_TRAINING_PLANS, value: {id: this.rfidNumber}});
            } else {
                eGymTrainingsModule.resetWorkouts();
            }
            this.rfidLoading = false;
            this.rfidNumber = '';
        } else {
            this.rfidNumber = this.rfidNumber + event.key;
            if (!this.rfidLoading) {
                this.rfidLoading = true;
            }
        }
    }

    togglePopUp()
    {
        this.popUpOpen = !this.popUpOpen;
    }

    toggleWaves()
    {
        this.isWavesActive = !this.isWavesActive;
    }

    removeReader()
    {
        window.removeEventListener("keypress", this.rfidReader);
    }

    addReader()
    {
        this.removeReader();
        window.addEventListener('keypress', this.rfidReader);
    }

    async removeUser(rfid: string)
    {
        eGymUsersModule.removeUser(rfid);
        if (this.users.length == 1) {
            this.rfidNumber = this.users[0].rfid
            await eGymTrainingsModule.getWorkouts(this.rfidNumber);
            ctrlCmdModule.triggerCtrlCmd({trigger: CTRL_COMMAND.UPDATE_EGYM_TRAINING_PLANS, value: {id: this.rfidNumber}});
        } else if (this.users.length < 1) {
            eGymTrainingsModule.resetWorkouts();
            ctrlCmdModule.triggerCtrlCmd({trigger: CTRL_COMMAND.RESET_EGYM_TRAINING_PLANS, value: {id: 'none'}});
        }
    }
}