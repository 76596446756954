import { Exercise, PreviewSlideData } from "@/types/workouts"
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"
import ExerciseTileComponent from "../exercise-tile/exercise-tile.component.vue"
import timeformat from "@/filters/timeformat"

@Component({
    components: {
        'exercise-tile-component': ExerciseTileComponent
    },
    filters: {
        timeformat
    }
})
export default class ZonePreviewComponent extends Vue {
    @Prop({ type: Array, default: [], required: false }) zones!: number[]
    @Prop({ type: Array, default: []}) activeRound!: PreviewSlideData[]

    public activeZonePreview = 0

    public swiperOption: any = {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        slidesPerView: 1,
        spaceBetween: 30,
        loop: false,
        speed: 400,
        observer: true,
        observeParents: true
    };

    get currentRound() {
        if(this.activeRound[0].zone != undefined) {
            return this.activeRound.filter((zone: PreviewSlideData) => zone.zone == this.activeZonePreview).shift()
        }
        return this.activeRound[0]
    }

    get activeClassZone() {
        return ['active-zone', 'primary-color-bg', 'primary-color-border' ]
    }

    get numberOfZones(): number {
        return this.zoneNumbers != undefined ? this.zoneNumbers.length : 0
    }

    get zoneNumbers(): number[] {
        return this.zones?.sort()
    }

    get isTimer() {
        return this.currentRound?.timer != undefined
    }

    get showRoundTimings() {
        if (this.currentRound?.restInterval && !this.currentRound.zone) {
            return true
        } else {
            return false
        }
    }

    get showZoneTimings() {
        if (this.currentRound?.zoneDuration || this.currentRound?.zonePrep) {
            return true
        } else {
            return false
        }
    }

    get activeRoundData() {
        return this.activeRound
    }

    get exerciseSlides(): Array<Exercise[]> {
        if (this.currentRound == undefined) {
            return []
        }
        const size = 8
        return this.currentRound?.exercises.map((exercise: Exercise, exerciseIndex: number, exercises: Exercise[]) => {
            const startFrom = size * exerciseIndex
            return (exercises.slice(startFrom , startFrom + size) as Exercise[])
        }).filter((exercises: Exercise[]) => exercises.length > 0)
    }

    get selectedZoneDuration() {
        if(this.currentRound?.zoneDuration && this.currentRound.zonePrep !== undefined) {
            return this.currentRound?.zoneDuration / this.numberOfZones - this.currentRound?.zonePrep
        }
    }

    renderZone(n: number) {
        this.activeZonePreview = n
    }

    @Watch('activeRound')
    renderFirstZone() {
        this.renderZone(0)
    }

    nextSlide() {
        this.workoutSwiper.$swiper.slideNext()
    }

    prevSlide() {
        this.workoutSwiper.$swiper.slidePrev()
    }

    get workoutSwiper(): any {
        return this.$refs.workoutSwiper
    }

}