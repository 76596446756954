import {
    BaseAdditionalActivity, BaseWorkout, WorkoutTimeFrame, CircuitAdditionalActivity, CircuitWorkout, TimerAdditionalActivity,
    WorkoutTypes, MssWorkout, RftWorkout
} from '@/types/workouts'
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules'
import store from '../index'
import runtimeProcessorModule from './runtime-processor.module'
import { TIMER_TYPE } from '@/types/timer'
import { CTRL_COMMAND_FEEDBACK, CTRL_COMMAND, FeedbackCtrlCmdMessage } from '@/types/socket'
import { ctrlCmdModule } from './control-commands.module'
import { TRAINING_TRIGGERED_BY } from '@/types/active-training'
import { mssWorkoutService } from '@/services/mss-workout.service'


@Module
class MssWorkoutActiveModule extends VuexModule {

    public workoutPartIdx = 0
    public activeWorkoutTimeFramesArrayIdx = 0
    public triggeredBy: TRAINING_TRIGGERED_BY | null = null

    public warmingUps: BaseAdditionalActivity[] = []
    public coolingDowns: BaseAdditionalActivity[] = []
    public workout: MssWorkout | null = null

    private workoutCommandsWatcher: CallableFunction | null = null
    public theEndOfTimeFrameWatcher: CallableFunction | null = null
    public synchronizarionWatcher: CallableFunction | null = null

    get currentTimeSec(): number {

        const passedParts = ([] as (MssWorkout | BaseAdditionalActivity)[]).concat(
            this.warmingUps, this.workout as MssWorkout, this.coolingDowns
        ).slice(0, this.workoutPartIdx)

        const totalTimeProgress = passedParts.reduce((acc, workout) => {

            acc += (workout as MssWorkout).duration

            if ((workout as BaseAdditionalActivity).prep_time) {
                acc += (workout as BaseAdditionalActivity).prep_time
            }
            return acc
        }, 0)

        return totalTimeProgress + this.activeWorkoutTimeProgress
    }


    get timeFrames(): WorkoutTimeFrame[] {
        return this.activeWorkoutTimeFramesArray
            .filter((timeFrame: WorkoutTimeFrame, idx) => idx < this.activeWorkoutTimeFramesArrayIdx)
    }

    get activeWorkoutTimeProgress(): number {

        let time = 0

        if (this.timeFrames.length > 0) {

            time += this.timeFrames.map(timeFrame => timeFrame.duration)
            .reduce((acc, timeFrameDuration) => acc += timeFrameDuration)
        }

        const timeLeft = runtimeProcessorModule.totalTime - runtimeProcessorModule.currentTime

        if (timeLeft < runtimeProcessorModule.totalTime) {
            time += timeLeft / 1000
        }

        return time
    }

    get activeWorkoutPart(): BaseWorkout | BaseAdditionalActivity | null {

        const warmingUpsPlusWorkout = (this.warmingUps.length + 1)

        if (this.workoutPartIdx < 0) {
            return null // workout isn't running
        }
        else if (this.workoutPartIdx < this.warmingUps.length) {

            return this.warmingUps[this.workoutPartIdx]

        } else if (this.workoutPartIdx < warmingUpsPlusWorkout) {

            return this.workout;

        } else {

            const coolingDownIdx = this.workoutPartIdx - warmingUpsPlusWorkout
            return this.coolingDowns[coolingDownIdx]
        }
        return null
    }

    get activeWorkoutTimeFramesArray(): WorkoutTimeFrame[] {

        let timeFrames: WorkoutTimeFrame[] = []

        if (this.activeWorkoutPart == null) {
            return timeFrames
        }

        if (this.isItAWarmingUp || this.isItACoolingDown) {

            if (this.isItACircuit) {

                timeFrames = mssWorkoutService.parseCircuitWuCdTimeFrames(this.activeWorkoutPart as CircuitAdditionalActivity)
            } else {
                timeFrames = mssWorkoutService.parseWuCdTimeFrames(this.activeWorkoutPart as BaseWorkout)
            }

        } else {

            timeFrames = mssWorkoutService.parseTimeFrames(this.activeWorkoutPart as MssWorkout)

            // Only for boutique workouts
            if (this.coolingDowns.length < 1 && (this.activeWorkoutPart as MssWorkout).boutiqueFinalInterval !== undefined) {
                const duration = (this.activeWorkoutPart as MssWorkout).boutiqueFinalInterval as number
                const restInterval = true
                timeFrames.push({duration, restInterval, 
                    roundIdx: (this.activeWorkoutPart as MssWorkout).roundsData.length-1
                })
              }
        }

        return timeFrames
    }

    get timeFrameCoordinates() {
        return { timeFrameIdx: this.activeWorkoutTimeFramesArrayIdx, workoutPartIdx: this.workoutPartIdx }
    }

    get activeTimeFrame(): WorkoutTimeFrame {
        return this.activeWorkoutTimeFramesArray[this.activeWorkoutTimeFramesArrayIdx]
    }

    get rounds(): number {

        if (this.isItACircuit) {
            return (this.activeWorkoutPart as CircuitWorkout).roundsData.length
        } else if (this.isItAnRft) {
            return (this.activeWorkoutPart as RftWorkout).rounds
        }
        return -1
    }

    get activeCircuitRoundIdx(): number {

        if (this.isItACircuit) {
            return this.activeTimeFrame.roundIdx as number
        }
        return 0
    }

    get isItTheLastRound(): boolean {
        return this.activeCircuitRoundIdx + 1 == this.rounds
    }

    get activeWorkoutPartName(): string {
        return (this.activeWorkoutPart as BaseWorkout)?.name
    }

    get isItAWarmingUp(): boolean {
        return this.workoutPartIdx < this.warmingUps.length
    }

    get isItLastWarmingUp(): boolean {
        return this.isItAWarmingUp && this.workoutPartIdx == this.warmingUps.length - 1
    }

    get isItACoolingDown(): boolean {
        return this.workoutPartIdx > this.warmingUps.length;
    }

    get isItARegularWorkout(): boolean {
        return !this.isItAWarmingUp && !this.isItACoolingDown
    }

    get isItATimer(): boolean {
        return !this.isItARegularWorkout && (this.activeWorkoutPart as TimerAdditionalActivity).timer > 0
    }

    get isItACircuit(): boolean {
        return (this.activeWorkoutPart as MssWorkout).type === WorkoutTypes.CIRCUIT
    }

    get isItAnRft(): boolean {
        return (this.activeWorkoutPart as MssWorkout).type === WorkoutTypes.RFT
    }

    get isItTheLastPart() {
        return (this.warmingUps.length + this.coolingDowns.length) - this.workoutPartIdx == 0;
    }

    get isItFirstTimeFrame(): boolean {
        return this.workoutPartIdx < 1 && this.activeWorkoutTimeFramesArrayIdx < 1
    }

    get isItTheLastTimeFrame(): boolean {
        return this.isItTheLastPart && this.activeWorkoutTimeFramesArrayIdx >= this.activeWorkoutTimeFramesArray.length - 1
    }

    get isItFirstTimeFrameInSet(): boolean {
        return this.activeWorkoutTimeFramesArrayIdx == 0
    }

    get isItTheLastTimeFrameInSet(): boolean {
        return this.activeWorkoutTimeFramesArrayIdx === this.activeWorkoutTimeFramesArray.length - 1
    }

    get isItTheMainWorkoutPreparationTime(): boolean {
        return (this.isItTheMainWorkoutActive && this.isItFirstTimeFrameInSet && !this.activeTimeFrame.restInterval && !this.activeTimeFrame.workInterval)
    }

    get isItPreparationTime(): boolean {

        return ((this.workoutPartIdx < 1 && this.isItFirstTimeFrameInSet) || 
            (this.isItAWarmingUp && this.isItTheLastTimeFrameInSet && this.workoutPartIdx < 1 && this.activeWorkoutTimeFramesArrayIdx > 1) ||
            (this.isItACoolingDown && this.isItFirstTimeFrameInSet) || this.isItTheMainWorkoutPreparationTime) 
            && this.activeWorkoutTimeFramesArray.length > 1
    }

    get isItTheMainWorkoutActive(): boolean {
        return !this.isItAWarmingUp && !this.isItACoolingDown
    }

    get isItBoutique(): boolean {
        return !this.workout?.circuit_mss
    }

    get totalTime(): number | undefined {
        return this.workout?.totalTime
    }

    @Mutation
    reset() {
        this.workoutPartIdx = 0
        this.activeWorkoutTimeFramesArrayIdx = 0

        this.workout = null
        this.warmingUps = []
        this.coolingDowns = []
        this.triggeredBy = null
    }

    @Mutation
    setTheEndOfTimeFrameWatcher(watcher: CallableFunction | null = null) {

        if (this.theEndOfTimeFrameWatcher) {
            this.theEndOfTimeFrameWatcher()
        }
        this.theEndOfTimeFrameWatcher = watcher
    }

    @Mutation
    setSyncWatcher(watcher: CallableFunction | null) {

        if (this.synchronizarionWatcher) {
            this.synchronizarionWatcher()
        }
        this.synchronizarionWatcher = watcher
    }

    @Action
    stopWatchingSync() {
        this.setSyncWatcher(null)
    }

    @Action
    stopWatchingTheEndOfTimeFrame() {
        this.setTheEndOfTimeFrameWatcher(null)
    }

    @Mutation
    setWorkoutCommandWatcher(watcher: CallableFunction | null = null) {
        if (this.workoutCommandsWatcher) {
            this.workoutCommandsWatcher()
        }
        this.workoutCommandsWatcher = watcher
    }

    @Mutation
    setTrriggeredBy(triggeredBy: TRAINING_TRIGGERED_BY) {
        this.triggeredBy = triggeredBy
    }

    @Mutation
    parse(workout: MssWorkout) {

        this.workoutPartIdx = 0
        this.activeWorkoutTimeFramesArrayIdx = 0

        // eslint-disable-next-line
        const { warming_ups, cooling_downs, ...mainPart } = workout

        // eslint-disable-next-line
        this.warmingUps = warming_ups
        // eslint-disable-next-line
        this.coolingDowns = cooling_downs.map(coolingDown => Object.assign({}, coolingDown))

        this.workout = mainPart as MssWorkout

        /** This is only for MSS Boutique Workouts */
        if ((mainPart as MssWorkout).boutiqueFinalInterval != undefined && this.coolingDowns.length > 0) {
            this.coolingDowns[0].prep_time += (mainPart as MssWorkout).boutiqueFinalInterval as number
        }
    }

    @Mutation
    setTimeFrameIdx(idx: number) {
        this.activeWorkoutTimeFramesArrayIdx = idx
    }

    @Mutation
    setWorkoutPartIdx(idx: number) {
        this.workoutPartIdx = idx
    }

    @Action
    loadTheNextTimeFrame() {

        if (this.activeWorkoutTimeFramesArrayIdx < this.activeWorkoutTimeFramesArray.length - 1) {

            this.setTimeFrameIdx(this.activeWorkoutTimeFramesArrayIdx + 1)

        } else if (!this.isItTheLastTimeFrame) {

            this.setTimeFrameIdx(0)
            this.setWorkoutPartIdx(this.workoutPartIdx + 1)
        }
    }

    @Action
    init(arg: { workout: MssWorkout; triggeredBy: TRAINING_TRIGGERED_BY }) {

        this.resetToDefault()
        this.setSyncWatcher(ctrlCmdModule.$watch(module => module.workoutCmdLastFeedback, lastMessage => {

            if (lastMessage?.event === CTRL_COMMAND_FEEDBACK.WORKOUT_GOT_FORWARD) {
                this.sync(lastMessage)
                ctrlCmdModule.cmdDisposal(lastMessage.event)
            }
        }))

        this.parse(arg.workout)
        this.setTrriggeredBy(arg.triggeredBy)
    }

    @Action
    timeFrameStart() {

        this.watchTheEndOfTimeFrame()

        let duration = this.activeTimeFrame.duration

        if (duration > 0) {
            if (this.isItPreparationTime) {
                runtimeProcessorModule.changeTimerType(TIMER_TYPE.DOWN)
            } else if ((this.activeWorkoutPart as BaseWorkout).type == WorkoutTypes.RFT) {
                duration = 0
                runtimeProcessorModule.changeTimerType(TIMER_TYPE.UP)
            } else {
                runtimeProcessorModule.changeTimerType(TIMER_TYPE.DOWN)
            }
            runtimeProcessorModule.start(duration)
        }  else if (!this.isItTheLastTimeFrame){

            this.loadTheNextTimeFrame()
            this.timeFrameStart()
        }
    }

    @Action
    watchTheEndOfTimeFrame() {

        this.stopWatchingTheEndOfTimeFrame()

        const theEndOfTimeFrameWatcher = runtimeProcessorModule.$watch(timerModule => timerModule.status, status => {

            const { stopped, finished } = status

            if (stopped) {
                this.stop()
            }
            else if (finished) { // finished automatically

                if (!this.isItTheLastTimeFrame) {

                    this.loadTheNextTimeFrame()
                    this.timeFrameStart()

                } else {
                    this.stop()
                }
            }
        })
        this.setTheEndOfTimeFrameWatcher(theEndOfTimeFrameWatcher)
    }

    @Action
    start() {

        return new Promise((resolve, reject) => {

            const rejectionTimeout = setTimeout(() => reject(), 2000)

            if (this.triggeredBy == TRAINING_TRIGGERED_BY.USER) {

                this.setWorkoutCommandWatcher(ctrlCmdModule.$watch(module => module.workoutCmdLastFeedback, lastMessage => {
    
                    if (lastMessage?.event === CTRL_COMMAND_FEEDBACK.WORKOUT_STARTED) {
   
                        window.clearTimeout(rejectionTimeout)
    
                        this.timeFrameStart()
    
                        ctrlCmdModule.cmdDisposal(lastMessage.event)
                    }
                }))
    
                const { id, totalTime } = this.workout as BaseWorkout
                ctrlCmdModule.triggerCtrlCmd({ trigger: CTRL_COMMAND.MSS_START, value: { id, time: totalTime } })
    
            } else {
    
                this.timeFrameStart()
                resolve(null)
            }
        })
    }

    @Action
    pause() {

        runtimeProcessorModule.pause()

        this.setWorkoutCommandWatcher(ctrlCmdModule.$watch(module => module.workoutCmdLastFeedback, lastMessage => {

            if (lastMessage?.event === CTRL_COMMAND_FEEDBACK.WORKOUT_PAUSED) {

                this.sync(lastMessage)

                ctrlCmdModule.cmdDisposal(lastMessage.event)
            }
        }))

        ctrlCmdModule.triggerCtrlCmd({
            trigger: CTRL_COMMAND.WORKOUT_PAUSE, value: {
                id: this.workout?.id as string,
                time: this.currentTimeSec
            }
        })
    }

    @Action
    stop() {

        this.stopWatchingTheEndOfTimeFrame()

        if (runtimeProcessorModule.isItInProcess) {
            runtimeProcessorModule.stop()
        }

        this.setWorkoutCommandWatcher(ctrlCmdModule.$watch(module => module.workoutCmdLastFeedback, lastMessage => {

            if (lastMessage?.event === CTRL_COMMAND_FEEDBACK.WORKOUT_STOPPED) {

                ctrlCmdModule.cleanUp()

                this.resetToDefault()

                ctrlCmdModule.cmdDisposal(lastMessage.event)
            }
        }))
    }

    @Action
    resume() {

        runtimeProcessorModule.resume()

        this.setWorkoutCommandWatcher(ctrlCmdModule.$watch(module => module.workoutCmdLastFeedback, lastMessage => {

            if (lastMessage?.event === CTRL_COMMAND_FEEDBACK.WORKOUT_RESUMED) {

                ctrlCmdModule.cmdDisposal(lastMessage.event)
            }
        }))

        ctrlCmdModule.triggerCtrlCmd({
            trigger: CTRL_COMMAND.WORKOUT_RESUME, value: {
                id: this.workout?.id as string,
                time: this.currentTimeSec,
            }
        })
    }

    @Action
    forward() {

        if (!this.isItPreparationTime && this.isItTheLastTimeFrame) {

            this.stop()

        } else {

            const { id } = this.workout as BaseWorkout

            ctrlCmdModule.triggerCtrlCmd({
                trigger: CTRL_COMMAND.WORKOUT_FORWARD, value: { id }
            })
        }
    }

    @Action
    backward() {

        this.setWorkoutCommandWatcher(ctrlCmdModule.$watch(module => module.workoutCmdLastFeedback, lastMessage => {

            if (lastMessage?.event === CTRL_COMMAND_FEEDBACK.WORKOUT_GOT_BACKWARD) {

                this.sync(lastMessage)

                ctrlCmdModule.cmdDisposal(lastMessage.event)
            }
        }))

        const { id } = this.workout as BaseWorkout

        ctrlCmdModule.triggerCtrlCmd({
            trigger: CTRL_COMMAND.WORKOUT_BACKWARD, value: { id }
        })
    }

    @Action
    sync(workoutSyncCmd: FeedbackCtrlCmdMessage) {

        if (workoutSyncCmd != undefined) {

            const { value } = workoutSyncCmd
            const { id, time } = value

            if (id == this.workout?.id && time != undefined) {
                this.syncWithTime(time)
            }
            
            ctrlCmdModule.cmdDisposal(workoutSyncCmd.event)
        }
    }
    
    @Action
    syncWithTime(time: number) {

        if (this.currentTimeSec != time) {

            if (time > 0) {

                const timeDiff = Number.parseFloat((this.currentTimeSec - time).toFixed(3))

                const currentTime = runtimeProcessorModule.currentTime / 1000
                const newCurrentTime = (currentTime + timeDiff) * 1000

                if ((timeDiff < 0 && newCurrentTime >= 0) || (timeDiff > 0 && newCurrentTime < runtimeProcessorModule.totalTime)) {
                    runtimeProcessorModule.currentTimeReset(newCurrentTime)
                } else if (timeDiff < 0) {
                    this.directSync(time)
                } else {
                    this.reverseSync(time)
                }

            } else {
                this.setWorkoutPartIdx(0)
                this.setTimeFrameIdx(0)

                const newTime = this.activeTimeFrame.duration * 1000

                runtimeProcessorModule.currentTimeReset(newTime)
                runtimeProcessorModule.totalTimeReset(newTime)
            }
        }
    }

    @Action
    reverseSync(time: number) {

        const timeLeft = (runtimeProcessorModule.totalTime - runtimeProcessorModule.currentTime) / 1000

        let syncTime = this.currentTimeSec - timeLeft
        let timeFrameIdx = this.activeWorkoutTimeFramesArrayIdx - 1


        while (!this.isItFirstTimeFrame && this.workoutPartIdx >= 0) {

            while (syncTime  > time && timeFrameIdx >= 0) {
                syncTime -= this.activeWorkoutTimeFramesArray[timeFrameIdx].duration
                timeFrameIdx--
            }

            if (timeFrameIdx >= 0 || syncTime <= time) {

                this.setTimeFrameIdx(timeFrameIdx + 1)

                const newTimeStart = this.activeTimeFrame.duration * 1000

                runtimeProcessorModule.currentTimeReset(newTimeStart)
                runtimeProcessorModule.totalTimeReset(newTimeStart)
                break
            }

            if (this.workoutPartIdx > 0) {
                this.setWorkoutPartIdx(this.workoutPartIdx - 1)
                timeFrameIdx = this.activeWorkoutTimeFramesArray.length - 1
                this.setTimeFrameIdx(timeFrameIdx)
            } else {
                this.setTimeFrameIdx(0)
            }
        }
    }

    @Action
    directSync(time: number) {

        const timeLeft = ( runtimeProcessorModule.currentTime / 1000 )

        let syncTime = this.currentTimeSec + timeLeft
        let timeFrameIdx = this.activeWorkoutTimeFramesArrayIdx + 1

        while (!this.isItTheLastTimeFrame) {

            while (syncTime < time && timeFrameIdx < this.activeWorkoutTimeFramesArray.length) {
                syncTime += this.activeWorkoutTimeFramesArray[timeFrameIdx].duration
                timeFrameIdx++
            }

            if (timeFrameIdx < this.activeWorkoutTimeFramesArray.length && syncTime == time) {

                this.setTimeFrameIdx(timeFrameIdx)

                const newTimeStart = this.activeTimeFrame.duration * 1000

                runtimeProcessorModule.currentTimeReset(newTimeStart)
                runtimeProcessorModule.totalTimeReset(newTimeStart)

                break
            }

            if (!this.isItTheLastPart) {
                this.setWorkoutPartIdx(this.workoutPartIdx + 1)
                
                timeFrameIdx = 0
                this.setTimeFrameIdx(timeFrameIdx)
            } else {
                this.setTimeFrameIdx(this.activeWorkoutTimeFramesArray.length - 1)
            }
        }
    }

    @Action
    resetToDefault() {
        this.reset()
        this.stopWatchingSync()
        this.setWorkoutCommandWatcher()
        this.stopWatchingTheEndOfTimeFrame()
    }
}

export default new MssWorkoutActiveModule({ store, name: 'activeMssWorkout' })