export enum PAGES {
  LOGIN = 'Login',
  DEVICE_CHANGING = 'DeviceChanging',
  HOME = 'HomePage',
  VIRTUAL_CLASSES = 'VirtualClasses',
  VIRTUAL_CLASS_DESCRIPTION = 'VirtualClassDescription',
  ACTIVE_VIRTUAL_CLASS = 'ActiveVirtualClass',
  REGULAR_WORKOUTS = 'RegularWorkouts',
  REGULAR_WORKOUT_DESCRIPTION = 'RegularWorkoutDescription',
  WORKOUT_ACTIVE_PAGE = 'ActiveWorkout',
  MONTHLY_TRAINING_DESCRIPTION = 'MonthlyTrainingDescription',
  MONTHLY_TRAINING_ACTIVE = 'MonthlyTrainingActive',
  MONTHLY_TRAINING_OVERVIEW = 'MonthlyTrainingOverview',
  MSS_WORKOUT_DESCRIPTION = 'MssWorkoutDescription',
  MSS_WORKOUT_ACTIVE_PAGE = 'ActiveMssWorkout',
  EGYM_TRAINING_DESCRIPTION = 'EgymTrainingDescription',
  EGYM_TRAINING_ACTIVE = 'EgymTrainingActive',
  EGYM_TRAINING_PLANS = 'EGymTrainingPlans',
  EGYM_ADD_USERS = 'EGymAddUsers',
  TIMERS = 'TimersDescription',
  TIMERS_ACTIVE = "ActiveTimer",
  DEVMONITOR = 'DevMonitor',
  CELEBRATION = 'Celebration'
}