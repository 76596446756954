import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import InfoTagComponent from '@/components/info-tag/info-tag.component.vue'
import EGymAddUserComponent from '../e-gym-add-user/e-gym-add-user.component.vue'
import { appModule } from '@/store/modules/app.module'
import { PAGES } from '@/types/router'

@Component({
    components: {
        'info-tag-component': InfoTagComponent,
        'egym-add-user-component': EGymAddUserComponent
    }
})
export default class DetailDescriptionComponent extends Vue {
  @Prop({ type: String, default: ''}) workoutName!: string
  @Prop({ type: Array, default: ['']}) tags!: string[]
  @Prop({ type: Array, default: ['']}) equipmentTags!: string[]
  @Prop({ type: String, default: ''}) workoutDescription!: string
  @Prop({ type: String, default: ''}) coverImageUrl!: string
  @Prop({ type: String, default: ''}) fitnessTypeTag!: string
  @Prop({ type: Boolean, default: true}) isCoverImg!: boolean

  get isEgymActive() {
    if (this.$router.currentRoute.name == PAGES.REGULAR_WORKOUT_DESCRIPTION) {
      return appModule.eCheckInOn
    } else {
      return false
    }
  }
}