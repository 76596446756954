import { TIMER_TYPE } from "@/types/timer"
import runtimeProcessorModule from "../modules/runtime-processor.module"
import { eGymTrainingsModule } from "../modules/egym-trainings.module"
import { ctrlCmdModule } from "../modules/control-commands.module"
import { CTRL_COMMAND } from "@/types/socket"

class CelebrationManager {
    public duration = 30 

    get elapsedTime() {
        return this.duration * 1000 - runtimeProcessorModule.currentTime
    }

    start() {
        if (!eGymTrainingsModule.isCelebrationActive) {
            runtimeProcessorModule.changeTimerType(TIMER_TYPE.DOWN)
            eGymTrainingsModule.startCelebration()
        }
        runtimeProcessorModule.start(this.duration)
    }

    syncOverTime(arg: { currentTime: number; prevTimeMarker: number }) {
        const { currentTime, prevTimeMarker } = arg

        const xSecondsLeft = Math.ceil(currentTime / 1000)
        const prevValue = Math.ceil(prevTimeMarker / 1000)
        const totalTime = runtimeProcessorModule.totalTime / 1000

        if ((xSecondsLeft < totalTime) && xSecondsLeft != prevValue &&
            xSecondsLeft % 5 === 0 && xSecondsLeft >= 5) { // Every 5 seconds

            const time = this.getCurrentWorkoutTime()

            if (time > this.elapsedTime) {
                
                const diff = time - this.elapsedTime
                let currentTimeCorrection = 0

                if (totalTime > diff) {
                    currentTimeCorrection = totalTime - diff
                    runtimeProcessorModule.currentTimeReset(currentTimeCorrection * 1000)
                }
            }
        }
    }

    private getCurrentWorkoutTime(): number {
        return (Date.now() - eGymTrainingsModule.startedAt as number) / 1000
    }

    backwards() {
        ctrlCmdModule.triggerCtrlCmd({
            trigger: CTRL_COMMAND.CELEBRATION_START, value: { id: 'reset-celebration' }
        })
    }

    sync(refTime: number) {
        if (this.elapsedTime != refTime) {
            eGymTrainingsModule.getUpdateStartTime(refTime)
            this.start()
        }
    }

    destroy() {
        runtimeProcessorModule.stop()
    }
}

export default new CelebrationManager