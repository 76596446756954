import { Module, VuexModule, Action, Mutation } from 'vuex-class-modules'
import store from '../index'

@Module
class HomepageModule extends VuexModule {

  activeTab!: string

  @Mutation
  setActiveTab(tabName: string) {
    this.activeTab = tabName
  }

  @Action
  changeActiveTab(tabName: string) {
    this.setActiveTab(tabName)
  }
}

export const homepageModule = new HomepageModule({ store, name: 'homePage' })