import { groupBy } from '@/utils/array.utils'
import { MssWorkout, MssPreviewSlideData, WorkoutTimeFrame,  MssBigRound, CircuitAdditionalActivity, WorkoutAdditionalActivity, SmallRound, WorkoutTypes, BaseWorkout, TimerAdditionalActivity, MssRoundCircuit } from '@/types/workouts'

class MssWorkoutService {

  getMssWorkoutPreviewSlides(workout: MssWorkout): MssPreviewSlideData[] {
    const { name, type, rounds } = workout
    const previewSlides: MssPreviewSlideData[] = []
    workout.roundsData.forEach(round => {
      const exercisesByZonesMap = groupBy(round.circuits[0].exercises, exercise => exercise.zone.position)
      exercisesByZonesMap.forEach((exercises, key) => {
        previewSlides.push({
          type,
          name,
          rounds,
          round: round.round,
          exercises: exercises,
          zone: key,
          zoneName: exercises[0].zone.name,
          zoneDuration: round.duration,
          zonePrep: round.zone_prep
        })
      })
    })
    return previewSlides
  }

  parseTimeFrames(workout: MssWorkout): WorkoutTimeFrame[] {

    const timeFramesArray = (workout as MssWorkout).roundsData.map((round: MssBigRound, roundIdx: number) => {

      const timeFramesArray: WorkoutTimeFrame[] = []

      const cicrcuit = round.circuits[0]
      const exercisesNumber = cicrcuit?.exercises.length as number

      const reps = round.duration / cicrcuit?.exercises.map(exercise => exercise.workInterval + exercise.restInterval)
      .reduce( (totalDuration, duration) => totalDuration + duration )

      let repsIdx = 0
      while (repsIdx < reps) {

        let exerciseIdx = 0
        while (exerciseIdx < exercisesNumber) {

          const { workInterval, restInterval } = (cicrcuit as MssRoundCircuit).exercises[exerciseIdx]
          
          const exerciseName = cicrcuit.exercises[exerciseIdx].name
          timeFramesArray.push({ duration: workInterval, workInterval: true, roundIdx, exerciseName })

          if (exerciseIdx + 1 < exercisesNumber) {
            timeFramesArray.push({ duration: restInterval as number, restInterval: true, roundIdx })
          }
          exerciseIdx++
        }

        const { restInterval } = (cicrcuit as MssRoundCircuit).exercises[exerciseIdx - 1]
        // eslint-disable-next-line
        const { zone_prep } = round
        // eslint-disable-next-line
        timeFramesArray.push({ duration: (restInterval + zone_prep), roundIdx })

        repsIdx++
      }

      if (roundIdx < (workout as MssWorkout).roundsData.length - 1) {
        timeFramesArray[timeFramesArray.length - 1].duration += (workout as MssWorkout).roundsData[roundIdx + 1].round_prep
        timeFramesArray[timeFramesArray.length - 1].roundIdx = roundIdx + 1
      } else {
        timeFramesArray.splice(timeFramesArray.length - 1, 1)
      }

      return timeFramesArray
    }).reduce((acc: WorkoutTimeFrame[], currentValue: WorkoutTimeFrame[]) => acc.concat(currentValue)) as WorkoutTimeFrame[]

    timeFramesArray.unshift({ duration: (workout as MssWorkout).workout_prep, roundIdx: 0 } as WorkoutTimeFrame )

    return timeFramesArray
  }

  parseCircuitWuCdTimeFrames(workout: CircuitAdditionalActivity): WorkoutTimeFrame[] {

    const exercisesNumber = (workout as WorkoutAdditionalActivity).exercises.length
    const roundsNumber = (workout as CircuitAdditionalActivity).roundsData.length

    const timeFramesArray = (workout as CircuitAdditionalActivity).roundsData.map((round: SmallRound, roundIdx: number) => {

      let currentIdx = 0
      const timeFramesArray: WorkoutTimeFrame[] = []

      while (currentIdx < exercisesNumber) {

        timeFramesArray.push({ duration: round.workInterval as number, workInterval: true, roundIdx })

        if (roundIdx < roundsNumber - 1 || currentIdx < exercisesNumber - 1) {
          timeFramesArray.push({ duration: round.restInterval as number, restInterval: true, roundIdx })
        }
        currentIdx++
      }
      return timeFramesArray

    }).reduce((acc: WorkoutTimeFrame[], currentValue: WorkoutTimeFrame[]) => acc.concat(currentValue)) as WorkoutTimeFrame[]

    timeFramesArray.unshift({ duration: workout.prep_time })

    if (workout.type === WorkoutTypes.CIRCUIT) {
      timeFramesArray[0].roundIdx = 0
    }

    return timeFramesArray
  }

  parseWuCdTimeFrames(workout: BaseWorkout | WorkoutAdditionalActivity | TimerAdditionalActivity) {

    const { duration } = workout

    const timeFramesArray = [{ duration }]

    if ((workout as WorkoutAdditionalActivity).activity_type) {

      timeFramesArray.unshift({ duration: (workout as WorkoutAdditionalActivity).prep_time })
    }

    return timeFramesArray
  }

  getMssWorkoutDurationTag(mssWorkout: MssWorkout) {
    const mssWorkoutDuration = mssWorkout.duration
    return mssWorkoutDuration ? `${Math.round(mssWorkoutDuration / 60)} min` : ''
  }
}

export const mssWorkoutService = new MssWorkoutService()