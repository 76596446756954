import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Location, NavigationGuardNext, Route } from 'vue-router'
import { MssWorkout, WorkoutTypes } from '@/types/workouts'
import { PAGES } from '@/types/router'
import MssCarouselPreviewComponent from '@/components/mss-carousel-preview/mss-carousel-preview.component.vue'
import InfoTagComponent from '@/components/info-tag/info-tag.component.vue'
import { mssWorkoutsModule } from '@/store/modules/mss-workouts.module'
import { mssWorkoutDescriptionModule } from '@/store/modules/mss-workout-description.module'
import DetailDescriptionComponent from '@/components/detail-description/detail-description.component.vue'
import TrainingPreviewComponent from '@/components/training-preview/training-preview.component.vue'
import workoutPreviewManager from '@/store/managers/workout-preview.manager'
import router from '@/router'
import { appModule } from '@/store/modules/app.module'

@Component({
  components: {
    'mss-carousel-preview-component': MssCarouselPreviewComponent,
    'info-tag-component': InfoTagComponent,
    'workout-details-component': DetailDescriptionComponent,
    'training-preview-component': TrainingPreviewComponent
  },
  beforeRouteEnter: (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {

    const id = to.params.id;
    const activeWorkout = mssWorkoutsModule.workouts.find(activity => activity.id === id) as MssWorkout
    
    if (activeWorkout != undefined) {
      mssWorkoutDescriptionModule.init(activeWorkout)
      next()
    } else {
      router.back()
    }
  },
  beforeRouteLeave: (to: Route, from: Route, next: NavigationGuardNext<Vue>)  => {

    if (mssWorkoutDescriptionModule.previewMode) {
      mssWorkoutDescriptionModule.togglePreviewMode()
    }
    
    next()
  }
})
export default class MssWorkoutDescriptionPageComponent extends Vue {

  public regularWorkoutsLocation = { name: PAGES.REGULAR_WORKOUTS } as Location
  public activeWorkoutLocation = { name: PAGES.MSS_WORKOUT_ACTIVE_PAGE } as Location

  public workoutPreviewRoundIdx?: number

  public workoutPreviewPartIdx = 0

  public isPreviewActive = false

  get id() {
    return this.$route.params.id
  }

  get workoutName() {
    return mssWorkoutDescriptionModule.workout?.name
  }

  get tags() {
    return mssWorkoutDescriptionModule.tags.slice(0,8)
  }

  get workoutDescription() {
    return mssWorkoutDescriptionModule.workoutDescription
  }

  get workoutPreviewSlidesData() {
    return mssWorkoutDescriptionModule.workoutPreviewSlidesData
  }

  get coverImageUrl() {
    return mssWorkoutDescriptionModule.coverImageUrl
  }

  get equipmentTags() {
    return mssWorkoutDescriptionModule.equipmentTags
  }

  get fitnessTypeTag() {
    return mssWorkoutDescriptionModule.fitnessTypeTag
  }

  get previewIcon() {
    return this.isPreviewActive ? "theme-style timer-btn stop-icon icon-stop" : "theme-style timer-btn triangle-icon icon-tirangle-arrow-right"
  }

  created() {
    if (mssWorkoutDescriptionModule.workout != null) {
      this.workoutPreviewPartIdx = mssWorkoutDescriptionModule.workout?.warming_ups.length
      this.workoutPreviewRoundIdx = mssWorkoutDescriptionModule.workout?.type === WorkoutTypes.CIRCUIT ? 0 : -1
    }
  }

  togglePreviewMode() {

    mssWorkoutDescriptionModule.togglePreviewMode()

    if (this.id != undefined) {

      if (mssWorkoutDescriptionModule.previewMode) {
        workoutPreviewManager.start(this.id, 'mss',
          this.workoutPreviewPartIdx, this.workoutPreviewRoundIdx)
          this.isPreviewActive = true
      } else {
        workoutPreviewManager.stop(this.id, 'mss',
          this.workoutPreviewPartIdx, this.workoutPreviewRoundIdx)
          this.isPreviewActive = false
      } 
      appModule.getPreview(this.isPreviewActive)
    }
  }

  onPreviewUpdate(d: { workoutPartIdx: number; roundIdx?: number }) {

    const { workoutPartIdx, roundIdx } = d

    if (mssWorkoutDescriptionModule.workout?.id && mssWorkoutDescriptionModule.previewMode) {
      if (workoutPartIdx != this.workoutPreviewPartIdx) {
        workoutPreviewManager.changeWorkoutPart(mssWorkoutDescriptionModule.workout.id, 'mss',
          workoutPartIdx, roundIdx)
      } else if (roundIdx != undefined && roundIdx != this.workoutPreviewRoundIdx) {
        workoutPreviewManager.changeRound(mssWorkoutDescriptionModule.workout.id, 'mss',
          workoutPartIdx, roundIdx)
      }
    }

    this.workoutPreviewPartIdx = workoutPartIdx
    this.workoutPreviewRoundIdx = roundIdx
  }

  returnBack() {
    return this.$router.back()
  }

  beforeDestroy() {
    if (this.isPreviewActive) {
      this.togglePreviewMode()
    }
  }
}