import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import LoginComponent from '@/views/login/login.component.vue'
import DeviceChangingComponent from '@/views/device-changing/device-changing.component.vue'
import HomePageComponent from '@/views/home-page/home-page.component.vue'
import VirtualClassListPageComponent from '@/views/virtual-class-list/virtual-class-list.component.vue'
import VirtualClassDescriptionComponent from '@/views/virtual-class-description/virtual-class-description.component.vue'
import WorkoutListPageCpmponent from '@/views/workout-list/workout-list.component.vue'
import WorkoutDescriptionPageComponent from '@/views/workout-description/workout-description.component.vue'
import VirtualClassActivePageComponent from '@/views/virtual-class-active/virtual-class-active.component.vue'
import { PAGES } from '@/types/router'
import WorkoutActivePageComponent from '@/views/workout-active/workout-active.component.vue'
import beforeEnterWorkoutActive from './hooks/workout-active-before-enter'
import beforeEnterMssActive from './hooks/mss-workout-active-before-enter'
import beforeEnterEgymActive from './hooks/egym-training-active-before-enter'
import virtualClassActivePageBeforeRouteEnter from './hooks/virtual-class-active-before-enter'
import beforeEachRouteEnter from './hooks/before-each'
import MonthlyTrainingsComponent from '@/views/monthly-training-workouts/monthly-trainings.component.vue'
import MonthlyTrainingDescriptionPageComponent from '@/views/monthly-training-description/monthly-training-description.vue'
import MonthlyTrainingActivePageComponent from '@/views/monthly-training-active/monthly-training-active.vue'
import monthlyTrainingActivePageBeforeRouteEnter from './hooks/monthly-training-active-before-enter'
import MssWorkoutDescriptionPageComponent from '@/views/mss-workout-description/mss-workout-description.component.vue'
import MssWorkoutActivePageComponent from '@/views/mss-workout-active/mss-workout-active.component.vue'
import afterEachRouteEnter from './hooks/after-each'
import TimerCreatorComponent from '@/views/timer-creator/timer-creator.component.vue'
import TimerActiveComponent from '@/views/timer-active/timer-active.component.vue'
import DevMonitorPageComponent from '@/views/dev-monitor/dev-monitor.component.vue'
import CelebrationComponent from '@/views/celebration/celebration.component.vue'
import EgymTrainingActivePageComponent from "@/views/egym-training-active/egym-training-active.component.vue";
import EgymTrainingDescriptionPageComponent from "@/views/egym-training-description/egym-training-description.component.vue";
import EgymTrainingsListPageCpmponent from "@/views/egym-trainings-list/egym-trainings-list.component.vue";

Vue.use(VueRouter)

const routes: RouteConfig[] = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/login',
    name: PAGES.LOGIN,
    component: LoginComponent
  },
  {
    path: '/device',
    name: PAGES.DEVICE_CHANGING,
    component: DeviceChangingComponent
  },
  {
    path: '/home',
    name: PAGES.HOME,
    component: HomePageComponent
  },
  {
    path: '/virtual-classes',
    name: PAGES.VIRTUAL_CLASSES,
    component: VirtualClassListPageComponent
  },
  {
    path: '/virtual-classes/:id',
    name: PAGES.VIRTUAL_CLASS_DESCRIPTION,
    component: VirtualClassDescriptionComponent
  },
  {
    path: '/virtual-class-active/:id/:triggeredBy?',
    name: PAGES.ACTIVE_VIRTUAL_CLASS,
    component: VirtualClassActivePageComponent,
    beforeEnter: (to, from, next) => virtualClassActivePageBeforeRouteEnter(to, from, next)
  },
  {
    path: '/monthly-training-workouts',
    name: PAGES.MONTHLY_TRAINING_OVERVIEW,
    component: MonthlyTrainingsComponent
  },
  {
    path: '/monthly-trainings/:id',
    name: PAGES.MONTHLY_TRAINING_DESCRIPTION,
    component: MonthlyTrainingDescriptionPageComponent
  },
  {
    path: '/monthly-trainings/active/:id',
    name: PAGES.MONTHLY_TRAINING_ACTIVE,
    component: MonthlyTrainingActivePageComponent,
    beforeEnter: (to, from, next) => monthlyTrainingActivePageBeforeRouteEnter(to, from, next)
  },
  {
    path: '/workouts',
    name: PAGES.REGULAR_WORKOUTS,
    component: WorkoutListPageCpmponent
  },
  {
    path: '/regular-workouts/:id',
    name: PAGES.REGULAR_WORKOUT_DESCRIPTION,
    component: WorkoutDescriptionPageComponent
  },
  {
    path: '/regular-workouts/active/:id/:triggeredBy?',
    name: PAGES.WORKOUT_ACTIVE_PAGE,
    component: WorkoutActivePageComponent,
    beforeEnter: (to, from, next) => beforeEnterWorkoutActive(to, from, next)
  },
  {
    path: '/mss-workouts/:id',
    name: PAGES.MSS_WORKOUT_DESCRIPTION,
    component: MssWorkoutDescriptionPageComponent
  },
  {
    path: '/mss-workouts/active/:id/:triggeredBy?',
    name: PAGES.MSS_WORKOUT_ACTIVE_PAGE,
    component: MssWorkoutActivePageComponent,
    beforeEnter: (to, from, next) => beforeEnterMssActive(to, from, next)
  },
  {
    path: '/egym-training/active/:id',
    name: PAGES.EGYM_TRAINING_ACTIVE,
    component: EgymTrainingActivePageComponent,
    beforeEnter: (to, from, next) => beforeEnterEgymActive(to, from, next)
  },
  {
    path: '/egym-trainings',
    name: PAGES.EGYM_TRAINING_PLANS,
    component: EgymTrainingsListPageCpmponent,
  },
  
  {
    path: '/egym-training/:id',
    name: PAGES.EGYM_TRAINING_DESCRIPTION,
    component: EgymTrainingDescriptionPageComponent
  },
  {
    path: '/timers',
    name: PAGES.TIMERS,
    component: TimerCreatorComponent
  },
  {
    path: '/timers-active',
    name: PAGES.TIMERS_ACTIVE,
    component: TimerActiveComponent
  },
  {
    path: '/dev-monitor',
    name: PAGES.DEVMONITOR,
    component: DevMonitorPageComponent
  },
  {
    path: '/celebration/:totalTime',
    name: PAGES.CELEBRATION,
    component: CelebrationComponent
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => await beforeEachRouteEnter(to, from, next))
router.afterEach((to, from) => afterEachRouteEnter(to, from))

export default router
