import { Module, VuexModule, Action, Mutation } from 'vuex-class-modules'
import store from '../index'
import { VirtualClassApiConfig, THUMBNAIL_URL, DURATION_IN_SECONDS } from '@/types/virtual-classes'
import { virtualClassesService } from '@/services/virtual-classes.service'
import { unique } from '@/utils/array.utils'

@Module
class VirtualClassDescriptionModule extends VuexModule {
  virtualClass: VirtualClassApiConfig | null = null
  

  get className() { return this.virtualClass ? this.virtualClass.attributes.name : '' }
  get classDescription(): string { return this.virtualClass ? this.virtualClass.attributes.description : '' }
  get tags(): string[] {
    const intensity = this.virtualClass?.relationships.level?.attributes.name
    const trainers = this.virtualClass?.relationships.instructors?.map(instructor => instructor.attributes.name)
    const duration = (virtualClassesService.getVirtualClassDurationTag(this.virtualClass?.attributes[DURATION_IN_SECONDS] || 0) || '')
    return unique([ duration, intensity, trainers ].flat().filter(tag => tag) as string[])
  }

  get equipmentTags() {
    return this.virtualClass?.relationships.equipment?.map(equipment => equipment.attributes.name)
  }

  @Mutation
  setVirtualClass(virtualClass: VirtualClassApiConfig) {
    this.virtualClass = virtualClass
  }

  @Action
  init(virtualClass: VirtualClassApiConfig) {
    this.setVirtualClass(virtualClass)
  }
}

export const virtualClassDescriptionModule = new VirtualClassDescriptionModule({ store, name: 'virtualClassesDescription' })