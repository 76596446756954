import { virtualClassesFiltersModule } from "../modules/virtual-classes-filters.module"
import { virtualClassesOverviewModule } from "../modules/virtual-classes-overview.module"
import { virtualClassesModule } from "../modules/virtual-classes.module"


class VirtualClassesStoreManager {

    backToDefault() {
        virtualClassesFiltersModule.reset()
        virtualClassesOverviewModule.initialize([])
    }

    applyFilters() {

        const { classes, virtualClassEquipments } = virtualClassesModule

        const { categoriesFilters, typeFilters, durationFilters, trainerFilters, searchPhraze, equipmentFilters, intensityFilters, languageFilters } = virtualClassesFiltersModule

        virtualClassesOverviewModule.changePage(1)
        virtualClassesOverviewModule.applyFilters({
            classes,
            virtualClassEquipments,
            categoriesFilters,
            typeFilters,
            durationFilters,
            trainerFilters,
            equipmentFilters, 
            intensityFilters,
            searchPhraze,
            languageFilters,
        })
    }

    load() {
        virtualClassesOverviewModule.initialize(virtualClassesModule.classes)
    }
}

export default new VirtualClassesStoreManager()