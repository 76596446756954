import { httpService } from '@/services/http.service'
import { MonthlyTrainingCircuit } from '@/types/workouts'
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules'
import { MonthlyTrainingSchedule } from '@/types/schedule'
import store from '../index'

@Module
class MonthlyTrainingsModule extends VuexModule {
  private monthlyTrainingInstances: MonthlyTrainingCircuit[] = []
  private scheduledMonthlyTrainingsInstances: MonthlyTrainingSchedule[] = []

  get monthlyTrainings(): MonthlyTrainingCircuit[] { return this.monthlyTrainingInstances }
  get scheduledMonthlyTrainings() { return this.scheduledMonthlyTrainingsInstances }

  @Mutation
  private setMonthlyTrainings(monthlyTrainings: MonthlyTrainingCircuit[]) {
    this.monthlyTrainingInstances = monthlyTrainings
  }

  @Mutation
  private injectTraining(training: MonthlyTrainingCircuit) {

    const trainingIdx = this.monthlyTrainingInstances.findIndex(monthlyTraining => monthlyTraining.id === training.id)

    if (trainingIdx >= 0) {
      this.monthlyTrainingInstances.splice(trainingIdx, 1, training)
    } else {
      this.monthlyTrainingInstances.push(training)
    }
  }

  @Mutation
  private setScheduledMonthlyTrainings(schedules: MonthlyTrainingSchedule[]) {
    this.scheduledMonthlyTrainingsInstances = schedules
  }

  @Action
  async getMonthlyTrainings() {
    this.setMonthlyTrainings(await httpService.monthlyTrainings())
  }

  @Action
  async getScheduledMonthlyTrainings() {
    this.setScheduledMonthlyTrainings(await httpService.scheduledMonthlyTrainings())
  }

  @Action
  async initialize() {
    await Promise.all([
      this.getMonthlyTrainings(),
      this.getScheduledMonthlyTrainings()
    ])
  }
}

export const monthlyTrainingsModule = new MonthlyTrainingsModule({ store, name: 'monthlyTrainings' })