import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import InfoTagComponent from '../info-tag/info-tag.component.vue'
import { virtualClassesModule } from '@/store/modules/virtual-classes.module'
import { Location } from 'vue-router'
import { THUMBNAIL_SMALL_URL, THUMBNAIL_URL, VirtualClassApiConfig } from '@/types/virtual-classes'
import { PAGES } from '@/types/router'

@Component({
  components: {
    'info-tag-component': InfoTagComponent
  }
})
export default class VirtualClassTileComponent extends Vue {
  @Prop() classEntity!: VirtualClassApiConfig
  private descriptionLocation!: Location

  get classImage() {
    return this.classEntity.attributes[THUMBNAIL_SMALL_URL]
  }

  get className() { return this.classEntity.attributes.name }
  get tags() { return virtualClassesModule.getClassTags(this.classEntity.id) }

  get classGoal() {
    const classCategories = virtualClassesModule.getClassCategories(this.classEntity.id)
    if (classCategories) {
      return classCategories[0].attributes.name
    }
  }

  get charactersInTags() {
    switch(this.tags.length) {
      case 1: { return 25 }
      case 2: { return 15 }
      case 3: { return 12 }
      case 4: { return 7 }
    }
  }

  goToActivity() {
    this.$router.push(this.descriptionLocation)
  }

  created() {
    this.descriptionLocation = { 
      name: PAGES.VIRTUAL_CLASS_DESCRIPTION,
      params: { id: this.classEntity.id } }
  }
}