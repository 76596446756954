import Vue from 'vue'
import Component from 'vue-class-component'
import VuePicker from 'vue-pickers'
import timeformat from '@/filters/timeformat'
import { IntervalTimer, TimerNames } from '@/types/timer'
import { PAGES } from '@/types/router'
import { timerActiveModule } from '@/store/modules/timer-active.module'
import { timersModule, TimerTabs } from '@/store/modules/timers.module'
import TimerPreSetsComponent from '@/components/timer-pre-sets/timer-pre-sets.component.vue'
import TimerIntervalComponent from '@/components/timer-interval/timer-interval.component.vue'
import TimerCountdownComponent from '@/components/timer-countdown/timer-countdown.component.vue'

@Component({
    components: {
        'timer-pre-sets-component': TimerPreSetsComponent,
        'timer-countdown': TimerCountdownComponent,
        'timer-interval': TimerIntervalComponent,
        VuePicker
    },
    filters: { timeformat }
})
export default class TimerCreatorComponent extends Vue {

    public minutes = 0
    public seconds = 0

    public tabId: TimerTabs = TimerTabs.PRESETS

    public interval!: IntervalTimer

    public timerPreset: TimerNames | null = null

    public countdownTime!: number

    public timerTabs = [false, false, false, false]

    get timerPresetsList() {
        return timersModule.presetsList
    }

    created() {
        this.interval = timersModule.intervalTimerData
        this.countdownTime = timersModule.countdownTime
    }

    onCountdownChange(time: number) {
        timersModule.updateCountdown(time)
    }

    onTimerPresetChange() {
        timersModule.updateTmerPreset(this.timerPreset)
    }

    onIntervalChange() {
        timersModule.updateInterval(this.interval)
    }

    onTimerChange(tabId: TimerTabs) {
        this.timerTabs[this.tabId] = false
        this.tabId = tabId
        timersModule.updateTimerType(tabId)
    }

    goToActivePage() {
        const workout = timersModule.workout
        if (workout != null) {
            timerActiveModule.init(workout)
            this.$router.push({name: PAGES.TIMERS_ACTIVE})
        }
    }

    beforeDestroy() {
        timersModule.resetToDefault()
    }
}