import {
    BaseWorkout, WorkoutWuCd, BigRound, CircuitAdditionalActivity, WorkoutTimeFrame, CircuitWorkout, SmallRound,
    TimerAdditionalActivity, WorkoutAdditionalActivity, BaseAdditionalActivity, ComplexWorkout, FunxtionFitnessCategory,
    FunxtionFitnessType, WorkoutDurationFilter, EQUIPMENT_OTHER, Equipment, MssWorkoutType, MssWorkout, WorkoutTypes
} from '@/types/workouts'
import { hasOwnProp } from '@/utils/object.utils'

class WorkoutService {

    parseCircuitWuCdTimeFrames(workout: CircuitAdditionalActivity ): WorkoutTimeFrame[] {

        const exercisesNumber = (workout as WorkoutAdditionalActivity).exercises.length
        const roundsNumber = (workout as CircuitAdditionalActivity).roundsData.length

        const timeFramesArray = (workout as CircuitAdditionalActivity).roundsData.map((round: SmallRound, roundIdx: number) => {

            let currentIdx = 0
            const timeFramesArray: WorkoutTimeFrame[] = []

            while (currentIdx < exercisesNumber) {

                timeFramesArray.push({ duration: round.workInterval as number, workInterval: true, roundIdx })
                
                if (roundIdx < roundsNumber - 1 || currentIdx < exercisesNumber - 1) {
                    timeFramesArray.push({ duration: round.restInterval as number, restInterval: true, roundIdx })
                }
                currentIdx++
            }
            return timeFramesArray
            
        }).reduce((acc: WorkoutTimeFrame[], currentValue: WorkoutTimeFrame[]) => acc.concat(currentValue)) as WorkoutTimeFrame[]

        if (workout.activity_type === WorkoutWuCd.CD) {
            timeFramesArray.unshift({ duration: workout.prep_time})
            
            if (workout.type === WorkoutTypes.CIRCUIT) {
                timeFramesArray[0].roundIdx = 0
            }
        } else {
            timeFramesArray.push({ duration: workout.prep_time})
        }

        return timeFramesArray
    }

    parseCircuitWorkoutTimeFrames(workout: CircuitWorkout): WorkoutTimeFrame[] {

        const roundsNumber = (workout as CircuitWorkout).roundsData.length

        return (workout as CircuitWorkout).roundsData.map((round: BigRound, roundIdx: number) => {

            const cicrcuit = round.circuits[0]
            const exercisesNumber = cicrcuit?.exercises.length as number
            let currentIdx = 0
            const timeFramesArray: WorkoutTimeFrame[] = []

            while (currentIdx < exercisesNumber) {

                timeFramesArray.push({ duration: round.workInterval as number, workInterval: true, roundIdx, exerciseName: cicrcuit.exercises[currentIdx].name })
                
                if (roundIdx < roundsNumber - 1 || currentIdx < exercisesNumber - 1) {
                    timeFramesArray.push({ duration: round.restInterval as number, restInterval: true, roundIdx })
                }
                currentIdx++
            }
            return timeFramesArray
        }).reduce((acc: WorkoutTimeFrame[], currentValue: WorkoutTimeFrame[]) => acc.concat(currentValue)) as WorkoutTimeFrame[]
    }

    parseTimeFrames(workout: BaseWorkout | WorkoutAdditionalActivity | TimerAdditionalActivity) {

        const timeFramesArray = []

        if ((workout as WorkoutAdditionalActivity).type == WorkoutTypes.RFT) {

            const duration = 5
            timeFramesArray.push({ duration })

        } else {
            const { duration } = workout
            timeFramesArray.push({ duration })
        }

        if ((workout as WorkoutAdditionalActivity).activity_type) {

            if ((workout as WorkoutAdditionalActivity).activity_type === WorkoutWuCd.CD) {
                timeFramesArray.unshift({ duration: (workout as WorkoutAdditionalActivity).prep_time})
                
            } else {
                timeFramesArray.push({ duration: (workout as WorkoutAdditionalActivity).prep_time})
            }
        }

        return timeFramesArray
    }

    parseExercises(workout: BaseWorkout | CircuitWorkout | WorkoutAdditionalActivity | TimerAdditionalActivity) {

        if ((workout as BaseAdditionalActivity).activity_type) {

            if ((workout as TimerAdditionalActivity).timer < 1) {
                return (workout as WorkoutAdditionalActivity).exercises
            }
        } else {

            return (workout as ComplexWorkout).exercises
        }

        return []
    }

    getFitnessCategoryNameById(id: number, categories: FunxtionFitnessCategory[]): string | null {
        return categories.find(category => category.id == id)?.attributes.name || null
    }

    getFitnessTypeNameById(id: number, types: FunxtionFitnessType[]): string | null {
        return types.find(type => type.id == id)?.attributes.name || null
    }

    getWorkoutDuration(workout: BaseWorkout) {
        const workoutDuration = workout.totalTime
        return workoutDuration ? `${Math.round(workoutDuration / 60)} min` : ''
    }

    filterWorkoutsByName(workouts: BaseWorkout[], searchPhraze: string) {
        if (searchPhraze) {
            return workouts.filter(workout => {
                return workout.name.toLowerCase().includes(searchPhraze.toLowerCase())
            })
        }
        return workouts
    }

    filterWorkoutsByDuration(workouts: BaseWorkout[], durationFilters: WorkoutDurationFilter[]) {
        if (durationFilters.length) {
            return workouts.filter(workout => {
                return durationFilters.reduce((result, durationFilter) => {
                    if (!result.isFiltered) {
                        result.isFiltered = workout.duration > durationFilter.from && workout.duration <= durationFilter.to
                    }
                    return result
                }, { isFiltered: false }).isFiltered
            })
        }
        return workouts
    }

    filterWorkoutsByType(workouts: BaseWorkout[], typeFilters: string[]) {
        if (typeFilters.length) {
            return workouts.filter(workout => {
                return typeFilters.reduce((result, typeId) => {
                    if (!result.isFiltered) {
                        if (typeId === MssWorkoutType.MSS_BOUTIQUE) {
                            result.isFiltered = hasOwnProp(workout, 'circuit_mss') ? !(workout as MssWorkout).circuit_mss : false
                        } else if (typeId === MssWorkoutType.MSS_CIRCUIT) {
                            result.isFiltered = hasOwnProp(workout, 'circuit_mss') ? (workout as MssWorkout).circuit_mss : false
                        } else {
                            result.isFiltered = workout.type === typeId
                        }
                    }
                    return result
                }, { isFiltered: false }).isFiltered
            })
        }
        return workouts
    }

    filterWorkoutsByFitnessCategory(workouts: BaseWorkout[], fitnessCategoryFilters: string[], workoutFitnessCategories: FunxtionFitnessCategory[]) {
        if (fitnessCategoryFilters.length) {
            return workouts.filter(workout => {
                return fitnessCategoryFilters.reduce((result, categoryId) => {
                    if (!result.isFiltered) {
                        result.isFiltered = workout.category?.toString() === categoryId
                    }
                    return result
                }, { isFiltered: false }).isFiltered
            })
        }
        return workouts
    }

    filterWorkoutsByFitnessType(workouts: BaseWorkout[], fitnessTypeFilters: string[], workoutFitnessTypes: FunxtionFitnessType[]) {
        if (fitnessTypeFilters.length) {
            return workouts.filter(workout => {
                return fitnessTypeFilters.reduce((result, typeId) => {
                    if (!result.isFiltered) {
                        result.isFiltered = workout.fitness_type?.toString() === typeId
                    }
                    return result
                }, { isFiltered: false }).isFiltered
            })
        }
        return workouts
    }

    filterWorkoutsByEquipment(workouts: BaseWorkout[], equipmentFilters: string[], workoutEquipments: Equipment[]) {
        if (equipmentFilters.length) {
            return workouts.filter(workout => {
                return equipmentFilters.reduce((result, equipmentId) => {
                    if (!result.isFiltered) {
                        let isWorkoutFiltered
                        if (equipmentId === EQUIPMENT_OTHER) {
                            isWorkoutFiltered = workout.gearList.some(wEquip => !workoutEquipments
                              .some(configEquip => configEquip.name === wEquip) || wEquip === EQUIPMENT_OTHER)
                        } else {
                            isWorkoutFiltered = workout.gearList.some(gear => gear === equipmentId)
                        }
                        if (isWorkoutFiltered) {
                            result.isFiltered = isWorkoutFiltered
                        }
                    }
                    return result
                }, { isFiltered: false }).isFiltered
            })
        }
        return workouts
    }

    filterWorkoutsByBodyParts(workouts: BaseWorkout[], bodyPartsFilters: string[],) {
        if (bodyPartsFilters.length) {
            return workouts.filter(workout => {
                return bodyPartsFilters.reduce((result, bodyPartId) => {
                    let isWorkoutFiltered 
                    if (!result.isFiltered) {
                            isWorkoutFiltered = workout.bodytypes.some(part => part === bodyPartId)
                        if (isWorkoutFiltered) {
                            result.isFiltered = isWorkoutFiltered
                        }
                    }
                    return result
                }, { isFiltered: false }).isFiltered
            })
        }
        return workouts
    }

    filterWorkoutsByTrainer(workouts: BaseWorkout[], trainerFilters: string[]) {
        if (trainerFilters.length) {
            return workouts.filter(workout => {
                return trainerFilters.reduce((result, trainerId) => {
                    if (!result.isFiltered) {
                        result.isFiltered = workout.creatorName === trainerId
                    }
                    return result
                }, { isFiltered: false }).isFiltered
            })
        }
        return workouts
    }

    filterWorkoutsByIntensity(workouts: BaseWorkout[], intensityFilters: string[]) {
        if (intensityFilters.length) {
            return workouts.filter(workout => {
                return intensityFilters.reduce((result, intensityId) => {
                    if (!result.isFiltered) {
                        result.isFiltered = workout.difficulty === intensityId
                    }
                    return result
                }, { isFiltered: false }).isFiltered
            })
        }
        return workouts
    }
}



export default new WorkoutService()