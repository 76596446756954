import {MssWorkout, MssWorkoutPagination} from "@/types/workouts";
import {allWorkoutsOverviewModule} from "../modules/all-workouts-overview.module";
import {mssWorkoutsModule} from "../modules/mss-workouts.module";
import workoutsGeneralFiltersModule from "../modules/workouts-general-filters.module";
import {workoutsModule} from "../modules/workouts.module";
import {httpService} from "@/services/http.service";
import {mssWorkoutsInZoneModule} from "../modules/mss-workouts-in-zone.module";


class WorkoutsStoreManager
{

    backToDefault()
    {
        workoutsGeneralFiltersModule.reset();
        allWorkoutsOverviewModule.initialize(workoutsModule.workouts.concat(mssWorkoutsModule.workouts));
    }

    applyFilters()
    {

        const {workoutEquipments, workoutFitnessCategories, workoutFitnessTypes} = workoutsModule;

        const {fitnessCategoryFilters, fitnessTypeFilters, typeFilters, durationFilters, trainerFilters, searchPhraze, equipmentFilters, intensityFilters, bodyPartFilters} = workoutsGeneralFiltersModule;

        const workouts = workoutsModule.workouts.concat(mssWorkoutsModule.workouts);

        allWorkoutsOverviewModule.changePage(1);
        allWorkoutsOverviewModule.applyFilters({
            workouts,
            workoutEquipments,
            workoutFitnessCategories,
            workoutFitnessTypes,
            fitnessTypeFilters,
            typeFilters,
            fitnessCategoryFilters,
            durationFilters,
            trainerFilters,
            equipmentFilters,
            intensityFilters,
            searchPhraze,
            bodyPartFilters
        });
    }

    deleteEgymTrainings() {
        mssWorkoutsModule.deleteEgymTrainings();
        mssWorkoutsInZoneModule.deleteEgymTrainings();
    }

}

export default new WorkoutsStoreManager();