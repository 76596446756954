import { CACHE_SECTION } from '@/services/cache.service'
import { httpService } from '@/services/http.service'
import { MssWorkoutSchedule } from '@/types/schedule'
import { MssWorkout, MssWorkoutPagination } from '@/types/workouts'
import { sleep } from '@/utils/common.utils'
import Vue from 'vue'
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules'
import store from '../index'
import {MADE_BY_EGYM_TRAINER} from "@/types/e-gym";

@Module
class MssWorkoutsModule extends VuexModule {
  
  private page = 0
  private readonly perPage = 4
  private workoutsEntries: MssWorkout[] = []
  private scheduledworkoutsEntries: MssWorkoutSchedule[] = []
  get egymTrainingPlans() { return this.workoutsEntries.filter((workout) => workout.made_by == MADE_BY_EGYM_TRAINER) }

  get workouts() {
    return this.workoutsEntries
  }

  get scheduledWorkouts() {
    return this.scheduledworkoutsEntries
  }

  @Mutation
  private changePage(page: number) {
    this.page = page
  }

  @Mutation
  private setScheduledWorkouts(scheduledWorkouts: MssWorkoutSchedule[]) {
    this.scheduledworkoutsEntries = scheduledWorkouts
  }

  @Mutation
  private setWorkouts(workouts: MssWorkout[]) {
    this.workoutsEntries = workouts.filter((workout, workoutIndex) => {
      const idx = workouts.findIndex(item => workout.id === item.id)
      return idx < 0 || idx == workoutIndex
    })
  }

  @Mutation
  private injectWorkout(workout: MssWorkout) {
    this.workoutsEntries.unshift(workout)
  }

  @Mutation
  removeWorkout(id: string) {
    
    const workoutIdx = this.workoutsEntries.findIndex(workout => workout.id === id)
    if (workoutIdx >= 0) {
      this.workoutsEntries.splice(workoutIdx, 1)
    }
  }

  @Action
  async getScheduledWorkouts() {

    const workouts = await httpService.scheduledMssWorkouts() as MssWorkoutSchedule[]
    this.setScheduledWorkouts(workouts)
  }

  @Action
  async getWorkouts() {
    
    if (this.page == 0) {
      this.changePage(1)
      let response = null
      try {
        response = await httpService.mssWorkouts(this.page, this.perPage) as MssWorkoutPagination
      } catch(err) {
        // eslint-disable-next-line
        console.error(err)
      }

      if (response != null && (!response?.cached || this.workouts.length < 1 || response?.total != this.workouts.length)) {
        let workouts: MssWorkout[] = []
        while (response.workouts.length > 0) {
          let nextPageWorkouts: MssWorkout[] = []
          try {
            nextPageWorkouts = await Promise.all(response.workouts.map(async (workout) => {
              if (workout.coverImage != undefined && workout.coverImage.small) {
                const { small } = workout.coverImage
                if (small) {
                  try {
                    workout.coverImage.small = await Vue.prototype.$cacheService.match(CACHE_SECTION.WORKOUTS, small)
                  } catch (err) {
                    // eslint-disable-next-line
                    console.error(err)
                  }
                }
              }
              return workout
            }))
          } catch (err) {
            // eslint-disable-next-line
            console.error(err)
          }
          workouts = workouts.concat(nextPageWorkouts)
          if (response.per_page * response.current_page >= response.total) {
            break
          }
          
          try {
            response = await httpService.mssWorkouts(this.page, this.perPage) as MssWorkoutPagination
            this.changePage(this.page + 1)
          } catch(err) {
            // eslint-disable-next-line
            console.error(err)
            const seconds = Math.floor(Math.random() * 10) + 1;
            await sleep(seconds * 1000) // wait from 1 to 10 seconds
          }
        }
        this.setWorkouts(workouts)
      }
      this.changePage(0)
    }
  }

  @Action
  async updateWorkout(id: string) {
    let workout = null
    while (workout == null) {
      try {
        workout = await httpService.mssWorkoutById(id) as MssWorkout
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
  
        if ((err as any).response && (err as any).response.status === 418) {
          this.removeWorkout(id)
          break;
        } else {
          const seconds = Math.floor(Math.random() * 10) + 1;
          await sleep(seconds * 1000) // wait from 1 to 10 seconds
        }
      }
    }

    if (workout != null) {
      this.removeWorkout(id)
      this.injectWorkout(workout)
    }
    return id
  }

  @Action
  append(workouts: MssWorkout[])
  {
    this.setWorkouts(this.workoutsEntries.concat(workouts));
  }

  @Action
  deleteEgymTrainings()
  {
    this.egymTrainingPlans.forEach(workout => this.removeWorkout(workout.id))
  }

  @Action
  async initialize() {
    await Promise.all([
      this.getWorkouts(),
      this.getScheduledWorkouts()
    ])
  }

  @Action
  resetToDefault() {
    this.changePage(0)
    this.setWorkouts([])
    this.setScheduledWorkouts([])
  }
}

export const mssWorkoutsModule = new MssWorkoutsModule({ store, name: 'mssWorkouts' })