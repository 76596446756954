import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules'
import store from '../index'
import { authModule } from './auth.module'
import { deviceModule } from './device.module'
import { monthlyTrainingsModule } from './monthly-trainings.module'
import { mssWorkoutsModule } from './mss-workouts.module'
import { workoutsModule } from './workouts.module'
import { schedulesAggregatorModule } from './schedules-aggregator.module'
import { ctrlCmdModule } from './control-commands.module'
import { virtualClassesModule } from './virtual-classes.module'
import { websoketConnectionModule } from './websocket-connection.module'
import { allWorkoutsModule } from './all-workouts.module'
import { CTRL_COMMAND } from '@/types/socket'
import runtimeProcessorModule from './runtime-processor.module'
import { themeModule } from './theme.module'
import { whiteLabelModule } from './white-label.module'
import { mssWorkoutsInZoneModule } from './mss-workouts-in-zone.module'
import { timersModule } from './timers.module'
import { dexieDbService } from '@/services/dexie-db.service'
import { BaseWorkout, MssWorkout } from '@/types/workouts'
import { settings } from '@/settings/settings'
import { eGymTrainingsModule } from './egym-trainings.module'

export const CONTENT_REFRESH_IN_SECONDS = 120

@Module
class AppModule extends VuexModule {

  public readyToStart = false
  public pendingState = false
  public contentRefreshingIntervalId = 0

  public _workoutsAvailable = false
  public _virtualClassesAvailable = false
  public _monthlyTrainingsAvailable = false
  public _timersAvailable = false

  public nextTrainingUpdateTimeout: number | null = null;

  public wssConnectionWatcher: CallableFunction | null = null
  public pingPongWatcher: CallableFunction | null = null
  public runtimeWatcher: CallableFunction | null = null

  public lastPlayerContactTimestamp = 0

  public devModeOn = false
  public eCheckInOn = true

  public isPreviewActive = false

  get workoutsAvailable() { return this._workoutsAvailable }
  get virtualClassesAvailable() { return this._virtualClassesAvailable }
  get monthlyTrainingsAvailable() { return this._monthlyTrainingsAvailable } 
  get timersAvailable() { return this._timersAvailable }
  get previewState() { return this.isPreviewActive }
  

  @Mutation
  changeDevModeTo(v: boolean) {
    this.devModeOn = v
  }

  @Mutation
  refreshLastPlayerTimestamp(time: number) {
    this.lastPlayerContactTimestamp = time 
  }

  @Mutation
  updateWssConnectionWatcher(watcher: CallableFunction | null) {

    if (this.wssConnectionWatcher != null) {
      this.wssConnectionWatcher()
    }
    this.wssConnectionWatcher = watcher
  }

  @Mutation
  updatePingPongWatcher(watcher: CallableFunction | null) {

    if (this.pingPongWatcher != null) {
      this.pingPongWatcher()
    }
    this.pingPongWatcher = watcher
  }

  @Mutation
  pendingStateChangeTo(pending: boolean) {
    this.pendingState = pending
  }

  @Mutation
  updateContentRefreshingIntervalId(interval = 0) {
    window.clearInterval(this.contentRefreshingIntervalId)
    this.contentRefreshingIntervalId = interval
  }

  @Mutation
  setNextTrainingTimeout(timeout: number | null) {

    if (this.nextTrainingUpdateTimeout) {
      window.clearTimeout(this.nextTrainingUpdateTimeout)
    }
    this.nextTrainingUpdateTimeout = timeout
  }

  @Mutation
  changeReadinessToStart(isReady: boolean) {
    this.readyToStart = isReady
  }

  @Mutation
  private setMonthlyTrainingsAvailability(available: boolean) {
    this._monthlyTrainingsAvailable = available
  }

  @Mutation
  private setWorkoutsAvailability(workoutsAvailable: boolean) {
    this._workoutsAvailable = workoutsAvailable
  }

  @Mutation
  private setVirtualClassesAvailability(virtualClassesAvailable: boolean) {
    this._virtualClassesAvailable = virtualClassesAvailable
  }

  @Mutation
  private setTimerAvailability(timersAvailable: boolean) {
    this._timersAvailable = timersAvailable
  }

  @Mutation
  setECheckIn(eCheckIn: boolean) {
    this.eCheckInOn = eCheckIn
  }

  @Mutation
  setPreview(state: boolean) {
    this.isPreviewActive = state
  }

  @Action
  getPreview(state: boolean) {
    this.setPreview(state)
  }

  @Action
  pendingStart() {
    this.pendingStateChangeTo(true)
  }

  @Action
  pendingStop() {
    this.pendingStateChangeTo(false)
  }

  @Action
  switchDevModeTo(v: boolean) {
    this.changeDevModeTo(v)
  }

  @Action
  async initialize(deviceId: string | undefined) {

    this.changeReadinessToStart(false)

    if (deviceId) {

      try {
        await authModule.authDevice(deviceId)
      } catch (err) {
        //console.error(err)
      }

      try {
        await this.connectToDevice()
      } catch (err) {
        //console.error(err)
      }

      this.changeReadinessToStart(true)
    }
  }

  @Action
  async connectToDevice() {

    try {
      await deviceModule.initialize()
    } catch (err) {
      //console.error(err)
      return
    }

    this.updatePingPongWatcher(ctrlCmdModule.$watch(module => module.pingPong, pingPong => {
      if (pingPong) {
        this.refreshLastPlayerTimestamp(Date.now())
      }
    }))

    this.updateWssConnectionWatcher(websoketConnectionModule.$watch(module => module.connected, (isItConnected, connectedBefore) => {

      if (isItConnected) {
        ctrlCmdModule.subscribe(deviceModule.channelId)
        ctrlCmdModule.subscribe(deviceModule.realtimeUpdatesChannelId)
        this.ping()
      }
    }))
    websoketConnectionModule.connect()

    this.setECheckIn(deviceModule.deviceConfig.eCheckIn)
  }

  @Action
  async loadContent() {

    await authModule.deviceTokenValidityCheck()

    return new Promise((resolve) => {

      Promise.all([
        virtualClassesModule.initialize(),
        workoutsModule.initialize(),
        mssWorkoutsModule.initialize(),
        mssWorkoutsInZoneModule.initialize(),
        monthlyTrainingsModule.initialize(),
        timersModule.initialize(),
        themeModule.initialize(),
      ]).then(() => {
        this.updateTrainingsAvailability()
        allWorkoutsModule.initialize({ regularWorkouts: workoutsModule.workouts, mssWorkouts: mssWorkoutsModule.workouts }),
        schedulesAggregatorModule.update()
        resolve(null)
      }).catch(err => {
        // console.error(err)
      })
    })
  }

  @Action
  async updateContent() {

    await authModule.deviceTokenValidityCheck()

    const processLocalQueue = () => {
      return new Promise((resolve, reject) => {
        
        dexieDbService.workouts.where('id').aboveOrEqual(1).toArray().then(workouts => {

          if (workouts.length > 0) {
            const plist = workouts.map(workout => {
              let plist = []
              if (workout.mss) {
                plist = [
                  mssWorkoutsModule.updateWorkout(workout.uuid),
                  mssWorkoutsInZoneModule.updateWorkout(workout.uuid)
                ]
              } else {
                plist.push(workoutsModule.updateWorkout(workout.uuid))
              }
              return plist
            }).reduce((prev, current) => prev.concat(current))

            Promise.all(plist).then((ids: Array<string>) => {
              ids.forEach((id: string) => dexieDbService.workouts.where('uuid')
                .equals(id).delete())
              resolve(ids)
            }).catch(err => {
              //console.error(err)
              reject(null)
            })
          }
        })
      })
    }

    return new Promise((resolve, reject) => {

      Promise.all([
        processLocalQueue(),
        eGymTrainingsModule.sendBacklog(),
        virtualClassesModule.initialize(),
        monthlyTrainingsModule.initialize(),
        workoutsModule.getScheduledWorkouts(),
        mssWorkoutsModule.getScheduledWorkouts(),
        timersModule.initialize(),
        themeModule.initialize(),
      ]).then(() => {
        this.updateTrainingsAvailability()
        allWorkoutsModule.initialize({ regularWorkouts: workoutsModule.workouts, mssWorkouts: mssWorkoutsModule.workouts })
        schedulesAggregatorModule.update()
        resolve(null)
      }).catch(err => {
        reject(err)
      })
    })
  }

  @Action
  refreshContentWithInterval() {

    if (!this.contentRefreshingIntervalId) {

      this.updateContentRefreshingIntervalId(window.setInterval(() => {

        if (deviceModule.deviceConfig.pid != undefined) {
  
          if (!websoketConnectionModule.connected) {
              
            this.refreshLastPlayerTimestamp(0)
            websoketConnectionModule.connect()
  
          } else if (!runtimeProcessorModule.isItInProcess) {
  
            if (this.lastPlayerContactTimestamp > 0 &&
              (Date.now() - this.lastPlayerContactTimestamp > CONTENT_REFRESH_IN_SECONDS * 2 * 1000)) {
  
              websoketConnectionModule.disconnect()
  
            } else {
              this.ping()
            }
  
            this.updateContent().then(() => {
              if (themeModule.themeInfo) {
                whiteLabelModule.initialize(themeModule.themeInfo)
              }
            }).catch((err) => {
              if (err.message === 'Network Error') {
                this.onNetworkError()
              }
            })
          }
        } else {
          this.resetToDefault()
        }
      }, CONTENT_REFRESH_IN_SECONDS * 1000))
    }
  }

  @Action
  ping() {
    ctrlCmdModule.triggerCtrlCmd({
      trigger: CTRL_COMMAND.PING,
      value: { id: CTRL_COMMAND.PING },
    })
  }

  @Action
  stopContentRefreshing(){
    this.updateContentRefreshingIntervalId()
  }

  @Action
  updateTrainingsAvailability() {
    return new Promise((resolve) => {
      if (virtualClassesModule.classes.length > 0) {
        this.setVirtualClassesAvailability(true)
      } else {
        this.setVirtualClassesAvailability(false)
      }

      if (timersModule.presetsList.length > 0) {
        this.setTimerAvailability(true)
      } else {
        this.setTimerAvailability(false)
      }

      const areWorkoutsAvailable = workoutsModule.workouts.length > 0 ||
        mssWorkoutsModule.workouts.length > 0 ||
        monthlyTrainingsModule.monthlyTrainings.length > 0

      this.setWorkoutsAvailability(areWorkoutsAvailable)
      this.setMonthlyTrainingsAvailability(monthlyTrainingsModule.monthlyTrainings.length > 0)
      resolve(null)
    })
  }

  @Action
  resetToDefault() {
    
    this.changeReadinessToStart(false)
    this.updateContentRefreshingIntervalId()

    this.updatePingPongWatcher(null)
    this.updateWssConnectionWatcher(null)
    
    websoketConnectionModule.disconnect()
    authModule.logout()
  }

  @Action
  beforeTheNextTrainingTimeout(timeout: number | null) {
    this.setNextTrainingTimeout(timeout)
  }

  private onlineCheck(timeout: number) {
    const controller = new AbortController();
    const options = { timeout }
    const id = setTimeout(() => controller.abort(), timeout * 1000);
    return new Promise(resolve => {
      window.fetch(settings.url.styles(), {
        ...options, signal: controller.signal
      }).then(() => {
        clearTimeout(id);
        resolve(true);
      }).catch(() => resolve(false))
    });
  }

  private onNetworkError() {
    this.onlineCheck(30).then(online => {
      if (!online) {
        websoketConnectionModule.disconnect()
      } else if (!websoketConnectionModule.connected) {
        websoketConnectionModule.connect()
      }
    });
  }
}

export const appModule = new AppModule({ store, name: 'app' })