import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Location, NavigationGuardNext, Route } from 'vue-router'
import { workoutsModule } from '@/store/modules/workouts.module'
import { BaseWorkout, WorkoutTypes } from '@/types/workouts'
import { PAGES } from '@/types/router'
import CarouselPreviewComponent from '@/components/carousel-preview/carousel-preview.component.vue'
import InfoTagComponent from '@/components/info-tag/info-tag.component.vue'
import workoutDescriptionModule from '@/store/modules/workout-description.module'
import DetailDescriptionComponent from '@/components/detail-description/detail-description.component.vue'
import TrainingPreviewComponent from '@/components/training-preview/training-preview.component.vue'
import workoutPreviewManager from '@/store/managers/workout-preview.manager'
import router from '@/router'
import { appModule } from '@/store/modules/app.module'

@Component({
  components: {
    'carousel-preview-component': CarouselPreviewComponent,
    'info-tag-component': InfoTagComponent,
    'workout-details-component': DetailDescriptionComponent,
    'training-preview-component': TrainingPreviewComponent,
  },
  beforeRouteEnter: (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {

    const id = to.params.id;
    const activeWorkout = workoutsModule.workouts.find(activity => activity.id === id) as BaseWorkout

    if (activeWorkout != undefined) {
      workoutDescriptionModule.init(activeWorkout);
      next()
    } else {
      router.back()
    }
  },
  beforeRouteLeave: (to: Route, from: Route, next: NavigationGuardNext<Vue>)  => {

    if (workoutDescriptionModule.previewMode) {
      workoutDescriptionModule.togglePreviewMode()
    }
    next()
  }
})
export default class WorkoutDescriptionPageComponent extends Vue {

  public regularWorkoutsLocation = { name: PAGES.REGULAR_WORKOUTS } as Location
  public activeWorkoutLocation = { name: PAGES.WORKOUT_ACTIVE_PAGE } as Location
  public eGymAddUsersLocation = { name: PAGES.EGYM_ADD_USERS } as Location

  public workoutPreviewRoundIdx?: number

  public workoutPreviewPartIdx = 0

  public isPreviewActive = false

  public isAddUserActive = false

  get isEGymActive() {
    return appModule.eCheckInOn
  }

  get nextLocation() {
    return this.isEGymActive ? this.eGymAddUsersLocation : this.activeWorkoutLocation
  }

  get workoutName() {
    return workoutDescriptionModule.workoutName
  }

  get tags() {
    return workoutDescriptionModule.descriptionTags.slice(0, 8)
  }
  get equipmentTags() {
    return workoutDescriptionModule.equipmentTags
  }

  get workoutDescription() {
    return workoutDescriptionModule.workoutDescription
  }

  get workoutPreviewSlidesData() {
    return workoutDescriptionModule.workoutPreviewSlidesData
  }

  get coverImageUrl() {
    return workoutDescriptionModule.coverImageUrl
  }

  get fitnessTypeTag() {
    return workoutDescriptionModule.fitnessTypeTag
  }

  get id() {
    return this.$route.params.id;
  }

  get previewIcon() {
    return this.isPreviewActive ? "theme-style timer-btn stop-icon icon-stop" : "theme-style timer-btn triangle-icon icon-tirangle-arrow-right"
  }

  get addUserMode() {
    return this.isAddUserActive
  }

  created() {
    if (workoutDescriptionModule.workout != null) {
      this.workoutPreviewPartIdx = workoutDescriptionModule.workout?.warming_ups.length
      this.workoutPreviewRoundIdx = workoutDescriptionModule.workout?.type === WorkoutTypes.CIRCUIT ? 0 : -1
    }
  }

  togglePreviewMode() {

    workoutDescriptionModule.togglePreviewMode()

    if (workoutDescriptionModule.workout?.id != undefined) {

      if (workoutDescriptionModule.previewMode) {
        workoutPreviewManager.start(workoutDescriptionModule.workout.id, 'regular',
          this.workoutPreviewPartIdx, this.workoutPreviewRoundIdx)
          this.isPreviewActive = true
      } else {
        workoutPreviewManager.stop(workoutDescriptionModule.workout.id, 'regular',
          this.workoutPreviewPartIdx, this.workoutPreviewRoundIdx)
          this.isPreviewActive = false
      } 
      appModule.getPreview(this.isPreviewActive)
    }
  }

  onPreviewUpdate(d: { workoutPartIdx: number; roundIdx?: number }) {

    const { workoutPartIdx, roundIdx } = d

    if (workoutDescriptionModule.workout?.id && workoutDescriptionModule.previewMode) {
      if (workoutPartIdx != this.workoutPreviewPartIdx) {
        workoutPreviewManager.changeWorkoutPart(workoutDescriptionModule.workout.id, 'regular',
          workoutPartIdx, roundIdx)
      } else if (roundIdx != undefined && roundIdx != this.workoutPreviewRoundIdx) {
        workoutPreviewManager.changeRound(workoutDescriptionModule.workout.id, 'regular',
          workoutPartIdx, roundIdx)
      }
    }

    this.workoutPreviewPartIdx = workoutPartIdx
    this.workoutPreviewRoundIdx = roundIdx
  }

  returnBack() {
    return this.$router.back()
  }

  beforeDestroy() {
    if (this.isPreviewActive) {
      this.togglePreviewMode()
    }
  }
}