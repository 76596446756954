import { timersService } from '@/services/timers.service'
import { TimerBaseWorkout, TimerNames, TIMER_TYPE } from '@/types/timer'
import { WorkoutSyncInterface, WorkoutTimeFrame } from '@/types/workouts'
import { Action, Module, Mutation, VuexModule } from 'vuex-class-modules'
import runtimeProcessorModule from './runtime-processor.module'
import store from '../index'

@Module
class TimerActiveModule extends VuexModule implements WorkoutSyncInterface {
    
    startedAt = 0
    workoutPartIdx = 0;
    activeWorkoutTimeFramesArrayIdx = 0
    isItTheLastPart = true;

    public timerWorkout: TimerBaseWorkout | null = null

    get isItStopWatch(): boolean {
        return this.timerWorkout?.id == TimerNames.STOP_WATCH
    }
    
    get isItCountdown(): boolean {
        return this.timerWorkout?.id == TimerNames.COUNTDOWN && 
        this.timerWorkout?.duration as number >= 0
    }

    get isItCircuit(): boolean {

        if (this.timerWorkout != undefined) {
            const { workInterval, restInterval, rounds} = this.timerWorkout as TimerBaseWorkout
            return workInterval != undefined && restInterval != undefined && rounds != undefined
        }
        return false
    }

    get workout() {
        return this.timerWorkout
    }

    get rounds(): number {
        if (this.timerWorkout?.rounds) {
            return this.timerWorkout.rounds
        }
        return -1
    }

    get timeFrames(): WorkoutTimeFrame[] {
        return this.activeWorkoutTimeFramesArray
            .filter((timeFrame: WorkoutTimeFrame, idx) => idx < this.activeWorkoutTimeFramesArrayIdx)
    }

    get activeWorkoutTimeFramesArray(): WorkoutTimeFrame[] {

        const prepTimeFrames: WorkoutTimeFrame[] = [{ duration: 5 }] // Preparation Time
        let timeFrames: WorkoutTimeFrame[] = [] // Preparation Time
        
        if (this.isItStopWatch) {
            timeFrames = [{ duration: 1 }]
        } else if (this.isItCircuit) {
            const { workInterval, restInterval, rounds } = this.workout as TimerBaseWorkout
            timeFrames = timersService.getCircuit(workInterval as number, 
                restInterval as number, 
                rounds as number)
        } else {
            timeFrames = [{
                duration: this.workout?.duration as number,
                workInterval: true
            }]
        }

        return prepTimeFrames.concat(timeFrames)
    }

    get isItFirstTimeFrame(): boolean {
        return this.activeWorkoutTimeFramesArrayIdx < 1
    }

    get totalTime() {
        if (this.isItStopWatch) {
            return 5 // Preparation Time
        }
        return this.activeWorkoutTimeFramesArray.map(timeFrame => timeFrame.duration)
            .reduce((totalTime, time) => totalTime + time, 0)
    }

    get elapsedTime(): number {

        if (this.timeFrames.length > 0) {

            return this.timeFrames.map(timeFrame => timeFrame.duration)
            .reduce((acc, timeFrameDuration) => acc += timeFrameDuration)
        }
        return 0
    }

    get activeTimeFrame() {
        return this.activeWorkoutTimeFramesArray[ this.activeWorkoutTimeFramesArrayIdx ]
    }

    get timeFrameCoordinates() {
        return { timeFrameIdx: this.activeWorkoutTimeFramesArrayIdx }
    }

    get isItTheLastTimeFrame(): boolean {
        return this.activeWorkoutTimeFramesArrayIdx === this.activeWorkoutTimeFramesArray.length - 1
    }

    get isItPreparationTime(): boolean {
        return this.activeWorkoutTimeFramesArrayIdx == 0 && this.activeWorkoutTimeFramesArray.length > 1
    }

    @Mutation
    setWorkout(timerWorkout: TimerBaseWorkout): void {
        this.timerWorkout = timerWorkout
    }
    
    @Mutation
    setWorkoutPartIdx(idx: number): void {
        this.workoutPartIdx = idx
    }

    @Mutation
    setTimeFrameIdx(idx: number): void {
        this.activeWorkoutTimeFramesArrayIdx = idx
    }

    @Mutation
    reset(): void {
        this.timerWorkout = null
        this.activeWorkoutTimeFramesArrayIdx = 0
    }

    @Mutation
    updateStartTime(time: number) {
        this.startedAt = time
    }

    @Action
    init(timerWorkout: TimerBaseWorkout) {
        this.setWorkout(timerWorkout)
    }

    @Action
    start() {
        this.updateStartTime(Date.now())
        this.timeFrameStart()
    }

    @Action
    loadTheNextTimeFrame() {

        if (this.activeWorkoutTimeFramesArrayIdx < this.activeWorkoutTimeFramesArray.length - 1) {

            this.setTimeFrameIdx(this.activeWorkoutTimeFramesArrayIdx + 1)
        } else {

            this.setTimeFrameIdx(0)
        }
    }

    @Action
    timeFrameStart() {

        const { duration } = this.activeTimeFrame

        if (duration > 0) {
            runtimeProcessorModule.changeTimerType(TIMER_TYPE.DOWN)
            runtimeProcessorModule.start(duration)
        } else if (this.isItStopWatch) {
            runtimeProcessorModule.changeTimerType(TIMER_TYPE.UP)
            runtimeProcessorModule.start(duration)
        } else if (!this.isItTheLastTimeFrame){
            this.loadTheNextTimeFrame()
            this.timeFrameStart()
        }
    }

    @Action
    onResume(activeTimeframeSecondsLeft: number) {
        this.updateStartTime((Date.now() - (this.elapsedTime + activeTimeframeSecondsLeft) * 1000))
    }

    @Action
    resetToDefault() {
        this.reset()
    }
}

export const timerActiveModule = new TimerActiveModule({ store, name: 'timerActive' })