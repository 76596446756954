import { VirtualClassApiConfig, VirtualClassCategoryApiConfig, VirtualClassEquipmentApiConfig, VirtualClassTrainerApiConfig, VirtualClassTypeApiConfig, DurationFilter, DURATION_IN_SECONDS, VirtualClassLanguageApiConfig } from '@/types/virtual-classes'
import { Equipment, EQUIPMENT_OTHER } from '@/types/workouts'


class VirtualClassesService {
  filterClassesByName(classes: VirtualClassApiConfig[], searchPhraze: string) {
    if (searchPhraze) {
      return classes.filter(virtualClass => {
        return virtualClass.attributes.name.toLowerCase().includes(searchPhraze.toLowerCase())
      })
    }
    return classes
  }

  filterClassesByDuration(classes: VirtualClassApiConfig[], durationFilters: DurationFilter[]) {
    if (durationFilters.length > 0) {
      return classes.filter(virtualClass => {
        return durationFilters.reduce((result, durationFilter) => {
          if (!result.isFiltered) {
            const virtualClassDuration = virtualClass.attributes[DURATION_IN_SECONDS]
            result.isFiltered = virtualClassDuration > durationFilter.from && virtualClassDuration <= durationFilter.to
          }
          return result
        }, { isFiltered: false }).isFiltered
      })
    }
    return classes
  }

  filterClassesByCategories(classes: VirtualClassApiConfig[], categoryFilters: string[]) {
    if (categoryFilters.length > 0) {
      return classes.filter(virtualClass => {
        return categoryFilters.reduce((result, categoryId) => {
          if (!result.isFiltered) {
            const isVirtualClassFiltered = virtualClass.relationships.categories?.some(category => category.id === categoryId)
            if (isVirtualClassFiltered) {
              result.isFiltered = isVirtualClassFiltered
            }
          }
          return result
        }, { isFiltered: false }).isFiltered
      })
    }
    return classes
  }

  filterClassesByTypes(classes: VirtualClassApiConfig[], typeFilters: string[]) {
    if (typeFilters.length > 0) {
      return classes.filter(virtualClass => {
        return typeFilters.reduce((result, typeId) => {
          if (!result.isFiltered) {
            const isVirtualClassFiltered = virtualClass.relationships.types?.some(type => type.id === typeId)
            if (isVirtualClassFiltered) {
              result.isFiltered = isVirtualClassFiltered
            }
          }
          return result
        }, { isFiltered: false }).isFiltered
      })
    }
    return classes
  }

  filterClassesByEquipmet(classes: VirtualClassApiConfig[], equipmentFilters: string[], virtualClassesEquipments: Equipment[]) {
    if (equipmentFilters.length > 0) {
      return classes.filter(virtualClass => {
        return equipmentFilters.reduce((result, equipmentId) => {
          if (!result.isFiltered) {
            let isVirtualClassFiltered
            if (parseInt(equipmentId) === 0) {
              isVirtualClassFiltered = virtualClass.relationships.equipment?.some(vcEquip => !virtualClassesEquipments
                .some(configEquip => configEquip.name === vcEquip.attributes.name) || vcEquip.attributes.name === EQUIPMENT_OTHER)
            } else {
              isVirtualClassFiltered = virtualClass.relationships.equipment?.some(equipment => equipment.id === equipmentId)
            }
            if (isVirtualClassFiltered) {
              result.isFiltered = isVirtualClassFiltered
            }
          }
          return result
        }, { isFiltered: false }).isFiltered
      })
    }
    return classes
  }

  filterClassesByIntensity(classes: VirtualClassApiConfig[], intensityFilters: string[]) {
    if (intensityFilters.length > 0) {
      return classes.filter(virtualClass => {
        return intensityFilters.reduce((result, intensityId) => {
          if (!result.isFiltered) {
            const isVirtualClassFiltered = virtualClass.relationships.level?.id === intensityId
            if (isVirtualClassFiltered) {
              result.isFiltered = isVirtualClassFiltered
            }
          }
          return result
        }, { isFiltered: false }).isFiltered
      })
    }
    return classes
  }

  filterClassesByTrainers(classes: VirtualClassApiConfig[], trainerFilters: string[]) {

    if (trainerFilters.length > 0) {
      return classes.filter(virtualClass => {
        return trainerFilters.reduce((result, trainerId) => {
          if (!result.isFiltered) {
            const isVirtualClassFiltered = virtualClass.relationships.instructors?.some(trainer => trainer.id === trainerId)
            if (isVirtualClassFiltered) {
              result.isFiltered = isVirtualClassFiltered
            }
          }
          return result
        }, { isFiltered: false }).isFiltered
      })
    }
    return classes
  }

  filterClassesByLanguage(classes: VirtualClassApiConfig[], languageFilters: any[]) {
    if (languageFilters.length > 0) {
      return classes.filter(virtualClass => {
        return languageFilters.reduce((result, languageId) => {
          if (!result.isFiltered) {
            const isVirtualClassFiltered = virtualClass.attributes.language == languageId
            if (isVirtualClassFiltered) {
              result.isFiltered = isVirtualClassFiltered
            }
          }
          return result
        }, { isFiltered: false }).isFiltered
      })
    }
    return classes
  }

  getVirtualClassDurationTag(durationInSeconds: number) {
    if (durationInSeconds) {
      return `${Math.round(durationInSeconds / 60)} min`
    }
    return null
  }
}

export const virtualClassesService = new VirtualClassesService()