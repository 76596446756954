import { CTRL_COMMAND, CTRL_COMMAND_FEEDBACK, FeedbackCtrlCmdMessage } from "@/types/socket"
import { TIMER_TYPE } from "@/types/timer"
import { PAUSE_TIMEOUT, RESUME_TIMEOUT, WorkoutTimeFrame } from "@/types/workouts"
import { ctrlCmdModule } from "../modules/control-commands.module"
import runtimeProcessorModule from "../modules/runtime-processor.module"
import { timerActiveModule } from "../modules/timer-active.module"
import workoutsSyncManager from "./workouts.sync.manager"
import {NotificationCommand, NotificationType} from "@/types/notification";
import {notificationModule} from "../modules/notification.module";

class TimerActiveManager {

    start() {
        const { timerWorkout } = timerActiveModule
        if (timerWorkout) {
            ctrlCmdModule.triggerCtrlCmd({ trigger: CTRL_COMMAND.TIMER_START, value: timerWorkout })
        }
    }

    pause() {
        ctrlCmdModule.triggerCtrlCmd({
            trigger: CTRL_COMMAND.WORKOUT_PAUSE, value: {
                id: timerActiveModule.timerWorkout?.id as string
            }
        })
        const tDialog = window.setTimeout(() => {
            notificationModule.showNotification({ command: NotificationCommand.RESUME_WORKOUT, type: NotificationType.CONFIRM })
            const tResume = window.setTimeout(() => this.resume(), RESUME_TIMEOUT)
            runtimeProcessorModule.changeActionTimeout(tResume)
        }, PAUSE_TIMEOUT)
        runtimeProcessorModule.changeActionTimeout(tDialog)
    }

    stop() {
        ctrlCmdModule.triggerCtrlCmd({ 
            trigger: CTRL_COMMAND.WORKOUT_STOP, value: { 
                id: timerActiveModule.timerWorkout?.id as string
            }
        })
    }

    resume() {
        ctrlCmdModule.triggerCtrlCmd({
            trigger: CTRL_COMMAND.WORKOUT_RESUME, value: {
                id: timerActiveModule.timerWorkout?.id as string
            }
        })
        runtimeProcessorModule.changeActionTimeout(0)
        notificationModule.hideNotification()
    }

    controlCommandsProcessor(cmd: FeedbackCtrlCmdMessage) {

        if (cmd?.event === CTRL_COMMAND_FEEDBACK.WORKOUT_STARTED) {
            timerActiveModule.start()
        } else if (cmd?.event === CTRL_COMMAND_FEEDBACK.WORKOUT_STOPPED) {
            ctrlCmdModule.cleanUp()
            this.destroy()
        } else if (cmd?.event === CTRL_COMMAND_FEEDBACK.WORKOUT_GOT_FORWARD) {
            const { value } = cmd
            const { time } = value
            if (time != undefined) {
                workoutsSyncManager.sync(time, timerActiveModule)
            }
        } else if (cmd?.event === CTRL_COMMAND_FEEDBACK.WORKOUT_PAUSED) {
            runtimeProcessorModule.pause()
            const { value } = cmd
            const { time } = value
            if (time != undefined) {
                workoutsSyncManager.sync(time, timerActiveModule)
            }
        } else if (cmd?.event === CTRL_COMMAND_FEEDBACK.WORKOUT_RESUMED) {
            const { value } = cmd
            const { time } = value
            if (time != undefined) {
                workoutsSyncManager.sync(time, timerActiveModule)
            }
            if (timerActiveModule.isItStopWatch && !timerActiveModule.isItPreparationTime) {
                timerActiveModule.onResume(runtimeProcessorModule.currentTime / 1000)
            } else {
                timerActiveModule.onResume(runtimeProcessorModule.elapsedTimeSeconds)
            }
            runtimeProcessorModule.resume()
        }
        
        ctrlCmdModule.cmdDisposal(cmd.event)
    }

    timeFrameSwitchingProcessor(activeTimeFrame: WorkoutTimeFrame) {

        let { duration } = activeTimeFrame
        
        if (duration > 0) {

            if (timerActiveModule.isItStopWatch && !timerActiveModule.isItPreparationTime) {
                duration = 0
                runtimeProcessorModule.changeTimerType(TIMER_TYPE.UP)
            } else {
                runtimeProcessorModule.changeTimerType(TIMER_TYPE.DOWN)
            }
            runtimeProcessorModule.start(duration)
        }
    }

    syncOverTime(arg: { currentTime: number; prevTimeMarker: number }) {

        const { currentTime, prevTimeMarker } = arg

        const xSecondsLeft = timerActiveModule.isItStopWatch ? Math.floor(currentTime / 1000) : Math.ceil(currentTime / 1000)
        const prevValue = timerActiveModule.isItStopWatch ? Math.floor(prevTimeMarker / 1000) : Math.ceil(prevTimeMarker / 1000)
        const totalTime = runtimeProcessorModule.totalTime / 1000

        if ((timerActiveModule.isItStopWatch || xSecondsLeft < totalTime) && xSecondsLeft != prevValue &&
            xSecondsLeft % 5 === 0 && xSecondsLeft >= 5) { // Every 5 seconds

            const time = this.getCurrentWorkoutTime()

            if (time > timerActiveModule.elapsedTime) {
                
                const diff = time - timerActiveModule.elapsedTime
                let currentTimeCorrection = 0

                if (timerActiveModule.isItStopWatch && !timerActiveModule.isItPreparationTime) {
                    currentTimeCorrection = diff
                    runtimeProcessorModule.currentTimeReset(currentTimeCorrection * 1000)
                } else if (totalTime > diff) {
                    currentTimeCorrection = totalTime - diff
                    runtimeProcessorModule.currentTimeReset(currentTimeCorrection * 1000)
                }
            }
        }
    }

    private getCurrentWorkoutTime(): number {
        return (Date.now() - timerActiveModule.startedAt as number) / 1000
    }

    destroy() {
        runtimeProcessorModule.stop()
        timerActiveModule.resetToDefault()
    }
}

export default new TimerActiveManager()