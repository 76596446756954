import Vue from "vue";
import { appModule } from "@/store/modules/app.module"
import monthlyTrainingWorkoutActive from "@/store/modules/monthly-training-workout-active"
import { monthlyTrainingsModule } from "@/store/modules/monthly-trainings.module"
import { notificationModule } from "@/store/modules/notification.module"
import { schedulesAggregatorModule } from "@/store/modules/schedules-aggregator.module"
import { TRAINING_TRIGGERED_BY } from "@/types/active-training"
import { NotificationCommand, NotificationType } from "@/types/notification"
import { MonthlyTrainingCircuit, WorkoutIntensities } from "@/types/workouts"
import { Route, NavigationGuardNext } from "vue-router"
import {convertTimeToString} from "@/utils/string.utils";
import {workoutsModule} from "@/store/modules/workouts.module";

export default function monthlyTrainingActivePageBeforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>) {

    const routeArgs = to.params as { id: string; triggeredBy?: TRAINING_TRIGGERED_BY }

    const { id } = routeArgs
    let { triggeredBy } = routeArgs

    const monthlyTraining = monthlyTrainingsModule.monthlyTrainings.find(monthlyTraining => monthlyTraining.id === id) 

    if (!triggeredBy) {
        triggeredBy = TRAINING_TRIGGERED_BY.USER
    }

    if (triggeredBy != TRAINING_TRIGGERED_BY.SCHEDULE) {

        const timeToNearestTraining = schedulesAggregatorModule.timeLeftToTheNextTraining()
        if (timeToNearestTraining) {
            if ((monthlyTraining as MonthlyTrainingCircuit).totalTime * 1000 > timeToNearestTraining) {
                notificationModule.showNotification({ command: NotificationCommand.SCHEDULED_TRAINING_WILL_START_SOON, type: NotificationType.ALERT })
                return
            }
        }
    }

    if (monthlyTraining) {
        const workoutCategory = workoutsModule.workoutFitnessCategories
            .filter(category => category.id == monthlyTraining.category)[0]?.attributes.name
        monthlyTrainingWorkoutActive.init({ monthlyTraining , triggeredBy })
        appModule.stopContentRefreshing()
        Vue.gtm.trackEvent({
            event: 'svt-monthly-training-start',
            scheduledStart: triggeredBy == TRAINING_TRIGGERED_BY.SCHEDULE,
            workoutName: monthlyTraining.name,
            workoutDuration: convertTimeToString(monthlyTraining.totalTime * 1000),
            workoutLevel: WorkoutIntensities.get(monthlyTraining.difficulty),
            workoutCategory,
        })
        next()
    }
}