import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules'
import store from '../index'
import { ACTIVE_TRAINING_STATE, TRAINING_TRIGGERED_BY, TRAINING_TYPE } from '@/types/active-training'
import { VirtualClassApiConfig } from '@/types/virtual-classes'
import { ctrlCmdModule } from './control-commands.module'
import { activeTrainingService } from '@/services/active-training.service'
import { CTRL_COMMAND, CTRL_COMMAND_FEEDBACK } from '@/types/socket'
import runtimeProcessorModule from './runtime-processor.module'
import { TIMER_TYPE } from '@/types/timer'
import { NotificationCommand } from '@/types/notification'

const NO_FEEDBACK_TIMEOUT_SEC = 5

@Module
class VirtualClassActiveModule extends VuexModule {
  // fields start
  private _type: TRAINING_TYPE = TRAINING_TYPE.NONE
  private _virtualClass: VirtualClassApiConfig | null = null
  private _status: ACTIVE_TRAINING_STATE = ACTIVE_TRAINING_STATE.INACTIVE
  // fields end

  triggeredBy: TRAINING_TRIGGERED_BY | null = null

  // getters start
  get type() { return this._type }

  get duration() { return activeTrainingService.getVirtualClassTotalTime(this._virtualClass as VirtualClassApiConfig) }
  
  get virtualClass() { return this._virtualClass }
  get status() { return this._status }
  // getters end


  @Mutation
  setTrriggeredBy(triggeredBy: TRAINING_TRIGGERED_BY) {
    this.triggeredBy = triggeredBy
  }

  @Mutation
  private setStatus(status: ACTIVE_TRAINING_STATE) {
    this._status = status
  }

  @Action
  changeStatus(status: ACTIVE_TRAINING_STATE) {
    this.setStatus(status)
  }

  @Action
  init(arg: { virtualClass: VirtualClassApiConfig; triggeredBy: TRAINING_TRIGGERED_BY }) {
    this.setVirtualClass(arg.virtualClass)
    this.setTrriggeredBy(arg.triggeredBy)
  }
  // common actions/mutations end

  // virtual class actions/mutations start
  @Mutation
  private setVirtualClass(virtualClass: VirtualClassApiConfig) {
    this._virtualClass = virtualClass
  }

  @Action
  start() {

    return new Promise((resolve, reject) => {
      if (this.virtualClass !== null) {

        runtimeProcessorModule.changeTimerType(TIMER_TYPE.DOWN)
  
        const { id } = this.virtualClass
        const duration = activeTrainingService.getVirtualClassTotalTime(this.virtualClass)
  
        if (this.triggeredBy == TRAINING_TRIGGERED_BY.USER) {
  
          const feedbackWatcher = ctrlCmdModule.$watch(module => module.virtualTrainingCmdLastFeedback, feedback => {
  
            if (feedback && feedback.event === CTRL_COMMAND_FEEDBACK.LESSON_STARTED) {
  
              feedbackWatcher()
  
              this.onStart(duration)
              ctrlCmdModule.cmdDisposal(feedback.event)
              
              resolve(null)
            }
          })
  
          ctrlCmdModule.triggerCtrlCmd({ trigger: CTRL_COMMAND.LESSON_START, value: { id } })
  
        } else {
  
          this.onStart(duration)
          resolve(null)
        }
      } else {
        reject(new Error(NotificationCommand.SOMETHING_WENT_WRONG))
      }
    })
  }

  @Action
  onStart(duration: number) {
    runtimeProcessorModule.start(duration)
    this.changeStatus(ACTIVE_TRAINING_STATE.PLAYING)
  }

  @Action
  pause() {

    if (this.virtualClass) {

      const feedbackWatcher = ctrlCmdModule.$watch(module => module.virtualTrainingCmdLastFeedback, feedback => {

        if (feedback && feedback.event === CTRL_COMMAND_FEEDBACK.LESSON_PAUSED) {

          feedbackWatcher()

          const { time } = feedback.value

          runtimeProcessorModule.pause()
          runtimeProcessorModule.currentTimeReset(time as number)
          this.changeStatus(ACTIVE_TRAINING_STATE.PAUSED)
          
          ctrlCmdModule.cmdDisposal(feedback.event)
        }
      })

      const { id } = this.virtualClass
      const time = runtimeProcessorModule.currentTime

      ctrlCmdModule.triggerCtrlCmd({ trigger: CTRL_COMMAND.LESSON_PAUSE, value: { id, time } })
    }
  }

  @Action
  resume() {

    if (this.virtualClass) {

      const feedbackWatcher = ctrlCmdModule.$watch(module => module.virtualTrainingCmdLastFeedback, feedback => {

        if (feedback && feedback.event === CTRL_COMMAND_FEEDBACK.LESSON_RESUMED) {

          feedbackWatcher()

          const { time } = feedback.value

          this.changeStatus(ACTIVE_TRAINING_STATE.PLAYING)
          runtimeProcessorModule.currentTimeReset(time as number)
          runtimeProcessorModule.resume()

          ctrlCmdModule.cmdDisposal(feedback.event)
        }
      })

      const { id } = this.virtualClass
      const time = runtimeProcessorModule.currentTime

      ctrlCmdModule.triggerCtrlCmd({ trigger: CTRL_COMMAND.LESSON_RESUME, value: { id, time } })
    }
  }

  @Action
  stop() {

    return new Promise((resolve, reject) => {
      if (this.virtualClass) {
  
        const { id } = this.virtualClass
        ctrlCmdModule.triggerCtrlCmd({ trigger: CTRL_COMMAND.LESSON_STOP, value: { id } })
        resolve(null)
        
      } else {
        reject(new Error(NotificationCommand.SOMETHING_WENT_WRONG))
      }
    })
  }

  @Action
  sync(time: number) {
    runtimeProcessorModule.currentTimeReset(time as number)
  }
  
  @Action
  resetToDefault() {
    this.changeStatus(ACTIVE_TRAINING_STATE.INACTIVE)
    runtimeProcessorModule.stop()
  }
  // virtual class actions/mutations end
}

export const virtualClassActiveModule = new VirtualClassActiveModule({ store, name: 'vitualClassActive' })