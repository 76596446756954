import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import TimerPreviewTileComponent from '../timer-preview-tile/timer-preview-tile.component.vue'
import ExerciseTileComponent from '../exercise-tile/exercise-tile.component.vue'
import { WorkoutWuCd, WorkoutTypes, MssPreviewSlideData } from '@/types/workouts'

@Component({
  components: {
    'timer-preview-tile-component': TimerPreviewTileComponent,
    'exercise-tile-component': ExerciseTileComponent
  }
})
export default class MssCarouselPreviewComponent extends Vue {
  @Prop() slidesData!: MssPreviewSlideData[]
  public fadeInterval = 5000

  public warmingUpTypeName = WorkoutWuCd.WU
  public circuitWorkoutTypeName = WorkoutTypes.CIRCUIT
}