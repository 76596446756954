import { WhiteLabelStyleRule } from '@/types/white-label';

class WhiteLabelService {

  getRules(rulesConig: WhiteLabelStyleRule[], config: { [key: string]: any }, themeEnviroment: { [key: string]: string } = {}, prefix=''): string[] {

    return rulesConig.map((rule: WhiteLabelStyleRule) => {

        const { style } = rule;
        const styleExpNum = style.split(';').length -1
        
        let resultStyle = style
        let resultStyleEpxNum = 0

        Object.keys(config).forEach(elementName => {

            if (rule.elementName == null) {

                const value = config[elementName]
                const replacementResult = resultStyle.replace(new RegExp(':' + elementName, 'g'), value)
                if (replacementResult != resultStyle) {
                    resultStyle = replacementResult
                    resultStyleEpxNum++
                }
                
            } else if (rule.elementName == elementName && 
                typeof config[elementName] == 'object' && 
                typeof themeEnviroment == 'object') {

                Object.keys(config[elementName]).forEach((variableName: string) => {
                    
                    let value = config[elementName][variableName]

                    if (value.startsWith('__') && themeEnviroment[value.slice(2)]) {
                        value = themeEnviroment[value.slice(2)]
                    }

                    const replacementResult = resultStyle.replace(new RegExp(':'+ variableName, 'g'), value)
                    if (replacementResult != resultStyle) {
                        resultStyle = replacementResult
                        resultStyleEpxNum++
                    }
                })
            }
        })
        if (resultStyle != style && resultStyleEpxNum == styleExpNum) {
            return ( prefix ? '.' + prefix : '' ) + rule.selector + '{' + resultStyle + '}'
        } else {
            return ''
        }
    }).filter(rule => rule.length > 0)
  }
}

export const whiteLabelService = new WhiteLabelService()