import Vue from 'vue'
import { Exercise, PreviewSlideData } from '@/types/workouts'
import { Component, Prop } from 'vue-property-decorator'
import ExerciseTileComponent from "@/components/exercise-tile/exercise-tile.component.vue"
import timeformat from "@/filters/timeformat"

@Component({
    components: {
        'exercise-tile-component': ExerciseTileComponent
    },
    filters: {
        timeformat
    }
})
export default class EgymRoundPreviewComponent extends Vue {
    @Prop({ type: Object, default: null}) data!: PreviewSlideData
    @Prop({ type: Boolean, default: true}) collapsed!: boolean

    public swiperOption: any = {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        slidesPerView: 1,
        spaceBetween: 30,
        loop: false,
        speed: 400,
        observer: true,
        observeParents: true
    };

    get workoutSwiper(): any {
        return this.$refs.workoutSwiper
    }

    get activeClassRound() {
        return ['active-round', 'primary-color-text']
    }

    get accordionId() {
        return (this.data.activity_type != undefined ? this.data.activity_type : this.data.type) 
    }

    get exerciseSlides(): Array<Exercise[]> {
        const size = 8
        return this.data?.exercises.map((exercise: Exercise, exerciseIndex: number, exercises: Exercise[]) => {
            const startFrom = size * exerciseIndex
            return (exercises.slice(startFrom , startFrom + size) as Exercise[])
        }).filter((exercises: Exercise[]) => exercises.length > 0)
    }

    nextSlide() {
        this.workoutSwiper.$swiper.slideNext()
    }

    prevSlide() {
        this.workoutSwiper.$swiper.slidePrev()
    }
}