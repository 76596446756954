export function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function pickerOptionsFormat(value: number, index: number, step: number) {
    value *= (index * step)
    return {
        value,
        label: value < 10 ? '0' + value.toString() : value.toString()
    }
}

export function generatePickerOptions(size: number, step: number) {
    return new Array(size/step)
    .fill(1).map((value, idx) => pickerOptionsFormat(value, idx, step))
}