import { httpService } from '@/services/http.service'
import { ThemeInfo } from '@/types/http'
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules'
import store from '../index'

@Module
class ThemeModule extends VuexModule {

  private _themeInfo: { [key: string]: any } = {}

  get themeInfo() {
    return this._themeInfo
  }

  @Mutation
  private setThemeInfo(config: { [key: string]: any } ) {
    this._themeInfo = config
  }

  @Action
  async initialize() {
    this.setThemeInfo(await httpService.styles() as ThemeInfo)
  }
}

export const themeModule = new ThemeModule({ store, name: 'theme' })