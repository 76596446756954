import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules'
import store from '../index'
import { httpService } from '@/services/http.service'
import { Device, DeviceConfigResponse } from '@/types/http'

@Module
class DeviceModule extends VuexModule {
  private devicesEntities: Device[] = []
  private selectedDeviceConfig: DeviceConfigResponse = {} as DeviceConfigResponse

  get devices () {
    return this.devicesEntities
  }

  get devicesExist () {
    return this.devices.length
  }

  get deviceConfig() {
    return this.selectedDeviceConfig
  }

  get icon() {
    // return `/${this.deviceConfig.theme}.png`
    return '/funxtion.png'
  }

  get channelId() {
    if (this.selectedDeviceConfig.cid) {
      return `control/${this.selectedDeviceConfig.cid}`
    } else {
      return ''
    }
  }

  get primeZoneChannelId() {
    if (this.selectedDeviceConfig.cid) {
      return `player/${this.selectedDeviceConfig.cid}`
    } else {
      return ''
    }
  }

  get realtimeUpdatesChannelId() { 
    if (this.selectedDeviceConfig.cid) {
      return `updates/${this.selectedDeviceConfig.cid}`
    } else {
      return ''
    }
  }

  @Mutation
  private setDevices (devices: Device[]) {
    this.devicesEntities = devices
  }

  @Mutation
  private setDeviceConfig(config: DeviceConfigResponse) {
    this.selectedDeviceConfig = config
  }

  @Action
  async receiveDevices () {
    this.setDevices(await httpService.devices())
  }

  @Action
  async initialize() {

    const config = await httpService.deviceConfig()
    this.setDeviceConfig(config)
  }
}

export const deviceModule = new DeviceModule({ store, name: 'device' })