import Vue from 'vue'
import { Component, Emit, Prop, Watch } from 'vue-property-decorator'
import IScroll from 'iscroll'

@Component
export default class ScrollContainerComponent extends Vue {
  @Prop() toWatch: any // eslint-disable-line @typescript-eslint/no-explicit-any

  private waitForUpdate = true
  
  @Emit()
  scroll() {
    return this.iScrollEntity?.y
  }
  public iScrollEntity: IScroll | null = null

  mounted() {
    this.createIScroll()
  }

  @Watch('toWatch')
  onChange() {
    this.waitForUpdate = true
  }

  private createIScroll() {
    this.iScrollEntity = new IScroll('.iscroll-container', {
      scrollX: false,
      scrollY: true,
      mouseWheel: true,
      invertWheelDirection: false,
      scrollbars: false,
      fadeScrollbars: true,
      tap: true,
      snap: true,
      y: 0
    })

    // this.iScrollEntity.on('scrollStart', () => this.scroll() );
    this.iScrollEntity.on('scrollEnd', () => this.scroll() );
  }

  updated() {
    if (this.waitForUpdate) {
      this.iScrollEntity?.refresh()
      this.waitForUpdate = false
    }
  }

  private desctroyIScroll() {
    if (this.iScrollEntity) {
      try {
        this.iScrollEntity.destroy()
      } catch (e) {
        this.iScrollEntity = null
      }
      this.iScrollEntity = null
    }
  }

  beforeDestroy() {
    this.desctroyIScroll()
  }
}