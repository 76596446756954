export enum ACTIVE_TRAINING_STATE {
  INACTIVE = 'Inactive',
  PLAYING = 'Playing',
  PAUSED = 'Paused'
}

export enum TRAINING_TYPE {
  VIRTUAL_CLASS = 'VirtualClass',
  REGULAR_WORKOUT = 'RegularWorkout',
  MSS_WORKOUT = 'MSSWorkout',
  MONTHLY_TRAINING = 'MonthlyTraining',
  NONE = 'None'
}

export enum TRAINING_TRIGGERED_BY {
  USER = 'user',
  SCHEDULE = 'schedule'
}
