import { appModule, CONTENT_REFRESH_IN_SECONDS } from "@/store/modules/app.module"
import { notificationModule } from "@/store/modules/notification.module"
import { schedulesAggregatorModule } from "@/store/modules/schedules-aggregator.module"
import { virtualClassActiveModule } from "@/store/modules/virtual-class-active.module"
import { virtualClassesModule } from "@/store/modules/virtual-classes.module"
import { TRAINING_TRIGGERED_BY } from "@/types/active-training"
import { NotificationCommand, NotificationType } from "@/types/notification"
import { DURATION_IN_SECONDS, VirtualClassApiConfig } from "@/types/virtual-classes"
import {convertTimeToString} from "@/utils/string.utils";
import Vue from "vue";
import { Route, NavigationGuardNext } from "vue-router"

export default function virtualClassActivePageBeforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>) {

    const routeArgs = to.params as { id: string; triggeredBy?: TRAINING_TRIGGERED_BY }

    const { id } = routeArgs
    let { triggeredBy } = routeArgs

    const virtualClass = virtualClassesModule.classes.find(virtualClass => virtualClass.id === id)

    if (!triggeredBy) {
        triggeredBy = TRAINING_TRIGGERED_BY.USER
    }

    if (triggeredBy != TRAINING_TRIGGERED_BY.SCHEDULE) {

        const timeToNearestTraining = schedulesAggregatorModule.timeLeftToTheNextTraining()
        if (timeToNearestTraining) {
            if ((virtualClass as VirtualClassApiConfig).attributes[DURATION_IN_SECONDS] * 1000 > timeToNearestTraining) {
                notificationModule.showNotification({ command: NotificationCommand.SCHEDULED_TRAINING_WILL_START_SOON, type: NotificationType.ALERT })
                return;
            }
        }
    }

    if (virtualClass) {
        const virtualClassDuration = convertTimeToString(
            virtualClass.attributes['duration-in-seconds'] * 1000)
        let virtualClassCategory
        if (Array.isArray(virtualClass.relationships.categories)) {
            virtualClassCategory = virtualClass
                .relationships.categories[0]
                .attributes.name
        }
        let virtualClassLevel
        if (virtualClass.relationships.level != undefined) {
            virtualClassLevel = virtualClass
                .relationships.level
                .attributes.name
        }
        const virtualClassProvider = virtualClass.attributes['provider']

        virtualClassActiveModule.init({ virtualClass, triggeredBy })
        appModule.stopContentRefreshing()
        Vue.gtm.trackEvent({
            event: 'svt-class-start',
            virtualClass: virtualClass.attributes.name,
            scheduledStart: triggeredBy == TRAINING_TRIGGERED_BY.SCHEDULE,
            virtualClassProvider,
            virtualClassDuration,
            virtualClassCategory,
            virtualClassLevel
        })
        next()
    }
}