import Vue from 'vue'
import { Component, Emit } from 'vue-property-decorator'

const CLICKS_BFORE_DEV_MODE = 5
const TIMEOUT_MS = 1500

@Component({})
export default class DevModeButtonComponent extends Vue {

    private timeoutId: number|undefined

    public clicksToTurnDev: number

    get version() {
        return `v ${process.env.VUE_APP_VERSION}`
    }

    constructor() {
        super()
        this.clicksToTurnDev = CLICKS_BFORE_DEV_MODE
    }

    onClick() {
        
        window.clearTimeout(this.timeoutId)
        this.clicksToTurnDev--

        if (this.clicksToTurnDev > 0) {
            this.timeoutId = window.setTimeout(() => this.reset(), TIMEOUT_MS)
        } else {
            this.devmode()
        }
        
    }

    @Emit('devmode')
    devmode() {
        this.reset()
    }

    reset() {
        this.timeoutId = undefined
        this.clicksToTurnDev = CLICKS_BFORE_DEV_MODE
    }
}