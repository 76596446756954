export enum TIMER_TYPE {
    UP = 'countup',
    DOWN = 'countdown'
}

export enum TIME {
    SEC = 'sec'
}

export interface PreSet {
    id: string;
    time?: number;
    work?: number;
    rest?: number;
    rounds?: number;
}

export enum TimerNames {
    INTERVAL = 'interval',
    STOP_WATCH = 'stop-watch',
    COUNTDOWN = 'countdown',
    TABATA = 'tabata'
  }
  

export interface TimerBaseWorkout {
    id: TimerNames;
    duration?: number;
    workInterval?: number;
    restInterval?: number;
    rounds?: number;
}

export interface TimerValues {
    value: number;
    text: string;
}

export enum TimerTypes {
    INTERVAL = 'interval',
    STOP_WATCH = 'stop-watch',
    COUNTDOWN = 'countdown',
    PRE_SET = 'preset'
}

export interface IntervalTimer {
    workInterval: number;
    restInterval: number;
    rounds: number;
}