import Vue from 'vue'
import Component from 'vue-class-component'
import MenuOverlayComponent from './components/menu-overlay/menu-overlay.component.vue'
import { appModule, CONTENT_REFRESH_IN_SECONDS } from './store/modules/app.module'
import { bluredContentModule } from './store/modules/blured-content.module'
import { schedulesAggregatorModule } from './store/modules/schedules-aggregator.module'
import { PAGES } from './types/router'
import { ScheduleTypes, ScheduleWithTime, SpecificScheduleWithTime, MonthlyTrainingScheduleTypes } from './types/schedule'
import { TRAINING_TRIGGERED_BY } from './types/active-training'
import { BaseWorkout, MonthlyTrainingCircuit, MssWorkout } from './types/workouts'
import ModalComponent from './components/modal/modal.component.vue'
import runtimeProcessorModule from './store/modules/runtime-processor.module'
import { monthlyTrainingsModule } from './store/modules/monthly-trainings.module'
import { workoutsModule } from './store/modules/workouts.module'
import { deviceModule } from './store/modules/device.module'
import i18n from './i18n'
import { mssWorkoutsInZoneModule } from './store/modules/mss-workouts-in-zone.module'
import { mssWorkoutsModule } from './store/modules/mss-workouts.module'
import { ctrlCmdModule } from './store/modules/control-commands.module'
import controlCommandsManager from './store/managers/control-commands.manager'
import { virtualClassesModule } from './store/modules/virtual-classes.module'
import EGymAddUserComponent from './components/e-gym-add-user/e-gym-add-user.component.vue'
import LottieAnimation from "lottie-web-vue"
import {eGymTrainingsModule} from "./store/modules/egym-trainings.module";
import {homepageModule} from "./store/modules/homepage.module";


@Component({
  components: {
    'menu-overlay-component': MenuOverlayComponent,
    'modal-component': ModalComponent,
    'egym-add-user-component': EGymAddUserComponent,
    'lottie-animation': LottieAnimation
  }
})
export default class AppComponent extends Vue {

  public idleTimer = 0
  public onClick: any = null

  get blured(): boolean {
    return bluredContentModule.isBlured
  }

  get pending(): boolean {
    return appModule.pendingState
  }

  get isEgymActive() {
    return appModule.eCheckInOn
  }

  get showEgymCheckedInUsers() {
    return !this.blured && !this.pending && 
    ![PAGES.REGULAR_WORKOUT_DESCRIPTION,
      PAGES.WORKOUT_ACTIVE_PAGE,
      PAGES.CELEBRATION
    ].includes(this.$route.name as PAGES)
  }

  get showCheckInAnimation() {
    const isMenuOpen = eGymTrainingsModule.filterMenuState
    return [PAGES.HOME, PAGES.REGULAR_WORKOUT_DESCRIPTION, PAGES.REGULAR_WORKOUTS, PAGES.CELEBRATION].some(pageName => pageName == this.$route.name) &&
    !isMenuOpen && !this.blured && !this.pending
  }

  created() {
    // Content update watcher
    ctrlCmdModule.$watch(module => module.contentUpdateCmd,
      cmd => controlCommandsManager.onContentUpdate(cmd))
      virtualClassesModule.syncWithPlayers()

    if (this.isEgymActive) {
      eGymTrainingsModule.$watch(module => module.workouts, 
        list => list.length > 0 ? homepageModule.changeActiveTab(PAGES.EGYM_TRAINING_PLANS) : null
      )
    }
  }

  mounted() {
    schedulesAggregatorModule.$watch(schedule => schedule.trainingsOfTheDay, trainingsOfTheDay => this.trainingsOfTheDayUpdate(trainingsOfTheDay))
    deviceModule.$watch(module => module.deviceConfig, deviceConfig => {
      const {lang} = deviceConfig
      if (lang != undefined && this.$i18n.availableLocales.indexOf(lang) >= 0) {
        i18n.locale = lang
      }
    })
    this.initTimeout()
  }

  startIdleTimer() {
    const NO_IDLE_PAGES = [ PAGES.WORKOUT_ACTIVE_PAGE, PAGES.MONTHLY_TRAINING_ACTIVE,
      PAGES.MSS_WORKOUT_ACTIVE_PAGE, PAGES.ACTIVE_VIRTUAL_CLASS, PAGES.TIMERS_ACTIVE, 
      PAGES.HOME, PAGES.LOGIN, PAGES.DEVICE_CHANGING, PAGES.DEVMONITOR ]

    if (NO_IDLE_PAGES.indexOf(this.$route.name as PAGES) >= 0 || appModule.previewState) {
      clearTimeout(this.idleTimer)
    } else {
      clearTimeout(this.idleTimer)
      this.idleTimer = window.setTimeout(() => {
          this.$router.push({ name: PAGES.HOME })
      }, 60 * 1000 * 5)
    }
  }

  initTimeout() {
    if (this.onClick) return

    const ROOT_TAG = document.getElementById('app')
    this.onClick = ROOT_TAG?.addEventListener('click', () => this.startIdleTimer())
  }

  trainingsOfTheDayUpdate(upcomingTrainings: ScheduleWithTime[]) {

    if (upcomingTrainings.length > 0) {
      
      upcomingTrainings.forEach(async (nextTraining) => {
        const { category } = nextTraining
        const { training } = (nextTraining as SpecificScheduleWithTime<BaseWorkout>)
        const { id } = training

        if (category == ScheduleTypes.WORKOUT) {

          const exists = workoutsModule.workouts.findIndex(workout => workout.id === id) >= 0

          if (!exists) {
            await workoutsModule.updateWorkout(id)
          }
        } else if (category == ScheduleTypes.MSS_WORKOUT) {

          const exists = mssWorkoutsInZoneModule.workouts.findIndex(workout => workout.id === id) >= 0

          if (!exists) {
            await mssWorkoutsInZoneModule.updateWorkout(id)
            await mssWorkoutsModule.updateWorkout(id)
          }
        }
      })

      let nextTrainingIdx = 0

      while (nextTrainingIdx < upcomingTrainings.length && upcomingTrainings[nextTrainingIdx].time < Date.now()) {
        nextTrainingIdx++
      }

      if (nextTrainingIdx < upcomingTrainings.length) {

        const nextTraining = upcomingTrainings[nextTrainingIdx]

        appModule.beforeTheNextTrainingTimeout(window.setTimeout(
          () => {
            if (!runtimeProcessorModule.isItInProcess) {
              const { category } = nextTraining
              const { training } = (nextTraining as SpecificScheduleWithTime<BaseWorkout>)
              const { id } = training
              const triggeredBy = TRAINING_TRIGGERED_BY.SCHEDULE
              const monthlyTrainingScheduleTypes = [MonthlyTrainingScheduleTypes.AC_LONG, MonthlyTrainingScheduleTypes.AC_SHORT, MonthlyTrainingScheduleTypes.MOVE_IT_LONG,
              MonthlyTrainingScheduleTypes.MOVE_IT_SHORT, MonthlyTrainingScheduleTypes.RUMBLE_LONG, MonthlyTrainingScheduleTypes.RUMBLE_SHORT,
              MonthlyTrainingScheduleTypes.SBTK_LONG, MonthlyTrainingScheduleTypes.SBTK_SHORT]

              if (monthlyTrainingScheduleTypes.some(scheduleType => scheduleType === category)) {

                this.$router.push({ name: PAGES.MONTHLY_TRAINING_ACTIVE, params: { id, triggeredBy } })
              
              } else if (category == ScheduleTypes.WORKOUT) {

                this.$router.push({ name: PAGES.WORKOUT_ACTIVE_PAGE, params: { id, triggeredBy } })

              } else if (category == ScheduleTypes.MSS_WORKOUT) {

                this.$router.push({ name: PAGES.MSS_WORKOUT_ACTIVE_PAGE, params: { id, triggeredBy } })

              } else {
                this.$router.push({ name: PAGES.ACTIVE_VIRTUAL_CLASS, params: { id, triggeredBy } })
              }
            }
          },
          nextTraining.time - Date.now()
        ))
      }
    } else {
      appModule.beforeTheNextTrainingTimeout(null)
    }
  }

  playerResponseCheck() {
    window.setTimeout(() => {

      /*if (!runtimeProcessorModule.isItInProcess &&
        (appModule.lastPlayerContactTimestamp < 1 || (Date.now() - appModule.lastPlayerContactTimestamp > CONTENT_REFRESH_IN_SECONDS * 1000))) {
        notificationModule.showNotification({ command: NotificationCommand.NO_PLAYER_FEEDBACK, type: NotificationType.ALERT })
      }
      this.playerResponseCheck()*/
    }, 15 * 1000)
  }

  switchToDevMode() {
    appModule.switchDevModeTo(true)
    window.setTimeout(() => appModule.switchDevModeTo(false), 60 * 1000)
  }

  onLogout() {
    workoutsModule.resetToDefault()
    mssWorkoutsModule.resetToDefault()
    mssWorkoutsInZoneModule.resetToDefault()
    appModule.resetToDefault()
    this.$router.push({ name: PAGES.LOGIN })
  }
}