import Vue from 'vue'
import { Component, Emit } from 'vue-property-decorator'
import { Credentials } from '@/types/http'
import { appModule } from '@/store/modules/app.module'

@Component
export default class LoginFormComponent extends Vue {

  public formData: Credentials = {
    email: '',
    password: ''
  }

  get pending() {
    return appModule.pendingState
  }

  @Emit()
  submit(evt: Event) {
    evt.preventDefault()
    return this.formData
  }
}