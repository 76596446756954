import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules'
import store from '../index'
import { BaseWorkout, EQUIPMENT_OTHER, MssWorkout, MssWorkoutType, MssWorkoutTypeNames, WorkoutDurationFilter, WorkoutEquipmentFilter, WorkoutFilterType, WorkoutFitnessCategoryFilter, WorkoutFitnessTypeFilter, WorkoutIntensities, WorkoutIntensityFilter, WorkoutTrainerFilter, WorkoutTypeFilter, WorkoutBodyPartFilter, BodyParts } from '@/types/workouts'
import { DurationFilterLong, DurationFilterMedium, DurationFilterShort } from '@/types/state/general-filter'
import { workoutsModule } from './workouts.module'
import workoutService from '@/services/workout.service'
import { hasOwnProp } from '@/utils/object.utils'

@Module
class AllWorkoutsModule extends VuexModule {
  private _workouts: BaseWorkout[] = []

  get workouts() { return this._workouts }

  get categories(): WorkoutFitnessCategoryFilter[] {
    const list = this._workouts.map(workout => workout.category)
    return list.map((cateoryId, idx) => {

      const fitnessCategoryName = workoutService.getFitnessCategoryNameById(cateoryId, workoutsModule.workoutFitnessCategories)

      if (!fitnessCategoryName || list.indexOf(cateoryId) !== idx) {
         return null
      }
      return {
        id: cateoryId.toString(),
        type: WorkoutFilterType.FITNESS_CATEGORY,
        name: fitnessCategoryName
      } as WorkoutFitnessCategoryFilter
    }).filter((categoryFilter) => categoryFilter !=null) as WorkoutFitnessCategoryFilter[]
  }

  get fitnessTypes(): WorkoutFitnessTypeFilter[] {

    const list = this._workouts.map(workout => workout.fitness_type)
    return list.map((fitnessTypeId, idx) => {

      const fitnessTypeName = workoutService.getFitnessTypeNameById(fitnessTypeId, workoutsModule.workoutFitnessTypes)

      if (!fitnessTypeName || list.indexOf(fitnessTypeId) != idx) {
         return null
      }
      return {
        id: fitnessTypeId.toString(),
        type: WorkoutFilterType.FITNESS_TYPE,
        name: fitnessTypeName
      } as WorkoutFitnessTypeFilter

    }).filter(item => item != null) as WorkoutFitnessTypeFilter[]
  }

  get types(): WorkoutTypeFilter[] {

    if (this._workouts.length < 1) {
      return []
    }
    
    const list = this._workouts.map(workout => {
      
      if ( hasOwnProp(workout, 'circuit_mss')) {

        if (!(workout as MssWorkout).circuit_mss) {
          return MssWorkoutType.MSS_BOUTIQUE
        } else {
          return MssWorkoutType.MSS_CIRCUIT
        }
      }
      return workout.type.toString()
    })

    return list.filter((workoutTypeName, idx) => {
      return list.indexOf(workoutTypeName) === idx
    }).map(workoutTypeName => {

      const result = {
        id: workoutTypeName,
        type: workoutTypeName,
        name: ''
      }

      if (workoutTypeName == MssWorkoutType.MSS_BOUTIQUE) {
        result.name = MssWorkoutTypeNames.get(MssWorkoutType.MSS_BOUTIQUE) as string
      } else if (workoutTypeName == MssWorkoutType.MSS_CIRCUIT) {
        result.name =  MssWorkoutTypeNames.get(MssWorkoutType.MSS_CIRCUIT) as string
      } else {
        result.name = workoutTypeName
      }

      return result as WorkoutTypeFilter
    })
    
  }

  get equipment(): WorkoutEquipmentFilter[] {

    if (this._workouts.length < 1) {
      return []
    }

    const commonEquipmentNames = workoutsModule.workoutEquipments.map(e => e.name)

    const list = this._workouts.map(workout => workout.gearList).reduce((prevValue, cValue) => prevValue.concat(cValue))
    .map(equipmentName => commonEquipmentNames.indexOf(equipmentName) >= 0 ? equipmentName : EQUIPMENT_OTHER)
    
    return list.filter((workoutEquipment, idx) => {
      return list.indexOf(workoutEquipment) === idx
    }).map(equipmentName => {
      return {
        id: equipmentName,
        type: WorkoutFilterType.EQUIPMENT,
        name: equipmentName
      }
    }) as WorkoutEquipmentFilter[]
  }

  get trainers(): WorkoutTrainerFilter[] {

    if (this._workouts.length < 1) {
      return []
    }

    const list = this._workouts.map(workout => workout.creatorName)
    return list.filter((workoutTrainer, idx) => list.indexOf(workoutTrainer) === idx && workoutTrainer).map(trainerName => {
      return {
        id: trainerName,
        type: WorkoutFilterType.TRAINER,
        name: trainerName
      }
    }) as WorkoutTrainerFilter[]
  }

  get intensity(): WorkoutIntensityFilter[] {

    const list = this._workouts.map(workout => workout.difficulty).filter(difficulty => difficulty != '')
    return list.filter((workoutDifficulty, idx) => list.indexOf(workoutDifficulty) === idx).map(workoutDifficulty => {
      return {
        id: workoutDifficulty,
        type: WorkoutFilterType.INTENSITY,
        name: WorkoutIntensities.get(workoutDifficulty) as string
      }
    })
  }

  get bodyPart(): WorkoutBodyPartFilter[] {

    if (this._workouts.length < 1) {
      return []
    }
    
    const commonBodyParts: string[] = [BodyParts.FULL_BODY, BodyParts.LOWER_BODY, BodyParts.UPPER_BODY, BodyParts.CORE]


    const list = this._workouts.map(workout => workout.bodytypes).reduce((prevValue, cValue) => prevValue.concat(cValue))
    .map(bodyPart => commonBodyParts.indexOf(bodyPart) >= 0 ? bodyPart : null)

    return list.filter((workoutBodyPart, idx) => {
      return list.indexOf(workoutBodyPart) === idx
    }).map(bodyPartName => {
      return {
        id: bodyPartName,
        type: WorkoutFilterType.BODY_PART,
        name: bodyPartName
      }
    }) as WorkoutBodyPartFilter[]
  }

  get durations(): WorkoutDurationFilter[] {
    
    return [DurationFilterShort, DurationFilterMedium, DurationFilterLong].filter(durationFilter => {

      let workoutIdx = 0
      let filterIsAvailable = false
      while (!filterIsAvailable && workoutIdx < this._workouts.length) {

        const workout = this._workouts[workoutIdx]
        filterIsAvailable = workout.duration > durationFilter.from && workout.duration < durationFilter.to
        workoutIdx++
      }
      return filterIsAvailable
    }) as WorkoutDurationFilter[]
  }

  @Mutation
  private setAllWorkouts(workouts: BaseWorkout[]) {
    this._workouts = workouts.sort((workoutA, workoutB) => {
      if (workoutA.assignedAt != workoutB.assignedAt) {
        return workoutA.assignedAt < workoutB.assignedAt ? 1 : -1
      }
      return 0
    })
  }

  @Action
  initialize({ regularWorkouts, mssWorkouts }: { regularWorkouts: BaseWorkout[]; mssWorkouts: BaseWorkout[] }) {
    this.setAllWorkouts(regularWorkouts.slice().concat(mssWorkouts.slice()))
  }
}

export const allWorkoutsModule = new AllWorkoutsModule({ store, name: 'allWorkouts' })