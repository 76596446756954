export const SUBSCRIBE = 'subscribe'
export const MESSAGE = 'message'

export enum CommandType {
  SUBSCRIBE = 'subscribe',
  MESSAGE = 'message'
}

export interface SocketMessage {
  command: string;
  channel: string;
  payload: string;
}

export interface SocketMessagePayloadResponse {
  event: string;
  sentAt?: number;
  value: PayloadValue;
}

export interface SocketMessagePayloadRequest {
  trigger: string;
  sentAt?: number;
  value: PayloadValue;
}

export interface PayloadValue {
  id: string;
  time?: number;
  scheduled?: boolean;
  workoutPartIdx?: number;
  timeFrameIdx?: number;
}

export interface CtrlCmdMessage {
  trigger: CTRL_COMMAND;
  value: PayloadValue | TimerPayloadValue | WorkoutPreviewPayloadValue;
  sentAt?: number;
}

export interface FeedbackCtrlCmdMessage {
  event: CTRL_COMMAND_FEEDBACK;
  value: PayloadValue;
  sentAt?: number;
}

export interface TimerPayloadValue {
  id: string;
  duration?: number;
  workInterval?: number;
  restInterval?: number;
  rounds?: number;
}

export interface WorkoutPreviewPayloadValue {
  id: string;
  type: string;
  workoutPartIdx: number;
  workoutRoundIdx?: number;
}

export enum CTRL_COMMAND {

  LESSON_START = 'lessonstart',
  LESSON_PAUSE = 'lessonpause',
  LESSON_RESUME = 'lessonresume',
  LESSON_STOP = 'lessonstop',
  WORKOUT_START = 'workout-start',
  WORKOUT_PAUSE = 'workout-pause',
  WORKOUT_RESUME = 'workout-resume',
  WORKOUT_STOP = 'workout-stop',
  WORKOUT_FORWARD = 'workout-forward',
  WORKOUT_BACKWARD = 'workout-backward',
  WORKOUT_LOAD = 'workout-load',
  WORKOUT_PREVIEW_START = 'workout-preview-start',
  WORKOUT_PREVIEW_STOP = 'workout-preview-stop',
  WORKOUT_PREVIEW_CHANGE_ROUND = 'workout-preview-change-round',
  WORKOUT_PREVIEW_CHANGE_PART = 'workout-preview-change-part',
  MONTHLY_START = 'monthly-start',
  MSS_START = 'mss-start',
  PING = 'ping',
  TIMER_START = 'timer-start',
  REALTIME_WORKOUT_UPDATE = 'workout-realtime-update',
  REALTIME_WORKOUT_MSS_UPDATE = 'workout-mss-realtime-update',
  CELEBRATION_START = 'celebration-start',
  UPDATE_EGYM_TRAINING_PLANS = 'egym-training-plans-update',
  RESET_EGYM_TRAINING_PLANS = 'egym-training-plans-reset'
}

export enum CTRL_COMMAND_FEEDBACK {

  LESSON_STARTED = 'lessonstarted',
  LESSON_PAUSED = 'lessonpaused',
  LESSON_RESUMED = 'lessonresumed',
  LESSON_STOPPED = 'lessonstopped',
  LESSON_SYNC = 'lessonsync',
  WORKOUT_STARTED = 'workout-started',
  WORKOUT_PAUSED = 'workout-paused',
  WORKOUT_RESUMED = 'workout-resumed',
  WORKOUT_STOPPED = 'workout-stopped',
  WORKOUT_GOT_FORWARD = 'workout-got-forward',
  WORKOUT_GOT_BACKWARD = 'workout-got-backward',
  WORKOUT_READY_TO_START = 'workout-ready-to-start',
  REALTIME_UPDATE_SUCCESS = 'realtime-update-success',
  PONG = 'pong',
  CELEBRATION_GOT_STARTED = 'celebration-got-started'
}