import { Module, VuexModule, Action, Mutation } from "vuex-class-modules";
import store from '../index'
import { DurationFilter, DURATION_IN_SECONDS, VirtualClassApiConfig, VirtualClassCategoryApiConfig, VirtualClassEquipmentApiConfig, VirtualClassIntensityApiConfig, VirtualClassTrainerApiConfig, VirtualClassTypeApiConfig, VirtualClassLanguageApiConfig, THUMBNAIL_SMALL_URL } from '@/types/virtual-classes';
import { virtualClassesService } from '@/services/virtual-classes.service';
import { DurationFilterLong, DurationFilterMedium, DurationFilterShort } from "@/types/state/general-filter";
import { Equipment } from "@/types/workouts";
import { getUniqueMetrics } from "@/utils/virtual-class.utils";
import Vue from "vue";
import { CACHE_SECTION } from "@/services/cache.service";

@Module
class VirtualClassesOverviewModule extends VuexModule {

  public sourceClassesList: VirtualClassApiConfig[] = []

  public classesToRenderList: Array <VirtualClassApiConfig | string> = []

  pageSize = 16

  currentPage = 1

  get lazyRenderingSliceSize() { return this.pageSize * this.currentPage }

  get filteredClasses() { return this.sourceClassesList.slice(0, this.lazyRenderingSliceSize) }

  get categories(): VirtualClassCategoryApiConfig[] {
    return getUniqueMetrics(this.sourceClassesList, 'categories') as VirtualClassCategoryApiConfig[]
  }

  get types(): VirtualClassTypeApiConfig[] {
    return getUniqueMetrics(this.sourceClassesList, 'types') as VirtualClassTypeApiConfig[]
  }

  get equipment(): VirtualClassEquipmentApiConfig[] {
    return getUniqueMetrics(this.sourceClassesList, 'equipment') as VirtualClassEquipmentApiConfig[]
  }

  get trainers(): VirtualClassTrainerApiConfig[] {
    return getUniqueMetrics(this.sourceClassesList, 'instructors') as VirtualClassTrainerApiConfig[]
  }

  get intensity(): VirtualClassIntensityApiConfig[] {
    return getUniqueMetrics(this.sourceClassesList, 'level') as VirtualClassIntensityApiConfig[]
  }

  get durations() {
    
    return [DurationFilterShort, DurationFilterMedium, DurationFilterLong].filter(durationFilter => {

      let classIdx = 0
      let filterIsAvailable = false
      while (!filterIsAvailable && classIdx < this.sourceClassesList.length) {

        const virtualClass = this.sourceClassesList[classIdx]
        filterIsAvailable = virtualClass.attributes[DURATION_IN_SECONDS] > durationFilter.from && virtualClass.attributes[DURATION_IN_SECONDS] < durationFilter.to
        classIdx++
      }
      return filterIsAvailable
    })
  }

  @Mutation
  changePage(page: number) {
    this.currentPage = page
  }

  @Mutation
  changePageSize(pageSize: number) {
    this.pageSize = pageSize
  }

  @Mutation
  private updateClassesList(classes: VirtualClassApiConfig[]) {
    this.sourceClassesList = classes
  }

  @Action
  applyFilters(arg: {
    classes: VirtualClassApiConfig[];
    virtualClassEquipments: Equipment[];
    searchPhraze: string;
    durationFilters: DurationFilter[];
    categoriesFilters: string[];
    typeFilters: string[];
    equipmentFilters: string[];
    intensityFilters: string[];
    trainerFilters: string[];
    languageFilters: string[];
  }) {

    let filteredClasses = arg.classes.slice()
    if (arg.searchPhraze) {
      filteredClasses = virtualClassesService.filterClassesByName(filteredClasses, arg.searchPhraze)
    }
    if (arg.durationFilters.length) {
      filteredClasses = virtualClassesService.filterClassesByDuration(filteredClasses, arg.durationFilters)
    }
    if (arg.categoriesFilters.length) {
      filteredClasses = virtualClassesService.filterClassesByCategories(filteredClasses, arg.categoriesFilters)
    }
    if (arg.typeFilters.length) {
      filteredClasses = virtualClassesService.filterClassesByTypes(filteredClasses, arg.typeFilters)
    }
    if (arg.equipmentFilters.length) {
      filteredClasses = virtualClassesService.filterClassesByEquipmet(filteredClasses, arg.equipmentFilters, arg.virtualClassEquipments)
    }
    if (arg.intensityFilters.length) {
      filteredClasses = virtualClassesService.filterClassesByIntensity(filteredClasses, arg.intensityFilters)
    }
    if (arg.trainerFilters.length) {
      filteredClasses = virtualClassesService.filterClassesByTrainers(filteredClasses, arg.trainerFilters)
    }
    if (arg.languageFilters.length) {
      filteredClasses = virtualClassesService.filterClassesByLanguage(filteredClasses, arg.languageFilters)
    }
    this.refreshClassesList(filteredClasses)
  }


  @Action
  initialize(classes: VirtualClassApiConfig[]) {
    this.refreshClassesList(classes)
  }

  @Action
  nextPage() {
    if (this.sourceClassesList.length > this.lazyRenderingSliceSize) {
      this.changePage(this.currentPage + 1)
    }
  }

  @Action
  refreshClassesList(classes: VirtualClassApiConfig[]) {
    Promise.all(classes.map(async (virtualClass) => {
      try {
        virtualClass.attributes[THUMBNAIL_SMALL_URL] =
          await Vue.prototype.$cacheService.match(CACHE_SECTION.VITRUAL_CLASSES, virtualClass.attributes[THUMBNAIL_SMALL_URL])
      } catch (err) {
        // eslint-disable-next-line
        console.error(err)
      }
      return virtualClass
    })).then(virtualClasses => {
      this.updateClassesList(virtualClasses)
      this.changePage(1)
    })
  }
}

export const virtualClassesOverviewModule = new VirtualClassesOverviewModule({ store, name: 'virtualClassesOverview' })