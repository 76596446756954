import Vue from 'vue'
import store from '../index'
import { Module, VuexModule, Action, Mutation } from 'vuex-class-modules'
import { CTRL_COMMAND_FEEDBACK, CTRL_COMMAND, SUBSCRIBE, MESSAGE, CtrlCmdMessage, SocketMessage, FeedbackCtrlCmdMessage, CommandType } from '@/types/socket'
import { controlCommandsService } from '@/services/control-commands.service'
import { deviceModule } from './device.module'


@Module
class CtrlCmdModule extends VuexModule {
  

  msgPool: (CtrlCmdMessage | FeedbackCtrlCmdMessage)[] = []

  get pingPong(): FeedbackCtrlCmdMessage | undefined {
    return this.msgPool.find((cmd) => (cmd as FeedbackCtrlCmdMessage).event === CTRL_COMMAND_FEEDBACK.PONG) as FeedbackCtrlCmdMessage
  }

  get workoutCmdLastFeedback(): FeedbackCtrlCmdMessage | undefined {

    const workoutCtrlCmdList = [
      CTRL_COMMAND_FEEDBACK.WORKOUT_STOPPED, CTRL_COMMAND_FEEDBACK.WORKOUT_STARTED,
      CTRL_COMMAND_FEEDBACK.WORKOUT_PAUSED, CTRL_COMMAND_FEEDBACK.WORKOUT_RESUMED,
      CTRL_COMMAND_FEEDBACK.WORKOUT_GOT_FORWARD, CTRL_COMMAND_FEEDBACK.WORKOUT_GOT_BACKWARD ]

    return this.msgPool.find((cmd) => workoutCtrlCmdList.indexOf((cmd as FeedbackCtrlCmdMessage).event) >= 0) as FeedbackCtrlCmdMessage
  }

  get virtualTrainingCmdLastFeedback() {
    
    const virtualTrainingCtrlCmdList = [
      CTRL_COMMAND_FEEDBACK.LESSON_PAUSED, CTRL_COMMAND_FEEDBACK.LESSON_RESUMED,
      CTRL_COMMAND_FEEDBACK.LESSON_STOPPED, CTRL_COMMAND_FEEDBACK.LESSON_STARTED, CTRL_COMMAND_FEEDBACK.LESSON_SYNC ]

    return this.msgPool.filter((cmd) => virtualTrainingCtrlCmdList.indexOf((cmd as FeedbackCtrlCmdMessage).event) >= 0).shift() as FeedbackCtrlCmdMessage
  }

  get contentUpdateCmd() {
    return this.msgPool.find((cmd) => [
      CTRL_COMMAND.REALTIME_WORKOUT_UPDATE, CTRL_COMMAND.REALTIME_WORKOUT_MSS_UPDATE
    ].indexOf((cmd as CtrlCmdMessage).trigger) >= 0) as CtrlCmdMessage
  }

  get celebrationCmd() {
    return this.msgPool.find((cmd) => [
      CTRL_COMMAND_FEEDBACK.CELEBRATION_GOT_STARTED
    ].indexOf((cmd as FeedbackCtrlCmdMessage).event) >= 0) as FeedbackCtrlCmdMessage
  }

  @Mutation
  private poolMsgDisposal(type: CTRL_COMMAND | CTRL_COMMAND_FEEDBACK) {

    this.msgPool = this.msgPool.filter(msg => {
      return ((msg as CtrlCmdMessage).trigger && (msg as CtrlCmdMessage).trigger !== type) &&
        ((msg as FeedbackCtrlCmdMessage).event && (msg as FeedbackCtrlCmdMessage).event !== type)
    })
  }

  @Mutation
  private cleanUpThePool() {
    this.msgPool = []
  }


  @Action
  cmdDisposal(cmd: CTRL_COMMAND | CTRL_COMMAND_FEEDBACK) {
    this.poolMsgDisposal(cmd)
  }

  @Action
  subscribe(channelId: string) {

    const msg = controlCommandsService.formUpAMessage(
      channelId, CommandType.SUBSCRIBE)

    this.send(msg)
  }
  

  @Action
  triggerCtrlCmd(payload: CtrlCmdMessage) { // must be replaced by "sendMessage" in the future

    payload.sentAt = Date.now()

    const msg = controlCommandsService.formUpAMessage(
      deviceModule.primeZoneChannelId, CommandType.MESSAGE, payload)

    this.send(msg)
  }

  @Action
  sendMessage(config: {channelId: string; payload: any}) { // this action will replace "triggerCtrlCmd"

    const {payload, channelId} = config
    payload.sentAt = Date.now()

    const msg = controlCommandsService.formUpAMessage(
      channelId, CommandType.MESSAGE, payload)

    Vue.prototype.$socket.send(msg)
  }

  @Action
  cleanUp() {
    this.cleanUpThePool()
  }

  @Action
  send(msg: string) {
    if (Vue.prototype.$socket) {
      Vue.prototype.$socket.send(msg)
    }
  }
}

export const ctrlCmdModule = new CtrlCmdModule({ store, name: 'controlCommandModule' })