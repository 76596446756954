import {TIMER_TYPE} from "@/types/timer";
import {eGymTrainingsModule} from "../modules/egym-trainings.module";
import {eGymUsersModule} from "../modules/egym-users.module";
import {EGymWorkoutData} from "@/types/e-gym";
import {httpService} from "@/services/http.service";

class EgymDataManager
{
    async sendData()
    {
        const report = {
            exercises: eGymTrainingsModule.timeframesTrace,
            users: eGymUsersModule.usersCheckedIn.map(user => user.rfid)
        } as EGymWorkoutData;
        httpService.egymReportSend(report).catch(() => eGymTrainingsModule.setBacklog(report))
            .finally(() =>
            {
                eGymTrainingsModule.resetToDefault();
                eGymUsersModule.resetToDefault();
            });
    }
}

export default new EgymDataManager;