import { WorkoutTimeFrame } from "@/types/workouts"

class TimersService {

    getCircuit(workInterval: number, restInterval: number, rounds: number): WorkoutTimeFrame[] {

        return (new Array(rounds)).fill(0).map((roundNumber, roundIdx, rounds) => {
            const timeFrames: WorkoutTimeFrame[] = [{
                duration: workInterval,
                workInterval: true,
                roundIdx
            }]
            if (roundIdx < rounds.length-1) {
                timeFrames.push({
                    duration: restInterval,
                    restInterval: true,
                    roundIdx
                })
            }
            return timeFrames
        }).reduce((prevRound, currentRound) => {
            return prevRound.concat(currentRound)
        })
    }
}

export const timersService = new TimersService()