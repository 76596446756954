import { VirtualClassApiConfig, VirtualClassCategoryApiConfig, VirtualClassEquipmentApiConfig, VirtualClassTrainerApiConfig, VirtualClassTypeApiConfig, VirtualClassLanguageApiConfig } from "@/types/virtual-classes"

export function getUniqueMetrics(classes: VirtualClassApiConfig[], merticName: string): (VirtualClassCategoryApiConfig | VirtualClassEquipmentApiConfig | VirtualClassTrainerApiConfig | VirtualClassTypeApiConfig | VirtualClassLanguageApiConfig)[] {
  
  if (classes.length < 1) {
    return []
  }
  const metrics = classes.map(virtualClass => {
  
      const relationTypes = [
        'categories',
        'instructors',
        'types',
        'equipment',
      ]
  
      if (relationTypes.indexOf(merticName) >= 0 &&
        (virtualClass.relationships as { [key: string]: any })[merticName] != null) {
        return (virtualClass.relationships as { [key: string]: any })[merticName] as (VirtualClassCategoryApiConfig | VirtualClassEquipmentApiConfig | VirtualClassTrainerApiConfig | VirtualClassTypeApiConfig)[]
      } else if (merticName == 'level' && virtualClass.relationships.level != null) {
        return [ virtualClass.relationships.level ]
      } else if (merticName == 'language' && virtualClass.attributes.language != null) {
        const id = virtualClass.attributes.language as any
        const name = virtualClass.attributes.language as any
        return [{
          id: id,
          type: merticName,
          attributes: {
            id: id,
            name: name
          }
        }]
      }
      return []
    }).reduce((previousValue, currentValue) => {
        return previousValue.concat(currentValue)
      }
    )
  
    return metrics.filter((mertic: VirtualClassCategoryApiConfig | VirtualClassEquipmentApiConfig | VirtualClassTrainerApiConfig | VirtualClassTypeApiConfig | VirtualClassLanguageApiConfig, fIdx: number) => {
      return metrics.findIndex((m: VirtualClassCategoryApiConfig | VirtualClassEquipmentApiConfig | VirtualClassTrainerApiConfig | VirtualClassTypeApiConfig | VirtualClassLanguageApiConfig, idx: number) => m.id == mertic.id && idx > fIdx) < 0
    })
  }
