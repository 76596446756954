import { DurationFilterLong, DurationFilterMedium, DurationFilterShort } from '@/types/state/general-filter'
import { CategoryFilter, DurationFilter, DURATION_IN_SECONDS, EquipmentFilter,
  FilterType,
  IntensityFilter, TrainerFilter, TypeFilter, VirtualClassCategoryApiConfig, VirtualClassEquipmentApiConfig, VirtualClassIntensityApiConfig, VirtualClassTypeApiConfig } from '@/types/virtual-classes'
import { Module, VuexModule, Action, Mutation } from 'vuex-class-modules'
import store from '../index'
import { virtualClassesOverviewModule } from './virtual-classes-overview.module'

@Module
class VirtualClassesFiltersModule extends VuexModule {
  
  public durationFilters: DurationFilter[] = []
  public categoriesFilters: string[] = []
  public typeFilters: string[] = []
  public equipmentFilters: string[] = []
  public intensityFilters: string[] = []
  public trainerFilters: string[] = []
  public languageFilters: string[] = []
  public phraze = ''


  get searchPhraze() { return this.phraze }

  get isFiltersActive() {
    return this.durationFilters.length > 0 ||
      this.categoriesFilters.length > 0 ||
      this.typeFilters.length > 0 ||
      this.equipmentFilters.length > 0 ||
      this.intensityFilters.length > 0 ||
      this.trainerFilters.length > 0 || 
      this.languageFilters.length > 0
  }

  get isSearchFilterActive() {
    return this.searchPhraze.length > 0
  }

  @Mutation
  setFilter(filter: { type: FilterType; id: string }) {
    const { type, id } = filter

    if (type === FilterType.DURATION) {
      
      const filterIdx = this.durationFilters.findIndex((filter) => filter.id == id)

      if (filterIdx >= 0) {
        this.durationFilters.splice(filterIdx, 1)
      } else {

        const durations = [DurationFilterShort, DurationFilterMedium, DurationFilterLong]
        const durationIdx = durations.findIndex(d => d.id == id)
        this.durationFilters.push(durations[durationIdx])
      }
    }
    else if (type === FilterType.TYPE) {

      const filterIdx = this.typeFilters.findIndex((filterId) => filterId == id)

      if (filterIdx >= 0) {
        this.typeFilters.splice(filterIdx, 1)
      } else {
        this.typeFilters.push(id)
      }
    }
    else if (type === FilterType.EQUIPMENT) {
      const filterIdx = this.equipmentFilters.findIndex((filterId) => filterId == id)

      if (filterIdx >= 0) {
        this.equipmentFilters.splice(filterIdx, 1)
      } else {
        this.equipmentFilters.push(id)
      }
    }
    else if (type === FilterType.LANGUAGE) {

      const filterIdx = this.languageFilters.findIndex((filterId) => filterId == id)

      if (filterIdx >= 0) {
        this.languageFilters.splice(filterIdx, 1)
      } else {
        this.languageFilters.push(id)
      }
    }
    else if (type === FilterType.INTENSITY) {
      const filterIdx = this.intensityFilters.findIndex((filterId) => filterId == id)

      if (filterIdx >= 0) {
        this.intensityFilters.splice(filterIdx, 1)
      } else {
        this.intensityFilters.push(id)
      }
    }
    else if (type === FilterType.TRAINER) {
      const filterIdx = this.trainerFilters.findIndex((filterId) => filterId == id)

      if (filterIdx >= 0) {
        this.trainerFilters.splice(filterIdx, 1)
      } else {
        this.trainerFilters.push(id)
      }
    }
    else if (type === FilterType.CATEGORY) {
      const filterIdx = this.categoriesFilters.findIndex((filterId) => filterId == id)

      if (filterIdx >= 0) {
        this.categoriesFilters.splice(filterIdx, 1)
      } else {
        this.categoriesFilters.push(id)
      }
    }
  }

  @Mutation
  private setSearchPhraze(searchPhraze: string) {
    this.phraze = searchPhraze
  }

  @Mutation
  private clear() {

    this.durationFilters = []
    this.categoriesFilters = []
    this.typeFilters = []
    this.equipmentFilters = []
    this.intensityFilters = []
    this.trainerFilters = []
    this.languageFilters = []
    this.phraze = ''
  }

  @Action
  updateFilter(config: {id: string; type: FilterType}) {
    this.setFilter(config)
  }

  @Action
  updateSearchPhraze(newPhraze: string) {
    this.setSearchPhraze(newPhraze)
  }

  @Action
  reset() {
    this.clear()
  }
}

export const virtualClassesFiltersModule = new VirtualClassesFiltersModule({ store, name: 'virtualClassesFilters' })